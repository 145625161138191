import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import MachineMasterValidation from './validation'
import { useTranslation } from 'react-i18next'
import useStores from '../../hooks'
import { IitemMaster } from './model'

const MachineMaster = observer((): JSX.Element => {

    const { t } = useTranslation()
    const sideNavAccordian: any = t("sideNavAccordian");

    const navigate = useNavigate();
    const { machineMasterStore, outletManagerStore } = useStores()

    const { Mcode } = useParams();

    const [localFields, setLocalFiedls] = useState<IitemMaster>(new IitemMaster());

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [errors, setError] = useState<any>({})


    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFiedls({ ...localFields, [name]: name === "manufact_year" || name === "machineValue" ? Number(value) : value })
    }

    async function handleSubmit() {
        setLoading(true)
        let error: any = {};
        error = MachineMasterValidation(localFields);
        setError(error)

        if (Object.keys(error)?.length === 0) {
            localFields.mid = 0;
            const status = await machineMasterStore.machineMasterSave(localFields)

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    async function initialFetchApi() {
        setLoading(false)
        if (Mcode) {
            await machineMasterStore.getMachineMasterList();
        } else {
            const entryNos = await outletManagerStore.getEntryNo('Machine');
            setLocalFiedls({ ...localFields, mcode: (entryNos?.entryNo + 1)?.toString() })
        }
    }

    function handleSuccessClose() {
        navigate('/Admin/MachineMaster/MachineMasterDetail')
    }

    const isInitialRender = useRef(true);


    useEffect(() => {
        if (isInitialRender.current) {
            initialFetchApi();
            isInitialRender.current = false;
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])

    useEffect(() => {
        if (machineMasterStore?.machineMasterList?.length) {

            let findObj = machineMasterStore?.machineMasterList?.find((obj) => obj?.Mcode === Number(Mcode))
            if (findObj?.Mcode) {
                let entryDet: IitemMaster = {
                    mcode: findObj?.Mcode,
                    machineName: findObj?.MachineName,
                    machineNo: findObj?.MachineNo,
                    machineMake: findObj?.MachineMake,
                    machinemodelno: findObj?.ModelNo,
                    doi: findObj?.DOI,
                    description: findObj?.Description,
                    manufact_year: findObj?.Manufact_year,
                    department: findObj?.Department,
                    machineValue: findObj?.MachineValue,
                    maint_Card_No: findObj?.Maint_Card_No,
                    hsn: findObj?.HSN,
                }
                setLocalFiedls(entryDet)
            }
        }
    }, [Mcode])

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <div>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox'>
                        <div className='hrBox' >
                            <h3>{sideNavAccordian?.machineMaster}</h3>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className="row">
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>Code <span>*</span></label>
                                            <input type="text" className=''
                                                name='mcode'
                                                value={localFields?.mcode}
                                                disabled
                                                style={{ width: "100%" }} placeholder='mcode...'></input>
                                            {errors.mcode && <p style={{ color: 'red' }}>{errors.mcode}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Name <span>*</span></label>
                                            <input type="text" className=''
                                                name='machineName'
                                                value={localFields?.machineName}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Name..'></input>
                                            {errors.machineName && <p style={{ color: 'red' }}>{errors.machineName}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Machine.No <span>*</span></label>
                                            <input type="text" className=''
                                                name='machineNo'
                                                value={localFields?.machineNo}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Machine No..'></input>
                                            {errors.machineNo && <p style={{ color: 'red' }}>{errors.machineNo}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Make <span>*</span></label>
                                            <input type="text" className=''
                                                name='machineMake'
                                                value={localFields?.machineMake}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Make..'></input>
                                            {errors.machineMake && <p style={{ color: 'red' }}>{errors.machineMake}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Model No <span>*</span></label>
                                            <input type="text" className=''
                                                name='machinemodelno'
                                                value={localFields?.machinemodelno}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Model No..'></input>
                                            {errors.machinemodelno && <p style={{ color: 'red' }}>{errors.machinemodelno}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>DOI <span>*</span></label>
                                            <input type="date" style={{ width: '100%' }}
                                                name="doi"
                                                onChange={handleInputChange} value={localFields.doi = localFields?.doi ?? new Date().toISOString().substr(0, 10)}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                            ></input>
                                            {errors.doi && <p style={{ color: 'red' }}>{errors.doi}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Description <span>*</span></label>
                                            <textarea className=''
                                                name='description'
                                                value={localFields?.description}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Description..'></textarea>
                                            {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Manufacturer Year <span>*</span></label>
                                            <input type="text" className=''
                                                name='manufact_year'
                                                value={localFields?.manufact_year}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Manufacturer Year..'></input>
                                            {errors.manufact_year && <p style={{ color: 'red' }}>{errors.manufact_year}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Department <span>*</span></label>
                                            <input type="text" className=''
                                                name='department'
                                                value={localFields?.department}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Department..'></input>
                                            {errors.department && <p style={{ color: 'red' }}>{errors.department}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Machine Value <span>*</span></label>
                                            <input type="text" className=''
                                                name='machineValue'
                                                value={localFields?.machineValue}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Machine Value..'></input>
                                            {errors.machineValue && <p style={{ color: 'red' }}>{errors.machineValue}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Maintenance Card No <span>*</span></label>
                                            <input type="text" className=''
                                                name='maint_Card_No'
                                                value={localFields?.maint_Card_No}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Maintenance Card No..'></input>
                                            {errors.maint_Card_No && <p style={{ color: 'red' }}>{errors.maint_Card_No}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>HSN/SAC</label>
                                            <input type="text" className=''
                                                name='hsn'
                                                value={localFields?.hsn}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder=''></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleSuccessClose}>List</button>
                                    <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <Modal show={isSuccessModal} onHide={handleSuccessClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            }
        </>
    )
})

export default MachineMaster;