import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import PackingPlanValidation from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { useTranslation } from 'react-i18next';
import { PackingPlanSave } from './model';
import useStores from '../../hooks';
import { QRCode } from 'react-qrcode-logo';
import moment from 'moment';

const PackingPlan = observer((): JSX.Element => {

    const { t } = useTranslation()
    const sideNavAccordian: any = t("sideNavAccordian");

    const navigate = useNavigate();
    const { commonStore, outletManagerStore, machineMasterStore } = useStores()
    const { loadTeamDetail, loadTeamDetails, loadItemMaster, loadItemMasters, loadOwnItemMaster, loadOwnItemMasters } = commonStore;

    const { Packingid } = useParams()

    const [localFields, setLocalFiedls] = useState<PackingPlanSave>(new PackingPlanSave());
    const [submitArr, setSubmitArr] = useState<any[]>([]);

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [errors, setError] = useState<any>({})

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFiedls({ ...localFields, [name]: name === "qty" ? Number(value) : value })
    }

    function handleMenuDropDowns(name: string, val: any) {
        if (val !== null && name == "productid") {
            setLocalFiedls({ ...localFields, productid: val?.itemid, unitid: val?.uid?.toString(), productName: val?.itemname, itemUOM: val?.uom, expiryDays: val?.expiryDays })
        } else if (val !== null) {
            setLocalFiedls({ ...localFields, [name]: name == "teamid" ? val?.teamMasterId : name === "machineid" ? val?.Mcode : val?.id })
        } else {
            setLocalFiedls({ ...localFields, [name]: "" })
        }
    }

    function handleAddFunc() {
        let error: any = {};
        error = PackingPlanValidation(localFields);
        setError(error)

        if (Object.keys(error)?.length === 0) {

            const newDate = new Date();
            newDate.setDate(newDate.getDate() + Number(localFields?.expiryDays));

            localFields.batchNo = localFields.teamid!.toString() + '/'
                + localFields!.packingdate!.replace(/[: -]/g, '') + '/'
                + localFields.productid?.toString() + '/'
                + moment(newDate).format('YYYY-MM-DD').replace(/[: -]/g, '') + '/'
                + localFields?.qty


            setSubmitArr([...submitArr, localFields])
            setLocalFiedls({ ...localFields, productName: '', itemUOM: '', qty: 0 })
        }
    }

    function handleRemove(index) {
        submitArr?.splice(index, 1)
        setSubmitArr([...submitArr])
    }

    async function handleSubmit() {
        setLoading(true)
        if (submitArr?.length) {
            const status = await machineMasterStore.packingPlanSave(submitArr)
            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        } else {
            let error: any = {};
            error.length = "Add Table Data"
            setError(error)
            setLoading(false)
        }
    }

    function handleSuccessClose() {
        navigate('/Admin/PackingPlan/PackingPlanList')
    }

    async function initialFetchApi() {
        setLoading(false)
        const [entryNos] = await Promise.all([outletManagerStore.getEntryNo('Packing'), loadTeamDetail(), loadOwnItemMaster(), machineMasterStore.getMachineMasterList()]);
        setLocalFiedls({ ...localFields, packingid: (entryNos?.entryNo + 1) })
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            initialFetchApi();
            isInitialRender.current = false;
        }
        return () => { };
    }, [])

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <div>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox'>
                        <div className='hrBox' >
                            <h3>{sideNavAccordian?.packingPlan}</h3>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className="row">
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>No <span>*</span></label>
                                            <input type="text" className=''
                                                name='packingid'
                                                value={localFields?.packingid}
                                                disabled
                                                style={{ width: "100%" }} placeholder='Code...'></input>
                                            {errors.packingid && <p style={{ color: 'red' }}>{errors.packingid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Date <span>*</span></label>
                                            <input
                                                value={localFields.packingdate = localFields?.packingdate ?? new Date().toISOString().substr(0, 10)}
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                type="date" style={{ width: '100%' }}
                                                name="packingdate"
                                                onChange={handleInputChange}
                                                placeholder="DD-MMM-YYYY" data-date="DD MM YYYY" data-date-format="DD MM YYYY"
                                            ></input>
                                            {errors.packingdate && <p style={{ color: 'red' }}>{errors.packingdate}</p>}
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Team <span>*</span></label>
                                            <AutoComplete
                                                clsName='autoComplete full-width'
                                                placeholder="Select Team.."
                                                emitOption={(val) => handleMenuDropDowns('teamid', val)}
                                                options={loadTeamDetails}
                                                getOptionLabel={'teamMasterName'}
                                            />
                                            {errors.teamid && <p style={{ color: 'red' }}>{errors.teamid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {machineMasterStore?.machineMasterList?.length > 0 &&
                                            <div className='inputBox'>
                                                <label>Machine/Manual <span>*</span></label>
                                                <AutoComplete
                                                    clsName='autoComplete full-width'
                                                    placeholder="Select Machine/Manual.."
                                                    emitOption={(val) => handleMenuDropDowns('machineid', val)}
                                                    options={machineMasterStore?.machineMasterList}
                                                    getOptionLabel='MachineName'
                                                />
                                                {errors.machineid && <p style={{ color: 'red' }}>{errors.machineid}</p>}
                                            </div>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Product <span>*</span></label>
                                            <AutoComplete
                                                clsName='autoComplete full-width'
                                                placeholder="Select Product.."
                                                emitOption={(val) => handleMenuDropDowns('productid', val)}
                                                options={loadOwnItemMasters}
                                                value={localFields?.productName}
                                                getOptionLabel='itemname'
                                            />
                                            {errors.productid && <p style={{ color: 'red' }}>{errors.productid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Qty <span>*</span></label>
                                            <input type="text" className=''
                                                name='qty'
                                                value={localFields?.qty}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Qty..'></input>
                                            {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>UOM <span>*</span></label>
                                            <input type="text" className=''
                                                name='unitid'
                                                value={localFields?.itemUOM}
                                                disabled
                                                style={{ width: "100%" }} placeholder='UOM..'></input>
                                            {errors.unitid && <p style={{ color: 'red' }}>{errors.unitid}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <button className='dfBtn' type='submit' onClick={handleAddFunc}>Add</button>
                                    </div>
                                </div>

                                <div className='vertical-space-20'></div>
                                <div className='vertical-space-20'></div>
                                <div className="tableListDetails">
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">UOM</th>
                                                    <th scope="col">Batch No</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {submitArr?.length ? submitArr?.map((submitObj, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{submitObj?.productName}</td>
                                                            <td>{submitObj?.qty}</td>
                                                            <td>{submitObj?.itemUOM}</td>
                                                            <td><QRCode value={submitObj?.batchNo} size={100} /> </td>
                                                            <td><button style={{
                                                                border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                                                borderRadius: '6px', fontSize: '12px'
                                                            }} type='submit'
                                                                onClick={() => handleRemove(index)}>Remove</button></td>
                                                        </tr>
                                                    )
                                                }) :
                                                    <div>
                                                        No data
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>

                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleSuccessClose}>List</button>
                                    <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <Modal show={isSuccessModal} onHide={handleSuccessClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            }
        </>
    )
})

export default PackingPlan
