import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { IOutletStockAnalysis } from './model';
import { Autocomplete, TextField, ThemeProvider, createTheme } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Modal } from 'react-bootstrap';
import { ISalesRegister, ISalesRegisterDetails, ISalesRegisterRequest } from './model';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { currentPageDatas } from '../../common/shared/utils';

export interface ExcelSheetData {
  Outlet_Name?: string;
  POS_Id?: number,
  Bill_No?: string;
  Bill_Date?: string;
  Bill_Type?: string,
  Customer_Type?: string,
  M_Id?: number,
  Member_Name?: string,
  Mobile_No?: string;
  Item_Id?: number,
  Item_Name?: string,
  Categry?: string,
  HSN_Code?: string,
  Barcode?: string,
  Batch_No?: string,
  Remarks?: string,
  Qty?: number,
  Unit_Name?: string,
  Free_Qty?: number,
  Rate?: number,
  Amount?: number,
  Discount_Value?: number,
  Discount_Per?: number,
  CGSTP?: number,
  CGST_Value?: number,
  SGSTP?: number,
  SGST_Value?: number,
  Total_Value?: number,
  Cash_Amount?: number,
  Redeem_Amount?: number,
  Sales_Return?: number,
  Card_Amount?: number,
  QR_Code_Amount?: number,
  Online_Store_Order?: number,
  Online_Bank_TR?: number,
  Employee_Name?: string,
  Create_DateTime?: string
}

const SalesRegister = observer((): JSX.Element => {

  const { salesRegisterStore, posMasterStore, invoiceStore, commonStore } = useStores();

  const { loadOutlets, loadOutlet } = commonStore;

  const [isLoading, setLoading] = useState(true);
  const [view, setView] = useState(false);
  const [print, setPrint] = useState(false);

  const [salesRegisterRequest, setSalesRegisterRequest] = useState<ISalesRegisterRequest>(new ISalesRegisterRequest())

  const [salesRegisterDetails, setSalesRegisterDetails] = useState<ISalesRegisterDetails[]>([]);
  const [posInvoiceDetails, setPosInvoiceDetails] = useState<any>({})

  console.log(posInvoiceDetails, 'posInvoiceDetails>>')
  const viewClose = () => setView(false);

  const viewShow = async (posid: any, flag: 'POSMASTER' | 'INVOICEMASTER') => {
    var res = await salesRegisterStore.loadOutletItemsDetails(posid, flag);
    setSalesRegisterDetails(res);
    setView(true);
  }

  const handleSalesRegister = async (e) => {
    e.preventDefault();
    setLoading(true)
    await salesRegisterStore.loadOutletItems(salesRegisterRequest);
    setLoading(false)
  }

  async function createExportExcelObj(stockReportDetails: any[]) {
    const salesRegisterData = await salesRegisterStore.loadOutletItemsExport(salesRegisterRequest);
    console.log(salesRegisterData, 'salesRegisterData');
  
    let excelArr: ExcelSheetData[] = salesRegisterData?.map((stockReport) => {
      const excelObj: ExcelSheetData = {
        Outlet_Name: stockReport?.Outletname,
        POS_Id: stockReport?.posid,
        Bill_No: stockReport?.Billno,
        Bill_Date: stockReport?.Billdate,
        Bill_Type: stockReport?.BillType,
        Customer_Type: stockReport?.cust_type,
        M_Id: stockReport?.mid,
        Member_Name: stockReport?.membername,
        Mobile_No: stockReport?.Mobileno,
        Item_Id: stockReport?.Itemid,
        Item_Name: stockReport?.itemname,
        Categry: stockReport?.Category,
        HSN_Code: stockReport?.HSNCode,
        Barcode: stockReport?.Barcode,
        Batch_No: stockReport?.batch_no,
        Remarks: stockReport?.Remarks,
        Qty: stockReport?.Qty,
        Unit_Name: stockReport?.Unitname,
        Free_Qty: stockReport?.freeqty,
        Rate: stockReport?.rate,
        Amount: stockReport?.Amount,
        Discount_Value: stockReport?.Discountvalue,
        Discount_Per: stockReport?.DiscountPer,
        CGSTP: stockReport?.CGSTP,
        CGST_Value: stockReport?.CGSTValue,
        SGSTP: stockReport?.SGSTP,
        SGST_Value: stockReport?.SGSTValue,
        Total_Value: stockReport?.Totalvalue,
        Cash_Amount: stockReport?.CashAmount,
        Redeem_Amount: stockReport?.RedeemAmount,
        Sales_Return: stockReport?.SalesReturn,
        Card_Amount: stockReport?.CardAmount,
        QR_Code_Amount: stockReport?.QRcodeAmount,
        Online_Store_Order: stockReport?.Onlinestoreorder,
        Online_Bank_TR: stockReport?.OnlinebankTR,
        Employee_Name: stockReport?.Employee_Name,
        Create_DateTime: stockReport?.createdatetime,
      };
      return excelObj;
    });
  
    exportToExcel(excelArr);
  }
  

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Sales Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Sales Register ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const printClose = () => setPrint(false);
  const printShow = async (type, posid) => {
    if (type === "Invoice") {
      const invoiceDetails = await invoiceStore?.getInvoiceBillPrint(posid)
      if (invoiceDetails) {
        setPosInvoiceDetails(invoiceDetails)
      }
    } else {
      const invoiceDetails = await posMasterStore.getInvoiceDetails(posid)
      if (invoiceDetails) {
        setPosInvoiceDetails(invoiceDetails)
      }
    }
    setPrint(true)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalesRegisterRequest({ ...salesRegisterRequest, [name]: value })
  }

  const handleOutlets = (name: any, event: object, val: any) => {
    if (val != null) {
      setSalesRegisterRequest({ ...salesRegisterRequest, outletId: val.outletid })
      Cookies.set('outletName', val.outletName);
    } else {
      salesRegisterRequest.outletId = 0;
    }
  }

  async function fetchOultetItems() {
    await loadOutlet();
    salesRegisterRequest.outletId = Number(Cookies.get('outletId'));
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchOultetItems();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  function calcRoundOff() {
    const total = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const decimalValue = Math.floor(total);
    const roundOff = total - decimalValue;
    const roundoffValue = total % 1 >= 0.5 ? `- ${roundOff?.toFixed(2)}` : `+ ${roundOff?.toFixed(2)}`
    return roundoffValue
  }

  function calcTotalAmt() {
    const total: number = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const roundedTotal = Math.round(total);
    const totalAmount = roundedTotal + Number(posInvoiceDetails?.deliverycharge);

    return totalAmount;
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (salesRegisterStore?.outletItemDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(salesRegisterStore?.outletItemDetails)
    }
  }, [salesRegisterStore?.outletItemDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(salesRegisterStore?.outletItemDetails, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  const convertArrayToMap = (students: any[]): Map<string, any[]> => {
    return students?.reduce((map, student) => {
      if (!map.has(student?.taxper)) {
        map.set(student?.taxper, []);
      }
      map.get(student?.taxper)!.push(student);
      return map;
    }, new Map<string, any[]>());
  };

  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Sales Register</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='ItemInwardInputBox'>
                    <div className='row'>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>From Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="startDate" onChange={handleInputChange} value={salesRegisterRequest?.startDate}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>To Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="endDate" onChange={handleInputChange} value={salesRegisterRequest?.endDate}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                        </div>
                        <div className='vertical-space-20'></div>
                      </div>
                      <div className='col-sm-3' style={{ marginTop: '17px' }}>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadOutlets}
                            getOptionLabel={(option: any) => option.outletName}
                            onChange={(event, val) => handleOutlets('outletId', event, val)}
                            value={loadOutlets.find((option) => option.outletName === Cookies.get('outletName'))}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '35ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Outlet Name..'
                                name='outletName'
                              />}
                          />
                        </div>
                        {/* <div className='vertical-space-10'></div> */}
                      </div>
                      <div className="col-sm-5">
                        <div className='btnBox' style={{ marginTop: '23px' }}>
                          <button className='secondaryBtn'
                            onClick={() => createExportExcelObj(salesRegisterStore.outletItemDetails)}
                          >Excel</button>
                          <button className='secondaryBtn' type='submit' onClick={handleSalesRegister}>Generate</button>
                        </div>
                      </div>
                    </div>
                    <div className='vertical-space-10'></div>
                    <div className='row'>
                      <div className='col-sm-5'>
                        <div className='btnBox'>
                          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{salesRegisterStore?.outletItemDetails?.length} Records</button>
                        </div>
                      </div>
                      <div className=' col-sm-7' >
                        <Stack spacing={2}>
                          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                        </Stack>
                      </div>
                    </div>
                    <div className='tableListDetails'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th scope="col">Bill No</th>
                            <th scope="col"> Date</th>
                            <th scope="col">Counter No  </th>
                            <th scope="col">Sales Person </th>
                            <th scope="col">No Of Items</th>
                            <th scope="col">Bill Value</th>
                            <th scope="col">Net Amount</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.length > 0 &&
                            currentPageData?.map((val, key) => {
                              console.log(currentPageData, 'currentPageData>>')
                              return (
                                <tr key={key}>
                                  <td>{val.type}</td>
                                  <td>{val.billno}</td>
                                  <td>{moment(val.billdate).format('DD-MMM-YYYY')} </td>
                                  <td>5</td>
                                  <td>{val.employeename}</td>
                                  <td>{val.nos} </td>
                                  <td>{val.billvalue} </td>
                                  <td>{val.netvalue}</td>
                                  <td><button onClick={(e: any) => viewShow(val.posid, 'INVOICEMASTER')} className='tablemodeltext'>Invoice</button></td>
                                  <td><button onClick={(e: any) => viewShow(val.posid, 'POSMASTER')} className='tablemodeltext'>POS</button></td>
                                  <td><button onClick={() => printShow(val.type, val?.posid)} className='tablemodeltext'>Print</button></td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <Modal show={view} onHide={viewClose} className='PriceHistoryModel'>
                      <Modal.Header closeButton>
                        <Modal.Title>View</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">ItemName</th>
                                <th scope="col"> Qty</th>
                                <th scope="col">Rate </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                salesRegisterDetails.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.itemname}</td>
                                      <td>{val.qty}</td>
                                      <td>{val.rate}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal show={print} onHide={printClose} className='PriceHistoryModel'>
                      <Modal.Header closeButton>
                        <Modal.Title>Print</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div id='printablediv' className='printablediv'>

                          <div className='Details Success'>
                            <div id='printablediv' className='printablediv'>
                              <div className="address">
                                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Ganga Super Market</h6>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Namakkal, 33 - Tamil Nadu, 637002</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>GSTIN / UIN: 33BZUPS6837H1ZL</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>FSSAI: 10020042007183</p>
                              </div>
                              <div className="taxinvoice">
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Tax Invoice</span>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.salesorderno}</span>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.billdate}</span>
                                </div>
                                <p style={{ fontFamily: 'system-ui', fontWeight: '700', marginTop: '12px' }} className='invoiceSpan'>{posInvoiceDetails?.paymentmode}</p>
                              </div>
                              <div className="Customer" style={{ marginTop: '0px' }}>
                                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Customer:{posInvoiceDetails.memberName}</h6>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Dr.No.: {posInvoiceDetails?.doorNo}{" "}{posInvoiceDetails.area}</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{`${posInvoiceDetails?.city}, ${posInvoiceDetails?.state}, ${posInvoiceDetails?.pinCode}`}</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Mobile: {posInvoiceDetails?.mobileNo}</p>
                              </div>
                              <hr />
                              <h5 className="address">SKU - HSNC - Item Description</h5>
                              <table className="itemtable"  >
                                <thead>
                                  <tr >
                                    <th className="heading">Item</th>
                                    <th className="heading">Qty</th>
                                    <th className="heading">MRP</th>
                                    <th className="heading">Price</th>
                                    <th className="heading">Disc</th>
                                    <th className="heading">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {convertArrayToMap(posInvoiceDetails?.posbilldetailsprint)?.size > 0 ?
                                    Array.from(convertArrayToMap(posInvoiceDetails?.posbilldetailsprint))?.map(([key, value], ind) => {
                                      return (
                                        <>
                                          <div style={{ color: 'black', padding: '10px 0px 0px 16px', fontWeight: '700', fontSize: '15px' }}>
                                            {`${ind + 1}) CGST ${key ?? 0}% & SGST ${key ?? 0}%`}
                                          </div>
                                          {value?.map((val, index) => {
                                            return val?.qty > 0 && (
                                              <tr key={index}>
                                                <td style={{ padding: '0px 15px' }}>
                                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.hsncode}</div>
                                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.itemid === 0 ? "Free" : val.itemname}</div>
                                                </td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.qty}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.mrp}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.sellingPrice}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.discountvalue}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.amount}</td>
                                              </tr>
                                            )
                                          })}
                                        </>
                                      )
                                    }) : null}
                                </tbody>
                              </table>
                              <hr style={{ margin: 0 }} />
                              <table className="amounttable">
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Qty: {posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.qty!, 0)}</td>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Items: {posInvoiceDetails?.posbilldetailsprint?.length}</td>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Delivery Charges</td>
                                  <td></td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.deliverycharge}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Round off:</td>
                                  <td></td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcRoundOff()}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Redeem Amount</td>
                                  <td> </td>
                                  <td></td>
                                  <td style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.redeemPointsamt}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0 }} className="tablesummeryvalue">Amount Paid</td>
                                  <td> </td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcTotalAmt()?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, }} className="tablesummeryvalue">You Saved Today</td>
                                  <td> </td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>
                                    {(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.discountvalue!, 0 + Number(posInvoiceDetails?.offerdiscount)))?.toFixed(2)}
                                  </td>
                                </tr>
                              </table>
                              <table className="itemtable"  >
                                <thead>
                                  <tr>
                                    <th className="heading">S.No</th>
                                    <th className="heading">Taxable <br /> amount</th>
                                    <th className="heading">CGST</th>
                                    <th className="heading">SGST</th>
                                    <th className="heading">IGST</th>
                                    <th className="heading">CESS</th>
                                    <th className="heading">Total <br /> amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {posInvoiceDetails?.posbilltaxdetailsprint?.map((val, key) => {
                                    return (
                                      <tr key={key}>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{key + 1}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount - val?.taxvalue)?.toFixed(2)}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.cgstvalue}` + `(${val?.cgstper}%)`}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.sgstvalue}` + `(${val?.sgstper}%)`}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount)?.toFixed(2)}</td>
                                      </tr>
                                    )
                                  })
                                  }
                                  <tr style={{ margin: '1rem 0', color: 'inherit', border: 0, borderTop: '1px solid rgb(11,56,93, 0.25)' }}></tr>
                                  <tr>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + (v?.amount! - v.taxvalue!), 0))?.toFixed(2)}</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.cgstvalue!, 0)}</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.sgstvalue!, 0)}</td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0))?.toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="bottom">
                                <h4>Thank You</h4>
                                <p>Customer Care: 9043426746</p>
                              </div>
                            </div>
                          </div>

                          <div className="bottomDate">
                            <p>{moment().format('DD-MM-YYYY')}</p>
                          </div>

                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </>

      }
    </>

  )
})

export default SalesRegister;
