import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { IOutletStockAnalysis } from './model';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { IInwardRegister, } from './model';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { currentPageDatas } from '../../common/shared/utils';

interface ExcelSheetData {
  Item_Name?: string;
  Bill_No?: number;
  Bill_Date?: string;
  Rate?: number;
  Qty?: number;
  Supplier?: string;
}
const InwardRegister = observer((): JSX.Element => {
  const { inwardRegisterStore, commonStore } = useStores();

  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({})

  const [inwardRegister, setInwardRegister] = useState<IInwardRegister>(new IInwardRegister());
  const [searchValue, setSearchValue] = useState('');

  const filteredData = useMemo(() => {
    if (searchValue != '' && searchValue?.length > 0 && inwardRegisterStore?.inwardDetails?.length > 0) {
      return inwardRegisterStore?.inwardDetails?.filter((stock) => {
        if (stock?.itemName?.toLowerCase().includes(searchValue?.toLowerCase())) {
          return stock;
        }
      })
    } else {
      return [];
    }
  }, [searchValue])


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInwardRegister({ ...inwardRegister, [name]: value });
  }

  const generateReport = async () => {
    if (inwardRegister?.fromDate && inwardRegister?.toDate && inwardRegister?.warehouseId) {
      setLoading(true);
      await inwardRegisterStore.getInwardRegisterReport(inwardRegister);
      setLoading(false);
    } else {
      let error: any = {
        warehouseId: 'Select Warehouse'
      }
      setErrors({ ...error })
    }
  }

  function createExportExcelObj(partyLedgerDetails: any[]) {
    let excelArr: ExcelSheetData[] = partyLedgerDetails?.map((partyLedger) => {
      const excelObj: ExcelSheetData = {
        Item_Name: partyLedger?.itemName,
        Bill_No: partyLedger?.billNo,
        Bill_Date: moment(partyLedger?.billDate).format("DD-MMM-YYYY"),
        Rate: partyLedger?.rate,
        Qty: partyLedger?.qty,
        Supplier: partyLedger?.supplier,
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Inward Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Inward Register ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async function fetchOultetItems() {
    if (inwardRegister?.fromDate && inwardRegister?.toDate) {
      await inwardRegisterStore.getInwardRegisterReport(inwardRegister);
    }
    await commonStore.loadWarehouse();
    setLoading(false);
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      fetchOultetItems();
      isCurrenPage.current = false;
    }
    return () => { }
  }, []);


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])


  useEffect(() => {
    if (inwardRegisterStore?.inwardDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(inwardRegisterStore?.inwardDetails)
    }
  }, [inwardRegisterStore?.inwardDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(inwardRegisterStore?.inwardDetails, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>

      <div className='container'>
        {
          isLoading ? <ProgressBar /> : null
        }
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Inward Register</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='row'>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>From Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputChange} value={inwardRegister?.fromDate} name='fromDate'
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>To Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputChange} value={inwardRegister?.toDate} name='toDate'
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                <div className='col-sm-2' style={{ marginTop: '17px' }}>
                  <div className='inputBox'>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={commonStore.loadWarehouses}
                      getOptionLabel={(option: any) => option.warehouseName}
                      onChange={(event, val) => setInwardRegister({ ...inwardRegister, warehouseId: val?.warehouseId })}
                      renderInput={(params) =>
                        <TextField  {...params}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select Warehouse..'
                          name='warehouseId'
                        />}
                    />
                  </div>
                  {errors?.warehouseId && <p style={{ color: 'red' }}>{errors?.warehouseId}</p>}
                </div>
                <div className="col-sm-3" style={{ marginTop: '24px' }}>
                  <div className="input-group mb-3">
                    <input type="text"
                      name='searchValue'
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="form-control" placeholder="Search Here.."
                      aria-label="Search Outlet..."
                      aria-describedby="search-button"
                    ></input>
                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary" type="button"
                        id="search-button">
                        <FontAwesomeIcon icon={faSearch}
                        // onClick={handleQuoteSearch}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1" style={{ marginTop: '25px' }}>
                  <button className='secondaryBtn' type='submit' onClick={generateReport}>Generate</button>
                </div>
                <div className='col-sm-1' style={{ marginTop: '25px', marginLeft: '20px' }}>
                  <button className='secondaryBtn' onClick={() => createExportExcelObj(inwardRegisterStore.inwardDetails)}>
                    Excel
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-5'>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{inwardRegisterStore?.inwardDetails?.length} Records</button>
                  </div>
                </div>
                <div className=' col-sm-7' >
                  <Stack spacing={2}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ItemName</th>
                      <th scope="col"> Bill No</th>
                      <th scope="col">Bill Date  </th>
                      <th scope="col">Rate </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Supplier</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(searchValue?.length > 0 ? filteredData : currentPageData).length > 0 &&
                      (searchValue?.length > 0 ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.itemName}</td>
                            <td>{val.billNo}</td>
                            <td>{val.billDate}</td>
                            <td>{val.rate}</td>
                            <td>{val.qty}</td>
                            <td>{val.supplier}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>

                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>

  )
})

export default InwardRegister;
