import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import assignOfferDiscountDetail, { IAssignOfferDiscount } from '../model/index';


class AssignOfferDiscountStores {
    @observable assignOfferDiscountDetls = assignOfferDiscountDetail;
    @observable assignOfferDiscountList= new Map();
    isLoading=true;
    @observable assignOfferDiscountLists= new Map<any,any>();
    assignOfferDiscountDetail = new Map();
    isInwardLoding=true;
    constructor(){
        makeObservable(this);
    }
     saveAssignOfferDiscount= async (assignOfferDiscount: IAssignOfferDiscount) => {
        
        try {
          const status=  await apiAgent.assignOfferDiscount.saveAssignOfferDiscount(assignOfferDiscount);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignOfferDiscounts = async ()=>{
        try{
            const assignOfferDiscountData=await apiAgent.assignOfferDiscount.getAssignOfferDiscounts();
            
            runInAction(()=>{
                
                this.assignOfferDiscountList = assignOfferDiscountData;
                this.isLoading = false;
            })
            return this.assignOfferDiscountList;
        }
        catch(error){
            console.log(error);
        }
    }
    getAssignOfferDiscountsDetails = async (offerid:number) =>{
        try{
            const assignOfferData = await apiAgent.assignOfferDiscount.getAssignOfferDiscountsDetails(offerid);
            runInAction(()=>{
                
                this.assignOfferDiscountDetail = assignOfferData;
            })
            return this.assignOfferDiscountDetail;
        }
        catch(error){
            console.log(error);
        }
    }
    getAssignOfferDiscountSearch  = async (SearchOptions)=> {
        try {
          const assignOfferDiscountData=await apiAgent.assignOfferDiscount.getAssignOfferDiscountSearch(SearchOptions);
            this.assignOfferDiscountList = assignOfferDiscountData;
            
            runInAction(() => {
                this.assignOfferDiscountList = assignOfferDiscountData;
                
            })
            return this.assignOfferDiscountList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignOfferMaster = async (assignOfferId) => {
        try {      
        const status=  await apiAgent.assignOfferDiscount.deleteAssignOfferMaster(assignOfferId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignOfferDetail = async (assignOffeDetailId) => {
        try {      
        const status=  await apiAgent.assignOfferDiscount.deleteAssignOfferDetail(assignOffeDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    @action setAssignOfferDiscount = (state:IAssignOfferDiscount) =>{
        this.assignOfferDiscountList.set(0,state);
    }
    @action setAssignOfferDiscountList = (state: IAssignOfferDiscount[]) =>{
        set(state,this.assignOfferDiscountList);
        return this.assignOfferDiscountList;
    }
}
export default AssignOfferDiscountStores;