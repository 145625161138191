import { observable } from "mobx";
export interface IStockAdjustment {
    assignOfferDiscount?: IStockAdjustment[];

}
export interface IStockAdjustment {
    stockadjsno?: number;
    Stockadjdate?: string;
    stkoid?: number;
    stkwid?: number;
    assignOffetDetails?: IStockAdjustmentDetails[];
}

export interface IStockAdjustmentSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

export class IStockAdjustmentModel {
    stockadjsno?: number;
    Stockadjdate?: string;
    stkoid?: number;
    stkwid?: number;
    assignOffetDetails?: IStockAdjustmentDetails[]
}

export class IStockAdjustmentDetails {
    stockadjmsno?: number;
    itemid?: number;
    barcode?: string;  
    batchno?: string;
    qty?: number;
    outletId?: number;
    currentstock?: number;
}

const StockAdjustmentModel = observable<IStockAdjustment>({
    stockadjsno: 0,
    Stockadjdate: '',
    stkoid: 0,
    stkwid: 0,
    assignOffetDetails: []
});

export default StockAdjustmentModel;