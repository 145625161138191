import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IAssignRedemption, IAssignRedemptionDetails } from './model';
import useStores from '../../hooks';
import assignRedemptionValidation from './validation';
import moment from 'moment';
import { Spinner, Modal, Image, Button } from 'react-bootstrap';
import _ from 'lodash';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';

const AssignRedemption = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState(true)
  const [assignRedemption, setAssignRedemption] = useState<IAssignRedemption>({})
  const [assignRedemptionDetails, setAssignRedemptionDetails] = useState<IAssignRedemptionDetails[]>([])
  const { assignRedemptionStore, memberStore } = useStores();

  const { getMembershipPlan, memberPlanList, } = memberStore
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const { assignRedemId } = useParams<{ assignRedemId: string }>();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignRedemption({ ...assignRedemption, [name]: value });
  }

  const handleCancel = () => {
    navigate('/Admin/AssignRedemption/AssignRedemptionList');
  }

  const handleAddRedemption = () => {
    let error: any = {};
    error = assignRedemptionValidation(assignRedemption);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      let assignRedemptionDetail: IAssignRedemptionDetails = {};
      assignRedemptionDetail.redemEffectiveFrom = assignRedemption.effectiveFrom;
      assignRedemptionDetail.redemBillValueFrom = Number(assignRedemption.billValueFrom);
      assignRedemptionDetail.redemBillValueTo = Number(assignRedemption.billValueTo);
      assignRedemptionDetail.redemPercentage = Number(assignRedemption.percentage);
      setAssignRedemptionDetails([...assignRedemptionDetails, assignRedemptionDetail]);
      setAssignRedemption({
        ...assignRedemption,
        billValueFrom: 0, billValueTo: 0,
        effectiveFrom: new Date().toISOString().substr(0, 10), percentage: 0,
      })
    }
  }

  async function handleRedemptionSave(e) {
    assignRedemption.assignRedemDetails = assignRedemptionDetails
    assignRedemption.amountPerPoints = Number(assignRedemption.amountPerPoints);
    let error: any = {}
    if (assignRedemptionDetails?.length) {
      setLoading(true);
      //   if (assignId !== undefined) {
      //     const status = await memberStore.assignMembershipEdit(assignMemberSave);
      //     if (status === "Success") {
      //         setLoading(false);
      //         setSuccessModal(true);
      //     } else {
      //         setLoading(false);
      //         setFailureModal(true);
      //     }
      // }
      const status = await assignRedemptionStore.saveAssignRedemption(assignRedemption);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      } else {
        setLoading(false);
        setFailureModal(true);
      }
    } else {
      error.addItem = 'Add Redemptions to Grid'
    }
    setErrors({ ...error })
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignRedemption/AssignRedemptionList');
  }

  function handleRemove(index) {
    assignRedemptionDetails.splice(index, 1)
    setAssignRedemptionDetails(_.cloneDeep(assignRedemptionDetails))
  }



  function handleAutoComplete(name, value) {
    setAssignRedemption({ ...assignRedemption, [name]: value?.PlanName });

  }

  async function fetchLoadData() {
    setLoading(false)
    await getMembershipPlan(0);
  }

  async function getassignRedemDet() {
    if (assignRedemId !== undefined) {
      const assignRedemptionList = await assignRedemptionStore.getAssignRedemption();
      const filterPlanList = assignRedemptionList.filter((planval) => planval.assignRedemptionId == assignRedemId); 
      const assignRedemptionDet = await assignRedemptionStore.getAssignRedemptiondetails(assignRedemId);
      console.log(assignRedemptionDet,'assignRedemptionDet');
      setAssignRedemptionDetails([
        ...assignRedemptionDetails,
        {
          redemptionId: assignRedemptionDet?.redemtionId,
          redemBillValueFrom: assignRedemptionDet?.redemBillValueFrom,
          redemBillValueTo: assignRedemptionDet?.redemBillValueTo,
          redemPercentage: assignRedemptionDet?.redemPercentage,
          redemEffectiveFrom: assignRedemptionDet?.redemEffectiveFrom
        }
      ]);    
      console.log(assignRedemptionDetails,'assignRedemptionDetails');
        
      setAssignRedemption({
        ...assignRedemption,
        assignRedemptionId: filterPlanList[0]?.assignRedemptionId,
        category: filterPlanList[0]?.category,
        amountPerPoints: filterPlanList[0]?.amountPerPoints,
        assignRedemDetails: [...assignRedemptionDetails],
      });
    }
  }
  
  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchLoadData()
      getassignRedemDet()
      isCurrentPage.current = false
    }
    return () => { }
  }, [])

  return (
    <div className='container'>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Assign Redemption </h3>
        </div>
        <div className='inputBoxLists pd-b-0'>
          <div className='LgInputListsBox mr-left-0'>
            <div className='row'>
              {
                isLoading ?
                  <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div> : null
              }
              {/* <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Plan Name <span>*</span></label>
                  <AutoComplete options={removeDuplicates(memberPlanList, 'PlanName')} placeholder={'Plan Name...'}
                    emitOption={(option: any) => handleAutoComplete('Membershipid', option)}
                    getOptionLabel='PlanName' clsName='full-width' />
                  {errors.planname && <p style={{ color: 'red' }}>{errors.planname}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {assignRedemption?.Membershipid &&  */}
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Category <span>*</span></label>
                  <AutoComplete options={removeDuplicates(memberPlanList, 'PlanName')} placeholder={'Category...'}
                   value={assignRedemption.category} emitOption={(option: any) => handleAutoComplete('category', option)}
                    getOptionLabel='PlanName' clsName='full-width' />
                  {errors.category && <p style={{ color: 'red' }}>{errors.category}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {/* } */}
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Amount Per Point </label>
                  <input type="text" className='full-width' placeholder='Amount Per Point..'
                    onChange={handleInputChange} name='amountPerPoints' value={assignRedemption.amountPerPoints}

                  ></input>
                  {errors.amountPerPoints && <p style={{ color: 'red' }}>{errors.amountPerPoints}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label> Bill Value From</label>
                  <input type="text" className='full-width' placeholder='Bill Value From..'
                    onChange={handleInputChange} name='billValueFrom' value={assignRedemption.billValueFrom}
                  ></input>
                  {errors.billValueFrom && <p style={{ color: 'red' }}>{errors.billValueFrom}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label> Bill Value To</label>
                  <input type="text" className='full-width' placeholder='Bill Value To..'
                    onChange={handleInputChange} name='billValueTo' value={assignRedemption.billValueTo}
                  ></input>
                  {errors.billValueTo && <p style={{ color: 'red' }}>{errors.billValueTo}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label> Redemption % as per cart value</label>
                  <input type="text" className='full-width' placeholder='Redemption %..'
                    onChange={handleInputChange} name='percentage' value={assignRedemption.percentage}
                  ></input>
                  {errors.percentage && <p style={{ color: 'red' }}>{errors.percentage}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-4'>
                <div className='inputBox'>
                  <label>Effective From <span>*</span></label>
                  <input type="date" placeholder="DD-MMM-YYYY"
                    onChange={handleInputChange} name='effectiveFrom' value={assignRedemption.effectiveFrom = assignRedemption.effectiveFrom ?? new Date().toISOString().substr(0, 10)}
                    data-date="" data-date-format="DD MMMM YYYY"></input>
                  {errors.effectiveFrom && <p style={{ color: 'red' }}>{errors.effectiveFrom}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2 mt-2'>
                <button className='dfBtn' onClick={handleAddRedemption}>ADD</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Bill Value From</th>
                        <th scope="col"> Bill Value To</th>
                        <th scope="col"> Redemption % </th>
                        <th scope="col"> Effective From</th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        assignRedemptionDetails?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.redemBillValueFrom}</td>
                              <td>{val.redemBillValueTo}</td>
                              <td>{val.redemPercentage}</td>
                              <td>{moment(val.redemEffectiveFrom).format('DD-MMM-YYYY')}</td>
                              <td><button style={{
                                border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                borderRadius: '6px', fontSize: '12px'
                              }} type='submit'
                                onClick={() => handleRemove(key)}>Remove</button></td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                  {errors?.addItem && <p style={{ color: 'red' }}>{errors?.addItem}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
          </div>
          <div className='vertical-space-10'></div>
        </div>
        <div className='btnBox'>
          <button className='secondaryBtn' onClick={handleCancel}>List</button>
          <button className='dfBtn' type='submit' onClick={handleRedemptionSave}>Submit</button>
        </div>
      </div>
      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>

            <h4>Succesfully Submitted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );

});

export default AssignRedemption;
