
function outwardoutletValidation(outwardOutlet) {
  let errors: any = {};

  if (!outwardOutlet.outwardDate) {
    errors.outwardDate = "Select Outward Date";
  }
  if (!outwardOutlet.outletId) {
    errors.outletId = "Select Outlet Name";
  }
  if (!outwardOutlet.warehouseId) {
    errors.warehouseId = "Select Warehouse";
  }
  if (!outwardOutlet.outletItemId) {
    errors.outletItemId = "Select Outlet Request No";
  }
  if (!outwardOutlet.itemName) {
    errors.itemName = "Enter Item Name";
  }
  if (!outwardOutlet.outletQty) {
    errors.outletQty = "Enter Out Qty";
  }

  return errors;
};

export default outwardoutletValidation;