import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { IIEmployee, IIEmployeeContact, IIEmployeeDetails, IIEmployeeEducation, IIEmployeeExperience, IIEmployeeFamily, IIEmployeeNominee, IIEmployeeOfficial, IIEmployeeSalary } from "./model";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import useStores from "../../hooks";
import moment from "moment";
import { employeeValidation, employeeContactValidation, employeeDetailsValidation, employeeExpValidation, employeeMasterValidation, employeeNomineeValidation, employeeOfficialValidation, employeeSalaryValidation } from "./validation";
import { Modal, Image, Button, Spinner } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AutoComplete from "../../common/shared/autoComplete";
import _ from "lodash";
import { IIEmployee, IIEmployeeContact, IIEmployeeDetails, IIEmployeeEducation, IIEmployeeExperience, IIEmployeeFamily, IIEmployeeNominee, IIEmployeeOfficial, IIEmployeeSalary, IInsurenceList } from "./model";

const Employee = observer((): JSX.Element => {

  const navigate = useNavigate();

  const { empId } = useParams()

  const { employeeStore, departmentStore, designationStore, payrollStore } = useStores();

  const [defaultKey, setDefaultKey] = useState("0")

  const [employeeMaster, setEmployeeMaster] = useState<IIEmployee>(new IIEmployee())
  const [employeeDetails, setEmployeeDetails] = useState<IIEmployeeDetails>(new IIEmployeeDetails())
  const [employeeContact, setEmployeeContact] = useState<IIEmployeeContact>(new IIEmployeeContact())
  const [employeeOfficial, setEmployeeOfficial] = useState<IIEmployeeOfficial>(new IIEmployeeOfficial())

  const [employeeFamily, setEmployeeFamily] = useState<IIEmployeeFamily>(new IIEmployeeFamily())
  const [employeeSalary, setEmployeeSalary] = useState<IIEmployeeSalary>(new IIEmployeeSalary())
  const [employeeExperience, setEmployeeExperience] = useState<IIEmployeeExperience>(new IIEmployeeExperience())
  const [employeeEductaion, setEmployeeEducation] = useState<IIEmployeeEducation>(new IIEmployeeEducation())
  const [employeeNominee, setEmployeeNominee] = useState<IIEmployeeNominee>(new IIEmployeeNominee())

  const [isLoading, setLoading] = useState(true);

  const [errors, setErrors] = useState<any>({});
  const [errorList, setErrorList] = useState<string[]>(['category', 'shithours', 'mobileNo', 'present_Add', 'permantent_Add', 'department', 'designation',
    'biometric_id', 'prefix', 'employee_Name', 'dob', 'employee_Fname', 'gender'])
  const [categorylist, setCategorylist] = useState<any[]>([])
  const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  // console.log(Object.keys(errors), 'errors>>')
  const handleCancel = () => {
    navigate("/Admin/employeeregistraion/list");
  };
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false)
    navigate("/Admin/employeeregistraion/list");
  };

  // Master
  function handleInputMasterChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleAutoComplete(name, Number(value))
    } else if (name === "doj") {
      handleAutoComplete(name, value)
      handleSalaryAutoComplete('effectDate', value)
    } else {
      handleAutoComplete(name, value)
    }
  }
  function handleAutoComplete(name, value) {
    if (name === "doB") {
      setEmployeeMaster({ ...employeeMaster, [name]: value, age: getAge(value) })
    } else {
      setEmployeeMaster({ ...employeeMaster, [name]: value })
    }
  }

  // Employee Details
  function handleInputDetailsChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleDetailsAutoComplete(name, Number(value))
    } else if (name === "doj") {
      handleDetailsAutoComplete(name, value)
      handleSalaryAutoComplete('effectDate', value)
    } else {
      handleDetailsAutoComplete(name, value)
    }
  }
  function handleDetailsAutoComplete(name, value) {
    setEmployeeDetails({ ...employeeDetails, [name]: value })
  }

  // Family
  function handleInputFamilyChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleAutoFamilyComplete(name, Number(value))
    } else {
      handleAutoFamilyComplete(name, value)
    }
  }
  function handleAutoFamilyComplete(name, value) {
    setEmployeeFamily({ ...employeeFamily, [name]: value })
  }
  function handleFamilyAdd(index) {
    if (index >= 0) {
      let employeeMasDet = Object.assign({}, employeeMaster)
      employeeMasDet.employeeFamily?.splice(index, 1)
      setEmployeeMaster(_.cloneDeep(employeeMasDet))
    } else {
      if (!employeeMaster?.employeeFamily?.length) {
        employeeMaster.employeeFamily = []
      }
      employeeMaster.employeeFamily.push(employeeFamily)
      setEmployeeFamily(new IIEmployeeFamily())
      setEmployeeMaster(_.cloneDeep(employeeMaster))
    }
  }

  // Salary
  function handleInputSalaryChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleSalaryAutoComplete(name, Number(value))
    } else {
      handleSalaryAutoComplete(name, value)
    }
  }
  function handleSalaryAutoComplete(name, value) {
    setEmployeeSalary({ ...employeeSalary, [name]: value })
  }
  function handleAddSalary(index) {
    if (index >= 0) {
      let employeeMasDet = Object.assign({}, employeeMaster)
      employeeMasDet.employeSalary?.splice(index, 1)
      setEmployeeMaster(_.cloneDeep(employeeMasDet))
    } else {
      let error: any = {}
      if (!employeeMaster?.employeSalary && !employeeMaster?.employeSalary?.length) {
        employeeMaster.employeSalary = [];
        employeeSalary['employeeid'] = employeeMaster?.employeeId
        error = employeeSalaryValidation(employeeSalary)
        if (Number(employeeSalary?.amount) <= Number(employeeSalary?.grossSalary)) {
        } else {
          error.amount = "Amount total cannot be greater/lesser than Gross Salary";
        }
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
          employeeMaster.employeSalary.push(employeeSalary)
          setEmployeeMaster(_.cloneDeep(employeeMaster))
        }
      } else {
        const disallowedTypeNotPresent = employeeMaster?.employeSalary!.every(obj => obj.earnType !== employeeSalary?.earnType);
        if (disallowedTypeNotPresent) {
          let salaryArray: IIEmployeeSalary[] = Object.assign([], employeeMaster?.employeSalary);
          let salaryAmountSum = salaryArray?.reduce((a, v: IIEmployeeSalary) => a = a + Number(v?.amount!), 0)
          let checkSum = salaryAmountSum + Number(employeeSalary?.amount)
          error = employeeSalaryValidation(employeeSalary);
          if (checkSum <= Number(employeeSalary?.grossSalary)) {
          } else {
            error.amount = "Amount total cannot be greater/lesser than Gross Salary";
          }
          setErrors({ ...error })
          if (Object.keys(error)?.length === 0) {
            employeeSalary['employeeid'] = employeeMaster?.employeeId
            employeeMaster?.employeSalary?.push(employeeSalary)
            setEmployeeMaster(_.cloneDeep(employeeMaster))
          }
        } else {
          let error: any = {
            earnType: `${employeeSalary?.earnType} already exists`
          }
          setErrors({ ...error })
        }
      }
    }
  }

  //Experience
  function handleInputExperiencChange(event) {
    const { name, value } = event.target;
    if (name === "lastDrawn") {
      handleExperienceAutoComplete(name, Number(value))
    } else {
      handleExperienceAutoComplete(name, value)
    }
  }
  function handleExperienceAutoComplete(name, value) {
    setEmployeeExperience({ ...employeeExperience, [name]: value })
  }
  function handleAddExperience(index) {
    if (index >= 0) {
      let employeeMasDet = Object.assign({}, employeeMaster)
      employeeMasDet.employExperience?.splice(index, 1)
      setEmployeeMaster(_.cloneDeep(employeeMasDet))
    } else {
      if (!employeeMaster?.employExperience?.length) {
        employeeMaster.employExperience = []
      }
      employeeExperience['employeeid'] = employeeMaster?.employeeId
      let error: any = {}
      error = employeeExpValidation(employeeExperience)
      if (Object.keys(error)?.length === 0) {
        employeeMaster.employExperience.push(employeeExperience)
        setEmployeeExperience(new IIEmployeeExperience())
        setEmployeeMaster(_.cloneDeep(employeeMaster))
        setErrors({})
      } else {
        setErrors({ ...error })
      }
    }
  }

  //Education
  function handleInputEducationChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleEducationAutoComplete(name, Number(value))
    } else {
      handleEducationAutoComplete(name, value)
    }
  }
  function handleEducationAutoComplete(name, value) {
    setEmployeeEducation({ ...employeeEductaion, [name]: value })
  }
  function handleAddEducation(index) {
    if (index >= 0) {
      let employeeMasDet = Object.assign({}, employeeMaster)
      employeeMasDet.employEducation?.splice(index, 1)
      setEmployeeMaster(_.cloneDeep(employeeMasDet))
    } else {
      if (!employeeMaster?.employEducation?.length) {
        employeeMaster.employEducation = []
      }
      employeeEductaion['employeeid'] = employeeMaster?.employeeId
      employeeMaster.employEducation.push(employeeEductaion)
      setEmployeeEducation(new IIEmployeeEducation())
      setEmployeeMaster(_.cloneDeep(employeeMaster))
    }
  }

  //Nominee
  function handleInputNomieeChange(event) {
    const { name, value } = event.target;
    if (name === "") {
      handleNomineeAutoComplete(name, Number(value))
    } else if (name === 'nomDOB') {
      setEmployeeNominee({ ...employeeNominee, [name]: value, nomAge: getAge(value)?.toString() })
    } else {
      handleNomineeAutoComplete(name, value)
    }
  }
  function handleNomineeAutoComplete(name, value) {
    setEmployeeNominee({ ...employeeNominee, [name]: value })
  }
  function handleAddNominee(index) {
    if (index >= 0) {
      let employeeMasDet = Object.assign({}, employeeMaster)
      employeeMasDet.employeNominee?.splice(index, 1)
      setEmployeeMaster(_.cloneDeep(employeeMasDet))
    } else {
      if (!employeeMaster?.employeNominee?.length) {
        employeeMaster.employeNominee = []
      }
      employeeNominee['employeeid'] = employeeMaster?.employeeId

      let error: any = {}
      error = employeeNomineeValidation(employeeNominee)

      if (Object.keys(error)?.length === 0) {
        employeeMaster.employeNominee.push(employeeNominee)
        setEmployeeNominee(new IIEmployeeNominee())
        setEmployeeMaster(_.cloneDeep(employeeMaster))
        setErrors({})
      } else {
        setErrors({ ...error })
      }
    }
  }

  function updateEmptyFiedls() {
    if (!employeeMaster?.employeeFamily?.length) {
      employeeMaster.employeeFamily = new Array<IIEmployeeFamily>()
      let employeeFamily = new IIEmployeeFamily();
      employeeMaster.employeeFamily?.push(employeeFamily)
    }
    if (!employeeMaster?.employEducation?.length) {
      employeeMaster.employEducation = new Array<IIEmployeeEducation>()
      let employEducation = new IIEmployeeEducation();
      employeeMaster.employEducation?.push(employEducation)
    }
    if (!employeeMaster?.employExperience?.length) {
      employeeMaster.employExperience = new Array<IIEmployeeExperience>()
      let employExperience = new IIEmployeeExperience();
      employeeMaster.employExperience?.push(employExperience)
    }
    if (!employeeMaster?.employeSalary?.length) {
      employeeMaster.employeSalary = new Array<IIEmployeeSalary>()
      let employeSalary = new IIEmployeeSalary();
      employeeMaster.employeSalary?.push(employeSalary)
    }
    if (!employeeMaster?.employeNominee?.length) {
      employeeMaster.employeNominee = new Array<IIEmployeeNominee>()
      let employeNominee = new IIEmployeeNominee();
      employeeMaster.employeNominee?.push(employeNominee)
    }
    if (!employeeMaster?.insuranceList?.length) {
      employeeMaster.insuranceList = new Array<IInsurenceList>()
      let insuranceList = new IInsurenceList();
      employeeMaster.insuranceList?.push(insuranceList)
    }
  }

  async function saveEmployee(e) {
    e.preventDefault();
    let error: any = {}
    if (!employeeMaster?.employeOfficial?.length) {
      let tempError = employeeOfficialValidation(employeeOfficial)
      error = { ...error, ...tempError }
      if (Object.keys(tempError)?.length === 0) {
        employeeMaster.employeOfficial = new Array<IIEmployeeOfficial>()
        employeeMaster?.employeOfficial?.push(employeeOfficial)
      }
    }
    if (!employeeMaster?.employeecontact?.length) {
      let tempError = employeeContactValidation(employeeContact)
      error = { ...error, ...tempError }
      if (Object.keys(tempError)?.length === 0) {
        employeeMaster.employeecontact = new Array<IIEmployeeContact>()
        employeeMaster?.employeecontact?.push(employeeContact)
      }
    }
    if (!employeeMaster?.employeedetails?.length) {
      let tempError = employeeDetailsValidation(employeeDetails)
      error = { ...error, ...tempError }
      if (Object.keys(tempError)?.length === 0) {
        employeeMaster.employeedetails = new Array<IIEmployeeDetails>()
        employeeMaster?.employeedetails?.push(employeeDetails)
      }
    }

    let masterError: any = {}
    masterError = employeeMasterValidation(employeeMaster)
    error = { ...error, ...masterError }
    setErrors({ ...error })
    if (Object?.keys(error)?.some((each) => errorList?.some((str) => str === each))) {
      let tempErrList = Object?.keys(error)?.filter((each) => errorList?.some((str) => str === each));
      let accFrst = ["biometric_id", "employee_Fname", "prefix", "employee_Fname",
        "department", "designation", "dob", "gender"]
      let accScnd = ["mobileNo", "present_Add", "permantent_Add"]
      let accThrd = ["category", "shithours"]
      if (tempErrList?.some((err) => accFrst?.some((str) => err === str))) {
        setDefaultKey('0')
      } else if (tempErrList?.some((err) => accScnd?.some((str) => err === str))) {
        setDefaultKey('1')
      } else if (tempErrList?.some((err) => accThrd?.some((str) => err === str))) {
        setDefaultKey('3')
      }
    }
    if (Object.keys(error)?.length === 0) {
      updateEmptyFiedls()
      setLoading(true);
      if (!empId) {
        const entryNos = await employeeStore.getEntryNo("Employee");
        employeeMaster.employeeId = entryNos.entryNo + 1;
        const status = await employeeStore.saveEmployee(employeeMaster);
        if (status === "Success") {
          if (emploeephote !== undefined) {
            await employeeStore.saveEmployeeImage(emploeephote, employee.employeeId);
          }
          setLoading(false);
          setSuccessModal(true);
        } else {
          setLoading(false);
          setFailureModal(true);
        }
      } else {
        employeeMaster.employeeId = Number(empId);
        const status = await employeeStore.updateEmployee(employeeMaster);
        if (status === "Success") {
          if (emploeephote !== undefined) {
            await employeeStore.saveEmployeeImage(emploeephote, employee.employeeId);
          }
          setLoading(false);
          setSuccessModal(true);
        } else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }

  function mapData(employeeDetails) {
    let employeeMaster: IIEmployee = new IIEmployee();
    let employeeEducations: IIEmployeeEducation[] = employeeDetails?.employEducation?.map((each) => {
      let employeeEducation: IIEmployeeEducation = {
        empeduid: each?.Empeduid,
        employeeid: employeeDetails?.EmployeeId,
        courses: each?.Courses,
        school_Univercity: each?.School_Univercity,
        yearofpassing: each?.yearofpassing,
      }
      return employeeEducation
    })
    let employExperiences: IIEmployeeExperience[] = employeeDetails?.employExperience?.map((each) => {
      let employExperience: IIEmployeeExperience = {
        empexpid: each?.Empexpid,
        employeeid: employeeDetails?.EmployeeId,
        qualification: each?.Qualification,
        occupation: each?.Occupation,
        designation: each?.Designation,
        lastEmployer: each?.LastEmployer,
        yearofExperience: each?.year,
        experpfno: each?.PFno,
        experEsino: each?.ESINo,
        lastDrawn: each?.LastDrawn,
      }
      return employExperience
    })
    let employeeNominees: IIEmployeeNominee[] = employeeDetails?.employeNominee?.map((each) => {
      let employeeNominee: IIEmployeeNominee = {
        employeeid: employeeDetails?.EmployeeId,
        empnimid: each?.Empnimid,
        nomName: each?.NomName,
        nomDOB: each?.NomDOB,
        nomAge: each?.NomAge?.toString(),
        nomMobileno: each?.NomMobileno?.toString(),
        relationship: each?.Relationship,
        nomAddress: each?.NomAddress,
      }
      return employeeNominee
    })
    let employeeOfficials: IIEmployeeOfficial[] = employeeDetails?.employeOfficial?.map((each) => {
      let employeeOfficial: IIEmployeeOfficial = {
        employeeid: employeeDetails?.EmployeeId,
        aadhaarno: each?.Aadhaarno,
        category: each?.Category,
        companyname: each?.Companyname,
        dlNo: each?.DLNo,
        dispansary: each?.Dispansary,
        empofficid: each?.Empofficid,
        empstatus: each?.Empstatus,
        esino: each?.Esino,
        otApplicable: each?.OTApplicable ? 1 : 0,
        panCardNo: each?.PANCardNo,
        ptApplicable: each?.PTApplicable ? 1 : 0,
        pfno: each?.Pfno,
        shithours: each?.Shithours,
        tdsApplicable: each?.TDSApplicable ? 1 : 0,
        uanNo: each?.UANNo,
        voterid: each?.Voterid,
        probation: each?.probationperiod,
      }
      return employeeOfficial;
    })
    if (employeeOfficials?.length) {
      setEmployeeOfficial({ ...employeeOfficials[0]! })
    }
    let employeeSalaries: IIEmployeeSalary[] = employeeDetails?.employeSalary?.map((each) => {
      let employeeSalary: IIEmployeeSalary = {
        employeeid: employeeDetails?.EmployeeId,
        amount: each?.Amount?.toString(),
        earnType: each?.EarnType,
        effectDate: each?.EffectDate,
        empsalid: each?.Empsalid,
        grossSalary: each?.GrossSalary?.toString(),
        paymentmode: each?.Paymentmode,
        salarytype: each?.Salarytype,
      }
      return employeeSalary;
    })
    if (employeeSalaries?.length) {
      setEmployeeSalary({ ...employeeSalaries[0]! });
    }
    let employeeFamilies: IIEmployeeFamily[] = employeeDetails?.employeeFamily?.map((each) => {
      let employeeFamily: IIEmployeeFamily = {
        empfid: each?.Empfid,
        fName: each?.Fname,
        relationship: each?.Frelationship,
        relationShipDOB: each?.FDOB,
        relationShipMobileNo: each?.Fmobileno?.toString(),
      }
      return employeeFamily;
    })
    let employeeConatacts: IIEmployeeContact[] = employeeDetails?.employeeContact?.map((each) => {
      let employeeContact: IIEmployeeContact = {
        alternateMobileNo: '',
        area: '',
        emailId: each?.Emailid,
        empcid: each?.Empcid,
        mobileNo: each?.Mobileno?.toString(),
        permantent_Add: each?.Permantent_Add,
        present_Add: each?.Present_Add,
      }
      return employeeContact
    })
    if (employeeConatacts?.length) {
      setEmployeeContact({ ...employeeConatacts[0]! })
    }
    employeeMaster = {
      employeeId: employeeDetails?.EmployeeId,
      employee_Name: employeeDetails?.Employee_Name,
      employee_Fname: employeeDetails?.Employee_Fname,
      biometric_id: employeeDetails?.Biometric_id,
      doB: new Date(employeeDetails?.DoB)?.toISOString()?.substr(0, 10),
      age: employeeDetails?.Age,
      emptype: employeeDetails?.EmpType,
      department: departmentStore.departmentList?.find((each) => each?.departmentId == employeeDetails?.department)?.department,
      designation: designationStore.designationList?.find((each) => each?.designationId == employeeDetails?.designation)?.designation,
      gender: employeeDetails?.Gender,
      maritial_status: employeeDetails?.Maritial_status,
      bloodgroup: employeeDetails?.Bloodgroup,
      height: employeeDetails?.Height,
      weight: employeeDetails?.weight,
      nationality: employeeDetails?.Nationality,
      religion: employeeDetails?.Religion,
      employee_photo: employeeDetails?.employee_photo,
      bankName: employeeDetails?.bankName,
      accountNo: employeeDetails?.accountNo,
      ifscCode: employeeDetails?.ifscCode,
      branch: employeeDetails?.branch,
      employEducation: employeeEducations,
      employExperience: employExperiences,
      employeNominee: employeeNominees,
      employeSalary: employeeSalaries,
      employeeFamily: employeeFamilies,
    }
    setEmployeeMaster({ ...employeeMaster })
  }

  const fetchEmployeeEditData = async (empId) => {
    setLoading(true);
    const data = await employeeStore.loadEmployeeMasterDetails();
    let findEmployeeDet = data?.findLast((each) => each?.EmployeeId == empId)
    mapData(findEmployeeDet)
    setLoading(false);
  }

  async function additionalApicals() {
    const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
    setCategorylist([...categoryMasterDetails])
    const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
    setIncentiveMaster([...incentiveMaster])
    await departmentStore.getDepartment();
    await designationStore.getDesignation();
  }

  async function getEntryNo() {
    if (isCurentPage.current) {
      let entryNo: any = {};
      if (!empId) {
        entryNo = await employeeStore.getEntryNo("Employee");
        setEmployeeMaster({ ...employeeMaster, employeeId: entryNo?.entryNo + 1 })
        setLoading(false);
      } else {
        setEmployeeMaster({ ...employeeMaster, employeeId: Number(empId) })
        fetchEmployeeEditData(empId)
        // setLoading(false);
      }
      additionalApicals()
    }
  }

  const isCurentPage = useRef(true);

  useEffect(() => {
    if (isCurentPage.current) {
      getEntryNo();
      isCurentPage.current = false;
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [employee, setEmployeeModel] = useState<any>({});
  const [insuranceList, setInsuranceList] = useState<any>({});
  const [insuranceLists, setInsuranceLists] = useState<any[]>([]);
  const [isEmployeeEdit, setEmployeeEdit] = useState(false);

  const [emploeephote, setemployeeImage] = useState();
  const [fileName, setImageFile] = useState();

  const handleInsurance = (event: any) => {
    const { name, value } = event.target;
    setInsuranceList({ ...insuranceList, [name]: value });
  };

  const addInsurance = () => {
    setInsuranceLists([...insuranceLists, insuranceList]);
  };

  const handleChangeImage = (e) => {
    employee.employee_photo = "";
    e.preventDefault();
    setemployeeImage(e.target.files[0]);
    setImageFile(e.target.files[0].name);
  };

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === "doB") {
      employee.age = getAge(value);
    }
    setEmployeeModel({ ...employee, [name]: value });
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <>
      {isLoading ? (
        <ProgressBar />
      ) : (
        <>
          <div className="vertical-space-20"></div>
          <div className="container">
            <div className="outletInputField inputFormBox LgInputField">
              <div className="hrBox">
                <h3>Employee Registration</h3>
              </div>
              <div className="inputBoxLists accordionLists">
                <Accordion activeKey={defaultKey} onSelect={(e: any) => setDefaultKey(e?.toString())}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Personal Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Employee ID</label>
                            <input type="text" className="" value={employeeMaster?.employeeId}
                              disabled style={{ width: "100%" }} placeholder="Employee ID.."></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="inputBox">
                            <label>Biometric ID <span>*</span></label>
                            <input type="text" className="" name="biometric_id" value={employeeMaster?.biometric_id}
                              onChange={handleInputMasterChange} style={{ width: "100%" }} placeholder="Biometric ID.."></input>
                            {errors.biometric_id && (<p style={{ color: "red" }}>{errors.biometric_id}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>Employee Name <span>*</span></label>
                            <input type="text" className="" name="employee_Name" value={employeeMaster.employee_Name}
                              onChange={handleInputMasterChange} style={{ width: "100%" }} placeholder="Enter Employee Name.."></input>
                            {errors.employee_Name && (<p style={{ color: "red" }}>{errors.employee_Name}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Prefix <span>*</span></label>
                            <select className="form-select" name="prefix" value={employeeMaster.prefix} onChange={handleInputMasterChange} id="prefix">
                              <option value="none">Select</option>
                              <option value="S/o">S/O</option>
                              <option value="W/o">W/O</option>
                              <option value="D/o">D/O</option>
                            </select>
                            {errors.prefix && (<p style={{ color: "red" }}>{errors.prefix}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> Father/Husband Name <span>*</span></label>
                            <input
                              type="text"
                              className=""
                              name="employee_Fname"
                              value={employeeMaster.employee_Fname}
                              onChange={handleInputMasterChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Father Name.."
                            ></input>
                          </div>
                          {errors.employee_Fname && (<p style={{ color: "red" }}>{errors.employee_Fname}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="inputBox">
                            <label>
                              Department <span>*</span>
                            </label>
                            <AutoComplete clsName="full-width" value={employeeDetails?.department!} options={departmentStore.departmentList} getOptionLabel="department" placeholder="Select Department"
                              emitOption={(val) => { setEmployeeDetails({ ...employeeDetails, departmentId: val?.departmentId, department: val?.department }); setEmployeeMaster({ ...employeeMaster, department: val?.departmentId }) }} />
                            {errors.department && (<p style={{ color: "red" }}>{errors.department}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="inputBox">
                            <label>Designation <span>*</span></label>
                            <AutoComplete clsName="full-width" value={employeeDetails?.designation} options={designationStore.designationList} getOptionLabel="designationName" placeholder="Select Designation"
                              emitOption={(val) => { setEmployeeDetails({ ...employeeDetails, designationId: val?.designationId, designation: val?.designationName }); setEmployeeMaster({ ...employeeMaster, designation: val?.designationId }) }} />
                            {errors.designation && (
                              <p style={{ color: "red" }}>
                                {errors.designation}
                              </p>
                            )}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>
                              DOJ <span>*</span>
                            </label>
                            <input
                              type="date"
                              style={{ width: "100%" }}
                              placeholder={"DD-MMM-YYYY"}
                              value={employeeMaster?.doj}
                              name="doj"
                              onChange={handleInputMasterChange}
                              max={moment().subtract(0, "years").format("YYYY-MM-DD")}
                              data-date=""
                              data-date-format="DD-MMM-YYYY"
                              onKeyDown={(event) => event.preventDefault()}
                            ></input>
                            {errors.doj && (<p style={{ color: "red" }}>{errors.doj}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>
                              DOB <span>*</span>
                            </label>
                            <input
                              type="date"
                              style={{ width: "100%" }}
                              placeholder={"DD-MMM-YYYY"}
                              value={employeeMaster.doB}
                              name="doB"
                              onChange={handleInputMasterChange}
                              max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                              data-date=""
                              data-date-format="DD-MMM-YYYY"
                              onKeyDown={(event) => event.preventDefault()}
                            ></input>
                            {errors.dob && (<p style={{ color: "red" }}>{errors.dob}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Age</label>
                            <input
                              type="text"
                              className=""
                              name="age"
                              value={employeeMaster.age}
                              onChange={() => { }}
                              style={{ width: "100%" }}
                              placeholder="Age.."
                              disabled
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Marital Status</label>
                            <select className="form-select" name="maritial_status" value={employeeMaster.maritial_status} onChange={handleInputMasterChange} id="Maritial">
                              <option value="none">Select</option>
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                            </select>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Gender <span>*</span></label>
                            <select className="form-select" name="gender" value={employeeMaster.gender} onChange={handleInputMasterChange} id="gender">
                              <option value="none">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">other</option>
                            </select>
                          </div>
                          {errors?.gender && (<p style={{ color: "red" }}>{errors.gender}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Blood Group</label>
                            <select className="form-select" name="bloodgroup" value={employeeMaster.bloodgroup} onChange={handleInputMasterChange} id="bloodgroup">
                              <option value="none">--Select--</option>
                              <option value="A-">A-</option>
                              <option value="A+">A+</option>
                              <option value="B-">B-</option>
                              <option value="B+">B+</option>
                              <option value="AB-">AB-</option>
                              <option value="AB+">AB+</option>
                              <option value="O-">O-</option>
                              <option value="O+">O+</option>
                            </select>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Height</label>
                            <input
                              type="text"
                              className=""
                              name="height"
                              value={employeeMaster.height}
                              onChange={handleInputMasterChange}
                              style={{ width: "100%" }}
                              placeholder="Height.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Weight</label>
                            <input
                              type="text"
                              className=""
                              name="weight"
                              value={employeeMaster.weight}
                              onChange={handleInputMasterChange}
                              style={{ width: "100%" }}
                              placeholder="Weight.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Nationality</label>
                            <select className="form-select" name="nationality" value={employeeMaster.nationality} onChange={handleInputMasterChange} id="nationality">
                              <option value="" selected>Select</option>
                              <option value="Indian">Indian</option>
                            </select>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-2">
                          <div className="inputBox">
                            <label>Religion</label>
                            <select className="form-select" name="religion" value={employeeMaster.religion} onChange={handleInputMasterChange} id="religion">
                              <option value="" selected>Select</option>
                              <option value="Hinduism">Hinduism</option>
                              <option value="Islam">Islam</option>
                              <option value="Christianity">Christianity</option>
                              <option value="Sikhism">Sikhism</option>
                              <option value="Buddhism">Buddhism</option>
                              <option value="Jainism">Jainism</option>
                              <option value="Adivasi">Adivasi</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Employee Photo</label>
                            <input
                              type="file"
                              className="full-width"
                              accept="image/*"
                              name="employee_photo"
                              onChange={handleChangeImage}
                            />
                            {errors.itemImage && (
                              <p style={{ color: "red" }}>{errors.itemImage}</p>
                            )}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Contact Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>Mobile No <span>*</span></label>
                            <input
                              type="number"
                              className=""
                              name="mobileNo"
                              value={employeeContact.mobileNo}
                              onChange={(event) => event.target.value?.length < 11 ? setEmployeeContact({ ...employeeContact, mobileNo: event?.target?.value }) : {}}
                              style={{ width: "80%" }}
                              placeholder="Enter Mobile No.."
                            ></input>
                          </div>
                          {errors.mobileNo && (<p style={{ color: "red" }}>{errors.mobileNo}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>
                              Email id
                            </label>
                            <input
                              type="text"
                              className=""
                              name="emailId"
                              value={employeeContact.emailId}
                              onChange={(event) => setEmployeeContact({ ...employeeContact, emailId: event?.target?.value })}
                              style={{ width: "80%" }}
                              placeholder="Enter Email id.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                          {errors.emailId && (<p style={{ color: "red" }}>{errors.emailId}</p>)}
                        </div>
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>
                              Present Address <span>*</span>
                            </label>
                            <textarea
                              className=""
                              name="present_Add"
                              value={employeeContact.present_Add}
                              onChange={(event) => setEmployeeContact({ ...employeeContact, present_Add: event?.target?.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter Present Address.."
                            ></textarea>
                          </div>
                          {errors.present_Add && (<p style={{ color: "red" }}>{errors.present_Add}</p>)}
                        </div>
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>Permanent Address <span>*</span></label>
                            <textarea
                              className=""
                              name="permantent_Add"
                              value={employeeContact.permantent_Add}
                              onChange={(event) => setEmployeeContact({ ...employeeContact, permantent_Add: event?.target?.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter Permanent Address.."
                            ></textarea>
                          </div>
                          {errors.permantent_Add && (<p style={{ color: "red" }}>{errors.permantent_Add}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-4 flex items-center">
                          <Checkbox
                            onClick={(evt) => console.log(evt.target)}
                            onChange={(event) => setEmployeeContact({ ...employeeContact, permantent_Add: event.target.checked ? employeeContact.present_Add : '' })}
                            inputProps={{
                              "aria-label": "controlled-checkbox",
                            }}
                          />
                          <div className="pt-2"> {"Same as Present"}</div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Family Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>Family Member Name</label>
                            <input
                              type="text"
                              className=""
                              name="fName"
                              value={employeeFamily.fName}
                              onChange={handleInputFamilyChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Name .."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="inputBox">
                            <label>Relationship</label>
                            <input
                              type="text"
                              className=""
                              name="relationship"
                              value={employeeFamily.relationship}
                              onChange={handleInputFamilyChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Relationship.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="inputBox">
                            <label>DOB</label>
                            <input
                              type="date"
                              className="inputBoxDate"
                              style={{ width: "100%", fontWeight: "lighter" }}
                              placeholder="DD/MMM/YYYY"
                              data-date=""
                              data-date-format="DD MMMM YYYY"
                              name="relationShipDOB"
                              value={employeeFamily?.relationShipDOB}
                              onChange={handleInputFamilyChange}
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-5">
                          <div className="inputBox">
                            <label>Mobile</label>
                            <input
                              type="number"
                              className=""
                              name="relationShipMobileNo"
                              value={employeeFamily.relationShipMobileNo}
                              onChange={(ev) => ev.target.value?.length < 11 ? handleInputFamilyChange(ev) : {}}
                              style={{ width: "100%" }}
                              placeholder="Enter Mobile No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <button
                            className="dfBtn"
                            type="submit"
                            style={{ height: "35px" }}
                            onClick={handleFamilyAdd}
                          >
                            Add
                          </button>
                          <div className="vertical-space-10"></div>
                        </div>
                      </div>
                      <div
                        className="tableListDetails"
                        style={{ marginBottom: "10px" }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Relationship</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeMaster?.employeeFamily?.map((val, key) => {
                              return val?.fName && (
                                <tr key={key}>
                                  <td>{val?.fName}</td>
                                  <td>{val?.relationship}</td>
                                  <td>{moment(val?.relationShipDOB)?.format('DD-MMM-YYYY')}</td>
                                  <td>{val?.relationShipMobileNo}</td>
                                  <td><button className="delete" onClick={() => handleFamilyAdd(key)}><FontAwesomeIcon icon={faTrashAlt} /> </button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Official</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="inputBox">
                            <label> Company Name <span>*</span></label>
                            <input
                              type="text"
                              className=""
                              name="mobileNo"
                              value={employeeOfficial.companyname = "Ganga Super Market"}
                              style={{ width: "100%" }}
                              placeholder="Enter Name .."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Category<span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="category" value={employeeOfficial.category}
                              onChange={(e) => { setEmployeeOfficial({ ...employeeOfficial, category: e.target.value }); setEmployeeMaster({ ...employeeMaster, emptype: e.target.value }) }} id="category">
                              <option value="" selected>Select</option>
                              {categorylist?.map((each) => {
                                return (
                                  <option value={each?.Emp_Category_Name}>{each?.Emp_Category_Name}</option>
                                )
                              })}
                            </select>
                          </div>
                          {errors?.category && (<p style={{ color: "red" }}>{errors.category}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>PF No</label>
                            <input
                              type="text"
                              className=""
                              name="pfno"
                              value={employeeOfficial.pfno}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, pfno: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter PF No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> ESI No </label>
                            <input
                              type="text"
                              className=""
                              name="esino"
                              value={employeeOfficial.esino}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, esino: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter ESI No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Aadhaar No.</label>
                            <input
                              type="number"
                              className=""
                              name="aadhaarno"
                              value={employeeOfficial.aadhaarno}
                              onChange={(evt) => evt.target.value?.length < 13 ? setEmployeeOfficial({ ...employeeOfficial, aadhaarno: evt.target.value }) : {}}
                              style={{ width: "100%" }}
                              placeholder="Enter Aadhaar No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Dispansary
                            </label>
                            <input
                              type="text"
                              className=""
                              name="dispansary"
                              value={employeeOfficial.dispansary}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, dispansary: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter Dispansary.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> PAN Card No</label>
                            <input
                              type="text"
                              className=""
                              name="panCardNo"
                              value={employeeOfficial.panCardNo}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, panCardNo: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter PAN Card No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> UAN No</label>
                            <input
                              type="text"
                              className=""
                              name="uanNo"
                              value={employeeOfficial.uanNo}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, uanNo: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter UAN No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> Voter ID</label>
                            <input
                              type="text"
                              className=""
                              name="voterid"
                              value={employeeOfficial.voterid}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, voterid: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter Voter ID.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              DL No
                            </label>
                            <input
                              type="text"
                              className=""
                              name="dlNo"
                              value={employeeOfficial.dlNo}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, dlNo: evt.target.value })}
                              style={{ width: "100%" }}
                              placeholder="Enter DL No.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="vertical-space-10"></div>
                        <div className="col-sm-12 text-lg">TDS & PT Details</div>
                        <div className="col-sm-4">
                          <div className="flex items-center full-height">
                            <Checkbox
                              onChange={(event) => setEmployeeOfficial({ ...employeeOfficial, tdsApplicable: event.target.checked ? 1 : 0 })}
                              defaultChecked={employeeOfficial?.tdsApplicable === 1 ? true : false}
                              checked={employeeOfficial?.tdsApplicable === 1 ? true : false}
                              inputProps={{
                                "aria-label": "controlled-checkbox",
                              }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', paddingRight: '12px' }}> {"TDS Applicable"}</div>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="flex items-center full-height">
                            <Checkbox
                              onChange={(event) => setEmployeeOfficial({ ...employeeOfficial, ptApplicable: event.target.checked ? 1 : 0 })}
                              defaultChecked={employeeOfficial?.ptApplicable === 1 ? true : false}
                              checked={employeeOfficial?.ptApplicable === 1 ? true : false}
                              inputProps={{
                                "aria-label": "controlled-checkbox",
                              }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', paddingRight: '12px' }}> {"PT Applicable"}</div>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="flex items-center full-height">
                            <Checkbox
                              onChange={(event) => setEmployeeOfficial({ ...employeeOfficial, otApplicable: event.target.checked ? 1 : 0 })}
                              defaultChecked={employeeOfficial?.otApplicable === 1 ? true : false}
                              checked={employeeOfficial?.otApplicable === 1 ? true : false}
                              inputProps={{
                                "aria-label": "controlled-checkbox",
                              }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', paddingRight: '12px' }}> {"OT Applicable"}</div>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Shift Hours <span>*</span>
                            </label>
                            <input
                              type="number"
                              className=""
                              name="shithours"
                              value={employeeOfficial.shithours}
                              onChange={(evt) => setEmployeeOfficial({ ...employeeOfficial, shithours: (evt.target.value) })}
                              style={{ width: "100%" }}
                              placeholder="Enter Shift Hours.."
                            ></input>
                          </div>
                          {errors?.shithours && (<p style={{ color: "red" }}>{errors.shithours}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Status <span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="paymentmode" value={employeeOfficial?.empstatus}
                              onChange={(event) => setEmployeeOfficial({ ...employeeOfficial, empstatus: event.target.value })} id="paymentmode">
                              <option value="none">Select</option>
                              <option selected value="Working">Working</option>
                              <option value="Relieved ">Relieved </option>
                              <option value="REjoined">Rejoined</option>
                            </select>
                          </div>
                          {errors?.empstatus && (<p style={{ color: "red" }}>{errors.empstatus}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Probation <span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="probation" value={employeeOfficial?.probation}
                              onChange={(event) => setEmployeeOfficial({ ...employeeOfficial, probation: Number(event.target.value) })} id="probation">
                              {Array.from(Array(13).keys())?.map((each, index) => {
                                return (
                                  <option selected value={each}>{each == 0 ? 'Select' : each}</option>
                                )
                              })}
                            </select>
                          </div>
                          {errors?.probation && (<p style={{ color: "red" }}>{errors.probation}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                      </div>
                      {/* <div className="btnBox">
                      <button className="dfBtn" type="submit" onClick={localStoreCompletedForm}>
                        Complete
                      </button>
                    </div> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Salary Details</Accordion.Header>
                    <Accordion.Body>
                      {errors.employeSalary && (<p style={{ color: "red" }}>{errors.employeSalary}</p>)}
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Payment Mode <span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="paymentmode" value={employeeSalary.paymentmode}
                              onChange={handleInputSalaryChange} id="paymentmode">
                              <option value="none">Select</option>
                              <option value="ByBank">By Bank</option>
                              <option value="ByCash">By Cash</option>
                            </select>
                          </div>
                          {errors.paymentmode && (<p style={{ color: "red" }}>{errors.paymentmode}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Salary Type <span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="salarytype" value={employeeSalary.salarytype}
                              onChange={handleInputSalaryChange} id="salarytype">
                              <option value="none">Select</option>
                              <option value="monthlyBases">Monthly Basis</option>
                              <option value="perDayBases">Per Day Basis</option>
                              <option value="weeklyBases">Weekly Basis</option>
                            </select>
                          </div>
                          {errors.salarytype && (<p style={{ color: "red" }}>{errors.salarytype}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Effective Date<span>*</span>
                            </label>
                            <input
                              type="date"
                              className="inputBoxDate"
                              style={{ width: "100%", fontWeight: "lighter" }}
                              placeholder="DD/MMM/YYYY"
                              data-date=""
                              data-date-format="DD MMMM YYYY"
                              name="effectDate"
                              value={employeeSalary.effectDate}
                              onChange={handleInputSalaryChange}
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Gross Salary <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="grossSalary"
                              value={employeeSalary.grossSalary}
                              onChange={handleInputSalaryChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Gross Salary.."
                            ></input>
                            {errors.grossSalary && (<p style={{ color: "red" }}>{errors.grossSalary}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Earn Type <span>*</span></label>
                            <select className="form-select" style={{ fontSize: "16px" }} name="earnType" value={employeeSalary.earnType}
                              onChange={handleInputSalaryChange} id="earnType">
                              <option value="none">Select</option>
                              {incentiveMaster?.map((each) => {
                                return each?.IType === "Allowance" && (
                                  <option value={each?.IDescription}>{each?.IDescription}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="vertical-space-10"></div>
                          {errors.earnType && (<p style={{ color: "red" }}>{errors.earnType}</p>)}
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Amount <span>*</span></label>
                            <input
                              type="text"
                              className=""
                              name="amount"
                              value={employeeSalary.amount}
                              onChange={handleInputSalaryChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Amount.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                          {errors.amount && (<p style={{ color: "red" }}>{errors.amount}</p>)}
                        </div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6">
                          <div className="btnBox">
                            <button
                              className="dfBtn"
                              type="submit"
                              style={{ height: "35px" }}
                              onClick={handleAddSalary}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tableListDetails"
                        style={{ marginBottom: "10px" }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Earn Type</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeMaster?.employeSalary?.map((val, key) => {
                              return val?.amount && (
                                <tr key={key}>
                                  <td>{val?.earnType}</td>
                                  <td>{val?.amount}</td>
                                  <td><button className="delete"><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddSalary(key)} /></button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="btnBox">
                      <button className="dfBtn" type="submit" onClick={localStoreCompletedForm}>
                        Complete
                      </button>
                    </div> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Experience & Education Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label> Qualification <span>*</span></label>
                            <input
                              type="text"
                              className=""
                              name="qualification"
                              value={employeeExperience?.qualification}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Qualification.."
                            ></input>
                            {errors.qualification && (<p style={{ color: "red" }}>{errors.qualification}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Occupation <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="occupation"
                              value={employeeExperience?.occupation}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Occupation .."
                            ></input>
                            {errors.occupation && (<p style={{ color: "red" }}>{errors.occupation}</p>)}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className='col-sm-4'></div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Last Employer <span>*</span></label>
                            <input type="text" className=''
                              name="lastEmployer"
                              value={employeeExperience?.lastEmployer}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }} placeholder='Enter Last Employer..'></input>
                            {errors.lastEmployer && (<p style={{ color: "red" }}>{errors.lastEmployer}</p>)}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Designation <span>*</span></label>
                            <input type="text" className=''
                              name="designation"
                              value={employeeExperience?.designation}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }} placeholder='Enter Designation..'></input>
                            {!errors?.employExperience?.length && errors.designationI && (<p style={{ color: "red" }}>{errors.designationI}</p>)}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Year of Exp. <span>*</span></label>
                            <input type="number" className=''
                              name="yearofExperience"
                              value={employeeExperience?.yearofExperience}
                              onChange={(e) => e.target.value?.length < 3 && handleInputExperiencChange(e)}
                              style={{ width: "100%" }} placeholder='Enter Year of Exp..'></input>
                            {errors.yearofExperience && (<p style={{ color: "red" }}>{errors.yearofExperience}</p>)}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>PF No</label>
                            <input type="text" className=''
                              name="experpfno"
                              value={employeeExperience?.experpfno}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }} placeholder='Enter PF No..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>ESI No </label>
                            <input type="text" className=''
                              name="experEsino"
                              value={employeeExperience?.experEsino}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }} placeholder='Enter ESI No..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Last Drawn </label>
                            <input type="text" className=''
                              name="lastDrawn"
                              value={employeeExperience?.lastDrawn}
                              onChange={handleInputExperiencChange}
                              style={{ width: "100%" }} placeholder='Enter Last Drawn..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className="col-sm-6"></div>

                        <div className="col-sm-6">
                          <div className="btnBox">
                            <button
                              className="dfBtn"
                              type="submit"
                              style={{ height: "35px" }}
                              onClick={handleAddExperience}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tableListDetails"
                        style={{ marginBottom: "10px" }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Qualification</th>
                              <th scope="col">Occupation</th>
                              <th scope="col">Last Employer</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Year</th>
                              <th scope="col">PF No</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeMaster?.employExperience?.map((val, key) => {
                              return val?.employeeid && (
                                <tr key={key}>
                                  <td>{val?.qualification}</td>
                                  <td>{val?.occupation}</td>
                                  <td>{val?.lastEmployer}</td>
                                  <td>{val?.designation}</td>
                                  <td>{val?.yearofExperience}</td>
                                  <td>{val?.experpfno}</td>
                                  <td><button className="delete" onClick={() => handleAddExperience(key)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {errors?.employExperience && (<p style={{ color: "red" }}>{errors.employExperience}</p>)}
                      <div className="row">
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Course</label>
                            <input type="text" className=''
                              name="courses"
                              value={employeeEductaion?.courses}
                              onChange={handleInputEducationChange}
                              style={{ width: "100%" }} placeholder='Enter Course..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>School/University</label>
                            <input type="text" className=''
                              name="school_Univercity"
                              value={employeeEductaion?.school_Univercity}
                              onChange={handleInputEducationChange}
                              style={{ width: "100%" }} placeholder='Enter School/University..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='inputBox'>
                            <label>Year of Passing</label>
                            <input type="text" className=''
                              name="yearofpassing"
                              value={employeeEductaion?.yearofpassing}
                              onChange={handleInputEducationChange}
                              style={{ width: "100%" }} placeholder='Enter Year of Passing..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6">
                          <div className="btnBox">
                            <button
                              className="dfBtn"
                              type="submit"
                              style={{ height: "35px" }}
                              onClick={handleAddEducation}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tableListDetails"
                        style={{ marginBottom: "10px" }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Courses</th>
                              <th scope="col">School/University</th>
                              <th scope="col">Year of Passing</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeMaster?.employEducation?.map((val, key) => {
                              return val?.employeeid && (
                                <tr key={key}>
                                  <td>{val?.courses}</td>
                                  <td>{val?.school_Univercity}</td>
                                  <td>{val?.yearofpassing}</td>
                                  <td><button className="delete" onClick={() => handleAddEducation(key)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Nominee Details</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              value={employeeNominee?.nomName}
                              name="nomName"
                              onChange={handleInputNomieeChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Name .."
                            ></input>
                          </div>
                          {errors.nomName && (<p style={{ color: "red" }}>{errors.nomName}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              DOB <span>*</span>
                            </label>
                            <input
                              type="date"
                              className="inputBoxDate"
                              style={{ width: "100%", fontWeight: "lighter" }}
                              placeholder="DD/MMM/YYYY"
                              data-date=""
                              data-date-format="DD MMMM YYYY"
                              name="nomDOB"
                              value={employeeNominee.nomDOB}
                              onChange={handleInputNomieeChange}

                            ></input>
                          </div>
                          {errors.nomAge && (<p style={{ color: "red" }}>{errors.nomAge}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>Age <span>*</span>
                            </label>
                            <input
                              type="text"
                              value={employeeNominee.nomAge}
                              style={{ width: "100%" }}
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Relationship <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="relationship"
                              value={employeeNominee.relationship}
                              onChange={handleInputNomieeChange}
                              style={{ width: "100%" }}
                              placeholder="Enter Relationship.."
                            ></input>
                          </div>
                          {errors.relationship && (<p style={{ color: "red" }}>{errors.relationship}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        {/* <div className="col-sm-6"></div> */}
                        <div className="col-sm-4">
                          <div className="inputBox">
                            <label>
                              Mobile <span>*</span>
                            </label>
                            <input
                              type="number"
                              className=""
                              value={employeeNominee.nomMobileno}
                              onChange={(ev) => ev?.target?.value?.length < 11 ? handleInputNomieeChange(ev) : {}}
                              name="nomMobileno"
                              style={{ width: "100%" }}
                              placeholder="Enter Mobile No.."
                            ></input>
                          </div>
                          {errors.nomMobileno && (<p style={{ color: "red" }}>{errors.nomMobileno}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-6">
                          <div className="inputBox">
                            <label>
                              Address <span>*</span>
                            </label>
                            <textarea
                              className=""
                              value={employeeNominee.nomAddress}
                              onChange={handleInputNomieeChange}
                              name="nomAddress"
                              style={{ width: "100%" }}
                              placeholder="Enter Present Address.."
                            ></textarea>
                          </div>
                          {errors.nomAddress && (<p style={{ color: "red" }}>{errors.nomAddress}</p>)}
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-sm-3">
                          <div className="btnBox">
                            <button
                              className="dfBtn"
                              type="submit"
                              style={{ height: "35px" }}
                              onClick={handleAddNominee}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tableListDetails"
                        style={{ marginBottom: "10px" }}
                      >
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Age</th>
                              <th scope="col">Relationship</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Address</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeMaster?.employeNominee?.map((val, key) => {
                              return val?.employeeid && (
                                <tr key={key}>
                                  <td>{val?.nomName}</td>
                                  <td>{moment(val?.nomDOB)?.format('DD-MMM-YYYY')}</td>
                                  <td>{val?.nomAge}</td>
                                  <td>{val?.relationship}</td>
                                  <td>{val?.nomMobileno}</td>
                                  <td>{val?.nomAddress}</td>
                                  <td><button className="delete" onClick={() => handleAddNominee(key)}> <FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {errors?.employeNominee && (<p style={{ color: "red" }}>{errors.employeNominee}</p>)}
                    </Accordion.Body>
                  </Accordion.Item>
                  {isEmployeeEdit ? (
                    <>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Official</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="inputBox">
                                <label>
                                  Company <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="company"
                                  value={employee.company}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Company.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>
                                  Category <span>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="category"
                                  value={employee.category}
                                  onChange={handleChangeInput}
                                  id="Category"
                                >
                                  <option value="none">Select</option>
                                  <option value="male">A</option>
                                  <option value="female">O</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>
                                  Department <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="department"
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Department.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-5">
                              <div className="inputBox">
                                <label>PFNO</label>
                                <input
                                  type="text"
                                  className=""
                                  name="pfNo"
                                  value={employee.pfNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter PFNO.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>Aadhaar No</label>
                                <input
                                  type="text"
                                  className=""
                                  name="aadhaarno"
                                  value={employee.aadhaarno}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Aadhaar No.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-5">
                              <div className="inputBox">
                                <label>
                                  DOJ <span>*</span>
                                </label>
                                <input
                                  type="date"
                                  name="doj"
                                  value={employee.doj}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="DD-MMM-YYYY"
                                  data-date=""
                                  data-date-format="DD MMMM YYYY"
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>ESINo</label>
                                <input
                                  type="text"
                                  className=""
                                  name="esiNo"
                                  value={employee.esiNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter ESINo.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-5">
                              <div className="inputBox">
                                <label>UAN No</label>
                                <input
                                  type="text"
                                  className=""
                                  name="uanNo"
                                  value={employee.uanNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter UAN No.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>Dispensary</label>
                                <input
                                  type="text"
                                  className=""
                                  name="dispensary"
                                  value={employee.dispensary}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Dispensary.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-5">
                              <div className="inputBox">
                                <label>PAN Card</label>
                                <input
                                  type="text"
                                  className=""
                                  name="panCardNo"
                                  value={employee.panCardNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter PAN Card.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-7">
                              <div className="inputBox">
                                <label>DL.NO</label>
                                <input
                                  type="text"
                                  className=""
                                  name="dlNo"
                                  value={employee.dlNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter DL.NO.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-5">
                              <div className="inputBox">
                                <label>PT Applicable</label>
                                <select
                                  className="form-select"
                                  name="ptApplicable"
                                  value={employee.ptApplicable}
                                  onChange={handleChangeInput}
                                  id="PTApplicable"
                                >
                                  <option value="none">Select</option>
                                  <option value="YES">Yes</option>
                                  <option value="NO">No</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-9">
                              <div className="inputBox">
                                <label>PT% </label>
                                <select
                                  className="form-select"
                                  name="ptPer"
                                  value={employee.ptPer}
                                  onChange={handleChangeInput}
                                  id="PT%"
                                >
                                  <option value="none">Select</option>
                                  <option value="4.0">4.0</option>
                                  <option value="5.0">5.0</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-9">
                              <div className="inputBox">
                                <label>TDS%</label>
                                <select
                                  className="form-select"
                                  name="tdsPer"
                                  value={employee.tdsPer}
                                  onChange={handleChangeInput}
                                  id="TDS%"
                                >
                                  <option value="none">Select</option>
                                  <option value="4.0">4.0</option>
                                  <option value="5.0">5.0</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-9">
                              <div className="inputBox">
                                <label>Reporting HR</label>
                                <select
                                  className="form-select"
                                  name="reportingHR"
                                  value={employee.reportingHR}
                                  onChange={handleChangeInput}
                                  id="ReportingHR"
                                >
                                  <option value="none">Select</option>
                                  <option value="A">A</option>
                                  <option value="O">O</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-9">
                              <div className="inputBox">
                                <label>Reporting Head</label>
                                <select
                                  className="form-select"
                                  name="reportingHead"
                                  value={employee.reportingHead}
                                  onChange={handleChangeInput}
                                  id="ReportingHead"
                                >
                                  <option value="none">Select</option>
                                  <option value="A">A</option>
                                  <option value="O">O</option>
                                </select>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Contact Details</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>
                                  Mobile No <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="mobileNo"
                                  value={employee.mobileNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Mobile No.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>
                                  Alternate Mobile No <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="alternateMobileNo"
                                  value={employee.alternateMobileNo}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Alternate Mobile No.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>
                                  Email id <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="emailId"
                                  value={employee.emailId}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Email id.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>
                                  Area <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="area"
                                  value={employee.area}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Area.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-12">
                              <div className="inputBox">
                                <label>
                                  Permanent Address <span>*</span>
                                </label>
                                <textarea
                                  className=""
                                  name="permantent_Add"
                                  value={employee.permantent_Add}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Permanent Address.."
                                ></textarea>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-12">
                              <div className="inputBox">
                                <label>
                                  Present Address <span>*</span>
                                </label>
                                <textarea
                                  className=""
                                  name="present_Add"
                                  value={employee.present_Add}
                                  onChange={handleChangeInput}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Present Address.."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Family Details</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-8">
                              <div className="inputBox">
                                <label>
                                  Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  // name='employee_Fname'
                                  // value={employee.employee_Fname}
                                  // onChange={handleChangeInput}
                                  className=""
                                  style={{ width: "100%" }}
                                  placeholder="Enter Name.."
                                ></input>
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <div className="vertical-space-10"></div>
                              <div className="inputBox">
                                <label>
                                  Relationship <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="relationship"
                                  value={employee.relationship}
                                  onChange={handleChangeInput}
                                  className=""
                                  style={{ width: "100%" }}
                                  placeholder="Enter Relationship.."
                                ></input>
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <div className="vertical-space-10"></div>
                              <div className="inputBox">
                                <label>
                                  DOB <span>*</span>
                                </label>
                                <input
                                  type="date"
                                  name="relationShipDOB"
                                  value={employee.relationShipDOB}
                                  onChange={handleChangeInput}
                                  placeholder="DD-MMM-YYYY"
                                  data-date=""
                                  data-date-format="DD MMMM YYYY"
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>
                                  Mobile No <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="relationShipMobileNo"
                                  value={employee.relationShipMobileNo}
                                  onChange={handleChangeInput}
                                  className=""
                                  style={{ width: "100%" }}
                                  placeholder="Enter Mobile No.."
                                ></input>
                                <div className="vertical-space-10"></div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <button className="dfBtn" type="submit">
                                ADD
                              </button>
                              <div className="vertical-space-10"></div>
                            </div>
                          </div>
                          <div className="vertical-space-10"></div>
                          <div className="tableBox">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Relationship</th>
                                  <th scope="col">DOB</th>
                                  <th scope="col">Mobile No</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Name</td>
                                  <td>Relationship</td>
                                  <td>DOB</td>
                                  <td>Mobile No</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Bank Details</Accordion.Header>
                        <Accordion.Body>
                          <div className="inputBox">
                            <label>Bank Name</label>
                            <input
                              type="text"
                              className=""
                              name="bankName"
                              value={employee.bankName}
                              onChange={handleChangeInput}
                              style={{ width: "80%" }}
                              placeholder="Enter Bank Name.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                          <div className="inputBox">
                            <label>
                              Account NO <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="accountNo"
                              value={employee.accountNo}
                              onChange={handleChangeInput}
                              style={{ width: "60%" }}
                              placeholder="Enter Account NO.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                          <div className="inputBox">
                            <label>
                              IFSC Code <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="ifscCode"
                              value={employee.ifscCode}
                              onChange={handleChangeInput}
                              style={{ width: "60%" }}
                              placeholder="Enter IFSC Code.."
                            ></input>
                          </div>
                          <div className="vertical-space-10"></div>
                          <div className="inputBox">
                            <label>
                              Branch <span>*</span>
                            </label>
                            <input
                              type="text"
                              className=""
                              name="branch"
                              value={employee.branch}
                              onChange={handleChangeInput}
                              style={{ width: "60%" }}
                              placeholder="Enter Branch.."
                            ></input>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Insurance Details</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="inputBox">
                                <label>Policy No.</label>
                                <input
                                  type="text"
                                  className=""
                                  name="policyNo"
                                  value={insuranceList.policyNo}
                                  onChange={handleInsurance}
                                  style={{ width: "100%" }}
                                  placeholder="Policy No..."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-12">
                              <div className="inputBox">
                                <label>
                                  Insurance Company <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="insuranceCompany"
                                  value={insuranceList.insuranceCompany}
                                  onChange={handleInsurance}
                                  style={{ width: "100%" }}
                                  placeholder="Insurance Company.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-10">
                              <div className="inputBox">
                                <label>
                                  Nominee <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="nominee"
                                  value={insuranceList.nominee}
                                  onChange={handleInsurance}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Nominee.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-8">
                              <div className="inputBox">
                                <label>
                                  Nominee Realtionship <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className=""
                                  name="nomineeRelation"
                                  value={insuranceList.nomineeRelation}
                                  onChange={handleInsurance}
                                  style={{ width: "100%" }}
                                  placeholder="Nominee Realtionship.."
                                ></input>
                              </div>
                              <div className="vertical-space-10"></div>
                            </div>
                            <div className="col-sm-4">
                              <button
                                className="dfBtn"
                                type="submit"
                                onClick={addInsurance}
                              >
                                ADD
                              </button>
                            </div>
                            <div className="vertical-space-10"></div>
                          </div>
                          <div className="vertical-space-10"></div>
                          <div className="tableBox">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Policy No</th>
                                  <th scope="col">Insurance</th>
                                  <th scope="col">Nominee</th>
                                  <th scope="col">Nominee Realtionship</th>
                                </tr>
                              </thead>
                              <tbody>
                                {insuranceLists.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.policyNo}</td>
                                      <td>{val.insuranceCompany}</td>
                                      <td>{val.nominee}</td>
                                      <td>{val.nomineeRelation}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  ) : null}
                </Accordion>
              </div>
              <div className="btnBox">
                <button className="secondaryBtn" onClick={handleCancel}>
                  List
                </button>
                <button className="dfBtn" type="submit" onClick={saveEmployee}>
                  Submit
                </button>
              </div>
              <Modal
                show={isSuccessModal}
                onHide={handleSuccessModalClose}
                className="SubmitModal"
              >
                <Modal.Body>
                  <div className="Details Success">
                    <div className="imgBox">
                      <Image
                        src={require("../../../gsmecom/images/checked.png")}
                      />
                    </div>

                    <h4>Succesfully {empId ? 'Updated' : 'Submitted'}</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={isFailureModal}
                onHide={handleFailureModalClose}
                className="SubmitModal"
              >
                <Modal.Body>
                  <div className="Details Success">
                    <div className="imgBox">
                      <Image
                        src={require("../../../gsmecom/images/warning.png")}
                      />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default Employee;
