import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import rackAllotmentValidation from './validation';
import { IRackAllotment, RackallotmentDetails } from './model';
import { Autocomplete, TextField } from '@mui/material';

const RackAllotment = observer((): JSX.Element => {
  const { rackAllotmentStore, commonStore, inwardOultetStore } = useStores();
  const [rackAllotment, setRactAllotment] = useState<IRackAllotment>(new IRackAllotment());
  const [rackAllotmentDetail, setRactAllotmentDetail] = useState<RackallotmentDetails>({});
  const [rackAllotmentDetails, setRactAllotmentDetails] = useState<RackallotmentDetails[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const handleChangeview = () => {
    navigate('/Admin/RackAllotment/RackAllotmentDetail');
  }

  const { isloadRackItemDetail, loadRackItemDetails, loadRackItemDetail,
    isloadRackBatchDetail, loadRackBatchDetails, loadRackBatchDetail,
    isOutletLoading, loadOutlets, loadOutlet } = commonStore;

  const handleRackAllotment = (event: any) => {

    const { name, value } = event.target;
    setRactAllotment({ ...rackAllotment, [name]: value });
  }

  const addRackAllotment = async () => {
    let newObject: RackallotmentDetails = {};

    let error: any = {};
    error = rackAllotmentValidation(rackAllotment);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      rackAllotmentDetail.rackAllotitemId = rackAllotment.itemId
      rackAllotmentDetail.rackAllotitemName = rackAllotment.itemName
      rackAllotmentDetail.rackAllotbatchQty = rackAllotment.batchQty
      rackAllotmentDetail.rackAllotbatchNo = rackAllotment.batchNo
      rackAllotmentDetail.rackAllotrackNo = rackAllotment.rackNo

      setSubmitbtn(true);
      setRactAllotmentDetails([...rackAllotmentDetails, rackAllotmentDetail]);
      setRactAllotmentDetail(newObject);

    }
  }
  const handleOutlet = (name: any, event: object, val: any) => {
    if (val != null) {
      setRactAllotment({ ...rackAllotment, [name]: val.outletid, ['outletName']: val.outletName });
    }
    else {
      rackAllotment.outletName = '';
    }
  }
  const handleBatch = (name: any, event: object, val: any) => {
    if (val != null) {
      setRactAllotment({ ...rackAllotment, [name]: val.batchNo, ['batchQty']: val.batchQty });
    }
    else {
      rackAllotment.batchQty = 0;
    }
  }
  const handleItem = async (name: any, event: object, val: any) => {
    if (val != null) {
      setRactAllotment({ ...rackAllotment, [name]: val.itemId, ['itemName']: val.itemName });
      await loadRackBatchDetail(val.itemId);

    }
    else {
      rackAllotment.itemName = '';
    }
  }
  async function saveRackAllotment(e) {
    e.preventDefault();
    setLoading(true)
    const entryNos = await inwardOultetStore.getEntryNo('RackAllotment');
    rackAllotment.rackEntryNo = entryNos.entryNo + 1;

    rackAllotment.rackallotmentDetails = rackAllotmentDetails
    const status = await rackAllotmentStore.saveRackAllotment(rackAllotment);

    if (status === "Success") {
      setLoading(false)
      setSuccessModal(true)
    }
    else {
      setLoading(false)
      setFailureModal(true);
    }

  }
  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/RackAllotment/RackAllotmentDetail');
  }

  async function fetchPreLoadngData() {
    await inwardOultetStore.getEntryNo('RackAllotment')
    await loadRackItemDetail();
    await loadOutlet();
    setLoading(false)
  }
  useEffect(() => {
    fetchPreLoadngData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>

              <div className='hrBox'>
                <h3>Rack Allotment</h3>
              </div>
              <div className='inputBoxLists pd-b-0'>
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text"
                          name='rackEntryNo' disabled
                          value={rackAllotment.rackEntryNo = inwardOultetStore.entryNo.entryNo + 1}
                          onChange={handleRackAllotment}
                          style={{ width: "100%" }} placeholder='Entry No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Date <span>*</span></label>
                        <input type="date"
                          name='entryDate'
                          value={rackAllotment.entryDate}
                          onChange={handleRackAllotment}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.entryDate && <p style={{ color: 'red' }}>{errors.entryDate}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Outlet Name <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadOutlets}
                            getOptionLabel={(option: any) => option.outletName}
                            onChange={(event, val) => handleOutlet('outletid', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select outletName..'
                                name='outletName'
                              />}
                          />
                          {errors.outletName && <p style={{ color: 'red' }}>{errors.outletName}</p>}
                        </Form.Group>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Rack No <span>*</span></label>
                        <input type="text"
                          value={rackAllotment.rackNo} name='rackNo'
                          onChange={handleRackAllotment}
                          className='' style={{ width: "100%" }} placeholder='Rack No..'></input>
                        {errors.rackNo && <p style={{ color: 'red' }}>{errors.rackNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Item <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadRackItemDetails}
                            getOptionLabel={(option: any) => option.itemName}
                            onChange={(event, val) => handleItem('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Item Name..'
                                name='itemName'
                              />}
                          />
                          {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                        </Form.Group>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {loadRackBatchDetails?.length && <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Batch No <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadRackBatchDetails}
                            getOptionLabel={(option: any) => option.batchNo}
                            onChange={(event, val) => handleBatch('batchNo', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Batch No..'
                                name='batchNo'
                              />}
                          />
                          {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                        </Form.Group>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>}
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Batch Qty </label>
                        <input type="text" className=''
                          name='batchQty'
                          value={rackAllotment.batchQty}
                          style={{ width: "100%" }} placeholder='batch Qty..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-2'>
                      <button className='dfBtn' onClick={addRackAllotment}>Add</button>
                    </div>
                    <div className='col-md-12'>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Item Name</th>
                              <th scope="col">Batch No</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Rack No</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              rackAllotmentDetails.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.rackAllotitemName}</td>
                                    <td>{val.rackAllotbatchNo}</td>
                                    <td>{val.rackAllotbatchQty}</td>
                                    <td>{val.rackAllotrackNo}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>

                <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveRackAllotment}>Submit</button>
              </div>

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
      }
    </>
  );

});

export default RackAllotment;
