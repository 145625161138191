import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IOutwardOutlet, IOutwardOutletDetails } from './model';
import { QRCode } from 'react-qrcode-logo';
import outwardoutletValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';
import outletItemRequest from '../../Stores/outletItemRequest/model';
import OutletItemRequest from '../../Stores/outletItemRequest/OutletItemRequest';

const OutwardToOutlet = observer((): JSX.Element => {
  const [outwardOutlet, setOutwardOutlet] = useState<IOutwardOutlet>({});
  const [outItemRequestItem, setOutItemRequestItem] = useState<any[]>([])
  const [batchnoList, setBatchNoList] = useState<any[]>([])
  const [tempOutItemRequest, setTempOutItemRequest] = useState<any[]>([])
  const [outwardOutletDetails, setOutwardOutletDetails] = useState<IOutwardOutletDetails[]>([]);

  console.log(outItemRequestItem, 'outItemRequestItem>')
  const { outwardtoOutletStore, commonStore } = useStores();

  const [isLoading, setLoading] = useState(false);
  const [submitbtn, setSubmitbtn] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [isCheckQtyModal, setCheckQtyModal] = useState(false);
  const [totalQty, setTotalQty] = useState(0);
  const [outletQty, setOutletQty] = useState(0);

  const { loadWarehouse, loadWarehouses } = commonStore;
  console.log(outwardOutlet, 'outwardOutlet');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOutletQty(value.outletQty)
    setOutwardOutlet({ ...outwardOutlet, [name]: value });
  }

  const handleEmployees = (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, createdBy: val.employeeId })
    }
    else {
      outwardOutlet.createdBy = 0;
    }
  }

  const handleOutletItems = async (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, [name]: val.outletid });
      setSpinner(true);
      if (name === 'Barcode') {
        debugger
        const getBarcodeBatch = await outwardtoOutletStore.loadOutletItemRequestnew(3, outwardOutlet?.outletId, outwardOutlet?.warehouseId, outwardOutlet?.outRequestNo,outwardOutlet?.itemId,val.Barcode)
        setBatchNoList([...getBarcodeBatch])
      }
      else {
        const outletrequestItem = await outwardtoOutletStore.loadOutletItemRequestnew(1, val.outletid, outwardOutlet?.warehouseId)
        let assignTempReqQty = outletrequestItem?.map((each) => {
          if (each?.requestedQty) {
            each.tempReqQty = each?.requestedQty;
            each.tempBatchQty = each?.Batchqty;
            return each
          }
          return each
        })
        setOutItemRequestItem([...assignTempReqQty])
        setTempOutItemRequest([...assignTempReqQty])
        setSpinner(false);
      }
      setSpinner(false);
      //  const outletrequestItem = await outwardtoOutletStore.loadOutletItemRequest(val?.outletid, outwardOutlet?.warehouseId);

    }
    else {
      outwardOutlet.outletId = 0;
    }
  }

  const handleoutletRequest = async (name, event, val) => {
    if (val != null) {
      setOutwardOutlet({
        ...outwardOutlet, [name]: val.outletReqno, outRequestNo: val.outletReqno
      });
    }
    else {
      outwardOutlet.outRequestNo = 0;
    }
  }

  async function fetchOultetItems() {
    setLoading(false);
    await Promise.all([outwardtoOutletStore.getEntryNo('OutwardtoOutlet'), loadWarehouse(),
    outwardtoOutletStore.loadOutletItems(), outwardtoOutletStore.getLoadEmployess()
    ]);
  }

  const handleItems = async (name, val) => {
    if (val != null) {
      let itemFilter = outItemRequestItem?.filter((obj) => obj?.itemid === val.itemid && obj?.wid === outwardOutlet?.warehouseId && obj?.outletReqno === outwardOutlet?.outRequestNo)
      setOutwardOutlet({
        ...outwardOutlet,
        itemId: val.itemid, itemName: val.itemname, brandtype: val?.BrandType,
        requestedQty: val?.requestedQty, warehosueStock: itemFilter?.reduce((a, v) => a = a + v?.Batchqty!, 0),
      });
      const getBarcodeBatch = await outwardtoOutletStore.loadOutletItemRequestnew(2, outwardOutlet?.outletId, outwardOutlet?.warehouseId, outwardOutlet?.outRequestNo, val.itemid)
      console.log(getBarcodeBatch, 'getBarcodeBatch');
      setOutItemRequestItem([...getBarcodeBatch])
    }
    else {
      outwardOutlet.itemId = 0;
      outwardOutlet.itemName = '';
    }
  }

  const handleBatch = (name, event, val) => {
    if (name === 'barcode' && val != null) {
      setOutwardOutlet({ ...outwardOutlet, [name]: val.Barcode })
    } else if (name === 'batchId' && val != null) {
      // setBatchNo(val.batchNo);
      setOutwardOutlet({ ...outwardOutlet, [name]: val.batchId, barcode: val?.Barcode, batchNo: val.batchno, tempBatchQty: val?.tempBatchQty, batchQty: val.Batchqty })
    } else {
      outwardOutlet.batchNo = 0;
      outwardOutlet.batchQty = 0;
    }
  }

  const handleWarehouse = (name: any, event: object, val: any) => {

    if (val != null) {
      setOutwardOutlet({ ...outwardOutlet, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    }
    else {
      outwardOutlet.wareHouse = '';
    }
  }

  async function addOutwardDetails(e) {
    const getBarcodeItem = await outwardtoOutletStore.loadOutletItemRequestnew(1, outwardOutlet?.outletId, outwardOutlet?.warehouseId)
    setBatchNoList([...getBarcodeItem])
    console.log(getBarcodeItem,'outletItemADDLIST');
    
    let qty: any = 0;
    e.preventDefault();
    let error: any = {};
    error = outwardoutletValidation(outwardOutlet);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      let outwardOutletDetail: IOutwardOutletDetails = {};
      setOutletQty(0);
      if (outwardOutletDetails.length > 0) {
        const checkQty = outwardOutletDetails.filter(m => m.outwardBatchNo === outwardOutlet.batchNo).reduce((a, v) => a = a + v.outwardOutletQty!, 0);
        const totalWareQty = outwardOutletDetails.filter(m => m.OutwardItemId === outwardOutlet.itemId).reduce((a, v) => a = a + v.outwardOutletQty!, 0);
        qty = Number(outwardOutlet.outletQty) + checkQty;
        if (qty > outwardOutlet.tempBatchQty!) {
          setTotalQty(qty);
          setCheckQtyModal(true);
          return;
        } else {
          outwardOutletDetail.outwardRequestNo = outwardOutlet.outRequestNo
          outwardOutletDetail.OutwardItemId = outwardOutlet.itemId;
          outwardOutletDetail.outwardItemName = outwardOutlet.itemName
          outwardOutletDetail.outwardWarehosueStock = Number(outwardOutlet.warehosueStock! - (Number(outwardOutlet.outletQty)));
          outwardOutletDetail.outwardOutletQty = Number(outwardOutlet.outletQty);
          outwardOutletDetail.outwardBatchNo = outwardOutlet.batchNo;
          outwardOutletDetail.outwardBatchId = outwardOutlet.batchId;
          outwardOutletDetail.barcode = outwardOutlet.barcode ? outwardOutlet.barcode! : '';
          outwardOutletDetail.batchno = outwardOutlet.batchNo;
          outwardOutletDetail.itemName = outwardOutlet.itemName!;
          setOutwardOutletDetails([...outwardOutletDetails, outwardOutletDetail]);
          let updatedList = outItemRequestItem?.map((each) => {
            if (each?.outletReqno === outwardOutlet?.outRequestNo && each?.itemid === outwardOutlet?.itemId) {
              each.requestedQty = each?.requestedQty - Number(outwardOutlet?.outletQty);
              if (each?.Barcode === outwardOutlet?.barcode && each?.batchno === outwardOutlet?.batchNo) {
                each.Batchqty = each?.Batchqty - Number(outwardOutlet?.outletQty!);
              }
              return each
            }
            return each
          })
          console.log(updatedList, outItemRequestItem, 'updatedList>>')
          setOutItemRequestItem([...updatedList])
        }
      } else {
        if (outwardOutlet.outletQty! > outwardOutlet.tempBatchQty!) {
          setTotalQty(outwardOutlet.outletQty!);
          setCheckQtyModal(true);
          return;
        } else {
          outwardOutletDetail.outwardRequestNo = outwardOutlet.outRequestNo
          outwardOutletDetail.OutwardItemId = outwardOutlet.itemId;
          outwardOutletDetail.outwardItemName = outwardOutlet.itemName
          outwardOutletDetail.outwardWarehosueStock = Number(outwardOutlet.warehosueStock! - outwardOutlet.outletQty!);
          outwardOutletDetail.outwardOutletQty = Number(outwardOutlet.outletQty);
          outwardOutletDetail.outwardBatchNo = outwardOutlet.batchNo;
          outwardOutletDetail.outwardBatchId = outwardOutlet.batchId;
          outwardOutletDetail.barcode = outwardOutlet.barcode ? outwardOutlet.barcode! : '';;
          outwardOutletDetail.batchno = outwardOutlet.batchNo;
          outwardOutletDetail.itemName = outwardOutlet.itemName!;
          setOutwardOutletDetails([...outwardOutletDetails, outwardOutletDetail]);

          let updatedList = outItemRequestItem?.map((each) => {
            if (each?.outletReqno === outwardOutlet?.outRequestNo && each?.itemid === outwardOutlet?.itemId) {
              let obj = each;
              obj.requestedQty = obj?.requestedQty - Number(outwardOutlet?.outletQty);
              if (obj?.Barcode === outwardOutlet?.barcode && obj?.batchno === outwardOutlet?.batchNo) {
                obj.Batchqty = obj?.Batchqty - Number(outwardOutlet?.outletQty!);
                obj = each;
              }
              return obj
            }
            return each
          })
          setOutItemRequestItem([...updatedList])
        }
      }
      setSubmitbtn(true);

      setOutwardOutlet({ ...outwardOutlet, itemId: 0, itemName: '', barcode: '', batchNo: '', batchQty: 0, tempBatchQty: 0, warehosueStock: 0, requestedQty: 0, outletQty: 0, batchId: 0 });
    }
  }

  // useEffect(() => {
  //   handleOutRequestItemList()
  // }, [outwardOutletDetails?.length])

  // function handleOutRequestItemList() {
  //   let tempOutReqeustItems = [...tempOutItemRequest];
  //   if (tempOutReqeustItems?.length) {
  //     let updatedList: any[] = [];
  //     tempOutReqeustItems?.map((obj, index) => {
  //       let filterItems = outwardOutletDetails?.filter((item) => item?.OutwardItemId === obj?.itemid)
  //       let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.outwardOutletQty ?? 0), 0)
  //       if (findBalQty < obj?.tempReqQty) {
  //         updatedList?.push(obj)
  //       }
  //     })
  //     setOutItemRequestItem([...updatedList])
  //   }
  // }

  function handleRemove(index) {
    let findObj = outwardOutletDetails![index];
    let updatedList = outItemRequestItem?.map((each) => {
      if (findObj && each?.outletReqno === findObj?.outwardRequestNo && each?.itemid === findObj?.OutwardItemId && each?.Barcode === findObj?.barcode && each?.batchno === findObj?.outwardBatchNo) {
        each.Batchqty = each?.Batchqty + Number(findObj?.outwardOutletQty!)
        each.requestedQty = each?.requestedQty + Number(findObj?.outwardOutletQty)
        return each
      }
      return each
    })
    setOutItemRequestItem([...updatedList])
    outwardOutletDetails?.splice(index, 1)
    setOutwardOutletDetails([...outwardOutletDetails]);
  }

  async function saveOutwardOutlet(e) {
   // setLoading(true);
    e.preventDefault();
    const entryNos = await outwardtoOutletStore.getEntryNo('OutwardtoOutlet');
    outwardOutlet.outwardId = entryNos.entryNo + 1;
    outwardOutlet.outwardOutletDetails = outwardOutletDetails;
    const status = await outwardtoOutletStore.saveOutwardOutlet(outwardOutlet);

    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }

  }

  const handleCancel = () => {
    navigate('/Admin/OutwardToOutlet/OutwardToOutletDetail');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleCheckQtyModalClose = () => setCheckQtyModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/OutwardToOutlet/OutwardToOutletDetail');
  }

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchOultetItems();
      isInitialRender.current = false;
    }
    return () => { };
  }, []);

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Outward To Outlet</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    {
                      isSpinner ?
                        <div className='SpinnerBox'>
                          <Spinner animation="grow" size="sm" />
                        </div> : null
                    }
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text" className=''
                          value={outwardOutlet.outwardId = outwardtoOutletStore.entryNo.entryNo + 1}
                          name='outwardId' disabled onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Entry No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Date <span>*</span></label>
                        <input type="date" style={{ width: "100%" }}
                          value={outwardOutlet.outwardDate = outwardOutlet?.outwardDate ?? new Date().toISOString().substr(0, 10)}
                          defaultValue={new Date().toISOString().substr(0, 10)}
                          name='outwardDate' onChange={handleInputChange}
                          placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.outwardDate && <p style={{ color: 'red' }}>{errors.outwardDate}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {(loadWarehouses?.length > 0) ? <div className='col-sm-4'>
                      <div className='inputBox'>
                        <label>Warehouse <span>*</span></label>
                        <AutoComplete placeholder='Select Warehouse..' clsName='full-width' getOptionLabel='warehouseName'
                          emitOption={(val) => handleWarehouse('warehouseId', {}, val)} options={loadWarehouses} />
                        {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div> : null}

                    {outwardtoOutletStore?.loadEmployess?.length > 0 && <div className='col-sm-4'>
                      <div className='inputBox'>
                        <label>Created by </label>
                        <AutoComplete placeholder='Select Created By..' clsName='full-width' getOptionLabel='employeeName'
                          emitOption={(val) => handleEmployees('createdBy', {}, val)} options={removeDuplicates(outwardtoOutletStore?.loadEmployess, 'employeeName')} />
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>}
                    {outwardtoOutletStore?.outletItemDetails?.length > 0 && <div className='col-md-5'>
                      <div className='inputBox'>
                        <label>Outlet Name<span>*</span> </label>
                        <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                          emitOption={(val) => handleOutletItems('outletId', {}, val)} options={outwardtoOutletStore?.outletItemDetails} />
                        {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>}
                    {outItemRequestItem?.length > 0 &&
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label>Outlet Request No<span>*</span> </label>
                          <AutoComplete placeholder='Select Request No..' clsName='full-width' getOptionLabel='outletReqno'
                            emitOption={(val) => handleoutletRequest('outletItemId', {}, val)} options={removeDuplicates(outItemRequestItem, 'outletReqno')} />
                          {errors.outletItemId && <p style={{ color: 'red' }}>{errors.outletItemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>}
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Requested Qty </label>
                        <input type="text" className='fullInput' name='requestedQty'
                          value={outwardOutlet.requestedQty} disabled
                          style={{ width: "100%" }} placeholder='Requested Qty..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {(outItemRequestItem?.length > 0 && outwardOutlet?.outRequestNo) &&
                      <>
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label>Item <span>*</span></label>
                            <AutoComplete value={outwardOutlet?.itemName} placeholder='Select Item Name..' clsName='full-width' getOptionLabel='itemname'
                              emitOption={(val) => handleItems('warehouseId', val)}
                              options={removeDuplicates(outItemRequestItem, 'itemid')?.filter((obj) => obj?.outletReqno === outwardOutlet?.outRequestNo && obj?.wid == outwardOutlet?.warehouseId && obj?.Batchqty)} />
                            {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        {/* outwardOutlet?.brandtype !== "Own Brand" && */}
                        {
                          <div className="col-md-3">
                            <div className='inputBox'>
                              <label>Barcode </label>
                              <AutoComplete placeholder='Select Barcode' clsName='full-width' getOptionLabel='Barcode'
                                emitOption={(val) => handleOutletItems('Barcode', {}, val)} options={removeDuplicates(outItemRequestItem, 'Barcode')?.filter((each) => Number(each?.itemid) == Number(outwardOutlet?.itemId))} />
                              {/* <AutoComplete value={outwardOutlet?.barcode} placeholder="Select Barcode"
                                emitOption={(val) => handleBatch('barcode', {}, val)}
                                options={removeDuplicates(outItemRequestItem, 'Barcode')?.filter((obj) => obj?.itemid === outwardOutlet?.itemId)}
                                getOptionLabel='Barcode'
                                clsName='full-width'
                              /> */}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>}
                        {outItemRequestItem?.length > 0 && <div className="col-md-3">
                          <div className='inputBox'>
                            <label>Batch No </label>
                            <AutoComplete placeholder="Select Batch No"
                              value={outwardOutlet?.batchNo}
                              emitOption={(val) => handleBatch('batchId', {}, val)}
                              options={removeDuplicates(batchnoList, 'batchno')?.filter((obj) => (outwardOutlet?.brandtype !== "Own Brand" && outwardOutlet?.barcode) ? obj?.Barcode === outwardOutlet?.barcode : (obj?.itemid === outwardOutlet?.itemId) && obj)}
                              getOptionLabel='batchno'
                              clsName='full-width'
                            />
                          </div>
                          {errors.batchId && <p style={{ color: 'red' }}>{errors.batchId}</p>}
                          <div className='vertical-space-10'></div>
                        </div>}
                      </>
                    }
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Warehouse Stock </label>
                        <input type="text" className='fullInput'
                          name='warehouseStock' disabled
                          value={outwardOutlet?.warehosueStock}
                          style={{ width: "100%" }} placeholder='Stock..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Batch Qty </label>
                        <input type="text" className='fullInput' name='batchQty'
                          value={outwardOutlet.batchQty} disabled
                          style={{ width: "100%" }} placeholder='Batch Qty..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Out Qty <span>*</span></label>
                        <input type="text" className='fullInput' style={{ width: "100%" }}
                          value={outletQty}
                          defaultValue={outwardOutlet.outletQty}
                          name='outletQty' onChange={handleInputChange}
                          placeholder='Out Qty..'></input>
                        {errors.outletQty && <p style={{ color: 'red' }}>{errors.outletQty}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-2'>
                      <button className='dfBtn' onClick={addOutwardDetails}>ADD</button>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col">Warehouse Stock</th>
                          <th scope="col">Out Qty</th>
                          <th scope="col">Outlet Request No</th>
                          <th scope="col">Barcode</th>
                          <th scope="col">Batch No</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody style={{ verticalAlign: 'middle' }}>
                        {outwardOutletDetails.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.outwardItemName}</td>
                              <td>{val.outwardWarehosueStock!}</td>
                              <td>{val.outwardOutletQty}</td>
                              <td>{val.outwardRequestNo}</td>
                              <td>{val.barcode}</td>
                              <td><QRCode value={val.outwardBatchNo} size={100} /></td>
                              <td>
                                <button style={{
                                  border: '2px solid #dc3545', background: '#dc3545',
                                  color: 'white', fontWeight: '400', borderRadius: '6px', fontSize: '12px'
                                }}
                                  type='submit' onClick={() => handleRemove(key)}>Remove
                                </button>
                              </td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel} >List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveOutwardOutlet}>Submit</button>
                </div>
              </div>
              {
                outwardOutlet ?
                  <Modal show={isCheckQtyModal} onHide={handleCheckQtyModalClose} className='SubmitModal'>
                    <Modal.Body>
                      <div className='Details Success'>
                        <div className='imgBox'>
                          <Image src={require('../../../gsmecom/images/warning.png')} />
                        </div>

                        <h4>This BatchNo:{outwardOutlet.batchNo} already reached batch quantity</h4>
                        <h4>Actual Batch Quantity:{outwardOutlet.batchQty}</h4>
                        <h4>Entered Out Quantity:{totalQty}</h4>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCheckQtyModalClose}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal> : null
              }

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  )
});

export default OutwardToOutlet;
