import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { ILedgerMaster } from './model';
import { Autocomplete, TextField } from '@mui/material';
import ledgerMasterValidation from './validation';
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';

interface LedgerMasterProps {
  fromPaymentVoucher?: boolean
  paymentVouCallBack?: () => void
}

const LedgerMaster: React.FC<LedgerMasterProps> = observer(({ fromPaymentVoucher, paymentVouCallBack }): JSX.Element => {
  const [ledgerMaster, setLedgerMaster] = useState<ILedgerMaster>({});
  const { ledgerMasterStore, commonStore, ledgerGroupStore } = useStores();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const { isloadLedgerGroup, loadLedgerGroup, loadLedgerGroups, } = commonStore;



  const { ledgerId } = useParams();

  const handleLedgerGroup = (name: any, event: object, val: any) => {
    if (val != null) {
      setLedgerMaster({ ...ledgerMaster, [name]: val.groupId, ['ledgerGroup']: val.ledgerGroupName });
    }
    else {
      ledgerMaster.ledgerGroup = '';

    }
  }

  const handleChangeview = () => {
    navigate('/Admin/LedgerMaster/LedgerMasterDetail');
  }

  async function saveLedgerMaster(e) {

    e.preventDefault();
    let error: any = {};
    error = ledgerMasterValidation(ledgerMaster);;
    setErrors(error)
    if (Object.keys(error).length === 0) {
      setLoading(true);
      ledgerMaster.openingBalance = Number(ledgerMaster.openingBalance);
      ledgerMaster.percentage = Number(ledgerMaster.percentage);

      // const entryNos = await ledgerGroupStore.getEntryNo('LedgerAccount');  
      // ledgerMaster.ledgerId = entryNos.entryNo+1;
      let status: string = '';
      if (ledgerId !== undefined) {
        status = await ledgerMasterStore.updateLedgerMaster(ledgerMaster);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await ledgerMasterStore.saveLedgerMaster(ledgerMaster);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    if (fromPaymentVoucher) {
      setSuccessModal(false)
      paymentVouCallBack!()
    } else {
      navigate('/Admin/LedgerMaster/LedgerMasterDetail');
    }
  }
  const handleInputchnage = (e) => {

    const { name, value } = e.target;
    setLedgerMaster({ ...ledgerMaster, [name]: value });

  };

  async function mapItemData(LedgerDels) {

    ledgerMaster.ledgerId = LedgerDels.ledgerId
    ledgerMaster.entryDate = moment(LedgerDels.entryDate).format('YYYY-MM-DD');
    ledgerMaster.ledgerGroup = LedgerDels.ledgerGroup;
    ledgerMaster.ledgerGroupId = LedgerDels.ledgerGroupId;
    ledgerMaster.ledgerName = LedgerDels.ledgerName;
    ledgerMaster.openingBalance = LedgerDels.openingBalance;
    ledgerMaster.percentage = LedgerDels.percentage;
    ledgerMaster.type = LedgerDels.type;
    setLedgerMaster(ledgerMaster);
  }

  async function fetchPreLoadngData() {
    if (ledgerId !== undefined) {
      const data = await ledgerMasterStore.LedgerMasterDetails(parseInt(ledgerId!));
      mapItemData(data);
    }
    else {
      const data = await ledgerGroupStore.getEntryNo('LedgerAccount');
      if (data !== undefined) {
        ledgerMaster.ledgerId = data.entryNo + 1;
        setLedgerMaster(ledgerMaster);
      }
    }

    await loadLedgerGroup();
    await ledgerGroupStore.getEntryNo('LedgerAccount');
    setLoading(false)
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData()
      isCurrentPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>{
      isLoading ? <ProgressBar /> :
        <>
          <div className='outletInputField inputFormBox'>
            {!fromPaymentVoucher && <div className='hrBox'>
              <h3>Ledger Master</h3>
            </div>}
            <div className='inputBoxLists'>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='inputBox'>
                    <label>Ledger ID </label>
                    <input type="text" className='' style={{ width: "100%" }} name='ledgerId'
                      value={ledgerMaster.ledgerId} disabled
                      onChange={handleInputchnage} placeholder='Ledger ID..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-12'>
                  <div className='inputBox'>
                    <label>Ledger Name <span>*</span></label>
                    <input type="text" className=''
                      onChange={handleInputchnage}
                      name="ledgerName"
                      value={ledgerMaster.ledgerName}
                      style={{ width: "100%" }} placeholder='Ledger Name..'></input>
                    {errors.ledgerName && <p style={{ color: 'red' }}>{errors.ledgerName}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-12'>
                  <div className='inputBox'>
                    <label>Ledger Group <span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={loadLedgerGroups}
                      getOptionLabel={(option: any) => option.ledgerGroupName}
                      onChange={(event, val) => handleLedgerGroup('ledgerGroupId', event, val)}
                      value={loadLedgerGroups.find((option) => option.ledgerGroupName === ledgerMaster.ledgerGroup)}
                      renderInput={(params: any) =>
                        <TextField  {...params} style={{ width: '36ch' }}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select Ledger..'
                          name='ledgerGroupId'
                        />}
                    />
                    {errors.ledgerGroup && <p style={{ color: 'red' }}>{errors.ledgerGroup}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label> Opening Balance <span>*</span></label>
                    <input type="text" className=''
                      onChange={handleInputchnage}
                      name="openingBalance"
                      value={ledgerMaster.openingBalance}
                      style={{ width: "100%" }} placeholder='Opening Balance..'></input>
                    {errors.openingBalance && <p style={{ color: 'red' }}>{errors.openingBalance}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Opening type <span>*</span></label>
                    <select className="form-selected"
                      onChange={handleInputchnage}
                      name="type"
                      value={ledgerMaster.type}
                      style={{ width: "100%" }} id="Openingtype" >
                      <option value="" selected>Select</option>
                      <option value="Credit"> Credit</option>
                      <option value="Debit">Debit </option>
                    </select>
                    {errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Percentage <span>*</span></label>
                    <input type="text"
                      onChange={handleInputchnage}
                      name="percentage"
                      value={ledgerMaster.percentage}
                      style={{ width: "100%" }} placeholder="Ledger Percentage.." ></input>
                    {errors.percentage && <p style={{ color: 'red' }}>{errors.percentage}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Date <span>*</span></label>
                    <input type="date"
                      onChange={handleInputchnage}
                      name="entryDate"
                      value={ledgerMaster.entryDate = ledgerMaster.entryDate ?? new Date().toISOString().substr(0, 10)}
                      style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                    {errors.entryDate && <p style={{ color: 'red' }}>{errors.entryDate}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
              </div>
            </div>
            <div className='btnBox'>
              {!fromPaymentVoucher && <button className='secondaryBtn' onClick={handleChangeview}>List</button>}
              <button className='dfBtn' type='submit' onClick={saveLedgerMaster}>Submit</button>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
    }

    </>
  );

});

export default LedgerMaster;
