import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Autocomplete, TextField } from '@mui/material';
import { CourierPartnerDetails, ICourierPartner } from './model';
import useStores from '../../hooks';
import CourierPartnerValidation from './validation';


function CourierPartnerCreation() {
  const [cityOptions, setCityOptions] = useState([]);
  const [courierPartner, setCourierPartner] = useState<ICourierPartner>({});
  const [courierPartnerDetail, setCourierPartnerDetail] = useState<CourierPartnerDetails>({});
  const [courierPartnerDetails, setCourierPartnerDetails] = useState<CourierPartnerDetails[]>([]);
  const [logo, setLogo] = useState();
  const [fileName, setImageFile] = useState();
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { courierPartnerStore, commonStore, userCreationStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any>({})

  const handleCancel = () => {
    navigate('/Admin/CourierPartnerCreation/CourierPartnerCreationDetail');
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleAccessModalClose = () => setAccessModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/CourierPartnerCreation/CourierPartnerCreationDetail');
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setCourierPartner({ ...courierPartner, [name]: value });
  };

  const addCourierPartner = () => {

    let newObject: CourierPartnerDetails = {};
    let error: any = {};
    error = CourierPartnerValidation(courierPartner);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      courierPartnerDetail.CourierPartnerbranchname = courierPartner.branchname;
      courierPartnerDetail.CourierPartnercontactNumber = courierPartner.contactNumber;
      courierPartnerDetail.CourierPartnercontactperson = courierPartner.contactperson;
      courierPartnerDetail.CourierPartneremailid = courierPartner.mailid;
      setCourierPartnerDetails([...courierPartnerDetails, courierPartnerDetail])
      setCourierPartnerDetail(newObject);
      setCourierPartner({ ...courierPartner, branchname: '', contactNumber: 0, contactperson: '', mailid: '' });
      setSubmitbtn(true);
    }
  }

  const saveCourierPartner = async (e) => {

    e.preventDefault();
    courierPartner.fileName = fileName;
    courierPartner.courierCompanyDetails = courierPartnerDetails
    setLoading(true);
    const status = await courierPartnerStore.saveCourierPartner(courierPartner);


    if (status !== undefined && status.responseMessage === "Success") {
      if (logo !== undefined) {
        courierPartner.logo = status.id;
        await courierPartnerStore.saveCourierImage(logo, courierPartner.logo);
      }
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const getStateCountry = (e: any, value: any) => {

    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      courierPartner.country = countries[0].name;
      setCourierPartner({ ...courierPartner, ['country']: countries[0].name });
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      courierPartner.state = states[0].name;
      setCourierPartner({ ...courierPartner, ['state']: states[0].name });
      setCourierPartner({ ...courierPartner, ['city']: value.name });
    }
    else {
      courierPartner.country = '';
      courierPartner.state = '';
    }
  }

  const handleChangeImage = (e) => {
    courierPartner.imageData = '';
    e.preventDefault();
    setLogo(e.target.files[0]);
    setImageFile(e.target.files[0].name);

  }

  useEffect(() => {
    fetchOultetItems();
  }, [])


  async function fetchOultetItems() {
    const userRights = await Promise.all([userCreationStore?.getUserRight('Courier_partner_creation')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
    await commonStore.loadCities();
    setLoading(false);
  }

  if (isLoadModal) {
    return (
      <div className="container">
        {isAccessModal && <h1>Access Denied</h1>}
        <Modal show={isAccessModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <div className='container'>
        {
          isLoading ? <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Courier Partner Creation</h3>
              </div>
              <div className='inputBoxLists pd-b-0' >
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row' >
                    <div className='col-md-8'>
                      <div className='inputBox'>
                        <label>Cargo Company Name<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }} autoComplete='off'
                          onChange={handleInputchnage}
                          name="cargocompanyName"
                          value={courierPartner.cargocompanyName}
                          placeholder='Cargo Company Name..'></input>
                        {errors.cargocompanyName && <p style={{ color: 'red' }}>{errors.cargocompanyName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Door No<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="doorNo"
                          value={courierPartner.doorNo}
                          placeholder='Door No..'></input>
                        {errors.doorNo && <p style={{ color: 'red' }}>{errors.doorNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Street<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="street"
                          value={courierPartner.street}
                          placeholder='Street..'></input>
                        {errors.street && <p style={{ color: 'red' }}>{errors.street}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Area<span>*</span></label>
                        <input type="text" className=''
                          onChange={handleInputchnage}
                          name="area"
                          value={courierPartner.area}
                          style={{ width: "100%" }} placeholder='Area..'></input>
                        {errors.area && <p style={{ color: 'red' }}>{errors.area}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label >City <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={commonStore.loadCity}
                          getOptionLabel={(option: any) => option.name}
                          onChange={getStateCountry}
                          value={courierPartner.city}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '25ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select City'
                              name='city'
                            />}
                        />
                        {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6 '>
                      <div className='inputBox'>
                        <label >State </label>
                        <Form.Group style={{ width: "120%" }}>
                          <TextField
                            value={courierPartner.state}
                            placeholder='Select State' autoComplete='nope'
                            name='state'
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Company Contact No<span>*</span></label>
                        <input type="text" className='' maxLength={10}
                          onChange={handleInputchnage}
                          name="contactNo"
                          value={courierPartner.contactNo}
                          style={{ width: "100%" }} placeholder='Cargo Company No..'></input>
                        {errors.contactNo && <p style={{ color: 'red' }}>{errors.contactNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Company Mail ID<span>*</span></label>
                        <input type="email" className=''
                          onChange={handleInputchnage}
                          name="emailid"
                          value={courierPartner.emailid}
                          style={{ width: "100%" }} placeholder='Company Mail ID..'></input>
                        {errors.emailid && <p style={{ color: 'red' }}>{errors.emailid}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Company Website Link<span>*</span></label>
                        <input type="text" className=''
                          onChange={handleInputchnage}
                          name="websitelink"
                          value={courierPartner.websitelink}
                          style={{ width: "100%" }} placeholder='Company Website Link..'></input>
                        {errors.websitelink && <p style={{ color: 'red' }}>{errors.websitelink}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Branch Name<span>*</span></label>
                        <input type="text" className=''
                          onChange={handleInputchnage}
                          name="branchname"
                          value={courierPartner.branchname}
                          style={{ width: "100%" }} placeholder='Branch Name..'></input>
                        {errors.branchname && <p style={{ color: 'red' }}>{errors.branchname}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Contact Person<span>*</span></label>
                        <input type="text" className=''
                          onChange={handleInputchnage}
                          name="contactperson"
                          value={courierPartner.contactperson}
                          style={{ width: "100%" }} placeholder='Contact Person..'></input>
                        {errors.contactperson && <p style={{ color: 'red' }}>{errors.contactperson}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Contact Number<span>*</span></label>
                        <input type="text" className='' maxLength={10}
                          onChange={handleInputchnage}
                          name="contactNumber"
                          value={courierPartner.contactNumber}
                          style={{ width: "100%" }} placeholder='Contact Number..'></input>
                        {errors.contactNumber && <p style={{ color: 'red' }}>{errors.contactNumber}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-5'>
                      <div className='inputBox'>
                        <label>Email <span>*</span></label>
                        <input type="email" className=''
                          onChange={handleInputchnage}
                          name="mailid"
                          value={courierPartner.mailid}
                          style={{ width: "100%" }} placeholder='Email..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-5'>
                      <div className='inputBox'>
                        <label>Logo <span>*</span></label>
                        <input type="file" className='' style={{ width: "100%" }} accept='image/*'
                          name='logo' onChange={handleChangeImage} />
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                    <div className='col-sm-2'>
                      <button className='dfBtn' onClick={addCourierPartner}>Add</button>
                    </div>
                    <div className='col-md-12'>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Branch Name</th>
                              <th scope="col">Contact Person </th>
                              <th scope="col">Contact No </th>
                              <th scope="col">Email Id </th>

                            </tr>
                          </thead>
                          <tbody>
                            {
                              courierPartnerDetails.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.CourierPartnerbranchname}</td>
                                    <td>{val.CourierPartnercontactperson}</td>
                                    <td>{val.CourierPartnercontactNumber}</td>
                                    <td>{val.CourierPartneremailid}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveCourierPartner}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        }

      </div>
    );
  }
};

export default CourierPartnerCreation;
