import React, { useState, useEffect, useRef, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
// import { any, PackingInwardDetails } from './model';
import { Autocomplete, TextField } from '@mui/material';
import ProgressBar from '../../common/shared/progressbar';
// import packingInwardValidation from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { INewItemReqest, INewItemRequestDetail } from './model';
import packingInwardValidation from '../packingInward/validation';
import { AddValidation, SubmitValidation } from './validation';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { removeDuplicates } from '../../common/shared/utils';

const NewItemRequest = observer((): JSX.Element => {

    const { commonStore, outwardtoOutletStore, salesReplaceRetrunStore } = useStores();
    const { loadOutlet, loadOutlets, loadEmployee, loadEmployees } = commonStore;

    const [newItemReqest, setNewItemRequest] = useState<INewItemReqest>(new INewItemReqest())
    const [newItemReqDetails, setNewItemReqDetails] = useState<INewItemRequestDetail>(new INewItemRequestDetail())

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});

    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);


    const handleChangeview = () => {
        navigate('/Admin/NewItemRequest/NewItemRequestList');
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/NewItemRequest/NewItemRequestList');
    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;
        if ((name === "itemname" || name === "remarks") && value) {
            setNewItemReqDetails({ ...newItemReqDetails, [name]: value })
        } else {
            setNewItemRequest({ ...newItemReqest, [name]: value })
        }
    }

    function hanldeAutoComplete(name, value) {
        if (name === "outletid" && value != null) {
            setNewItemRequest({ ...newItemReqest, [name]: value?.outletid })
        } else if (name === "requiestby" && value != null) {
            setNewItemRequest({ ...newItemReqest, [name]: value?.employeeId })
        }
    }

    const addPackingInward = async () => {
        let error: any = {};
        error = AddValidation(newItemReqDetails);
        setErrors(error);
        if (Object.keys(error).length === 0) {
            if (!newItemReqest?.neweitemRequestDetails?.length) {
                newItemReqest.neweitemRequestDetails = []
            }
            setNewItemReqDetails({ ...newItemReqDetails, itemname: '', remarks: '' })
            newItemReqest?.neweitemRequestDetails?.push(newItemReqDetails)
            setNewItemRequest(newItemReqest)
        }
    }

    function handleRemove(index) {
        newItemReqest?.neweitemRequestDetails?.splice(index, 1)
        setNewItemRequest(_.cloneDeep(newItemReqest))
    }

    async function savePackingInward(e) {
        e.preventDefault();
        setLoading(true);
        let error: any = {};
        error = SubmitValidation(newItemReqest);
        setErrors(error);

        const entryNos = await outwardtoOutletStore.getEntryNo('NewItemRequest');
        newItemReqest.reqid = entryNos?.entryNo + 1;

        if (Object?.keys(error)?.length === 0) {
            // const status = "Success"
            const status = await salesReplaceRetrunStore.newItemRequest(newItemReqest);

            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            setLoading(false);
        }
    }


    async function fetchPreLoadingData() {
        const entryNo = await outwardtoOutletStore.getEntryNo('NewItemRequest');
        setNewItemRequest({ ...newItemReqest, reqid: entryNo?.entryNo + 1 })
        await loadOutlet();
        await loadEmployee();
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadingData();
            isCurrentPage.current = false;
        }
    }, []);


    return (
        <>
            {
                isLoading ?
                    <ProgressBar /> :
                    <>
                        <div className='container'>
                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox'>

                                <div className='hrBox'>
                                    <h3>New Item Request</h3>
                                </div>
                                <div className='inputBoxLists pd-b-0'>
                                    <div className='LgInputListsBox mr-left-0'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Entry No <span>*</span></label>
                                                    <input type="text" className=''
                                                        name='reqid' disabled
                                                        value={newItemReqest.reqid}
                                                        style={{ width: "100%" }} placeholder='Entry No..'></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Date <span>*</span></label>
                                                    <input type="date"
                                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                                        name='reqdate'
                                                        value={newItemReqest.reqdate = newItemReqest?.reqdate ?? new Date().toISOString().substr(0, 10)}
                                                        onChange={handleChangeInput}
                                                        style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                                    {errors.reqdate && <p style={{ color: 'red' }}>{errors.reqdate}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Outlet Name  <span>*</span></label>
                                                    <AutoComplete placeholder='Select OuletName..' options={loadOutlets} getOptionLabel="outletName" emitOption={(options) => hanldeAutoComplete('outletid', options)} />
                                                    {errors.outletid && <p style={{ color: 'red' }}>{errors.outletid}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label> Req By<span>*</span></label>
                                                    <AutoComplete placeholder='Select OuletName..' options={removeDuplicates(loadEmployees, 'employeeName')} getOptionLabel="employeeName" emitOption={(options) => hanldeAutoComplete('requiestby', options)} />
                                                    {errors.requiestby && <p style={{ color: 'red' }}>{errors.requiestby}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='inputBox'>
                                                    <label>Item Name<span>*</span></label>
                                                    <input type="text" className=''
                                                        name='itemname'
                                                        value={newItemReqDetails?.itemname}
                                                        onChange={handleChangeInput}
                                                        style={{ width: "100%" }} placeholder='Entry Item Name..'></input>
                                                    {errors.itemname && <p style={{ color: 'red' }}>{errors.itemname}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-8'>
                                                <div className='inputBox'>
                                                    <label>Remarks <span>*</span></label>
                                                    <textarea className='' style={{ width: "100%" }}
                                                        name='remarks'
                                                        value={newItemReqDetails?.remarks}
                                                        onChange={handleChangeInput}
                                                        placeholder='Remarks..'></textarea>
                                                    {errors.remarks && <p style={{ color: 'red' }}>{errors.remarks}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <button className='dfBtn' onClick={addPackingInward}>Add</button>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Item Name</th>
                                                                <th scope="col">Remarks</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {newItemReqest?.neweitemRequestDetails?.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{val.itemname}</td>
                                                                        <td>{val.remarks}</td>
                                                                        <td><button onClick={() => handleRemove(key)} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {errors.neweitemRequestDetails && <p style={{ color: 'red' }}>{errors.neweitemRequestDetails}</p>}
                                                <div className='vertical-space-10'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                                    <button className='dfBtn' type='submit' onClick={savePackingInward}>Submit</button>
                                </div>

                                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>
                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/checked.png')} />
                                            </div>

                                            <h4>Succesfully Submitted</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>

                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/warning.png')} />
                                            </div>
                                            <h4>Failed</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleFailureModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </>

            }
        </>
    );

});

export default NewItemRequest;
