import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Spinner, Modal, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate, } from 'react-router-dom';
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { QRCode } from 'react-qrcode-logo';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const SalesReturnOrReplacementList = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const { salesReplaceRetrunStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const navigate = useNavigate();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    preLoadingData();
  }
  const handleCreate = () => {
    navigate('/Admin/SalesReturnOrReplacement');
  }

  async function preLoadingData() {
    setLoading(false);   
    const [userRights] = await ([userCreationStore?.getUserRight('Sales_return_or_replacement'), salesReplaceRetrunStore.getSalesRetrunDetails()])
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }
  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      preLoadingData();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  return (

    <div className='container-fluid'>
      {
        isLoading ? <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> : <>
          <div className='vertical-space-20'></div>
          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={salesReplaceRetrunStore?.returnreplaceDetails}
            searchTitles={[{ key: "posId", value: "POS Voucher" }]}
            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope='col'>Entry No </th>
                  <th scope='col'>Date  </th>
                  <th scope='col'>POS Voucher </th>
                  <th scope='col'>Return Item  </th>
                  <th scope='col'>Return Batch  </th>
                  <th scope='col'>Repalcement Item  </th>
                  <th scope='col'>Repalcement Batch  </th>
                  <th scope='col'>Return Qty </th>
                  <th scope='col'>Reason </th>
                  <th scope='col'>Entered By </th>
                  <th scope='col'>Create </th>
                  <th scope='col'>Update </th>
                  <th scope='col'>Delete </th>
                </tr>
              </thead>
               <tbody>
                {!isLoading &&
                  // (isSearch ? filteredData : salesReplaceRetrunStore?.returnreplaceDetails)?.map((val, key) => {
                  (isSearch ? filteredData : salesReplaceRetrunStore?.returnreplaceDetails)?.length > 0 &&
                  (isSearch? filteredData : salesReplaceRetrunStore?.returnreplaceDetails)?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val.returnNo}</td>
                        <td>{val.returnDate}</td>
                        <td>{val.posno}</td>
                        <td>{val.itemName}</td>
                        <td><QRCode value={val.batchNo} size={50} /></td>
                        <td>{val.replaceItemName}</td>
                        {
                          val.replaceBatchNo ? <td><QRCode value={val.replaceBatchNo} size={50} /></td> : <td>{val.replaceBatchNo}</td>
                        }
                        <td>{val.returnQty}</td>
                        <td>{val.reason}</td>
                        <td>{val.enteredByName}</td>
                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                      </tr>
                    )
                  }
                  )}
              </tbody> 
            </table>
          </div>
        </>
      }


      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>

            <h4>Succesfully Submitted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

});

export default SalesReturnOrReplacementList;
