import { faAdd, faPenToSquare, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import useStores from '../../hooks';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import moment from 'moment';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import ImageCoverter from '../../common/shared/imagePrinter';
import { IPrintSizeMaster } from './model';

const tableHeadings = ["Packingid", "Packingdate", "Team Name", "Machine Name", "", "Create", "Update", "Delete", "Print"]

const PackingPlanList = () => {
  const navigate = useNavigate()

  const { machineMasterStore, itemMasterStore, packingDistributionStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const { packingSizeMasterSave, getPackingSizeMaster } = packingDistributionStore;

  const [packingDetails, setPackingDetails] = useState<any[]>([])

  const [isSpinner, setSpinner] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isPrintSizeSuccess, setPrintSizeSuccess] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [modal, setModal] = useState({ bool: false, packid: 0 })

  const [isPrintSize, setPrintSize] = useState(false)
  const [printSizeMaster, setPrintSizeMaster] = useState<IPrintSizeMaster>(new IPrintSizeMaster())
  const [printSizeList, setPrintSizeList] = useState<any>([])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  async function handlePrintSizeSave() {
    const status = await packingSizeMasterSave(printSizeMaster)
    if (status === 'Success') {
      setSpinner(false);
      setPrintSizeSuccess(true);
      fetchPrintSizeMaster();
      setPrintSizeMaster(new IPrintSizeMaster())
      setPrintSize(false)
    } else {
      setSpinner(false);
      setFailureModal(true);
    }
  }

  async function fetchPrintSizeMaster() {
    const getPrintSizeMaster = await getPackingSizeMaster()
    console.log(getPrintSizeMaster, 'getPrintSizeMaster>>')
    let printSizeList = getPrintSizeMaster?.map((each) => {
      if (each?.packid) {
        each.id = each?.packid;
        each.height = each?.Height;
        each.width = each?.Width;
        each.label = `${Number(each?.Width)}${each?.Unit} x ${Number(each?.Height)}${each?.Unit}`;
        return each;
      }
      return each;
    })
    setPrintSizeList([...printSizeList])
  }

  function updateItems(machineData, itemData) {
    let updatedArray: any[] = Object.assign([], machineData);
    if (itemData?.length) {
      itemData?.forEach((items) => {
        const { itemid, ...itemInfo } = items;
        updatedArray?.forEach((pack) => {
          const { Batchno, ...packInfo } = pack;
          let expiryDate = new Date(pack?.Packingdate);
          expiryDate.setDate(expiryDate.getDate() + Number(items?.expiryDays));
          if (Batchno) {
            let findItemId = Batchno?.split('/');
            if (findItemId && findItemId[2] == itemid) {
              pack['itemId'] = itemid;
              pack['mrp'] = items.MRP;
              pack['uspper'] = items.purchasePrice;
              pack['packofdate'] = moment(pack?.Packingdate)?.format('DD-MM-YYYY');
              pack['usedbydate'] = moment(expiryDate)?.format('DD-MM-YYYY');
            }
          }
        })
      })
    }
    return updatedArray;
  }

  async function fetchPackingPlans() {
    setSpinner(false);
    const [userRights, itemMasterDetails] = await ([userCreationStore?.getUserRight('Packing_plan'), itemMasterStore.getItemMasterDetails(), machineMasterStore.getPackingPlanList()])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    setPackingDetails([...updateItems(machineMasterStore?.packingPlanList, itemMasterDetails)])
  }

  const handleCreate = () => {
    navigate('/Admin/PackingPlan')
  }

  function editPackingPlan(Packingid) {
    navigate('/Admin/PackingPlan/' + Packingid)
  }

  const deletePackingPlanDetail = async (Packingid) => {
    setSpinner(true);
    // const status = "Success";
    const status = await machineMasterStore.deleteMachineMaster(Packingid);

    if (status === 'Success') {
      fetchPackingPlans();
      setSpinner(false);
      setSuccessModal(true);
    }
    else {
      setSpinner(false);
      setFailureModal(true);
    }
  }

  const [printModal, setPrintModal] = useState({ bool: false, packid: 0 })

  function handleClosePrint() {
    setPrintModal({ bool: false, packid: 0 });
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchPackingPlans();
      fetchPrintSizeMaster();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  useEffect(() => {
    if (packingDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(packingDetails)
    }
  }, [packingDetails])

  const goToPage = (value: number) => {
    let removeDup = removeDuplicates(packingDetails, 'Packingid')
    const currentPageList = currentPageDatas(removeDup?.slice()?.sort((a, b) => b.Packingid - a.Packingid), value, 5)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <div className="flex alignItemCenter justifyContentCenter">
        <div className='outletInputField inputFormBox LgInputField' style={{ width: '80%' }}>
          <div className='hrBox'>
            <h3>Packing Plan Details</h3>
          </div>
          <div className='vertical-space-20'></div>
          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={removeDuplicates(packingDetails, 'Packingid')?.slice()?.sort((a, b) => b.Packingid - a.Packingid)}
            searchTitles={[{ key: "itemname", value: "Item Name" }, { key: "MachineName", value: "Machine Name" }]}
            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} buttonChildren={<button className='searchclearBtn' onClick={() => setPrintSize(true)}>Printing Size</button>} />
          <div className='vertical-space-20'></div>
          <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='btnBox'>
              <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{packingDetails?.length} Records</button>
            </div>
            <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
              <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
            </Stack>
          </div>
          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  {tableHeadings?.map((heading, index) => {
                    return (
                      <th key={index}>{heading}</th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {(isSearch ? filteredData : currentPageData).length > 0 &&
                  (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val?.Packingid}</td>
                        <td>{moment(val?.Packingdate).format("DD-MMM-YYYY")}</td>
                        <td>{val?.TeamName}</td>
                        <td>{val?.MachineName}</td>
                        <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => setModal({ bool: true, packid: val?.Packingid })}>Details</button></td>
                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editPackingPlan(val.Packingid)} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deletePackingPlanDetail(val.Packingid)} /></button></td>
                        <td><button disabled={!userRights?.print} className="Edit" onClick={() => setPrintModal({ bool: true, packid: val?.Packingid })}><FontAwesomeIcon icon={faPrint} /></button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>

          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Deleted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isPrintSizeSuccess} onHide={() => setPrintSizeSuccess(false)} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>
                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setPrintSizeSuccess(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={printModal?.bool} onHide={handleClosePrint} className='PriceHistoryModel'>
            <Modal.Header><h4>Image Print</h4></Modal.Header>
            <Modal.Body>
              <ImageCoverter printSizeList={printSizeList} printObj={(packingDetails)?.find((each) => each?.Packingid === printModal?.packid)} />
            </Modal.Body>
          </Modal>
          <Modal show={isPrintSize} onHide={() => setPrintSize(false)} className='PriceHistoryModel'>
            <Modal.Header closeButton>Print Size Master</Modal.Header>
            <Modal.Body>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='inputBoxLists'>
                  <div className="row">
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Width <span>*</span> </label>
                        <input type="text" className='' style={{ width: "100%" }} name='record_no'
                          placeholder='Width..' onChange={(event) => !isNaN(Number(event?.target?.value)) && setPrintSizeMaster({ ...printSizeMaster, width: Number(event?.target?.value) })}></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Height <span>*</span> </label>
                        <input type="text" className='' style={{ width: "100%" }} name='record_no'
                          placeholder='Height..' onChange={(event) => !isNaN(Number(event?.target?.value)) && setPrintSizeMaster({ ...printSizeMaster, height: Number(event?.target?.value) })}></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Unit <span>*</span> </label>
                        <select value={printSizeMaster?.unit} onChange={(event) => setPrintSizeMaster({ ...printSizeMaster, unit: event?.target?.value })}>
                          <option value="" selected>Select</option>
                          <option value="inch" selected>Inch</option>
                          <option value="mm" selected>MM</option>
                        </select>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                  <div className='btnBox'>
                    <button className='secondaryBtn' onClick={() => setPrintSize(false)}>Cancel</button>
                    <button className='dfBtn' type='submit' onClick={handlePrintSizeSave}>Submit</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={modal?.bool} onHide={() => setModal({ bool: false, packid: 0 })} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {["Item Name", "qty", "UOM", "Batch No"]?.map((title) => {
                        return (
                          <th scope='col'>{title}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {(packingDetails)?.length > 0 &&
                      (packingDetails)?.map((val, key) => {
                        return val?.Packingid === modal?.packid && (
                          <tr key={key}>
                            <td>{val?.itemname}</td>
                            <td>{val?.qty}</td>
                            <td>{val?.Unitname}</td>
                            <td><QRCode value={val?.Batchno} size={100} /> </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
          <div className='vertical-space-20'></div>
        </div>
      </div>
    </div >
  )
}


export default PackingPlanList