import React, { useEffect, useRef, useState } from 'react'
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import AutoComplete from '../../common/shared/autoComplete'
import { useNavigate, useParams } from 'react-router-dom'
import useStores from '../../hooks';
import { IStockWarehouse, StockToWarehouseDetails } from './modal';
import { StkToWrhDetailValidation, StkToWrhValidation } from './validation';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';

const StockToWarehouse = observer((): JSX.Element => {

    const navigate = useNavigate();
    const { stockToWid } = useParams()
    const { commonStore, receiptStore, outletManagerStore, } = useStores();

    const { loadWarehouse, loadWarehouses } = commonStore;
    const { saveStockToWare, loadWarehousePackingItem, getStockToWarehouseDetails } = receiptStore;

    const [stckToWrhsSave, setStckToWrhsSave] = useState<IStockWarehouse>(new IStockWarehouse())
    const [stocktoWDet, setStocktoWDet] = useState<StockToWarehouseDetails>(new StockToWarehouseDetails())

    const [loadWarehousePackingItems, setloadWarehousePackingItems] = useState<any[]>([])
    const [tempWarehousePackingItems, settempWarehousePackingItems] = useState<any[]>([])

    // console.log(loadWarehousePackingItems, 'loadWarehousePackingItems>>')
    const [errors, setError] = useState<any>({})

    const [isLoading, setLoading] = useState(true)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    function handleInputchnage(e) {
        const { name, value } = e.target;
        if (name === "qty" && value !== undefined) {
            let error: any = {}
            const assignQty = Number(value);
            if (assignQty! > Number(stocktoWDet?.balQty!)) {
                error.qty = "Exceeds from Batch Quantity"
            }
            setError(error);
            setStocktoWDet({ ...stocktoWDet, [name]: Number(value) })
        } else {
            setStckToWrhsSave({ ...stckToWrhsSave, [name]: value })
        }
    }

    const hanldeAutoComplete = (name: any, val: any) => {
        if (name === "warehouseId" && val != undefined) {
            setStckToWrhsSave({ ...stckToWrhsSave, [name]: val?.warehouseId })
        } else if (name === "Itemid" && val != undefined) {
            setStocktoWDet({ ...stocktoWDet, [name]: val?.Itemid, itemname: val?.itemname, pidid: val?.pid })
        } else if (name === 'batchNo' && val != undefined) {
            setStocktoWDet({ ...stocktoWDet, batchNo: val?.Batchno, balQty: val?.Balqty })
        } else if (name === 'pid') {
            setStocktoWDet({ ...stocktoWDet, pid: Number(val) })
        }
    }

    function handleAddtoGrid() {
        let error: any = {};
        error = StkToWrhDetailValidation(stocktoWDet, stckToWrhsSave);
        setError(error)
        if (Object.keys(error)?.length === 0) {
            if (!stckToWrhsSave?.stocktoWarehousedetails) {
                stckToWrhsSave.stocktoWarehousedetails! = new Array<StockToWarehouseDetails>();
            }
            stckToWrhsSave?.stocktoWarehousedetails?.push(stocktoWDet)
            setStckToWrhsSave(stckToWrhsSave)
            let updatedList = loadWarehousePackingItems?.map((each) => {
                if (each?.Itemid === stocktoWDet?.Itemid && each?.Batchno === stocktoWDet?.batchNo && each?.pid === stocktoWDet?.pid) {
                    each.Balqty = Number(each.Balqty) - stocktoWDet?.qty!;
                    return each
                }
                return each
            })
            setloadWarehousePackingItems([...updatedList]);
            setStocktoWDet({ ...stocktoWDet, pid: 0, Itemid: 0, itemname: '', batchNo: '', balQty: '', qty: 0 })
        }
    }

    useEffect(() => {
        handleOutRequestItemList()
    }, [stckToWrhsSave?.stocktoWarehousedetails?.length])

    function handleOutRequestItemList() {
        let tempWPackingItems = [...tempWarehousePackingItems];
        if (tempWPackingItems?.length) {
            let updatedList: any[] = [];
            tempWPackingItems?.map((obj, index) => {
                let filterItems = stckToWrhsSave?.stocktoWarehousedetails?.filter((item) => item?.Itemid === obj?.Itemid && item?.batchNo === obj?.Batchno)
                let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.qty ?? 0), 0)
                if (findBalQty! < obj?.Balqty) {
                    updatedList?.push(obj)
                }
            })
            setloadWarehousePackingItems([...updatedList])
        }
    }

    function handleRemove(index) {
        let findObj = stckToWrhsSave!.stocktoWarehousedetails![index];
        let updatedList = loadWarehousePackingItems?.map((each) => {
            if (findObj && each?.Itemid === findObj?.Itemid && each?.Batchno === findObj?.batchNo && each?.pid === findObj?.pid) {
                each.Balqty = Number(each.Balqty) + findObj?.qty!;
                return each
            }
            return each
        })
        setloadWarehousePackingItems([...updatedList]);
        stckToWrhsSave!.stocktoWarehousedetails!.splice(index, 1)
        setStckToWrhsSave(_.cloneDeep(stckToWrhsSave))
    }

    function handleCancel() {
        navigate('/Admin/StockToWarehouse/List')
    }

    async function saveDamageEntryWarehouse() {

        let error: any = {};
        error = StkToWrhValidation(stckToWrhsSave);
        setError(error)
        if (Object.keys(error)?.length === 0) {
            const sumbitArr = stckToWrhsSave?.stocktoWarehousedetails?.map((obj) => {
                let newObj = {
                    ...obj,
                    stocktoWId: stckToWrhsSave?.stocktoWId
                }
                return newObj
            })

            stckToWrhsSave.stocktoWarehousedetails = sumbitArr
            // const status = "Success";
            const status = await saveStockToWare(stckToWrhsSave);

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        }
    }

    function mapToData(data) {

        let findObj = receiptStore?.stockToWarehouseList?.find((obj) => obj?.StocktoWId === Number(stockToWid))
        stckToWrhsSave.stocktoWId = findObj?.StocktoWId;
        stckToWrhsSave.stocktoWDate = findObj?.StocktoWDate;
        stckToWrhsSave.warehouseId = findObj?.WarehouseId;

        let objArr = data?.map((obj) => {
            let mapObj: StockToWarehouseDetails = {
                Itemid: obj?.Itemid,
                qty: obj?.Qty,
                batchNo: obj?.BatchNo,
                stocktoWId: obj?.StocktoWdId
            }
            return mapObj
        })
        stckToWrhsSave.stocktoWarehousedetails = objArr;
        setStckToWrhsSave(stckToWrhsSave)

    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadingData() {
        if (stockToWid) {
            const [paymentList] = await Promise.all([getStockToWarehouseDetails(stockToWid), getStockToWarehouseDetails()])
            if (paymentList) {
                mapToData(paymentList)
            }
        } else {
            const entryNo = await outletManagerStore.getEntryNo('Stocktowarehouse');
            setStckToWrhsSave({ ...stckToWrhsSave, stocktoWId: (entryNo?.entryNo + 1) })
        }
        setLoading(false);
        const [loadWarehousePackingItems] = await Promise.all([loadWarehousePackingItem(), loadWarehouse()])
        setloadWarehousePackingItems(loadWarehousePackingItems);
        settempWarehousePackingItems([...loadWarehousePackingItems]);
    }

    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadingData();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> : null}
            <div className="container">
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox '>
                    <div className='hrBox'>
                        <h3>Stock to Warehouse</h3>
                    </div>
                    <div className='inputBoxLists'>
                        <div className="row">
                            <div className='col-md-4'>
                                <div className='inputBox'>
                                    <label>Entry No <span>*</span></label>
                                    <input type="text" className=''
                                        name='stocktoWId' disabled
                                        value={stckToWrhsSave.stocktoWId}
                                        style={{ width: "100%" }} placeholder='Entry No..'></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-4'>
                                <div className='inputBox'>
                                    <label>Entry Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        onChange={handleInputchnage}
                                        name="stocktoWDate"
                                        value={stckToWrhsSave.stocktoWDate = stckToWrhsSave?.stocktoWDate ?? new Date().toISOString().substr(0, 10)}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                    {errors.stocktoWDate && <p style={{ color: 'red' }}>{errors.stocktoWDate}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='col-md-12'>
                                <div className='inputBox'>
                                    <label>Warehouse <span>*</span></label>
                                    <AutoComplete clsName='full-width' getOptionLabel='warehouseName' options={loadWarehouses}
                                        placeholder='Select Warehouse'
                                        value={loadWarehouses?.length ?
                                            loadWarehouses?.find((obj) => obj?.warehouseId === stckToWrhsSave?.warehouseId)?.warehouseName :
                                            stckToWrhsSave?.warehouseId}
                                        emitOption={(option) => hanldeAutoComplete("warehouseId", option)} />
                                    {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-6'>
                                <div className='inputBox'>
                                    <label>Production Entry No <span>*</span></label>
                                    <select className='full-width' aria-placeholder='Select Production No' value={stocktoWDet?.pid} onChange={(event) => hanldeAutoComplete("pid", event?.target?.value)}>
                                        <option selected value={""}>Select</option>
                                        {removeDuplicates(loadWarehousePackingItems, 'pid')?.map((each, index) => {
                                            return (
                                                <option key={index} value={`${each?.pid}`}>{each?.pid}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.pid && <p style={{ color: 'red' }}>{errors.pid}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-6'>
                                <div className='inputBox'>
                                    <label>Item <span>*</span></label>
                                    <AutoComplete clsName='full-width' options={removeDuplicates(loadWarehousePackingItems, 'Itemid')?.filter((each) => each?.pid === stocktoWDet?.pid)}
                                        placeholder='Select Item' value={stocktoWDet?.itemname}
                                        emitOption={(option) => hanldeAutoComplete("Itemid", option)} getOptionLabel='itemname' />
                                    {errors.Itemid && <p style={{ color: 'red' }}>{errors.Itemid}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            {stocktoWDet?.Itemid! != 0 &&
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Batch No <span>*</span></label>
                                        <AutoComplete clsName='full-width' options={loadWarehousePackingItems?.filter(each => each?.Itemid === stocktoWDet?.Itemid && each?.pid === stocktoWDet?.pid)}
                                            placeholder='Select Item' value={stocktoWDet?.batchNo}
                                            emitOption={(option) => hanldeAutoComplete("batchNo", option)} getOptionLabel='Batchno' />
                                        {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>}
                            <div className='col-sm-4'>
                                <div className='inputBox'>
                                    <label>Qty <span>*</span></label>
                                    <input type="text" className='' style={{ width: "100%" }}
                                        name='qty'
                                        value={stocktoWDet?.qty}
                                        onChange={handleInputchnage}
                                        placeholder='Qty..'></input>
                                    {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-4'>
                                <div className='inputBox'>
                                    <label>UOM<span>*</span></label>
                                    <input type="text" className=''
                                        value={stocktoWDet?.Itemid ? loadWarehousePackingItems?.find((obj) => obj?.Itemid === stocktoWDet?.Itemid)?.UOM : ''}
                                        disabled name="uom"
                                        style={{ width: "100%" }} placeholder='Uom..'></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='col-md-4'>
                                <div className='inputBox'>
                                    <label>Balance Qty <span>*</span></label>
                                    <input type="text" style={{ width: '100%' }}
                                        name="balQty"
                                        disabled
                                        value={Number(stocktoWDet?.balQty! ?? 0) - Number(stocktoWDet?.qty ?? 0)}
                                        placeholder="Select Batch No.."
                                    ></input>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='btnBox justify-flex-start pd-t-0'>
                                    <button className='dfBtn' type='submit' onClick={handleAddtoGrid}>ADD</button>
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='tableBox' style={{ position: 'relative', maxHeight: '300px', overflow: 'auto' }}>
                            <table className="table table-striped">
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th scope="col">Item</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">BatchNo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stckToWrhsSave?.stocktoWarehousedetails?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{tempWarehousePackingItems?.find((obj) => obj?.Itemid === val?.Itemid)?.itemname}</td>
                                                <td>{val.qty}</td>
                                                <td>{tempWarehousePackingItems?.find((obj) => obj?.Itemid === val?.Itemid)?.UOM}</td>
                                                <td>{val?.batchNo}</td>
                                                <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {errors.stocktoWarehousedetails && <p style={{ color: 'red' }}>{errors.stocktoWarehousedetails}</p>}
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit' onClick={saveDamageEntryWarehouse}>Submit</button>
                        </div>
                    </div>
                </div>
                <Modal show={isSuccessModal} onHide={handleCancel} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../../image/checked.png')} />
                            </div>

                            <h4>Succesfully Submitted</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancel}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>

                            <div className='imgBox'>
                                <Image src={require('../../../../image/warning.png')} />
                            </div>
                            <h4>Failed</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setFailureModal(false)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
})

export default StockToWarehouse