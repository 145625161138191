import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../APIAgent/index';
import { wait } from '@testing-library/user-event/dist/utils';
import { IsubCategories } from '../commonStore/model';
import { IBrand } from '../../Warehouse/Brand/model';
import { City } from 'country-state-city';
import moment from 'moment';
interface cityModel {
    name?: string;
    statusCode?: string;
    countryCode?: string;
}
class commonStore {
    @observable loadtaxs = new Map();
    @observable loadSuppliers = new Map();
    @observable loadbrands = new Map();
    @observable loadSubcategories = new Map();
    @observable isLoading = true;
    @observable brands = new Map<string, IBrand>();
    @observable isbrandLoading = true;
    @observable istaxLoading = true;
    @observable isSupplierLoading = true;
    @observable isSubcategory = true;
    @observable loadWarehouses = new Map();
    @observable isWarehousesLoading = true;
    @observable loadItemMasters = new Map();
    @observable loadOwnItemMasters = new Map();
    @observable isItemMasterLoading = true;
    loadOutlets = new Map();
    isOutletLoading = true;

    loadEmployees = new Map();
    isloadEmployeeLoading = true;
    loadItemInwards = new Map();
    isloadItemInwardLoading = true;


    loadManageOffersOrDiscounts = new Map();
    isloadManageOffersOrDiscounts = true;

    loadManagePriceMrps = new Map();
    isloadManagePriceMrp = true;
    loadLedgerGroups = new Map();
    isloadLedgerGroup = true;

    loadItemConversions = new Map();
    isloadItemConversion = true;

    loadUnitOfMeasures = new Map();
    isloadUnitOfMeasure = true;
    loadCity: any = [];
    loadPackingItems = new Map();
    isloadPackingItem = true;

    loadBatchDetails = new Map();
    isloadBatchDetail = true;

    loadTeamDetails = new Map();
    isloadTeamDetail = true;

    loadBatchPackingDetails = new Map();
    isloadBatchPackingDetail = true;

    loadShiftDetails = new Map();
    isloadShiftDetail = true;
    isloadAssignOrders = true;

    loadTerminalDetails = new Map();
    isloadTerminalDetail = true;

    loadRackBatchDetails = new Map();
    loadAssignOrderItems = new Map();

    loadRackItemDetails = new Map();

    loadManageLoyaltyDetails = new Map();
    isloadManageLoyalty = true;

    loadAssignOrderDetails = new Map();
    isloadAssignOrder = true;

    AssignItemDetails = new Map();
    isloadAssignItemDetails = true;
    loadAssignOrderItemDetails = new Map();

    loadDespatchOrderDetails = new Map();
    isloadDespatchOrder = true;

    getEmployees = new Map();
    isgetEmployeeLoading = true;

    loadDamageItemDetails = new Map();
    isloadDamageItem = true;

    loadDamageWarehouseItemDetails = new Map();
    isloadDamageWarehouseItem = true;

    loadIndentItemNames = new Map();
    isloadIndentItemName = true;

    loadIndentWarehouses = new Map();
    isloadIndentWarehouse = true;

    loadIndentOutlets = new Map();
    isloadIndentOutlet = true;

    loadItems = new Map();

    @observable loadNotification = new Map();
    @observable isLoadNotification = true;
    isExist = false;
    constructor() {
        makeAutoObservable(this);

    }
    loadNotificationMasters = async (userid) => {
        try {
            const taxs = await apiAgent.commonMasters.loadNotificationMasters(userid);
            const minimumStock = await apiAgent.adminReports.getMinimumStock();
            if (minimumStock?.length && window && window.location.pathname !== '/Admin/MinimumStockReport') {
                let body = {
                    aleartMessage: "Low Level Stocks Appears",
                    formname: null,
                    ndate: moment(),
                    nid: 0,
                    userid: 0
                }
                taxs?.push(body)
            }
            runInAction(() => {
                this.loadNotification = taxs;
                this.isLoadNotification = false;
            })
            return this.loadNotification;
        }
        catch (error) {
            console.log(error);
        }
    }
    closeNotification = async (nid) => {
        try {
            const closeNotify = await apiAgent.commonMasters.closeNotification(nid);
            return closeNotify
        } catch (error) {
            console.log(error)
        }
    }
    loadTaxMastes = async () => {
        try {
            const taxs = await apiAgent.commonMasters.loadTaxMastes();
            runInAction(() => {
                this.loadtaxs = taxs;
                this.istaxLoading = false;
            })
            return this.loadtaxs;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadSupplierMastes = async (flag) => {
        try {
            const taxs = await apiAgent.commonMasters.loadSuppliers(flag);
            runInAction(() => {
                this.loadSuppliers = taxs;
                this.isSupplierLoading = false;
            })
            return taxs
        }
        catch (error) {
            console.log(error);
        }
    }
    loadBrands = async () => {
        try {
            const brands = await apiAgent.brand.loadBrands();
            runInAction(() => {
                this.loadbrands = brands;
                this.isbrandLoading = false;
            })
            return this.loadbrands;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadSubcategory = async () => {
        try {
            const subcategories = await apiAgent.commonMasters.loadSubcategory();
            runInAction(() => {
                this.loadSubcategories = subcategories;
                this.isSubcategory = false;
            })
            return this.loadSubcategories;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadWarehouse = async () => {
        try {

            const warehouses = await apiAgent.commonMasters.loadWarehouses();

            runInAction(() => {

                this.loadWarehouses = warehouses;
                this.isWarehousesLoading = false;
            })
            return this.loadWarehouses;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadItemMaster = async () => {
        try {
            const itemMaster = await apiAgent.commonMasters.loadItemMaster();
            runInAction(() => {
                this.loadItemMasters = itemMaster;
                this.isItemMasterLoading = false;
            })
            return itemMaster;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadOwnItemMaster = async () => {
        try {
            const itemMaster = await apiAgent.commonMasters.loadOwnItemMaster();
            runInAction(() => {
                this.loadOwnItemMasters = JSON.parse(itemMaster);
            })
            return this.loadOwnItemMasters;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadWarehouseStockJSON = async (warehouseId) => {
        try {
            const warehouseStockJSON = await apiAgent.commonMasters.loadWarehouseStockJSON(warehouseId);
            return JSON.parse(warehouseStockJSON)
        }
        catch (error) {
            console.log(error);
        }
    }
    loadOutlet = async () => {
        try {
            const outlet = await apiAgent.commonMasters.loadOutlet();
            this.loadOutlets = outlet;
            runInAction(() => {
                this.loadOutlets = outlet;
                this.isOutletLoading = false;
            })
            return this.loadOutlets;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployee = async () => {
        try {
            const outlet = await apiAgent.commonMasters.loadEmployee();
            this.loadEmployees = outlet;
            runInAction(() => {
                this.loadEmployees = outlet;
                this.isloadEmployeeLoading = false;
            })
            return this.loadEmployees;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEmployee = async () => {
        try {
            const outlet = await apiAgent.commonMasters.getEmployee();
            this.getEmployees = outlet;
            runInAction(() => {
                this.getEmployees = outlet;
                this.isgetEmployeeLoading = false;
            })
            return this.loadEmployees;
        }
        catch (error) {
            console.log(error);
        }
    }
    usercreationEmployee = async () => {
        try {

        }
        catch (error) {
            console.log(error)
        }
    }
    loadIteminward = async (loadOptions) => {
        try {
            const Iteminward = await apiAgent.commonMasters.loadIteminward(loadOptions);
            this.loadItemInwards = Iteminward;
            runInAction(() => {

                this.loadItemInwards = Iteminward;
                this.isloadItemInwardLoading = false;
            })

            return this.loadItemInwards;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInwardItems = async (loadOptions) => {
        try {
            const items = await apiAgent.commonMasters.loadInwardItems(loadOptions);
            this.loadItems = items;
            runInAction(() => {

                this.loadItems = items;
            })

            return this.loadItemInwards;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadManageOfferOrDiscount = async () => {
        try {
            const Iteminward = await apiAgent.commonMasters.loadManageOfferOrDiscount();
            this.loadManageOffersOrDiscounts = Iteminward;
            runInAction(() => {
                this.loadManageOffersOrDiscounts = Iteminward;
                this.isloadManageOffersOrDiscounts = false;
            })
            return this.loadManageOffersOrDiscounts;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadManagePriceMrp = async () => {
        try {
            const ManagePriceMrp = await apiAgent.commonMasters.loadManagePriceMrp();

            runInAction(() => {

                this.loadManagePriceMrps = ManagePriceMrp;
                this.isloadManagePriceMrp = false;
            })
            return this.loadManagePriceMrps;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadLedgerGroup = async () => {
        try {
            const loadLedgerGroup = await apiAgent.commonMasters.loadLedgerGroup();

            runInAction(() => {

                this.loadLedgerGroups = loadLedgerGroup;
                this.isloadLedgerGroup = false;
            })
            return this.loadLedgerGroups;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadItemConversion = async () => {
        try {
            const loadItemConversion = await apiAgent.commonMasters.loadItemConversion();

            runInAction(() => {

                this.loadItemConversions = loadItemConversion;
                this.isloadItemConversion = false;
            })
            return loadItemConversion;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadUnitOfMeasure = async () => {
        try {
            const loadUnitOfMeasure = await apiAgent.commonMasters.loadUnitOfMeasure();


            runInAction(() => {

                this.loadUnitOfMeasures = loadUnitOfMeasure;
                this.isloadUnitOfMeasure = false;
            })
            return this.loadUnitOfMeasures;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadCities = async () => {
        try {

            const cityData = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');

            runInAction(() => {

                this.loadCity = cityData;
            })
            return this.loadCity;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadPackingItem = async () => {
        try {
            const loadPackingItem = await apiAgent.commonMasters.loadPackingItem();
            runInAction(() => {
                this.loadPackingItems = JSON.parse(loadPackingItem);
                this.isloadPackingItem = false;
            })
            return JSON.parse(loadPackingItem);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadBatchDetail = async () => {
        try {
            const loadBatchDetail = await apiAgent.commonMasters.loadBatchDetail();


            runInAction(() => {

                this.loadBatchDetails = loadBatchDetail;
                this.isloadBatchDetail = false;
            })
            return this.loadBatchDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadTeamDetail = async () => {
        try {
            const loadTeamDetail = await apiAgent.commonMasters.loadTeamDetail();


            runInAction(() => {

                this.loadTeamDetails = loadTeamDetail;
                this.isloadTeamDetail = false;
            })
            return this.loadTeamDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadBatchPackingDetail = async () => {
        try {
            const loadBatchPackingDetail = await apiAgent.commonMasters.loadBatchPackingDetail();


            runInAction(() => {

                this.loadBatchPackingDetails = loadBatchPackingDetail;
                this.isloadBatchPackingDetail = false;
            })
            return this.loadBatchPackingDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    IsExistMobileNo = async (mobileNo) => {
        try {
            const isExist = await apiAgent.helper.IsExistMobileNo(mobileNo);

            runInAction(() => {

                this.isExist = isExist;
            })
            return this.isExist;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadShiftDetail = async () => {
        try {
            const loadShiftDetail = await apiAgent.commonMasters.loadShiftDetail();


            runInAction(() => {

                this.loadShiftDetails = loadShiftDetail;
                this.isloadShiftDetail = false;
            })
            return this.loadShiftDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadTerminalDetail = async () => {
        try {
            const loadTerminalDetail = await apiAgent.commonMasters.loadTerminalDetail();


            runInAction(() => {

                this.loadTerminalDetails = loadTerminalDetail;
                this.isloadTerminalDetail = false;
            })
            return this.loadTerminalDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadRackBatchDetail = async (itemId) => {
        try {
            const loadRackBatchDetail = await apiAgent.rackAllotment.loadRackBatchDetail(itemId);


            runInAction(() => {

                this.loadRackBatchDetails = loadRackBatchDetail;
            })
            return this.loadBatchPackingDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadRackItemDetail = async () => {
        try {
            const loadRackItemDetail = await apiAgent.commonMasters.loadRackItemDetail();


            runInAction(() => {

                this.loadRackItemDetails = loadRackItemDetail;
            })
            return this.loadBatchPackingDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadManageLoyaltyDetail = async () => {
        try {
            const loadManageLoyaltyDetail = await apiAgent.commonMasters.loadManageLoyaltyDetail();


            runInAction(() => {

                this.loadManageLoyaltyDetails = loadManageLoyaltyDetail;
                this.isloadManageLoyalty = false;
            })
            return this.loadManageLoyaltyDetails;
        }
        catch (error) {
            console.log(error);
        }
    }


    loadAssignOrderDetail = async () => {
        try {
            const loadAssignOrderDetail = await apiAgent.commonMasters.loadAssignOrderDetail();


            runInAction(() => {

                this.loadAssignOrderDetails = loadAssignOrderDetail;
                this.isloadAssignOrder = false;
            })
            return this.loadManageLoyaltyDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDespatchOrder = async () => {
        try {

            const loadDespatchOrder = await apiAgent.commonMasters.loadDespatchOrder();


            runInAction(() => {

                this.loadDespatchOrderDetails = loadDespatchOrder;
                this.isloadDespatchOrder = false;
            })
            return this.loadManageLoyaltyDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageItem = async (outletId) => {
        try {
            const loaddamageItem = await apiAgent.commonMasters.loadDamageItem(outletId);
            runInAction(() => {
                this.loadDamageItemDetails = loaddamageItem;
                this.isloadDamageItem = false;
            })
            return loaddamageItem;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageWarehouseItem = async (warehouseId) => {

        try {
            const loaddamagewarehouseItem = await apiAgent.commonMasters.loadDamageWarehouseItem(warehouseId);
            runInAction(() => {
                this.loadDamageWarehouseItemDetails = loaddamagewarehouseItem;
                this.isloadDamageWarehouseItem = false;
            })
            return loaddamagewarehouseItem;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadIndentItemName = async () => {

        try {
            const loadIndentItemName = await apiAgent.commonMasters.loadIndentItemName();

            runInAction(() => {
                this.loadIndentItemNames = loadIndentItemName;
                this.isloadIndentItemName = false;
            })
            return this.loadIndentItemNames;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadIndentWarehouse = async () => {

        try {
            const loadIndentWarehouse = await apiAgent.commonMasters.loadIndentWarehouse();

            runInAction(() => {
                this.loadIndentWarehouses = loadIndentWarehouse;
                this.isloadIndentWarehouse = false;
            })
            return this.loadIndentWarehouses;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadIndentOutlet = async () => {

        try {
            const loadIndentOutlet = await apiAgent.commonMasters.loadIndentOutlet();

            runInAction(() => {
                this.loadIndentOutlets = loadIndentOutlet;
                this.isloadIndentOutlet = false;
            })
            return this.loadIndentOutlets;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default commonStore;