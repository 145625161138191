import { useState, useEffect, useRef, useMemo, } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { IInvoice, IInvoiceAddress, IInvoiceProducts } from './model';
import { Modal, Image, Button, Spinner, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { IAddress, IConfirmPaymentPayload, IEntryNoRequest, IMemberDetails, IPOSProductDetails, IPaymentRequest, IPosBatchDetails, IPossalesMaster } from '../../Admin/POS/model';
import { QRCode } from 'react-qrcode-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Country, State, City } from 'country-state-city';
import invoiceValidation from './validation';
import _ from 'lodash';
import BarcodeBatches from '../../Admin/POS/common/batchDetail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NewMember from '../NewMember/NewMemberRegistration';
import DeliveryAddressModal from '../../Admin/POS/common/deliveryAddress';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';

const Invoice = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { invoiceStore, commonStore, posMasterStore, memberStore } = useStores();
  const { getNewmember, newmemberList } = memberStore;

  const [posProductList, setPosProductList] = useState<any[]>(posMasterStore?.loadPOSProducts)
  const [posItemMaster, setPosItemMaster] = useState<any[]>(posMasterStore?.posItemMaster)
  const [posItemOffers, setPosItemOffers] = useState<any[]>(posMasterStore?.getItemOffers)
  const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)
  const [posBatchDetails, setPOSBatchDetails] = useState<IPosBatchDetails[]>(new Array<IPosBatchDetails>());

  const [salesOrderList, setSalesOrderList] = useState<any[]>([])
  const [salesLedgerList, setSalesLedgerList] = useState<any[]>([])
  const [selectedLedgerList, setSelectedLedgerList] = useState<any[]>([])

  const [posDetail, setPosDetail] = useState<IPossalesMaster>({});
  const [posItemDetils, setPOStItemDetails] = useState<IPOSProductDetails[]>([]);
  const [salesOrderItems, setSalesOrderItems] = useState<any>([])

  const [isAgainstPo, setAgainsPo] = useState('')

  const [barcode, setBarcode] = useState<string>('');
  const [isBarcodeBatches, setBarcodeBatches] = useState({ bool: false, batchArr: Array<any>() })

  const [newMemberModal, setNewMemberModal] = useState(false);
  const [memberDetail, setMemberDetail] = useState<IMemberDetails>({});
  const [selectedMember, setSelectedMember] = useState<any>(null)
  // console.log(selectedLedgerList, 'selectedLedgerList>>')

  const [isDelivery, setDelivery] = useState(false);
  const [invoiceAddress, setInvoiceAddress] = useState<IInvoiceAddress>({});
  const [address, setAddress] = useState<IAddress>({});
  const [isNewAddress, setNewAddress] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);

  const [assignedOffers, setAssignedOff] = useState<any[]>([])

  const [invoice, setInvoice] = useState<IInvoice>({});

  const [isLoading, setLoading] = useState(true);
  const [addMoreItem, setAddMoreItem] = useState(false);
  const [isSpinner, setSpinner] = useState(false)
  const [isBatchModal, setBatchModalClose] = useState(false);

  const [isShippingAddress, setShippingAddress] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [errors, setErrors] = useState<any>({})

  const batchModalClose = () => {
    setBatchModalClose(false);
  }

  const shippingModalClose = () => {
    setShippingAddress(false);
  }

  const handleCancel = () => {
    navigate("/Admin/Invoice/InvoiceDetail")
  }

  const handleOutlet = async (val, outletId) => {
    if (val != null) {
      setLoading(true);
      let request: IEntryNoRequest = {};
      request.flag = 'Invoice';
      request.outletId = val.outletid;
      setInvoice({ ...invoice, outletId: val.outletid });
      const posProductList = await posMasterStore.loadPOSProductList(val.outletid);
      setPosProductList([...posProductList])
      await posMasterStore.getPosBillNo(request);
      setLoading(false);
    }
  }

  const handlebatchNo = (e, value) => {

    let { checked } = e.target;

    const existing = posBatchDetails.filter(m => m.batchNo === value.batchNo)
    if (checked === true) {
      if (existing.length === 0) {
        let posBatchDetail: IPosBatchDetails = {};
        posBatchDetail.batchid = value.batchid;
        posBatchDetail.batchNo = value.batchNo;
        posBatchDetail.batchQty = value.batchQty;
        posBatchDetail.itemId = value.itemId;
        setPOSBatchDetails([...posBatchDetails, posBatchDetail])
      }
    } else {
      const posBatchDetail = posBatchDetails.filter(m => m.batchid !== value.batchid)
      setPOSBatchDetails([])
      setPOSBatchDetails(posBatchDetail)
    }
  }

  const handlebatchdetails = (e, batchid) => {
    setPOSBatchDetails(posBatchDetails.map((item, i) => {
      if (item.batchid === batchid) {
        return { ...item, batchQty: Number(e.target.value) };
      }
      return item;
    }));
    setInvoice({ ...invoice, itemQty: Number(e.target.value) });
  }

  const handleMembers = async (val, memberId) => {
    debugger
    if (val != null) {
      let findMember = invoiceStore.memberDetails?.find((each) => each?.mid === memberId)

      console.log(findMember, "findMember>>")
      await posMasterStore.getPOSCustomerDetails(findMember.mobileno)
      setMemberDetail({
        ...memberDetail,
        memberId: posMasterStore.posCustomerDetails.memberId,
        memberType: posMasterStore.posCustomerDetails.memberType,
        memberCategory: posMasterStore.posCustomerDetails.memberCategory,
        memberCatId: posMasterStore?.posCustomerDetails?.memberCategory?.split('-')![0],
        memberName: posMasterStore.posCustomerDetails.memberName,
        memberAddress: posMasterStore.posCustomerDetails.memberAddress,
        mobileNo: val?.memberMoblieNo,
        memberEmail: posMasterStore.posCustomerDetails.memberEmail,
        earnedPoints: posMasterStore.posCustomerDetails.earnedPoints,
        balanceEarnedPoints: posMasterStore.posCustomerDetails.balanceEarnedPoints,
        redeemPoints: posMasterStore.posCustomerDetails.redeemPoints
      })
      let findObj = newmemberList?.find((each) => each?.memberId === findMember?.mid)
      setSelectedMember({ ...findObj });
      setInvoice({
        ...invoice, salesOrderNo: val?.SOrdMNo?.toString(), memberId: val.mid, customerCategory: val.cust_type, gstIn: val.gstin, memberEmail: val.emailid, mobileNo: val?.mobileno,
        memberType: val.memberType
      });
    } else {
      setInvoice({
        ...invoice, memberId: 0
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value })
  }

  const handleNewAddress = () => {
    setShippingAddress(false);
    setNewAddress(true);
  }

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceAddress({ ...invoiceAddress, [name]: value })
  }

  const handleCountryChange = (name, event, val) => {
    let getCities: any;
    getCities = City.getAllCities().filter(m => m.countryCode === val.isoCode);
    setCityOptions(getCities);
    setInvoiceAddress({ ...invoiceAddress, invoiceCountry: val.name })
  }

  const handleCityChange = (name, event, val) => {
    if (val !== undefined) {
      let getState: any;
      getState = State.getAllStates().find(m => m.countryCode === val.countryCode && m.isoCode === val.stateCode);
      setInvoiceAddress({ ...invoiceAddress, invoiceCity: val.name, invoiceState: getState.name })
    } else {
      invoiceAddress.invoiceCity = '';
    }

  }

  const handleAddress = (event) => {
    setInvoiceAddress({ ...invoiceAddress, invoiceAddressId: memberDetail?.memberId })
    setDelivery(false)
  }

  async function handleSaveInvoice(e) {
    let error: any = {};
    error = invoiceValidation(invoice, invoiceAddress, isAgainstPo);
    if (!posItemDetils?.length) {
      error.addItems = 'Add Items to Grid'
    }
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      if (invoice.paymentMode !== undefined && invoice.paymentMode === 'Cash') {
        setLoading(true)
        savePOSMaster();
      } else if (invoice.paymentMode === 'Online') {
        setLoading(true)
        const paymentRequest: IPaymentRequest = {};
        let amount: any = 0;
        paymentRequest.name = memberDetail.memberName;
        paymentRequest.email = memberDetail.memberEmail !== "" ? memberDetail.memberEmail : undefined;
        paymentRequest.phoneNumber = memberDetail.mobileNo;
        paymentRequest.address = memberDetail.memberAddress;
        invoice.netvalue! = Number(posItemDetils?.length && posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount!), 0)?.toFixed(2));
        amount! = invoice.billValue ? invoice.billValue : invoice.netvalue;

        if (invoice.deliveryCharge !== undefined) {
          invoice.netvalue = amount! + Number(invoice.deliveryCharge);
        } else {
          invoice.netvalue = amount!;
        }

        if (billDisounct !== undefined) {
          invoice.netvalue = amount! - Number(billDisounct);
        } else {
          invoice.netvalue = amount!;
        }

        if (selectedLedgerList?.length) {
          invoice.netvalue = invoice?.netvalue + selectedLedgerList?.reduce((a, v) => a = a + v?.SaleOrdLedgerAmount!, 0)
        }

        paymentRequest.amount = Number(invoice.netvalue!) - billDisounct +
          (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, v) => a = a + v?.SaleOrdLedgerAmount!, 0) : 0);

        const response = await posMasterStore.ProceedPayment(paymentRequest);
        if (!response) {
          setLoading(false)
        }

        const options = {
          amount: paymentRequest.amount,
          name: 'Ganga Super Market',
          description: 'Ganga Super Market',
          image: '../../../image/Ganga_SuperMarket-1.png',
          order_id: response?.orderId,
          handler: async (response) => {
            setSpinner(true)
            let confirmPaymentPayload: IConfirmPaymentPayload = {}
            confirmPaymentPayload.razorpay_order_id = response?.razorpay_order_id;
            confirmPaymentPayload.razorpay_payment_id = response?.razorpay_payment_id;
            confirmPaymentPayload.razorpay_signature = response?.razorpay_signature;

            const staus = await posMasterStore.confirmPayment(confirmPaymentPayload);

            if (staus === 'Payment Successful') {
              savePOSMaster();
            }
          },
          prefill: {
            name: posDetail?.memberName,
            email: paymentRequest?.email
          },
          theme: {
            color: '#DC3545'
          }
        };

        const _window = window as any;
        const rzp1 = new _window.Razorpay(options);
        rzp1.open();
        setSpinner(false)
      }
    }
  }

  async function savePOSMaster() {
    invoice.invoiceAddress = invoiceAddress;
    let invoiceProductDetails: IInvoiceProducts[] = posItemDetils?.map((obj) => {
      let invoiceProducts: IInvoiceProducts = {
        productRowId: obj?.postRowId,
        productId: obj?.posItemId,
        productName: obj?.posItemName,
        productQty: obj?.posItemQty,
        productMRPPrice: obj?.posItemMRP,
        productSellingPrice: obj?.posItemSellingRate,
        productTaxAmount: obj?.posTaxValue,
        productTaxPercentage: obj?.posTaxPercentage,
        productDiscountPercentage: obj?.posTaxPercentage,
        productDiscountValue: obj?.posDiscountValue,
        netAmount: obj?.posNetAmount,
        totalAmount: obj?.posTotalPrice,
        productDiscountMode: obj?.posDiscountMode,
        productTaxId: obj?.PosTaxId,
      }
      return invoiceProducts;
    });
    invoice.invoiceProductsDetails! = invoiceProductDetails;
    invoice.netvalue = Number(posItemDetils.reduce((a, v) => a = a + Number(v.posNetAmount!), 0).toFixed(2)) + Number(invoice?.deliveryCharge! ?? 0) - billDisounct +
      (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, v) => a = a + v?.SaleOrdLedgerAmount!, 0) : 0);
    invoice.taxValue = Number(posItemDetils.reduce((a, v) => a = a + Number(v.posTaxValue!), 0).toFixed(2));
    invoice.discountValue = Number(posItemDetils.reduce((a, v) => a = a + Number(v.posDiscountValue!), 0).toFixed(2));
    invoice.employeeId = Number(Cookies.get('employeeId'));
    invoice.itemQty = Number(posItemDetils.reduce((a, v) => a = a + Number(v.posItemQty!), 0));
    invoice.invoiceBatchDetails = posBatchDetails;
    invoice.offdiscount = billDisounct;

    const billNo = posMasterStore?.entryNo?.billNo?.split('-');
    invoice.invoiceId = (billNo?.length > 0) ? Number(billNo[1]) : 0;

    // const status = await posMasterStore.savePosMaster(invoice);
    const status = await invoiceStore?.saveDirectInvoicesave(invoice);

    console.log(status, invoice, 'Saveddd>>>>')
    if (status === "Success") {
      setLoading(false)
      setSuccessModal(true);
    } else {
      setLoading(false)
      setFailureModal(true)
    }
  }

  const handleUpdateInvoice = async (order) => {
    let filterList = salesOrderList?.filter((each) => each?.SOrdMNo === order?.SOrdMNo)
    if (filterList?.length && posProductList?.length) {
      handleMembers(order, order?.mid)
      setInvoiceAddress({
        ...invoiceAddress,
        invoiceArea: filterList[0]?.SOrdMArea,
        invoiceCity: filterList[0]?.SOrdMCity,
        invoiceContactNo: filterList[0]?.SOrdMConactNo,
        invoceFullName: filterList[0]?.SOrdMCustName,
        invoiceCountry: "",
        invoiceDoorAndSt: filterList[0]?.SOrdMDoorNo,
        invoicePincode: filterList[0]?.SOrdMPincode,
        invoiceState: filterList[0]?.SOrdMState,
      })
      let posIProdList = filterList?.map((each) => {
        let itemObj = posProductList?.find((obj) => obj?.itemid === Number(each?.SOrdDItemID))
        if (itemObj) {
          itemObj.itemId = each?.itemid;
          itemObj.itemName = each?.itemname;
          itemObj.SOrdDMRP = each?.SOrdDMRP;
          itemObj.SOrdDRate = each?.SOrdDRate;
          itemObj.SOrdDAmt = each?.SOrdDAmt;
          itemObj.SOrdDGST = each?.SOrdDGST;
          itemObj.SOrdDQty = each?.SOrdDQty;
          return itemObj
        }
      })
      setSalesOrderItems([])
      setSalesOrderItems([...posIProdList])

      let filterLedger = salesLedgerList?.filter((each) => each?.SaleOrdId === order?.SOrdDMID)
      if (filterLedger?.length) {
        setSelectedLedgerList([...filterLedger])
      }
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    navigate('/Admin/Invoice/InvoiceDetail');
  }

  async function handleItemDetails(val, type) {
    if (val != null) {
      if (isAgainstPo !== "" && !addMoreItem) {
        let findIndex = salesOrderItems?.findIndex((each) => each?.itemid === val?.itemId)
        if (findIndex !== -1) {
          salesOrderItems.splice(findIndex, 1)
          setSalesOrderItems([...salesOrderItems])
        }
      }
      let itemObj = posProductList?.find((obj) => obj?.itemid === val?.itemId)
      getPosBatchStocks(itemObj?.Barcode ? itemObj?.Barcode : itemObj?.Gsmcode)
    }
  }

  function getPosBatchStocks(barcode) {
    setBarcode('');
    let itemBatchList: any[] = []
    itemBatchList = posProductList?.filter((obj) => obj?.Barcode == barcode)
    if (itemBatchList?.length === 0) {
      itemBatchList = posProductList?.filter((obj) => obj?.Gsmcode === barcode)
    }
    if (barcode) {
      if (itemBatchList?.length > 1) {
        // open batch modal to choose batch details
        setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: itemBatchList })
      } else if (itemBatchList?.length === 1) {
        // add to cart function 
        // array has one obj send it to add to cart function
        let itemDetail: IPOSProductDetails = {
          posItemId: itemBatchList![0]?.itemid,
          availableQty: itemBatchList![0]?.stock,
          posItemName: itemBatchList![0]?.itemname,
          posItemQty: 1,
          posItemMRP: Number(itemBatchList![0]?.Mrprate),
          posItemSellingRate: Number(memberDetail?.memberCatId === 'B2B' ? itemBatchList![0]?.BTBSellingRate : itemBatchList![0]?.BTCSellingRate),
          posTaxPercentage: itemBatchList![0]?.taxper,
          posDiscountPercentage: itemBatchList![0]?.Discount_Pv,
          posDiscountValue: itemBatchList![0]?.Discount_Pv,
          posDiscountMode: itemBatchList![0]?.Dis_Mode,
          posbatchNo: itemBatchList![0]?.batchno,
          posBarcode: itemBatchList![0]?.Barcode,
          posSubCategoryId: Number(itemBatchList![0]?.subcategory)
        }
        addToCart(itemDetail)
      }
    }
  }

  function isEqual(currentItem, newItem) {
    return (
      currentItem?.posItemMRP == newItem?.posItemMRP &&
      currentItem?.posItemSellingRate == newItem?.posItemSellingRate
    )
  }

  function addToCart(itemBatch: IPOSProductDetails) {

    let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemBatch))
    let taxValue: number = 0;
    let discountValue: number = 0;
    let offerDiscountValue: number = 0;
    let totalPrice: number = 0;

    if (itemBatch?.posTaxPercentage) {
      taxValue = (Number(itemBatch?.posItemSellingRate) - (Number(itemBatch?.posItemSellingRate!) / (1 + itemBatch?.posTaxPercentage! / 100)))?.toFixed(2) as any;
    }

    posItemOffers?.map((offers) => {
      if (new Date() > new Date(offers?.effectivefromDate) && new Date() < new Date(offers?.effectivetoDate)) {
        if (offers?.AssinOfferType === "products" && offers?.itemId && itemBatch?.posItemId === offers?.itemId) {
          if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
            let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
            if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } else if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc %") {
            let sumTotalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any;
            if (sumTotalPrice >= offers.From_QV && sumTotalPrice <= offers.To_QV) {
              offerDiscountValue = sumTotalPrice * (itemBatch?.posDiscountPercentage! / 100)
            } else {
              offerDiscountValue = 0;
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc %") {
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = itemQty * (itemBatch?.posDiscountPercentage! / 100)
            } else {
              offerDiscountValue = 0;
            }
          } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Value") {

          } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Disc %") {

          }
        }
        if (offers?.AssinOfferType === "category" && offers?.categoryId && itemBatch?.posSubCategoryId === offers?.categoryId) {
          if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
            let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
            if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc %") {

          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = (itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!);
              setAssignedOff(assignedOffers?.filter((obj) => obj?.offerid != offers?.offerid))
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc %") {

          }
        }
      }
    })

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

    itemBatch.posItemQty = itemBatch?.posItemQty;
    itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
    itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
    itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
    itemBatch.posTaxValue = Number(taxValue);
    itemBatch.posDiscountValue = discountValue + offerDiscountValue;
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount);

    const freeItems = checkForFreeItem(itemBatch)

    if (!isexistsItem) {
      posItemDetils?.push(itemBatch);
      if (freeItems) {
        posItemDetils?.push(freeItems)
        let batchObj: IPosBatchDetails = {
          itemId: freeItems?.posFreeItemId,
          barcode: freeItems?.posBarcode?.toString(),
          batchNo: freeItems?.posbatchNo,
          batchQty: freeItems?.posItemQty
        }
        if (batchObj?.itemId) {
          posBatchDetails?.push(batchObj)
          setPOSBatchDetails(posBatchDetails)
        }
      }
      setPOStItemDetails(_.cloneDeep(posItemDetils))
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode?.toString(),
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      if (batchObj?.itemId) {
        posBatchDetails?.push(batchObj)
        setPOSBatchDetails(posBatchDetails)
      }
    } else {
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode,
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.availableQty
      }
      setPOStItemDetails(posItemDetils.map((item, i) => {
        if (item.posItemId === itemBatch.posItemId && itemBatch?.posbatchNo === item?.posbatchNo) {
          return item = itemBatch;
        }
        return item;
      }));
      setPOStItemDetails(posItemDetils.map((item, i) => {
        if (!item?.posItemId && item?.posFreeItemId === freeItems?.posFreeItemId && item?.posbatchNo === freeItems?.posbatchNo) {
          return item = freeItems;
        }
        return item;
      }));
      if (freeItems) {
        let batchObj: IPosBatchDetails = {
          itemId: freeItems?.posFreeItemId,
          barcode: freeItems?.posBarcode?.toString(),
          batchNo: freeItems?.posbatchNo,
          batchQty: freeItems?.posItemQty
        }
        if (batchObj?.itemId) {
          setPOSBatchDetails(posBatchDetails?.map((obj) => {
            if (batchObj?.itemId && obj?.itemId === batchObj?.itemId && obj?.batchNo === obj?.batchNo) {
              return obj = batchObj;
            }
            return obj
          }))
        }
      } else if (batchObj?.itemId) {
        setPOSBatchDetails(posBatchDetails?.map((obj) => {
          if (batchObj?.itemId && obj?.itemId === batchObj?.itemId && obj?.batchNo === obj?.batchNo) {
            return obj = batchObj;
          }
          return obj
        }))
      }
    }
    setPosProductList(posProductList.map((item, i) => {
      if (item.itemid === itemBatch.posItemId && itemBatch?.posbatchNo === item?.batchno) {
        item.stock = item.stock - itemBatch?.posItemQty!
        return item;
      }
      return item;
    }));
  }

  function checkForFreeItem(itemDetail: IPOSProductDetails) {
    let freeItems = posFreeItems?.filter((obj) => obj?.itemid === itemDetail?.posItemId);
    const freeItemRet = freeItems?.map((obj) => {
      let freeItem: IPOSProductDetails;
      if (new Date() < new Date(obj?.efftodate)) {
        if (obj?.isbatch === "Yes" && obj?.batch_no === itemDetail?.posbatchNo) {
          return freeItem = {
            posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
            posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
            posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
            posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
            posItemQty: itemDetail?.posItemQty! * obj?.freeqty,
            posTaxValue: 0,
            posDiscountValue: 0,
            posTotalPrice: 0,
            posNetAmount: 0,
            posTaxPercentage: 0,
            posDiscountPercentage: 0,
          }
        } else if (obj?.issales === "Yes" && obj?.saleqty) {
          const divisions = Math.floor(itemDetail?.posItemQty! / obj?.saleqty);
          return freeItem = {
            posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
            posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
            posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
            posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
            // posbatchNo: itemDetail?.posbatchNo,
            posItemQty: divisions * obj?.freeqty,
            posTaxValue: 0,
            posDiscountValue: 0,
            posTotalPrice: 0,
            posNetAmount: 0,
            posTaxPercentage: 0,
            posDiscountPercentage: 0
          }
        }
      }
    })

    return freeItemRet[0]!;
  }

  function useCartQuantityUpdater(type: string, obj: IPOSProductDetails, qty?: number) {
    let existsItem = posItemDetils?.find((items) => isEqual(items, obj));

    if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
      if (type == 'add') {
        existsItem!.posItemQty! += qty!;
      } else {
        existsItem!.posItemQty! -= qty!;
      }
    } else if (type == 'add') {
      obj.posItemQty = 0;
      obj.posItemQty! += qty!;
    } else {
      obj.posItemQty = 0
      obj.posItemQty! -= qty!;
    }
    addToCart(obj);
  }

  const handleDeleteItem = (itemId, barcode, batchNo) => {
    setPOStItemDetails(posItemDetils?.filter(m => m.posItemId !== itemId));

    let batchItem: IPosBatchDetails = {
      itemId: itemId,
      barcode: barcode,
      batchNo: batchNo,
      batchQty: 0
    }
    // updateBatchList(batchItem, true, null, true)

    setPOSBatchDetails(posBatchDetails?.filter((batches) => (batches?.barcode != barcode && batches?.itemId != itemId && batches?.batchNo != batchNo)))
  }

  async function fetchPreLoading() {
    setLoading(false);
    let getAllCountries: any;
    getAllCountries = Country.getAllCountries();
    const [posItemMaster, posItemOffer, posFreeItems, salesOrderList, salesOrderLedger] = await Promise.all([
      posMasterStore.getPosItemMaster(), posMasterStore.getPosItemOffers(), posMasterStore.getPosFreeItems(),
      invoiceStore?.loadSalesOrederDetails(), invoiceStore?.loadSalesOrderLedger(), getNewmember(), invoiceStore.getMembers(),
      commonStore.loadOutlet(),
    ])
    setPosItemMaster(posItemMaster)
    setPosItemOffers(posItemOffer);
    setPosFreeItems(posFreeItems)
    setSalesOrderList([...salesOrderList])
    setSalesLedgerList([...salesOrderLedger])
    setCountryOptions(getAllCountries);
  }

  function calculateAvailQty(batchList: any[]) {
    let totalAvailQty = batchList?.length > 0 ? batchList?.reduce((acc, val) => acc + val.avalqty, 0) : 0
    return totalAvailQty
  }

  const billDisounct: number = useMemo(() => {
    let offerDiscount: number = 0;
    if (posItemOffers?.length > 0 && posItemDetils?.length > 0) {
      let billlValue = posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0)
      posItemOffers?.map((offers) => {
        if (new Date() > new Date(offers?.effectivefromDate) && new Date() < new Date(offers?.effectivetoDate)) {
          if (offers?.AssinOfferType === "billValue") {
            if (billlValue >= offers?.From_QV && billlValue <= offers?.To_QV) {
              offerDiscount = offers?.Discount_PV
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscount = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          }
        }
      })
    }
    return offerDiscount;
  }, [posItemDetils, posItemOffers])

  const paymentRoundOff: number = useMemo(() => {
    let total: number = 0;
    let decimal = 0;
    let roundOff: number = 0;
    total = posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges ?? 0) - billDisounct) + (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, v) => a = a + v?.SaleOrdLedgerAmount!, 0) : 0);
    decimal = Math.round(total);
    roundOff = Math.abs(total - decimal);
    return roundOff;
  }, [posItemDetils, posItemOffers])

  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoading();
      isInitialRender.current = false;
    } else {
    }
    return () => {
      // Cleanup logic (if needed)
    };
  }, [])

  return (
    <div className='container'>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox LgInputField'>
        <div className='hrBox'>
          <h3>Invoice</h3>
        </div>
        <div className='inputBoxLists pd-b-0'>
          <div className='mr-left-0'>
            <div className='row'>
              {isLoading &&
                <div className='SpinnerBox'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>}
              {isSpinner &&
                <div className='SpinnerBox'>
                  <Spinner animation="grow" size="sm" />
                </div>}
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Invoice No <span>*</span></label>
                  <input type="text" className='full-width' name='invoiceId'
                    value={invoice.invoiceId = posMasterStore?.entryNo?.billNo ?? 0}
                    disabled
                    placeholder='Invoice No..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Invoice Date <span>*</span></label>
                  <input type="date" placeholder="DD-MMM-YYYY" data-date=""
                    value={invoice.invoiceDate = invoice.invoiceDate ?? new Date().toISOString().substr(0, 10)} name='invoiceDate' onChange={handleInputChange}
                    data-date-format="DD MMMM YYYY" className='full-width'></input>
                  {errors.invoiceDate && <p style={{ color: 'red' }}>{errors.invoiceDate}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-3'>
                <div className='inputBox'>
                  <label>Outlet Name <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={commonStore.loadOutlets}
                    getOptionLabel={(option: any) => option.outletName}
                    onChange={(event, val) => handleOutlet(val, '')}
                    renderInput={(params: any) =>
                      <TextField  {...params}
                        id="outlined-size-small"
                        color='info'
                        className='full-width'
                        size="small"
                        type="text"
                        placeholder='Select Outlet'
                        name='outletId'
                      />}
                  />
                  {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-3'>
                <div className='inputBox'>
                  <label>Payment Mode <span>*</span></label>
                  <select className='full-width'
                    name='paymentMode' onChange={handleInputChange}
                    id="OfferType">
                    <option value="" selected>Select</option>
                    <option value="Online">Online</option>
                    <option value="Cash">Cash</option>

                  </select>
                  {errors.paymentMode && <p style={{ color: 'red' }}>{errors.paymentMode}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className="col-sm-2">
                <div className='inputradio mt-3'>
                  <input type='checkbox' checked={isAgainstPo != ""} onChange={(e) => e?.target?.checked ? (setAgainsPo(e.target.value), setAddMoreItem(false)) : setAgainsPo("")}
                    className='mt-4 justifyContentStart' name='againstPo' ></input>
                  <label className="mx-2">Against Sales Order</label>
                </div>
              </div>
              <div className='vertical-space-10'></div>
              <div className='col-md-2'>
                {isAgainstPo === "" ?
                  <div className='inputBox'>
                    <label>Sales Order No <span>*</span></label>
                    <input type="text" className='full-width' name='salesOrderNo' value={invoice.salesOrderNo} onChange={handleInputChange} placeholder='Sales Order No..'></input>
                  </div> :
                  <div className='inputBox'>
                    <label>Sales Order No<span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={removeDuplicates(salesOrderList, 'SOrdMNo')}
                      getOptionLabel={(option: any) => option.SOrdMNo}
                      onChange={(event, val) => handleUpdateInvoice(val)}
                      renderInput={(params: any) =>
                        <TextField  {...params} fullWidth
                          id="outlined-size-small"
                          size="small"
                          className='full-width'
                          placeholder='Select Order No..'
                          name='orderNo'
                        />
                      }
                    />
                  </div>}
                {errors.salesOrderNo && <p style={{ color: 'red' }}>{errors.salesOrderNo}</p>}
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Customer Name  <span>*</span></label>
                  <Autocomplete
                    fullWidth
                    readOnly={isAgainstPo != ""}
                    value={selectedMember}
                    options={newmemberList}
                    getOptionLabel={(option: any) => option?.memberName}
                    onChange={(event, val) => handleMembers(val, val?.memberId)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth placeholder='Select Member'
                        variant="standard" style={{ fontWeight: "lighter" }}
                      />
                    )}
                  />
                  {errors.memberId && <p style={{ color: 'red' }}>{errors.memberId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <button className='newdfBtn full-width' onClick={() => setNewMemberModal(true)}>New Member</button>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Customer Category </label>
                  <input type="text" className='full-width'
                    value={invoice.customerCategory} name='customerCategory' disabled
                  ></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>GSTIN  </label>
                  <input type="text" className='full-width' value={invoice.gstIn} disabled placeholder='GSTIN..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='vertical-space-10'></div>
              {invoice?.memberId! > 0 &&
                <div className='col-md-2'>
                  <button className='newdfBtn full-width' onClick={() => setDelivery(true)}>Address</button>
                </div>}
              {errors.addressId && <p style={{ color: 'red' }}>{errors.addressId}</p>}
              {posMasterStore?.posItemMaster?.length > 0 &&
                <div className='col-md-3'>
                  <div className='inputBox'>
                    <label>Item <span>*</span></label>
                    <AutoComplete clsName='full-width' getOptionLabel='itemName' placeholder='Select Product/Item..'
                      emitOption={(option) => handleItemDetails(option, '')} options={(isAgainstPo != "" && !addMoreItem) ? salesOrderItems : posMasterStore?.posItemMaster} />
                    <div className='vertical-space-10'></div>
                    {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                  </div>
                </div>}
              <div className='col-md-3'>
                <div className='inputBox'>
                  <label>Barcode <span>*</span></label>
                  <div className="SearchBox" style={{ margin: 0, }}>
                    <div className="input-group flex" style={{ height: '45px', borderRadius: '7px' }}>
                      <input value={barcode} type="text" placeholder='Enter Barcode' className='form-control'
                        name="batchScannerNo" maxLength={20}
                        onChange={(e) => setBarcode(e.target.value)}></input>
                      <div onClick={() => getPosBatchStocks(barcode)} className="flex alignItemCenter justifyContentCenter full-height"
                        style={{ background: 'lightgray', padding: '0 10px' }}>
                        <ArrowForwardIcon sx={{ fontSize: 20, color: 'black' }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4 btnBox'>
                <button className='dfBtn' type='submit' onClick={() => setAddMoreItem(true)}>Add More</button>
              </div>
              <div className='vertical-space-10'></div>
            </div>
          </div>
          <div className='col-md-42'>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope="col">S.No</th>
                    <th scope="col">Product </th>
                    <th scope="col">Qty</th>
                    <th scope="col">MRP</th>
                    <th scope="col">Selling Price</th>
                    <th scope="col">Tax</th>
                    <th scope="col">Discount </th>
                    <th scope="col">Total </th>
                    <th scope="col">Net Amount </th>
                    <th scope='col'></th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody>
                  {posItemDetils?.map((val: IPOSProductDetails, key) => {
                    return (val?.posItemQty! > 0) && (
                      <tr key={key}>
                        <td
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#dc3545', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                          onClick={() => { val?.posItemQty! > 1 && useCartQuantityUpdater('minus', val, 1) }}
                        >
                          -
                        </td>
                        <td>{val.postRowId = key + 1}</td>
                        <td>{val.posItemName}</td>
                        <td>{val.posItemQty}</td>
                        <td>{val.posItemMRP}</td>
                        <td>{val.posItemSellingRate}</td>
                        <td>{val.posTaxValue}</td>
                        <td>{val?.posDiscountValue?.toFixed(2)}</td>
                        <td>{val.posTotalPrice}</td>
                        <td>{val.posNetAmount}</td>
                        <td><FontAwesomeIcon icon={faTrash} className='inputPlaceholderIcon' onClick={() => handleDeleteItem(val.posItemId, val?.posBarcode, val.posbatchNo)} /></td>
                        <td
                          onClick={() => { val?.posItemQty! < val?.availableQty! && useCartQuantityUpdater('add', val, 1) }}
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0ac074', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                        >
                          +
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
              {errors?.addItems && <p style={{ color: 'red' }}>{errors?.addItems}</p>}
            </div>
            <div className='vertical-space-30'></div>
          </div>
        </div>
        <div className='btnBox'>
          <div className='inputradio'>
            <label>Dis Amount  : <span>&#8377;</span> <span>{posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * v?.posDiscountValue!), 0 + billDisounct)?.toFixed(2) : 0}</span></label><br />
            {selectedLedgerList?.map((each) => {
              return (
                <>
                  <label>
                    {each?.ledger_accout_name}  :
                    <span>&#8377;</span>
                    <span>{each?.SaleOrdLedgerAmount ?? 0}</span>
                  </label>
                  <br />
                </>
              )
            })}
            <label>Tax Amount  : <span>&#8377;</span> <span>{posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * v?.posTaxValue!), 0)?.toFixed(2) : 0}</span></label><br />
            <label>Ronud Off  : <span>&#8377;</span> <span>{paymentRoundOff?.toFixed(2)}</span></label><br />
            <label>Net Amount  : <span>&#8377;</span> <span>
              {((posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(invoice.deliveryCharge ?? 0) - billDisounct + paymentRoundOff) : 0) +
                (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, v) => a = a + v?.SaleOrdLedgerAmount!, 0) : 0))?.toFixed(2)}
            </span></label>
          </div>
        </div>
        <div className='btnBox'>
          <button className='dfBtn' style={{ marginRight: '4px' }} type='submit' onClick={handleSaveInvoice}>Submit</button>
          <button className='secondaryBtn' onClick={handleCancel}>List</button>
        </div>
      </div>

      <BarcodeBatches
        isitemmodel={isBarcodeBatches?.bool}
        itemModalClose={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}
        itemImage={''} itemBatchNo={isBarcodeBatches?.batchArr}
        memberCatId={memberDetail?.memberCatId!}
        addtoCart={(obj: any) => {
          useCartQuantityUpdater('add', obj, Number(obj?.posItemQty! / Number(obj?.posMinSalQty != 0 ? obj?.posMinSalQty : 1)));
        }}
        setBarcodeBatches={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}
      />

      <DeliveryAddressModal isDelivery={isDelivery} isNewAddress={isNewAddress} holdClose={() => { setDelivery(false); setNewAddress(false); }}
        countryOptions={countryOptions} handleCountryChange={handleCountryChange} errors={errors} handleAddressInputChange={handleAddressInputChange}
        address={address} cityOptions={cityOptions} handleNewAddress={handleNewAddress}
        handleCityChange={handleCityChange} handleInputChange={handleInputChange} memberDetail={memberDetail}
        handleAddress={handleAddress} handleNewDeliverAddress={() => { setDelivery(false); setNewAddress(true); }} />

      <Modal show={newMemberModal} onHide={() => setNewMemberModal(false)} className='PriceHistoryModel'
        style={{ marginTop: "0px", height: '90vh', overflow: 'auto' }}>
        <Modal.Header closeButton>
          <Modal.Title>New Member Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewMember fromPOS={true} />
        </Modal.Body>
      </Modal>

      <Modal show={isBatchModal} onHide={batchModalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Batch Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className=' inputFormBox'>
              <div className='col-md-42'>
                <div className='tableBox'>
                  <table className="table table-striped" style={{ overflow: 'auto' }}>
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col"> BatchNo</th>
                        <th scope="col">Batch Qty</th>
                        <th scope="col">Available Qty</th>
                        <th scope="col">MRP</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posMasterStore?.itemBatchNo?.length > 0 && posMasterStore?.itemBatchNo?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <input type="checkbox" value={val} onChange={(e) => handlebatchNo(e, val)}></input>
                            </td>
                            <td><QRCode value={val.batchNo} size={100} /></td>
                            <td>
                              <input type="text" placeholder='Batch Qty' className='TypeaheadSelectInput2'
                                name="batchqty" maxLength={10} onChange={(e) => handlebatchdetails(e, val.batchid)}>
                              </input>
                            </td>
                            <td>
                              {val?.avalqty}
                            </td>
                            <td>{val?.mrp}</td>
                            <td>{val?.price}</td>
                          </tr>
                        )
                      })

                      }
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total: {calculateAvailQty(posMasterStore.itemBatchNo)}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div className='vertical-space-10'></div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={isShippingAddress} onHide={shippingModalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Address Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='col-sm-12'>
            <div className=' inputFormBox'>
              <div className='inputBoxLists' >
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='inputBox'>
                      {invoiceStore?.shippingAddress?.lenght > 0 && invoiceStore.shippingAddress.map((val, key) => {
                        return (
                          <>
                            <div >
                              <input type='radio' className='inputradio' name='shippingAddress' onChange={() => handleAddress(val.invoiceAddressId)} ></input>

                              <label>{val.invoiceDoorAndSt},{val.invoiceLandmark},{val.invoiceity}, {val.invoiceState}.{val.invoiceCountry}, {val.invoicePincode}</label>
                              <br />
                              <label>Mobile No: {val.invoiceContactNo}</label>
                            </div>
                            <div className='vertical-space-20'></div>
                          </>
                        )
                      })
                      }

                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='btnBox'>
                    <button className='dfBtn' type='submit' onClick={shippingModalClose}>Submit</button>
                  </div>
                  <div className='btnBox'>
                    <button className='dfBtn' type='submit' onClick={handleNewAddress}>Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>
            <h4>Succesfully Submitted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

});

export default Invoice;
