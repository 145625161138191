import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { IItemConversionSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const ItemConversionDetail = observer((): JSX.Element => {
  const { itemConversionStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getItemConversionDetails, itemConversionDetails } = itemConversionStore;
  const [isLoading, setLoading] = useState(true);
  const [isItemConversionShow, setItemConversionModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteItemConversionMaster = async (itemConversionId) => {
    setLoading(true);
    const status = await itemConversionStore.deleteItemConversionMaster(itemConversionId);
    if (status === 'Success') {
      fetchItemConversion();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchItemConversion();
  }

  const getItemConversionDetail = async (itemConversionId) => {
    const data = await getItemConversionDetails(itemConversionId);
    if (data !== undefined || data !== null) {

      setItemConversionModal(true);
    }
  }

  const handleClose = () => {
    setItemConversionModal(false);
  }
  const handleCreate = () => {
    navigate('/Admin/ItemConversion');
  }

  async function fetchItemConversion() {
    setLoading(false)
    const [userRights] = await Promise?.all([userCreationStore?.getUserRight('Item_conversion'), itemConversionStore?.getItemConversion()])
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }
  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchItemConversion();
      isCurrenPage.current = false
    }
    return () => { }
  }, []);

   const editItemConversion = (itemconvertId) => {
    navigate('/Admin/ItemConversion/' + itemconvertId);
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (itemConversionStore?.getItemConversions?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(itemConversionStore?.getItemConversions)
    }
  }, [itemConversionStore?.getItemConversions])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(itemConversionStore?.getItemConversions, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={itemConversionStore?.getItemConversions}
                searchTitles={[{ key: "itemName", value: "Item Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{itemConversionStore?.getItemConversions?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th scope='col'>Entry No </th> */}
                      {/* <th scope='col'>Date  </th> */}
                      <th scope='col'>Item Name  </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{val.itemConversionId}</td> */}
                            {/* <td>{moment(val.itemConversionDate).format('DD-MMM-YYYY')}</td> */}
                            <td>{val.itemName}</td>
                            <td><button disabled={!userRights?.view} onClick={() => getItemConversionDetail(val.itemConversionId)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editItemConversion(val.itemConversionId)}/></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteItemConversionMaster(val.itemConversionId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>

              {
                isItemConversionShow ?
                  <Modal show={isItemConversionShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Conversion Name</th>
                              <th scope="col">Conversion Qty</th>
                              <th scope="col">UOM</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              itemConversionDetails?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.conversionItemName}</td>
                                    <td>{val.conversionQty}</td>
                                    <td>{val.conversionUOM}</td>
                                    {/* <td><button className='delete'><FontAwesomeIcon icon={faTrash} onClick={()=>deleteItemConversionDetail(val.itemConversionDetailId)}/></button></td> */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default ItemConversionDetail;
