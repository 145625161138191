import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import sellingPriceUpdateDetails, { ISellingPriceUpdate } from '../model/index';


class SellingPriceUpdateStore {
    @observable sellingPriceUpdateDetls = sellingPriceUpdateDetails;
    @observable sellingPriceUpdateList = new Map<number, ISellingPriceUpdate>();
    isInwardLoding = true;
    getsellingPriceUpdates = new Map();
    @observable getsellingPriceUpdateDetls = new Map();
    getsellingPriceMRPDetls = new Map();
    getLastInwardDetls = new Map();
    getMRPPrice = new Map();
    getsellingHistoryDetls = new Map();
    getSellingPrice = new Map();

    saveMRPDetails = async (mrppriceDetail) => {

        try {
            const saveStatus = await apiAgent.sellingPriceUpdate.saveMRPDetails(mrppriceDetail);
            return saveStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveSellingDetails = async (sellingPriceDetail) => {

        try {
            const saveStatus = await apiAgent.sellingPriceUpdate.saveSellingDetails(sellingPriceDetail);
            return saveStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSellingPriceUpdate = async () => {

        try {
            const getsellingPriceUpdate = await apiAgent.sellingPriceUpdate.getSellingPriceUpdate();
            console.log(getsellingPriceUpdate, 'getsellingPriceUpdate>>')
            runInAction(() => {

                this.getsellingPriceUpdates = getsellingPriceUpdate;
            });
            return this.getsellingPriceUpdates;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSellingPriceUpdateDetails = async (itemId) => {

        try {
            // const getSelling= await apiAgent.sellingPriceUpdate.getSellingPriceUpdateDetails(itemId);

            // runInAction(() => {

            //     this.getsellingPriceUpdateDetls = getSelling;
            // });
            return this.getsellingPriceUpdateDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getMRPHistory = async (itemId, BatchNo) => {

        try {
            const getSellingMRP = await apiAgent.sellingPriceUpdate.getMRPHistory(itemId, BatchNo);

            runInAction(() => {

                this.getsellingPriceMRPDetls = getSellingMRP;
            });
            return this.getsellingPriceMRPDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
   
    getSellingHistory = async (itemId, BatchNo, Ptype) => {
        try {
            const data = await apiAgent.sellingPriceUpdate.getSellingPriceMRPHistory(itemId, BatchNo, Ptype);
            runInAction(() => {
                this.getsellingHistoryDetls = data;
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    // getSellingHistory = async (itemId, BatchNo, Ptype) => {

    //     try {
    //         const getSellingHistory = await apiAgent.sellingPriceUpdate.getSellingPriceMRPHistory(itemId, BatchNo, Ptype);

    //         runInAction(() => {

    //             this.getsellingHistoryDetls = getSellingHistory;
    //         });
    //         return this.getsellingHistoryDetls;
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }
    getMRPPriceDetails = async (itemId) => {
        try {
            const getMRP = await apiAgent.sellingPriceUpdate.getMRPPriceDetails(itemId);
            runInAction(() => {
                this.getMRPPrice = getMRP;
            });
            return this.getMRPPrice;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSellingDetails = async (itemId, batchNo) => {
        try {
            const getSelling = await apiAgent.sellingPriceUpdate.getSellingDetails(itemId, batchNo);
            runInAction(() => {
                this.getSellingPrice = getSelling;
            });
            return this.getSellingPrice;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLastInwardHistory = async (itemId, batchNo) => {

        try {
            const getLastInward = await apiAgent.sellingPriceUpdate.getLastInwardHistory(itemId, batchNo);

            runInAction(() => {

                this.getLastInwardDetls = getLastInward;
            });
            return this.getLastInwardDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSellingPriceSearch = async (SearchOptions) => {

        try {
            const SellingPriceData = await apiAgent.sellingPriceUpdate.getSellingPriceSearch(SearchOptions);

            runInAction(() => {
                this.getsellingPriceUpdates = SellingPriceData;

            })
            return this.getsellingPriceUpdates;


        }
        catch (error) {
            console.log(error);
        }
    }
}
export default SellingPriceUpdateStore;