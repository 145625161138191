import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IStockAnalysis } from './model';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import OutletStockSummaryValidation from '../OutletStockSummary/validation';
import StockAnalysisValidation from './validation';
import { currentPageDatas, sortedData } from '../../common/shared/utils';

interface ExcelSheetData {
  Date?: string;
  Item_Name?: string;
  Type?: string;
  UOM?: string;
  Supplier?: string;
  Opening_Stock?: string;
  In_qty?: string;
  In_Batch_Details?: string;
  Out_Quantity?: string;
  Out_For?: string;
  Out_Batch_Details?: string;
  Closing?: string;
  Stock_Batch_Details?: string;
}

const StockAnalysis = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { commonStore, stockAnalysisStore } = useStores();
  const [stockAnalysis, setStockAnalysis] = useState<IStockAnalysis>(new IStockAnalysis());
  const [isLoading, setLoading] = useState(true);
  const [isItemName, setItemName] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [isSubCategory, setSubCategory] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState<any>({})

  const { loadWarehouse, loadWarehouses, loadSubcategory,
    loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;
  const { getStockAnalysis, stockReportDetails } = stockAnalysisStore


  const filteredData = useMemo(() => {
    if (searchValue != '' && searchValue?.length > 0 && stockReportDetails?.length > 0) {
      return sortedData(stockReportDetails, 'Inwarddate')?.filter((stock) => {
        if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
          return stock;
        }
      })
    } else {
      return [];
    }
  }, [searchValue])

  const handleSubcategoryValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, subCategoryId: val.subCategoryId });
    }
  }
  const handleWareHouseValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, wareHouseId: val.warehouseId });
    }
  }
  const handleItemValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, itemId: val.itemId });
    }
  }

  const searchStockAnalysis = async (e) => {
    setLoading(true);
    let error: any = {}
    error = StockAnalysisValidation(stockAnalysis)
    if (Object.keys(error).length === 0) {
      const stockAnalysisList = await getStockAnalysis(stockAnalysis);
      setErrors({})
    } else {
      setErrors(error)
    }
    setLoading(false);
  }

  function createExportExcelObj(StockAnalysis: any[]) {
    let excelArr: ExcelSheetData[] = StockAnalysis?.map((stock) => {
      const excelObj: ExcelSheetData = {
        Date: moment(stock?.Inwarddate).format('DD-MMM-YYYY'),
        Item_Name: stock?.itemname,
        Type: stock?.ttype,
        UOM: stock?.unitOfMeasure,
        // Supplier: stock?.supplier,
        Opening_Stock: stock?.open_stk,
        In_qty: stock?.inqty,
        // In_Batch_Details: stock?.inBatchNo,
        Out_Quantity: stock?.outqty,
        // Out_For: stock?.outfor,
        // Out_Batch_Details: stock?.outBatchNo,
        Closing: stock?.closingstk,
        // Stock_Batch_Details: stock?.stockbatchNo,
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Warehouse Stock Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Warehouse Stock Report ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setStockAnalysis({ ...stockAnalysis, [name]: value });
  };

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setItemName(false);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'ItemName') {
      setItemName(true);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'Category') {
      setItemName(false);
      setCategory(true);
      setSubCategory(false);
    }
    if (value === 'SubCategory') {
      setItemName(false);
      setCategory(false);
      setSubCategory(true);
    }
    setStockAnalysis({ ...stockAnalysis, [name]: value })
  }

  function handleSearchInput(e) {
    setSearchValue(e.target.value);
  }

  async function fetchPreLoadngData() {
    await loadItemConversion();
    await loadWarehouse();
    await loadSubcategory();
    setLoading(false);
  }

  const isCurrent = useRef(true)
  useEffect(() => {
    if (isCurrent.current) {
      fetchPreLoadngData();
      isCurrent.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])


  console.log(currentPageData, 'currentPageData>>')
  useEffect(() => {
    if (stockReportDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(stockReportDetails)
    }
  }, [stockReportDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData(stockReportDetails, 'Inwarddate'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
      <div className="" style={{ width: '100%' }}>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Warehouse Stock Report</h3>
          </div>
          {isLoading ? <ProgressBar /> : <></>}
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='row'>
                <div className='col-sm-1'>
                  <div className='inputBox'>
                    <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputchnage}
                      name="fromDate"
                      value={stockAnalysis?.fromDate}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-1'>
                  <div className='inputBox'>
                    <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputchnage}
                      name="toDate"
                      value={stockAnalysis?.toDate}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                  <div className='vertical-space-20'></div>
                </div>
                <div className="col-sm-2" style={{ marginTop: '21px' }}>
                  <div className='inputBox'>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={loadWarehouses}
                      getOptionLabel={(option: any) => option.warehouseName}
                      onChange={(event, val) => handleWareHouseValue('wareHouseId', event, val)}
                      renderInput={(params: any) =>
                        <TextField  {...params}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select WareHouse Name..'
                          name='warehouseName'
                        />}
                    />

                  </div>
                  {errors.outletId && <p style={{ color: 'red' }}>Select Warehouse</p>}
                </div>
                <div className="col-sm-2" style={{ marginTop: '18px' }}>
                  <div className="input-group mb-3">
                    <input type="text"
                      style={{ height: "47px" }}
                      name='searchValue'
                      value={searchValue}
                      onChange={handleSearchInput}
                      className="form-control" placeholder="Search Here.."
                      aria-label="Search Outlet..."
                      aria-describedby="search-button"
                    ></input>
                  </div>
                </div>
                <div className='col-sm-2' style={{ marginTop: '4px' }}>
                  <div className='inputBox' style={{ marginTop: '17px' }}>
                    <select style={{ height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                      <option value="">Select Search Criteria</option>
                      <option value="ItemName">Item Name</option>
                      <option value="Category">CateGory </option>
                      <option value="SubCategory">Sub Category </option>
                    </select>
                  </div>

                </div>
                <div className='col-sm-2' style={{ marginTop: '20px', }}>
                  {
                    isItemName ?
                      <>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadItemConversions}
                            getOptionLabel={(option: any) => option.itemName}
                            onChange={(event, val) => handleItemValue('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Item..'
                                name='itemId'
                              />}
                          />
                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null

                  }
                  {
                    isCategory ?
                      <>
                        <div className='inputBox'>
                          <select style={{ height: "47px" }} className="form-selected" onChange={handleInputchnage} id="Category" name="category">
                            <option value="" selected>Not Select</option>
                            <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                            <option value="Pets">Pets</option>
                            <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                            <option value="Grocery">Grocery</option>
                          </select>
                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null
                  }
                  {
                    isSubCategory ?
                      <>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadSubcategories}
                            getOptionLabel={(option: any) => option.subCategoryName}
                            onChange={(event, val) => handleSubcategoryValue('subCategoryId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Subcategory..'
                                name='subcategory'
                              />}
                          />

                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null
                  }
                </div>
                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                  <button className='secondaryBtn' type='submit' onClick={searchStockAnalysis}>Generate</button>
                </div>
                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                  <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(searchValue?.length > 0 ? filteredData : stockReportDetails)}>Excel</button>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-5'>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{(searchValue?.length > 0 ? filteredData : currentPageData)?.length} Records</button>
                </div>
              </div>
              <div className=' col-sm-7' >
                <Stack spacing={2}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
            </div>

            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">UOM</th>
                    {/* <th scope="col">Supplier </th> */}
                    <th scope="col">Opening Stk</th>
                    <th scope="col">In Qty</th>
                    {/* <th scope="col">In batch Det</th> */}
                    <th scope="col">Out Qty</th>
                    {/* <th scope="col">Out for</th> */}
                    {/* <th scope="col">Out batch Details</th> */}
                    <th scope="col">Closing</th>
                    {/* <th scope="col">Stock batch Details</th> */}
                  </tr>
                </thead>
                <tbody>

                  {(searchValue?.length > 0 ? filteredData : currentPageData)?.length > 0 ?
                    (searchValue?.length > 0 ? filteredData : currentPageData)?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{moment(val.Inwarddate).format("DD-MMM-YYYY")} </td>
                          <td>{val.itemname} </td>
                          <td>{val.ttype} </td>
                          <td>{val.unitOfMeasure} </td>
                          {/* <td>{val.supplier} </td> */}
                          <td>{val.open_stk}</td>
                          <td>{val.inqty} </td>
                          {/* <td>
                                    <div className="">
                                      {val?.inBatchNo?.slice(0, val?.inBatchNo?.length / 2)}
                                    </div>
                                    <div className="">
                                      {val?.inBatchNo?.slice(val?.inBatchNo?.length / 2, val?.inBatchNo?.length)}
                                    </div>
                                  </td> */}
                          <td>{val.outqty} </td>
                          {/* <td>{val.outfor}</td> */}
                          {/* <td>
                                    <div className="">
                                      {val?.outBatchNo?.slice(0, val?.outBatchNo?.length / 2)}
                                    </div>
                                    <div className="">
                                      {val?.outBatchNo?.slice(val?.outBatchNo?.length / 2, val?.outBatchNo?.length)}
                                    </div>
                                  </td> */}
                          <td>{val.closingstk}</td>
                          {/* <td>
                                    <div className="">
                                      {val?.stockbatchNo}
                                    </div>
                                    <div className="">
                                      {val?.stockbatchNo?.slice(val?.stockbatchNo?.length / 2, val?.stockbatchNo?.length)}
                                    </div>
                                  </td> */}
                        </tr>
                      )
                    }) : <h6> No Data Available</h6>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StockAnalysis;
