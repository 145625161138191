import React, { useState, useEffect, useRef, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IItemConversion, IItemConversionOfDetails } from './model';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import itemConversionValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

const ItemConversion = observer((): JSX.Element => {
  const { commonStore, itemConversionStore, inwardStore } = useStores();
  const { getItemConversionDetails } = itemConversionStore;
  const [itemConversion, setItemConversion] = useState<IItemConversion>({});
  const [itemOfConversion, setItemOfConversion] = useState<IItemConversionOfDetails>({});
  const [itemOfConversions, setItemOfConversions] = useState<IItemConversionOfDetails[]>([]);
  const navigate = useNavigate();
  const [submitBtn, setSubmitbtn] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const { itemconvertId } = useParams<{ itemconvertId: string }>();

  const { loadUnitOfMeasure, loadUnitOfMeasures, } = commonStore;

  const [itemMasterJSON, setItemMasterJSON] = useState<any[]>([])

  const handleItemValue = (name: any, val: any) => {
    if (val != null) {
      debugger
      setItemConversion({ ...itemConversion, [name]: val.itemid, ["itemName"]: val.itemname, ["itemUOM"]: val?.convuom });
    }
    else {
      itemConversion.itemName = '';
      itemConversion.itemUOM = '';
    }
  }

  const handleConItemValue = (name: any, val: any) => {
    if (val != null) {
      const itemUom = loadUnitOfMeasures?.find((unitData) => {
        if (unitData?.unitName === (val?.itemUOM)) {
          return unitData
        }
      })
      setItemOfConversion({ ...itemOfConversion, [name]: val.itemid, ["conversionItemId"]: val.itemid, ["itemName"]: val.itemname, ["conversionItemUOM"]: val.uom });
    }
    else {
      itemOfConversion.itemConversionId = 0;
    }
  }

  function hanldeItemConversion(event: any) {
    const { name, value } = event.target;
    setItemOfConversion({ ...itemOfConversion, [name]: value })
  }

  const handleUnitOfMeasureValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setItemOfConversion({ ...itemOfConversion, [name]: val.unitId, ["conversionUOM"]: val.unitName });
    }
    else {
      itemOfConversion.conversionUOM = '';
    }
  }

  function addItemConversion(e) {
    e.preventDefault();
    let error: any = {};
    error = itemConversionValidation(itemOfConversion, itemConversion);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      itemOfConversion.conversionItemId = itemOfConversion.conversionItemId
      itemOfConversion.conversionItemUOM = itemOfConversion.conversionItemUOM
      itemOfConversion.conversionQty = Number(itemOfConversion.conversionQty)
      itemOfConversion.conversionUOM = itemOfConversion.conversionUOM
      itemOfConversion.itemConversionId = itemOfConversion.itemConversionId
      itemOfConversion.conversionQty = Number(itemOfConversion.conversionQty)
      itemOfConversion.itemconversiondtlid = Number(0)
      setItemOfConversions([...itemOfConversions, itemOfConversion]);
      setItemOfConversion({ ...itemOfConversion, itemName: '', conversionItemUOM: '', conversionQty: 0, unitName: '', conversionItemId: 0 })
      setSubmitbtn(true);
    }
  }

  function handleRemove(index) {
    itemOfConversions.splice(index, 1)
    setItemOfConversions(_.cloneDeep(itemOfConversions))
  }

  async function saveItemConversion(e) {
    itemConversion.itemConversionDetails = itemOfConversions
    itemOfConversion.conversionQty = Number(itemOfConversion.conversionQty)
    const status = await itemConversionStore.saveItemConversion(itemConversion);
    if (status === "Success") {
      setSuccessModal(true)
    } else {
      setFailureModal(true);
    }
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    const [itemMasterJSON] = await Promise.all([inwardStore.loadInwardItemMasterJSON(), loadUnitOfMeasure()]);
    setItemMasterJSON([...itemMasterJSON])
  }

  async function getTeamMasterDet() {
    if (itemconvertId) {
      const data: IItemConversion[] = await itemConversionStore.getItemConversion();
      const formattedData = Array.from(data);
      console.log(JSON.stringify(formattedData, null, 2), 'data');
      const dataDet = await getItemConversionDetails(Number(itemconvertId));
      console.log(dataDet, 'dataDet');
      const updatedData = dataDet.map((item: any) => ({
        itemConversionId: item.itemConversionId,
        conversionItemId: item.conversionItemId,
        itemName: item.conversionItemName,
        conversionUOM: item.conversionUOM,
        conversionQty: item.conversionQty,
        conversionItemUOM: item.conversionItemUOM,
        unitName: item.conversionUOM,
        itemconversiondtlid: item.itemConversionDetailId
      }));

      setItemOfConversions(updatedData);
      console.log(updatedData, 'updatedData');

      const matchedUser = formattedData.find(team => Number(team.itemConversionId) === Number(itemconvertId));
      if (matchedUser) {
        setItemConversion(matchedUser);
      }
    }
  }

  useEffect(() => {
    console.log(itemConversion, 'itemConversion');
  }, [itemConversion]);

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      getTeamMasterDet();
      isCurrentPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    navigate('/Admin/ItemConversion/ItemConversionDetail');
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/ItemConversion/ItemConversionDetail');
  }


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Item Conversion (Packing)</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>

                      <div className='col-md-7'>
                        <div className='inputBox'>
                          <label>Item Name  <span>*</span></label>
                          <AutoComplete
                            options={itemMasterJSON}
                            value={itemConversion.itemName}
                            emitOption={(val) => handleItemValue('itemId', val)}
                            placeholder={'Select Item..'}
                            clsName='full-width'
                            getOptionLabel='itemname'
                          />
                          {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label>Stock UOM<span>*</span> </label>
                          <input disabled
                            value={itemConversion.itemUOM} name='itemUOM'
                            className='fullInput' style={{ width: "100%" }} placeholder='UOM..'></input>
                          {errors.itemUOM && <p style={{ color: 'red' }}>{errors.itemUOM}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-7'>
                        <div className='inputBox'>
                          <label>Conversion Items<span>*</span></label>
                          <AutoComplete
                            value={itemOfConversion?.itemName}
                            options={itemMasterJSON}
                            emitOption={(val) => handleConItemValue('itemId', val)}
                            placeholder={'Select Item Conversion..'}
                            clsName='full-width'
                            getOptionLabel='itemname'
                          />
                          {errors.conversionItemId && <p style={{ color: 'red' }}>{errors.conversionItemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label>UOM<span>*</span> </label>
                          <input disabled
                            value={itemOfConversion.conversionItemUOM} name='conversionItemUOM'
                            className='fullInput' style={{ width: "100%" }} placeholder='UOM..'></input>
                          {errors.conversionItemUOM && <p style={{ color: 'red' }}>{errors.conversionItemUOM}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Conversion Qty<span>*</span></label>
                          <input type="text"
                            value={itemOfConversion.conversionQty} name='conversionQty'
                            onChange={hanldeItemConversion}
                            className='' style={{ width: "100%" }} placeholder='Conversion Qty..'></input>
                          {errors.conversionQty && <p style={{ color: 'red' }}>{errors.conversionQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label>UOM<span>*</span> </label>
                          <input type="text" name='conversionQty'
                            value={itemOfConversion.conversionUOM = itemConversion?.itemUOM}
                            // onChange={hanldeItemConversion}
                            className='' style={{ width: "100%" }} placeholder='Conversion Qty..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={addItemConversion}>Add</button>
                      </div>
                      <div className='col-md-12'>
                        <div className='tableBox' style={{ maxHeight: '300px', overflow: 'scroll' }}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Conversion Name</th>
                                <th scope="col">Conversion Qty </th>
                                <th scope="col">UOM</th>
                                <th scope="col">Item Con. UOM</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemOfConversions.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.itemName}</td>
                                    <td>{val.conversionQty}</td>
                                    <td>{val.conversionUOM}</td>
                                    <td>{val.conversionItemUOM}</td>
                                    <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }}
                                      type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCreate}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveItemConversion}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  );

});

export default ItemConversion;
