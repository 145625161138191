import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IOutletItemRequest, OutletItemRequestDetail } from './model';

import { observer } from 'mobx-react-lite';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import { IItemInward } from './model';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import outletRequestItemValidation, { addValidation, submitVaidation } from './validation';
import { faL } from '@fortawesome/free-solid-svg-icons';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';

const OutletItemRequest = observer((): JSX.Element => {
  const navigate = useNavigate();

  const { commonStore, outletItemRequestStore, inwardOultetStore } = useStores();

  const { loadItemMaster, loadItemMasters, loadWarehouse, loadWarehouses,
    loadOutlet, loadOutlets,
    loadEmployee, loadEmployees, } = commonStore;

  const [outetItemReqMaster, setOutletItemReqMaster] = useState<IOutletItemRequest>({})
  const [itemReqFields, setItemReqFiedls] = useState<OutletItemRequestDetail>({})

  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isExistModal, setExistErrorModal] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [submitbtn, setSubmitbtn] = useState(false);

  const [errors, setErrors] = useState<any>({});


  function handleInputMasterChange(event) {
    const { name, value } = event.target;
    handleMasterAutoComplete(name, value)
  }

  function handleMasterAutoComplete(name, value) {
    if (name == 'outletId') {
      setOutletItemReqMaster({ ...outetItemReqMaster, 'outletId': value.outletid, outletName: value.outletName })
    } else if (name === 'employeeId') {
      setOutletItemReqMaster({ ...outetItemReqMaster, [name]: value.employeeId, ['employeeName']: value.employeeName })
    } else {
      setOutletItemReqMaster({ ...outetItemReqMaster, [name]: value })
    }
  }

  function handleItemInputchange(event) {
    const { name, value } = event.target;
    handleItemReqAutoComplte(name, Number(value))
  }

  function handleItemReqAutoComplte(name, value) {
    if (name === 'requestItemId') {
      setItemReqFiedls({ ...itemReqFields, 'requestItemId': value.itemid, requestItemName: value.itemName, outletStock: value.batchQty })
    } else if (name === 'warehouse') {
      setItemReqFiedls({ ...itemReqFields, [name]: value.warehouseName })
    } else {
      setItemReqFiedls({ ...itemReqFields, [name]: value })
    }
  }

  function handleAddtoGrid() {
    itemReqFields.outletItemId = outetItemReqMaster?.outletItemId
    let error: any = {}
    error = addValidation(itemReqFields)
    setErrors({ ...error })

    if (Object.keys(error)?.length === 0) {
      let outletItemRequestDetails: OutletItemRequestDetail[] = Object.assign([], outetItemReqMaster?.outletItemRequestDetails)
      outletItemRequestDetails?.push(itemReqFields)
      outetItemReqMaster.outletItemRequestDetails = outletItemRequestDetails;
      setOutletItemReqMaster({ ...outetItemReqMaster, })
      setItemReqFiedls(new OutletItemRequestDetail())
      setSubmitbtn(true);
    }
  }

  function handleRemove(index) {
    let outletItemRequestDetails: OutletItemRequestDetail[] = Object.assign([], outetItemReqMaster?.outletItemRequestDetails)
    outletItemRequestDetails?.splice(index, 1)
    setOutletItemReqMaster({ ...outetItemReqMaster, outletItemRequestDetails: outletItemRequestDetails })

  }

  async function saveOutletItemRequest(e) {
    e.preventDefault();
    let error: any = {}
    error = submitVaidation(outetItemReqMaster)
    setErrors({ ...error })
    if (Object.keys(error).length === 0) {
      setLoading(true)
      const entryNo = await inwardOultetStore.getEntryNo('OutletRequestItem');
      outetItemReqMaster.outletItemId = entryNo.entryNo;
      const status = await outletItemRequestStore.saveOutletItemRequest(outetItemReqMaster);
      if (status === "Success") {
        setSuccessModal(true)
        setLoading(false)
      } else {
        setFailureModal(true);
        setLoading(false)
      }
    }
  }

  async function fetchPreLoadngData() {
    const entryNo = await inwardOultetStore.getEntryNo('OutletRequestItem');
    setOutletItemReqMaster({ ...outetItemReqMaster, outletItemId: entryNo.entryNo })
    await loadOutlet();
    setLoading(false)

    await loadItemMaster();
    await loadEmployee();
    await loadWarehouse();
  }
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadngData();
      isInitialRender.current = false;
    } else {
    }
    return () => { };
  }, []);

  const handleCancel = () => {
    navigate('/Admin/OutletItemRequest/OutletItemRequestDetails');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleExistModalClose = () => setExistErrorModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/OutletItemRequest/OutletItemRequestDetails');
  }
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Outlet Item Request</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text" className=''
                          name='OutletRequestId' disabled
                          value={outetItemReqMaster.outletItemId}
                          style={{ width: "100%" }} placeholder='Entry No..' ></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Date <span>*</span></label>
                        <input type="date"
                          name='outletItemDate' value={outetItemReqMaster.outletItemDate = outetItemReqMaster.outletItemDate ?? new Date().toISOString().substr(0, 10)}
                          onChange={handleInputMasterChange}
                          onKeyDown={(event) => event.preventDefault()}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.outletItemDate && <p style={{ color: 'red' }}>{errors.outletItemDate}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Outlet Name <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={loadOutlets}
                          getOptionLabel={(option: any) => option.outletName}
                          onChange={(event, val) => handleMasterAutoComplete('outletId', val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '45ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Outlet Name..'
                              name='outletName'
                            />}
                        />
                        {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {loadEmployees?.length > 0 && <div className='col-sm-6'>
                      <div className='inputBox'>
                        <label>Created by  <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={removeDuplicates(loadEmployees, 'employeeName')}
                          getOptionLabel={(option: any) => option.employeeName}
                          onChange={(event, val) => handleMasterAutoComplete('employeeId', val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '43ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Created by..'
                              name='employeeName'
                            />}
                        />
                        {errors.employeeId && <p style={{ color: 'red' }}>{errors.employeeId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>}
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Item <span>*</span></label>
                        <AutoComplete placeholder="Select Item" value={itemReqFields?.requestItemName}
                          emitOption={(val) => handleItemReqAutoComplte('requestItemId', val)} getOptionLabel='itemName' options={loadItemMasters} />
                        {errors.requestItemId && <p style={{ color: 'red' }}>{errors.requestItemId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>WareHouse <span>*</span></label>
                        <AutoComplete placeholder="Select Warehouse..." value={itemReqFields?.warehouse}
                          emitOption={(val) => handleItemReqAutoComplte('warehouse', val)} getOptionLabel='itemName' options={loadWarehouses} />
                        {errors.warehouse && <p style={{ color: 'red' }}>{errors.warehouse}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Required Qty <span>*</span></label>
                        <input type="text" className='fullInput'
                          name='requiredQuantity'
                          value={itemReqFields?.requiredQuantity}
                          onChange={handleItemInputchange}
                          style={{ width: "100%" }} placeholder='Qty..'></input>
                        {errors.requiredQuantity && <p style={{ color: 'red' }}>{errors.requiredQuantity}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-2'>
                      <button className='dfBtn' type='submit' onClick={handleAddtoGrid}>ADD</button>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='tableBox' style={{ overflow: 'scroll', maxHeight: '400px' }}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col">Ware House</th>
                          <th scope="col">Required Qty</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {outetItemReqMaster?.outletItemRequestDetails?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.requestItemName}</td>
                              <td>{val.warehouse}</td>
                              <td>{val.requiredQuantity}</td>
                              <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                            </tr>
                          )
                        })
                        }
                        {errors.outletItemRequestDetails && <p style={{ color: 'red' }}>{errors.outletItemRequestDetails}</p>}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveOutletItemRequest} >Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isExistModal} onHide={handleExistModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>

                    <h4>This Item Already Exist, If you want add more quantiity</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleExistModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>
          </>
      }
    </>
  );

});

export default OutletItemRequest;
