import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { ITerminalCreation } from './model';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import terminalCreationValidation from './validation';
import moment from 'moment';

const TerminalCreation = observer((): JSX.Element => {
  const [terminalCreation, setTerminalCreation] = useState<ITerminalCreation>({});
  const { terminalCreationStore, commonStore, memberStore } = useStores();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const { isOutletLoading, loadOutlets, loadOutlet } = commonStore;

  const { terminalId } = useParams();

  const handleTerminal = (name: any, event: object, val: any) => {
    if (val != null) {
      setTerminalCreation({ ...terminalCreation, [name]: val.outletid, ['outletName']: val.outletName });
    }
  }
  const handleCance = () => {
    navigate('/Admin/Terminal/TerminalDetail');
  }

  async function saveTerminalCreation(e) {
    e.preventDefault();
    let error: any = {};
    error = terminalCreationValidation(terminalCreation);
    setErrors(error);
    console.log(terminalCreation, 'terminalCreation');

    if (Object.keys(error).length === 0) {
      setLoading(true);
      terminalCreation.terminalId = Number(terminalCreation.terminalId)
      let status: string = '';
      if (terminalId !== undefined) {
        status = await terminalCreationStore.updateTerminalCreation(terminalCreation);


        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true)
        }
      }
      else {
        const status = await terminalCreationStore.saveTerminalCreation(terminalCreation);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    };
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/Terminal/TerminalDetail');
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setTerminalCreation({ ...terminalCreation, [name]: value });

  };

  async function getTerminalCreationDet() {
    if (terminalId !== undefined) {
      const TerminalDels = await terminalCreationStore.TerminalDetails(parseInt(terminalId!));
      if (TerminalDels) {
        const updatedMaster = {
          ...terminalCreation,
          terminalId: TerminalDels.terminalId,
          brand: TerminalDels.brand,
          counterNumber: TerminalDels.counterNumber,
          installationDate: moment(TerminalDels.installationDate).format('YYYY-MM-DD'),
          outletId: TerminalDels.outletId,
          outletName: TerminalDels.outletname,
          terminalName: TerminalDels.terminalName
        };
        setTerminalCreation({...updatedMaster});
        console.log(updatedMaster,'updatedMaster');
        console.log(terminalCreation,'terminalCreation');
      }
    }
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    if (terminalId) {

    }
    else{
      await loadOutlet();
      const data = await memberStore.getEntryNo('Teminal');
      if (data !== undefined) {
        terminalCreation.terminalId = data.entryNo + 1;
        setTerminalCreation(terminalCreation);
    }
    }
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoadngData()
      getTerminalCreationDet()
      isCurrenPage.current = false
    }
    return () => { }
  }, []);

  useEffect(() => {
    if (loadOutlets?.length > 0 && !terminalCreation.outletName) {
      const matchedOutlet = loadOutlets.find((outlet) => outlet.outletId === terminalCreation.outletId);
      if (matchedOutlet) {
        setTerminalCreation((prev) => ({ ...prev, outletName: matchedOutlet.outletName }));
      }
    }
  }, [loadOutlets, terminalCreation.outletId]);  

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Terminal Creation</h3>
              </div>

              <div className='inputBoxLists'>
                <div className='row'>

                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Terminal ID </label>
                      <input className=''
                        onChange={handleInputchnage}
                        name="terminalId" disabled
                        value={terminalCreation.terminalId}
                        style={{ width: "100%" }} placeholder='Terminal ID..'></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Terminal Name <span>*</span></label>
                      <input type="text" className=''
                        onChange={handleInputchnage}
                        name="terminalName"
                        value={terminalCreation.terminalName}
                        style={{ width: "100%" }} placeholder='Enter Terminal Name..'></input>
                      {errors.terminalName && <p style={{ color: 'red' }}>{errors.terminalName}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Brand Name <span>*</span></label>
                      <input type="text" className=''
                        onChange={handleInputchnage}
                        name="brand"
                        value={terminalCreation.brand}
                        style={{ width: "100%" }} placeholder='Enter Brand Name..'></input>
                      {errors.brand && <p style={{ color: 'red' }}>{errors.brand}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  {(loadOutlets?.length > 0) && <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Outlet Name <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadOutlets}
                        getOptionLabel={(option: any) => option.outletName}
                        onChange={(event, val) => handleTerminal('outletId', event, val)}
                        value={loadOutlets?.find((option) => option.outletName === terminalCreation.outletName) || null}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '100%' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Outlet Name..'
                            name='outletName'
                          />}
                      />
                      {errors.outletName && <p style={{ color: 'red' }}>{errors.outletName}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>}
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Installation Date <span>*</span></label>
                      <input type="date"
                        onChange={handleInputchnage}
                        name="installationDate"
                        value={terminalCreation.installationDate = terminalCreation.installationDate ?? new Date().toISOString().substr(0, 10)}

                        style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      {errors.installationDate && <p style={{ color: 'red' }}>{errors.installationDate}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Counter No <span>*</span></label>
                      <input className=''
                        onChange={handleInputchnage}
                        name="counterNumber"
                        value={terminalCreation.counterNumber}
                        style={{ width: "100%" }} placeholder='Enter Counter Number..'></input>
                      {errors.counterNumber && <p style={{ color: 'red' }}>{errors.counterNumber}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                </div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleCance}>List</button>
                <button className='dfBtn' type='submit' onClick={saveTerminalCreation}>Submit</button>
              </div>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
      }
    </>
  );
});

export default TerminalCreation;
