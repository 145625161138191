import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AutoComplete from '../../common/shared/autoComplete';
import useStores from '../../hooks';
import { IDamageApprovalDetails, IDamageEntryApproval } from './model';
import moment from 'moment';
import { SubmitValidation } from './validation';
import { removeDuplicates } from '../../common/shared/utils';

function DamageEntryApproval() {

    const { commonStore, damageEntryOutletStore, warehouseStores, outwardtoOutletStore } = useStores();

    const navigate = useNavigate();

    const { loadEmployee, loadEmployees } = commonStore;

    const [damageApproval, setDamgeApproval] = useState<IDamageEntryApproval>(new IDamageEntryApproval());
    const [damageApprovalDetail, setDamageApprovalDetail] = useState<IDamageApprovalDetails[]>(new Array<IDamageApprovalDetails>())


    const [damageEntryDetails, setDamageEntryDetails] = useState<any[]>([])
    const [damageEntryItemList, setDamageEntryItemList] = useState<any[]>([]);

    // console.log(damageEntryDetails, 'damageEntryDetails>>', damageApprovalDetail)
    const [errors, setErrors] = useState<any>({})

    const [dialogOpen, setDialog] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false)
    const [isFailureModal, setFailureModal] = useState(false)

    function handleInputchange(e) {
        const { name, value } = e.target;
        setDamgeApproval({ ...damageApproval, [name]: value })
    }

    function handleAutoComplete(name, value) {
        if (name === "convertedby" && value != null) {
            setDamgeApproval({ ...damageApproval, convertedby: value?.employeeId })
        }
    }

    async function handleOpenDetails(val, e) {
        setSpinner(true)
        if (e.target.checked === true) {
            let mode = val?.Entrytype === 'Warehouse' ? 2 : 1;
            const damageEntryDetails = await damageEntryOutletStore.loadDamageTransferPendingDetails(val?.damage_entry_master_id, mode);
            damageEntryDetails?.forEach((obj) => {
                if (obj?.itemid) {
                    obj['damage_entry_master_id'] = val?.damage_entry_master_id;
                    obj['damagefrom'] = val?.Entrytype;
                    return obj
                }
            })
            setDamageEntryItemList(damageEntryDetails)
            setDamgeApproval({ ...damageApproval, [val?.Entrytype === "Warehouse" ? 'wid' : 'outletid']: val?.Entrytype === "Warehouse" ? val?.wid : val?.oid })
            setDialog(true)
        } else if (e.target.checked === false) {
            setDamgeApproval({ ...damageApproval, [val?.Entrytype === "Warehouse" ? 'wid' : 'outletid']: 0 })
        }
        setSpinner(false)
    }

    function validateQty(qty, value) {
        let error: any = {}
        if (value > qty) {
            error.qty = 'Entered value cannot be greater than Qty'
        }
        return error
    }

    function addOrRemove(val) {
        if (damageApprovalDetail?.length > 0) {
            let index = damageApprovalDetail?.findIndex((obj) =>
                obj?.masterid === val?.damage_entry_master_id && obj?.itemid === val?.itemid)
            if (index !== -1) {
                setDamageApprovalDetail(prevArr => prevArr?.splice(index, 1))
            }
        }
    }

    function handleUpdate(event, val) {
        const { name, value } = event?.target;
        let error: any = {}
        if (val != null) {
            if (!(damageApprovalDetail?.some((obj) => obj?.damagefrom === val?.damagefrom && obj?.masterid === val?.damage_entry_master_id && obj?.itemid === val?.itemid))) {
                let dmgAppObj: IDamageApprovalDetails = {
                    itemid: val?.itemid,
                    barcode: val?.Barcode,
                    batchno: val?.batch_no,
                    qty: val?.damage_stk,
                    entryid: damageApproval.entryNo,
                    masterid: val?.damage_entry_master_id,
                    damagefrom: val?.damagefrom,
                    [name]: Number(value),
                };
                error = validateQty(val?.damage_stk, value)
                if (Object.keys(error)?.length == 0) {
                    damageApprovalDetail?.push(dmgAppObj)
                    setDamageApprovalDetail([...damageApprovalDetail])
                    debugger
                    setDamageEntryItemList(prevState => prevState?.map((each) => {
                        if (each?.damage_entry_master_id == val?.damage_entry_master_id && each?.itemid === val?.itemid && each?.batch_no === val?.batch_no) {
                            each[name] = Number(value);
                        }
                        return each
                    }))
                }
            } else {
                let findObj = damageApprovalDetail?.find((each) => each?.itemid === val?.itemid)
                let findSumOfQty = (name === 'rejectionQty' ? Number(value) : Number(findObj?.rejectionQty! ?? 0)) +
                    (name === 'reuseQty' ? Number(value) : Number(findObj?.reuseQty! ?? 0)) +
                    (name === 'returnQty' ? Number(value) : Number(findObj?.returnQty! ?? 0));

                error = validateQty(val?.damage_stk, findSumOfQty)
                if (Object.keys(error)?.length == 0) {
                    if (findSumOfQty) {
                        setDamageApprovalDetail(prevArr => prevArr?.map((obj) => {
                            if (obj?.itemid === val?.itemid) {
                                obj['sumOfQty'] = (name === 'rejectionQty' ? Number(value) : Number(obj?.rejectionQty! ?? 0)) +
                                    (name === 'reuseQty' ? Number(value) : Number(obj?.reuseQty! ?? 0)) +
                                    (name === 'returnQty' ? Number(value) : Number(obj?.returnQty! ?? 0));
                                obj[name] = Number(value);
                            }
                            return obj;
                        }))
                        setDamageEntryItemList(prevState => prevState?.map((each) => {
                            if (each?.damage_entry_master_id == val?.damage_entry_master_id) {
                                each[name] = Number(value);
                            }
                            return each
                        }))
                    } else {
                        addOrRemove(val)
                    }
                }




            }
        }
        setErrors({ ...error })
    }

    async function saveDamageEntryApproval() {
        damageApproval.damageApprovaldetails = damageApprovalDetail;
        let error: any = {}
        error = SubmitValidation(damageApproval)
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            const status = await damageEntryOutletStore.saveDamageEntryApproval(damageApproval);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    const handleCancel = () => {
        navigate('/Admin/DamageEntryApproval/DamageEntryApprovalDetails');
    }

    const handleSuccessModalClose = () => {
        navigate('/Admin/DamageEntryApproval/DamageEntryApprovalDetails');
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const isCurrenPage = useRef(true)

    async function fetchPreLoadData() {
        await loadEmployee()
        const entryNo = await outwardtoOutletStore.getEntryNo('DamageEntryApproval');
        setDamgeApproval({ ...damageApproval, entryNo: entryNo?.entryNo + 1 })

        const damageEntryDetail = await damageEntryOutletStore.loadDamageTransferPendingDetails(0);

        setDamageEntryDetails([...damageEntryDetail])
        setLoading(false)
    }

    useEffect(() => {
        if (isCurrenPage.current) {
            fetchPreLoadData();
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    const sortedData = useMemo((): any => {
        if (damageEntryDetails?.length) {
            const listedData = damageEntryDetails?.slice()?.sort((a, b) => {
                const dateA = new Date(a.indentdate);
                const dateB = new Date(b.indentdate);

                if (dateA > dateB) return -1;
                if (dateB < dateA) return 1;
                return 0;
            });
            return listedData;
        }
    }, [damageEntryDetails])

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> : null
            }
            <>
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Damage Entry Approval</h3>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='' >
                                <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">DC.NO & Date</th>
                                                <th scope='col'>Entry Type</th>
                                                <th scope="col">Warehouse / Outlet Name</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ verticalAlign: 'middle' }}>
                                            {sortedData?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{`${val?.damage_entry_master_id} & ${moment(val?.indentdate).format('DD-MMM-YYYY')}`}</td>
                                                        <td>{val?.Entrytype}</td>
                                                        <td>{val?.Outletname}</td>
                                                        <td>{val?.Employee_Name}</td>
                                                        <td><input type='checkbox' onChange={(e) => handleOpenDetails(val, e)} name='option'></input></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {errors.damageApprovaldetails && <p style={{ color: 'red' }}>{errors.damageApprovaldetails}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                                <div className='row'>
                                    {isSpinner &&
                                        <div className='SpinnerBox'>
                                            <Spinner animation="grow" size="sm" />
                                        </div>}
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Entry No <span>*</span></label>
                                            <input type="text"
                                                name='assignNo' disabled
                                                value={damageApproval?.entryNo}
                                                className='' style={{ width: "100%" }} placeholder='Entry No..'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Entry Date <span>*</span></label>
                                            <input type="date"
                                                name='entryDate'
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                value={damageApproval.entryDate = damageApproval?.entryDate ?? new Date().toISOString().substr(0, 10)}
                                                onChange={handleInputchange}
                                                style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            {errors.entryDate && <p style={{ color: 'red' }}>{errors.entryDate}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-10'>
                                        <div className='inputBox'>
                                            <label>Created by </label>
                                            <AutoComplete
                                                placeholder='Select Created by..'
                                                options={removeDuplicates(loadEmployees, 'employeeName')}
                                                emitOption={(val) => handleAutoComplete('convertedby', val)}
                                                getOptionLabel='employeeName'
                                            />
                                        </div>
                                        {errors.convertedby && <p style={{ color: 'red' }}>{errors.convertedby}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit'
                                onClick={saveDamageEntryApproval}
                            >Submit</button>
                        </div>
                        <Modal show={dialogOpen} onHide={() => setDialog(false)} className='PriceHistoryModel'>
                            <Modal.Header closeButton>
                                <Modal.Title>Item Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='inputBoxLists pd-b-0'>
                                    <div className='LgInputListsBox mr-left-0'>
                                        <div className='tableBox'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">UOM</th>
                                                        <th scope="col">Barcode</th>
                                                        <th scope="col">Batch No</th>
                                                        <th scope='col'>Return</th>
                                                        <th scope='col'>Reuse</th>
                                                        <th scope='col'>Rejection</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: 'middle' }}>
                                                    {damageEntryItemList?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{val?.itemname}</td>
                                                                <td>{val?.damage_stk}</td>
                                                                <td>{val?.Unitname}</td>
                                                                <td>{val?.Barcode}</td>
                                                                <td>{val?.batch_no}</td>
                                                                <td>
                                                                    <input name='returnQty' value={val?.returnQty ?? 0} onChange={(e) => handleUpdate(e, val)} />
                                                                </td>
                                                                <td>
                                                                    <input name='reuseQty' value={val?.reuseQty ?? 0} onChange={(e) => handleUpdate(e, val)} />
                                                                </td>
                                                                <td>
                                                                    <input name='rejectionQty' value={val?.rejectionQty ?? 0} onChange={(e) => handleUpdate(e, val)} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        {errors?.qty && <p style={{ color: 'red' }}>{errors?.qty}</p>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../../image/checked.png')} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>

                                    <div className='imgBox'>
                                        <Image src={require('../../../../image/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </>
        </>
    );
}

export default DamageEntryApproval;