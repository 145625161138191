import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IAssignPos } from './model';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import { removeDuplicates } from '../../common/shared/utils';

const AssignPOSStaff = observer((): JSX.Element => {
  const [assignPos, setAssignPos] = useState<IAssignPos>({});
  const { assignPosStore, commonStore, memberStore } = useStores();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const { loadEmployees, loadEmployee,
    loadShiftDetails, loadShiftDetail,
    loadTerminalDetails, loadTerminalDetail } = commonStore;

  const handleEmployee = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignPos({ ...assignPos, [name]: val.employeeId, ['employeeName']: val.employeeName });
    }
    else {
      assignPos.employeeName = '';
    }
  }

  const handleShift = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignPos({ ...assignPos, [name]: val.shiftId, ['shiftName']: val.shiftName });
    }
    else {
      assignPos.shiftName = '';
    }
  }

  const handleCounter = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignPos({ ...assignPos, [name]: val.counterNumber, ['terminalId']: val.terminalId });
    }
    else {
      assignPos.terminalId = 0;
    }
  }

  async function saveAssignPos(e) {
    e.preventDefault();

    let error: any = {}
    error = validation(assignPos)
    setErrors(error)

    if (Object.keys(error)?.length === 0) {
      setLoading(true);
      const entryNos = await memberStore.getEntryNo('AssignPOS');
      assignPos.entryId = entryNos.entryNo + 1;
      const status = await assignPosStore.saveAssignPos(assignPos);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true);
      }
      else {
        setLoading(false);
        setFailureModal(true)
      }

    }

  };

  function validation(data: IAssignPos) {
    let error: any = {}

    if (!data?.employeeId) {
      error.employeeId = 'Select Employee'
    }
    if (!data?.terminalId) {
      error.terminalId = 'Select Counter Number'
    }
    if (!data?.shiftId) {
      error.shiftId = 'Select Shift'
    }

    return error;
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignPOSStaff/AssignPOSStaffDetail');
  }
  const handleCancel = () => {
    navigate('/Admin/AssignPOSStaff/AssignPOSStaffDetail');
  }
  const handleInputchnage = (e) => {

    const { name, value } = e.target;
    setAssignPos({ ...assignPos, [name]: value });

  };
  async function fetchPreLoadngData() {
    setLoading(false)
    await Promise.all([loadEmployee(),
    loadShiftDetail(),
    loadTerminalDetail(),
    memberStore.getEntryNo('AssignPOS')]);
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoadngData()
      isCurrenPage.current = false
    }
    return () => { }
  }, []);

  return (
    <div>
      <>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> :
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Assign POS Staff</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Entry No</label>
                        <input type="text" className='' disabled
                          onChange={handleInputchnage}
                          name="entryid"
                          value={assignPos.entryId = memberStore.entryNo.entryNo + 1}
                          style={{ width: "100%" }} placeholder='Entry No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Date <span>*</span></label>
                        <input type="date" style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="entryDate"
                          value={assignPos.entryDate = assignPos.entryDate ?? new Date().toISOString().substr(0, 10)}
                          placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Counter Number <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadTerminalDetails}
                            getOptionLabel={(option: any) => option.counterNumber}
                            onChange={(event, val) => handleCounter('terminalId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Counter Number..'
                                name='terminalId'
                              />}
                          />
                        </Form.Group>
                      </div>
                      {errors?.terminalId && <p style={{ color: 'red' }}>{errors?.terminalId}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Employee <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={removeDuplicates(loadEmployees, 'employeeName')}
                            getOptionLabel={(option: any) => option.employeeName}
                            onChange={(event, val) => handleEmployee('employeeId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Employee..'
                                name='employeeId'
                              />}
                          />
                        </Form.Group>
                      </div>
                      {errors?.employeeId && <p style={{ color: 'red' }}>{errors?.employeeId}</p>}

                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Shift <span>*</span></label>
                        <Form.Group style={{ width: "100%" }}>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadShiftDetails}
                            getOptionLabel={(option: any) => option.shiftName}
                            onChange={(event, val) => handleShift('shiftId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Shift..'
                                name='shiftId'
                              />}
                          />

                        </Form.Group>
                      </div>
                      {errors?.shiftId && <p style={{ color: 'red' }}>{errors?.shiftId}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Effective Form <span>*</span></label>
                        <input type="date"
                          onChange={handleInputchnage}
                          name="effectDate"
                          value={assignPos.effectDate = assignPos.effectDate ?? new Date().toISOString().substr(0, 10)}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                </div>
                <div className='btnBox'>

                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveAssignPos}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
        }
      </>
    </div>

  );

});

export default AssignPOSStaff;
