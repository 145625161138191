import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IBrand } from './model';
import useStores from '../../hooks';
import './BrandStyle.css';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import brandValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { startTransition } from 'react';

const Brand = observer((): JSX.Element => {

  const [brand, setBrandModel] = useState<IBrand>({})
  const [brandList, setBrandList] = useState<any[]>([])
  const { brandStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isExists, setExists] = useState(false)
  const handleChangeview = () => {
    navigate('/Admin/brand/list');
  }

  const { brandId } = useParams();

  const saveBrand = async (e) => {
    debugger
    e.preventDefault();

    let error: any = {};
    error = brandValidation(brand);
    setErrors(error);
    if (Object.keys(error).length === 0 && !isExists) {
      setLoading(true);

      let status: string = '';
      if (brandId !== undefined) {
        status = await brandStore.updateBrand(brand);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await brandStore.saveBrand(brand);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'brandCode') {
      // let isExists = brandList?.forEach((each)=>each)
      const isExists = brandList.some(brand => brand.brandCode === value);
      let error: any = {};
      if (isExists) {
        error.brand = 'Brand already exists'
      } else {
        error = {}
      }
      setExists(isExists)
      setErrors(error);
    }
    setBrandModel({ ...brand, [name]: value })
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/Brand/list');
  }

  async function getBrandDet() {
    setLoading(false)
    if (brandId !== undefined) {
      const data = await brandStore.BrandDetails(parseInt(brandId!));
      console.log(data,'branddata');
      brand.brandId = data.brandId
      brand.brandCode = data.brandCode;
      brand.brandCompany = data.brandCompany;
      setBrandModel(brand);
      console.log(brand,'brand');
      
    }
  }

  async function getBrandNo() {
      const [data, brandList] = await Promise.all([brandStore.getEntryNo('BrandMaster'), brandStore.getBrand()]);
      setBrandList([...brandList])
      if (data !== undefined) {
        brand.brandId = data.entryNo + 1;
        setBrandModel(brand);
    }
  }

  const isCurrentPage = useRef(true);

//   async function getBrandNoEdit() {
//     const [data, brandList] = await Promise.all([brandStore.getEntryNo('BrandMaster'), brandStore.getBrand()]);
//     setBrandList([...brandList])
//     if (data !== undefined) {
//       brand.brandId = data.entryNo;
//       setBrandModel(brand);
//   }
// }

async function getBrandNoEdit() {
  try {      
      const [data, brandList] = await Promise.all([
          brandStore.getEntryNo('BrandMaster'),
          brandStore.getBrand()
      ]);      
      startTransition(() => {
          setBrandList([...brandList]);
      });       
      if (data && data.entryNo !== undefined) {
          const targetBrandId = brandId; //data.entryNo;           
          const selectedBrand = brandList.find(brand => brand.brandId === targetBrandId);
          if (selectedBrand) {
              console.log("Selected brand:", selectedBrand);               
              startTransition(() => {
                  setBrandModel(selectedBrand);
              });
          } else {
              console.error(`Brand with ID ${targetBrandId} not found in the brand list.`);
          }
      } else {
          console.error("No entry number found in data:", data);
      }
  } catch (error) {
      console.error("Error fetching brand data:", error);
  }
}

useEffect(() => {
  if (isCurrentPage.current) {
    getBrandDet(); 
    if (brandId !== undefined){
      getBrandNoEdit();  
    }else{
      getBrandNo();       
    }      
    isCurrentPage.current = false;
  }
  return () => { }
}, [])

  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>

              <form onSubmit={saveBrand}>
                <div className='hrBox'>
                  <h3>Brand</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='inputBox'>
                    <label>Code</label>
                    <input type="text" style={{ width: "50%" }}
                      name='brandId' disabled
                      value={brand.brandId}
                      className='brandinput' placeholder='Code..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='inputBox'>
                    <label>Brand <span>*</span></label>
                    <input type="text"
                      name='brandCode'
                      value={brand.brandCode} onChange={handleChangeInput}
                      className='brandinput' placeholder='Enter Brand..'></input>
                    {errors.brandCode && <p style={{ color: 'red' }}>{errors.brandCode}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='inputBox'>
                    <label>Company <span>*</span></label>
                    <input type="text"
                      name='brandCompany'
                      value={brand.brandCompany} onChange={handleChangeInput}
                      className='brandinput' placeholder='Enter Company..'></input>
                    {errors.brandCompany && <p style={{ color: 'red' }}>{errors.brandCompany}</p>}
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                  <button className='dfBtn' type='submit' onClick={saveBrand}>Submit</button>
                </div>
              </form>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </>
  );

});

export default Brand;
