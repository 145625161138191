import React, { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IManageLoyalty } from './model';
import manageLoyaltyValidation from './validation';
import _ from 'lodash';
import AutoComplete from '../../common/shared/autoComplete';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';

const ManageLoyalty = observer((): JSX.Element => {
  const { manageLoyaltyStore, memberStore, outletManagerStore } = useStores();
  const { getManageLoyalty } = manageLoyaltyStore;
  const [manageLoyalty, setManageLoyalty] = useState<IManageLoyalty>({});
  const [manageLoyaltyDetails, setManageLoyaltyDetails] = useState<IManageLoyalty[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [billValueTo, setBillValueTo] = useState('');
  const [billValueFrom, setBillValueFrom] = useState('');
  const [points, setPoints] = useState('');


  const { loyalId } = useParams<{ loyalId: string }>();

  const handleCancel = () => {
    navigate('/Admin/ManageLoyalty/ManageLoyaltyList');
  }

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    setBillValueTo(value.billValueTo)
    setBillValueFrom(value.billValueFrom)
    setPoints(value.points)
    setManageLoyalty({ ...manageLoyalty, [name]: value })
  }

  const addManageLoyalty = async () => {
    let newObject: IManageLoyalty = {};

    let error: any = {};
    error = manageLoyaltyValidation(manageLoyalty);
    setErrors(error);
    setBillValueTo('')
    setBillValueFrom('')
    setPoints('')
    debugger
    if (Object.keys(error).length === 0) {
      newObject.category = manageLoyalty.category;
      newObject.billFrom = Number(manageLoyalty.billFrom)
      newObject.billTo = Number(manageLoyalty.billTo)
      newObject.points = Number(manageLoyalty.points)
      newObject.mlslno = Number(manageLoyalty.mlslno)
      setSubmitbtn(true);
      setManageLoyaltyDetails([...manageLoyaltyDetails, newObject])
      setManageLoyalty({ ...manageLoyalty, billFrom: 0, billTo: 0, manageLoyaltyId: 0, points: 0 }) // category: '', 
      //setManageLoyaltyDetail(newObject); 
    }
  }
  async function saveManageLoyalty(e) {

    // setManageLoyaltyDetails([...manageLoyaltyDetails, mlslno: entryNos ])
    e.preventDefault();
    setLoading(true);
     await manageLoyaltyStore.deleteManageLoyalty(manageLoyalty.mlslno);
    const status = await manageLoyaltyStore.saveManageLoyalty(manageLoyaltyDetails);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  async function handleRemove(index,id) {
    manageLoyaltyDetails.splice(index, 1)
    setManageLoyaltyDetails(_.cloneDeep(manageLoyaltyDetails))
  }
  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/ManageLoyalty/ManageLoyaltyList');
  }

  async function fetchPreLoadingData() {
    await memberStore?.getMembershipPlan(0);
    setLoading(false)
    if (loyalId !== undefined) {
      const getManageDet = await getManageLoyalty(loyalId);
      console.log(getManageDet, 'getManageDet');
      setManageLoyalty({ ...manageLoyalty, mlslno: getManageDet[0]?.mlslno })
      setManageLoyaltyDetails(getManageDet);
      console.log(manageLoyaltyDetails,'manageLoyaltyDetails');   
    }
    else {
      const entryNos = await outletManagerStore.getEntryNo('ManageLoyalty');
      setManageLoyalty({ ...manageLoyalty, mlslno: entryNos.entryNo + 1 })
    }
  }

  const isCurrentPage = useRef(true)
  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadingData();
      isCurrentPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleAutoComplete(name, value) {
    setManageLoyalty({ ...manageLoyalty, [name]: value?.Membershipid })
  }

  return (
    <div className='container'>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Manage Loyalty</h3>
        </div>
        <div className='inputBoxLists pd-b-0'>
          <div className='LgInputListsBox mr-left-0'>
            <div className='row'>
              <div className='inputBox' >
                <label>SNo <span>*</span></label>
                <input type="text"
                  disabled
                  value={manageLoyalty.mlslno}
                  defaultValue={manageLoyalty.mlslno} name='sno'
                  className='' style={{ width: "20%", marginBottom: '5%' }} placeholder=''></input>
                {errors.sno && <p style={{ color: 'red' }}>{errors.sno}</p>}
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Customer Category <span>*</span></label>
                  <AutoComplete options={removeDuplicates(memberStore?.memberPlanList, 'PlanName')} placeholder={'Customer Category...'}
                    emitOption={(option: any) => handleAutoComplete('Membershipid', option)}
                    clsName="full-width" getOptionLabel='PlanName' />
                  {errors.category && <p style={{ color: 'red' }}>{errors.category}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>

              <div className='col-md-6'>
                {manageLoyalty?.Membershipid && <div className='inputBox'>
                  <div className='inputBox'>
                    <label>Plan Details <span>*</span></label>
                    <AutoComplete value={manageLoyalty?.category}
                      options={memberStore?.memberPlanList?.filter((each) => each?.Membershipid === manageLoyalty?.Membershipid)} placeholder={'Plan Amount...'}
                      emitOption={(option: any) => setManageLoyalty({ ...manageLoyalty, category: option?.PlanName })}
                      clsName="full-width" getOptionLabel='Amount' />
                    {errors.customerCategory && <p style={{ color: 'red' }}>{errors.customerCategory}</p>}
                    <div className='vertical-space-10'></div>
                  </div>
                  {errors.cardName && <p style={{ color: 'red' }}>{errors.cardName}</p>}
                </div>}
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Bill Value From <span>*</span></label>
                  <input type="text"
                    value={billValueFrom}
                    defaultValue={manageLoyalty.billFrom} name='billFrom'
                    onChange={handleChangeInput}
                    className='' style={{ width: "100%" }} placeholder='Bill Value From..'></input>
                  {errors.billFrom && <p style={{ color: 'red' }}>{errors.billFrom}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Bill Value To <span>*</span></label>
                  <input type="text"
                    value={billValueTo}
                    defaultValue={manageLoyalty.billTo} name='billTo'
                    onChange={handleChangeInput}
                    className='' style={{ width: "100%" }} placeholder='Bill Value To..'></input>
                  {errors.billTo && <p style={{ color: 'red' }}>{errors.billTo}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Points<span>*</span></label>
                  <input type="text"
                    value={manageLoyalty.points} name='points'
                    onChange={handleChangeInput}
                    className='' style={{ width: "100%" }} placeholder='Points..'></input>
                  {errors.points && <p style={{ color: 'red' }}>{errors.points}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-4'>
                <div className='inputBox'>
                  <label>Points 100 Rs</label>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2'>
                <button className='dfBtn' onClick={addManageLoyalty}>ADD</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Category</th>
                        <th scope="col">Bill Value From</th>
                        <th scope="col">Bill Value To </th>
                        <th scope="col">Points</th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        manageLoyaltyDetails.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.category}</td>
                              <td>{val.billFrom}</td>
                              <td>{val.billTo}</td>
                              <td>{val.points}</td>
                              <td><button style={{
                                border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                borderRadius: '6px', fontSize: '12px'
                              }} type='submit'
                                onClick={() => handleRemove(key,val.mlslno)}>Remove</button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-10'></div>
              </div>

            </div>
          </div>
          <div className='vertical-space-10'></div>
        </div>
        <div className='btnBox'>
          <button className='secondaryBtn' onClick={handleCancel}>List</button>
          <button className='dfBtn' type='submit' onClick={saveManageLoyalty}>Submit</button>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );

});

export default ManageLoyalty;
