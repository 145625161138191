import React, { useEffect, useRef, useState } from "react"
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import AutoComplete from "../../common/shared/autoComplete";
import { IMembershipSave, IMemebershipDetails } from "./model";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useStores from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

function MembershipPlan() {

    const { memberStore } = useStores()
    const navigate = useNavigate();
    const { getMembershipPlan } = memberStore;

    const { memberplanId } = useParams<{ memberplanId: string }>();
    const [memberShipObj, setMemberShipObj] = useState<IMembershipSave>(new IMembershipSave())
    const [memberDetailObj, setMemberDetailObj] = useState<IMemebershipDetails>(new IMemebershipDetails())
    const [memberShipArr, setMemberShipArr] = useState<IMemebershipDetails[]>(new Array<IMemebershipDetails>())
    const [errors, setError] = useState<any>({})
    const [isLoading, setLoading] = useState(true)
    const [isSpinner, setSpinner] = useState(false)

    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    function handleInputChagne(e) {
        const { name, value } = e.target;
        if (name === "planName" || name === "effectdate" && value != null) {
            setMemberShipObj({ ...memberShipObj, [name]: value })
        } else {
            setMemberDetailObj({ ...memberDetailObj, [name]: Number(value) })
        }
    }

    function hanldeAddFunction() {
        let error: any = {}
        memberDetailObj.effectdate = memberShipObj.effectdate;
        if (!memberDetailObj.duration) {
            error.duration = "Enter Duration"
        }
        // if (!memberDetailObj.amount) {
        //     error.amount = "Enter Amount"
        // }
        setError(error)

        if (Object.keys(error).length === 0) {
            setMemberShipArr([...memberShipArr, memberDetailObj])
            setMemberDetailObj({ ...memberDetailObj, amount: 0, duration: 0 })
        }
    }

    function handleRemove(index) {
        setMemberShipArr(memberShipArr?.filter((obj, ind) => ind != index))
    }

    async function submitFunc() {
        let error: any = {}

        memberShipObj.membershipdetails = memberShipArr;

        if (!memberShipObj.planName) {
            error.planName = "Enter Plan Name"
        }
        if (!memberShipObj.effectdate) {
            error.effectdate = "Enter Date"
        }
        if (!memberShipObj.membershipdetails?.length) {
            error.membershipdetails = "Add Membership plan to grid"
        }
        setError(error)

        if (Object.keys(error).length === 0) {
            setLoading(true)
            if (memberplanId !== undefined) {
                debugger
                const status = await memberStore.membershipEdit(memberShipObj);
                if (status === "Success") {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            }
            else {
                const status = await memberStore.memberShipSave(memberShipObj);
                if (status === "Success") {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            }
        }
    }

    const handleSuccessModalClose = () => {
        navigate('/Admin/MembershipPlanList')
        setSuccessModal(false);
    }

    async function fetchpreLoadingData() {
        setLoading(false);
    }

    const isInitialRender = useRef(true);

    async function getMemberDet() {
        if (memberplanId !== undefined) {
            const membershipPlanList = await memberStore.getMembershipPlan(memberplanId);
            console.log(membershipPlanList, 'membershipPlist');
    
            if (membershipPlanList && membershipPlanList.length > 0) {
                membershipPlanList.forEach(plan => {
                   
                    const updatedDetails = {
                        effectdate: new Date(plan.Effectdate).toISOString().substr(0, 10),
                        duration: plan.Duration,
                        amount: plan.Amount
                    };
    
       
                    setMemberShipArr(prevState => [
                        ...prevState,
                        updatedDetails
                    ]);
    
                    const updatedMasters = {
                        ...memberShipObj,
                        effectdate: new Date(plan.Effectdate).toISOString().substr(0, 10),
                        planName: plan.PlanName,
                        mid: plan.Membershipid,
                        membershipdetails: [
                            ...(memberShipObj.membershipdetails || []),
                            updatedDetails
                        ]
                    };
    
                    console.log(updatedMasters, 'updatedMasters');
                    console.log(updatedDetails, 'updatedDetails');
    
                    setMemberShipObj(updatedMasters);
    
             
                    setMemberDetailObj(prevState => ({
                        ...prevState,
                        ...updatedDetails
                    }));
                });
    
               
                console.log(memberShipArr, 'memberShipArr'); 
                console.log(memberDetailObj, 'memberDetailObj'); 
            }
        }
    }
    

    useEffect(() => {
        if (isInitialRender.current) {
            fetchpreLoadingData();
            getMemberDet();
            isInitialRender.current = false;
        }
        return () => { };
    }, [])
    return (
        <>
            {isLoading &&
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>}
            <div className='container'>
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox '>
                    <div className='hrBox'>
                        <h3>Membership Plan</h3>
                    </div>
                    <div className='inputBoxLists'>
                        <div className='row'>
                            {isSpinner &&
                                <div className='SpinnerBox'>
                                    <Spinner animation="grow" size="sm" />
                                </div>}
                            <div className='col-sm-6' >
                                <div className='inputBox'>
                                    <label>Plan Name <span>*</span></label>
                                    <input type="text" style={{ width: "100%" }}
                                        name='planName'
                                        value={memberShipObj.planName}
                                        onChange={handleInputChagne}
                                        placeholder="Plan Name..." data-date=""
                                    ></input>
                                    {errors.planName && <p style={{ color: 'red' }}>{errors.planName}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='inputBox'>
                                    <label>Effect. Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        name='effectdate'
                                        value={memberShipObj.effectdate = memberShipObj?.effectdate != undefined ? memberShipObj?.effectdate : new Date().toISOString().substr(0, 10)}
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                        onChange={handleInputChagne}
                                        placeholder="DD-MMM-YYYY" data-date=""
                                        data-date-format="DD MM YYYY"></input>
                                    {errors.effectdate && <p style={{ color: 'red' }}>{errors.effectdate}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-8' >
                                <div className='inputBox'>
                                    <label>Duration</label>
                                    <input type="text" style={{ width: "100%" }}
                                        name='duration'
                                        value={memberDetailObj?.duration}
                                        onChange={handleInputChagne}
                                    ></input>
                                    {errors.duration && <p style={{ color: 'red' }}>{errors.duration}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-8' >
                                <div className='inputBox'>
                                    <label>Amount</label>
                                    <input type="text" style={{ width: "100%" }}
                                        name='amount'
                                        onChange={handleInputChagne}
                                        value={memberDetailObj?.amount}></input>
                                    {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>

                            <div className='col-sm-4' >
                                <div className='btnBox pd-t-0'>
                                    <button className='dfBtn' type='submit' onClick={hanldeAddFunction}>ADD</button>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>

                            <div className='vertical-space-10'></div>

                            <div className="col-sm-12">
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Duration</th>
                                                {/* <th scope="col">Days</th> */}
                                                <th scope="col">Amount</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {memberShipArr.length > 0 &&
                                                memberShipArr.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{val?.duration}</td>
                                                            {/* <td>{moment(val?.effectdate).format("DD-MMM-YYYY")}</td> */}
                                                            <td>{val?.amount}</td>
                                                            <td><button className='delete' onClick={() => handleRemove(index)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                            <button className='dfBtn' type='submit' onClick={submitFunc} >Submit</button>
                        </div>
                    </div>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../../image/checked.png')} />
                            </div>

                            <h4>Succesfully Submitted</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>

                            <div className='imgBox'>
                                <Image src={require('../../../../image/warning.png')} />
                            </div>
                            <h4>Failed</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setFailureModal(false)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
export default MembershipPlan