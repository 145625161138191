import { observable } from "mobx";
export interface IItemInward {
    inwardNo?: number;
    inwardDate?: string;
    SupplierId?: number;
    itemWarehouse?: string;
    billNo?: string;
    billDate?: string;
    itemId?: number;
    itemName?: string;
    itemQuantity?: number;
    freeQuantiity?: string;
    itemRate?: number;
    totalValue?: number | undefined;
    itemvalue?: number;
    discountPercentage?: number;
    discountValue?: number;
    gstLedger?: string;
    gstPercentage?: string;
    gstValue?: any;
    warehouseId?: number;
    inwardDetailList?: IInwardDetails[];
    Ntotalvalue?: number;
    NDiscountV?: number;
    Ngstvalue?: number;
    Netvalue?: number;
    supplier?: string;
    wareHouse?: string;
    discountType?: string;
    discount?: number;
    discountAmount?: any;
    ledgers?: IInwardLedgers[];
}
export interface IInwardDetails {
    inwardItemid?: number;
    inwardItemName?: string;
    inwardItemQuantity?: number;
    inwardFreeQuantiity?: number;
    inwardItemRate?: number;
    inwardTotalValue?: number | undefined;
    inwardItemvalue?: number;
    inwardDisPercentage?: number;
    inwardDiscountPerItem?: number;
    inwardGstPercentage?: number;
    inwardGstValue?: number;
    inwardValue?: number;

}
export interface IInwardLedgers {
    Id?: number;
    ledgerId?: number;
    ledgerName?: string;
    ledgerAmount?: number;
    ledgerDetlId?: number;

}
export class IOutletItemRequest {
    outletItemId?: number; // entry no
    outletRequestId?: number = 0;
    outletItemDate?: string = new Date()?.toISOString()?.substr(0, 10);
    outletId?: number; // ffrom outlet name
    outletName?: string;// ffrom outlet name
    employeeId?: number; // ffrom employee created by name
    employeeName?: string; // ffrom employee created by name
    itemId?: number = 0;
    itemName?: string = "";
    outletStock?: number = 0;
    requiredQuantity?: number = 0;
    warehouse?: string = "";
    outletItemRequestDetails?: OutletItemRequestDetail[];

}

export class OutletItemRequestDetail {
    outletItemDetailId?: number = 0;
    outletItemId?: number = 0; // entry no
    requestItemId?: number = 0; // from Item name
    requestItemName?: string = "";// from Item name
    outletStock?: number = 0;// from item batchQty
    requiredQuantity?: number = 0;// from required input fields
    warehouse?: string = "";//from WareHouse warehouseName
}
export interface IOutletItemRequestSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

const outletItemRequest = observable<IOutletItemRequest>({
    outletItemId: 0,
    outletRequestId: 0,
    outletItemDate: '',
    outletId: 0,
    outletName: '',
    employeeId: 0,
    employeeName: '',
    itemId: 0,
    itemName: '',
    outletStock: 0,
    requiredQuantity: 0,
    outletItemRequestDetails: [],

});

export default outletItemRequest;