import { Autocomplete, Pagination, Stack, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react"
import useStores from "../../hooks";
import ProgressBar from "../../common/shared/progressbar";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { currentPageDatas, getCurrentMonthFirstDate, sortedData } from "../../common/shared/utils";
import StockSummaryValidation from "./validation";
import { excelKeyupdate, ExcelSheetData, IExcelSheetData, IIExceluploadModal } from "./modal";
import { convertExcelToArrayOfObjects } from "../../common/shared/convertExcToarrObj/covertExctoArrObj";
import { Button, Image, Modal, Spinner } from "react-bootstrap";

interface ExcelObject {
    [key: string]: any;
}

class IStockSummaryFields {
    fromDate: string = getCurrentMonthFirstDate()?.firstDate
    toDate: string = getCurrentMonthFirstDate()?.currentDate
    warehouseId: number = 0
    itemId: number = 0
    subCategoryId: number = 0
    category: string = ""

}

const WareStockSummaryBatchwise = () => {

    const { stockSummaryStore, commonStore } = useStores();

    const { loadWarehouse, loadWarehouses, loadSubcategory,
        loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;

    const { importExportFormSave } = stockSummaryStore

    const [loading, setLoading] = useState({ isLoading: true });
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false })
    const [stockSummaryFields, setStockSummaryFields] = useState<IStockSummaryFields>(new IStockSummaryFields())
    const [stockSummaryDetail, setStockSummaryDetails] = useState<any[]>([]);

    const [errors, setErrors] = useState<any>({});
    const [searchValue, setSearchValue] = useState('');

    const [isExcel, setExcel] = useState(false)
    const [data, setData] = useState<ExcelObject[]>([]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const result = await convertExcelToArrayOfObjects(file);
                setData(result);
            } catch (error) {
                console.error('Error reading Excel file:', error);
            }
        }
    };

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && stockSummaryDetail?.length > 0) {
            return sortedData(stockSummaryDetail, 'Inwarddate')?.filter((stock) => {
                if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    const handleInputchnage = (e) => {
        const { name, value } = e.target;
        setStockSummaryFields({ ...stockSummaryFields, [name]: value });
    };

    const handleWareHouseValue = (name: any, event: object, val: any) => {
        if (val != null) {
            setStockSummaryFields({ ...stockSummaryFields, warehouseId: val.warehouseId });
        }
    }

    function handleSearchInput(e) {
        setSearchValue(e.target.value);
    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;

        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true })
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setStockSummaryFields({ ...stockSummaryFields, [type]: value.subCategoryId });
        } else {
            setStockSummaryFields({ ...stockSummaryFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        setLoading({ ...loading, isLoading: true })

        let error: any = {};
        error = StockSummaryValidation(stockSummaryFields)
        setErrors(error);

        if (Object.keys(error).length === 0) {
            let body = {
                "warehouseId": stockSummaryFields?.warehouseId,
                "itemId": stockSummaryFields?.itemId,
                "subCategoryId": stockSummaryFields?.subCategoryId,
                "category": stockSummaryFields?.category,
                "fromDate": stockSummaryFields?.fromDate,
                "toDate": stockSummaryFields?.toDate
            }
            const stockSummaryDetails = await stockSummaryStore.getWarehouseStockSummaryBatchwise(body);
            setStockSummaryDetails(stockSummaryDetails)
        }
        setLoading({ ...loading, isLoading: false })
    }

    function excelNewSheet() {
        let excelArr = new Array<IExcelSheetData>();
        let intitalObj = new IExcelSheetData();
        excelArr.push(intitalObj)
        const csvContent = [Object.keys(excelArr[0]).join(',')].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Warehouse Stock - Batchwise ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function handleUploadExcel() {
        let excleMaster: IIExceluploadModal = {};
        let updatedArr = excelKeyupdate(data);
        excleMaster.impsno = 1;
        excleMaster.outletstock_import = updatedArr;
        const status = await importExportFormSave(excleMaster)
        if (status === "Success") {
            setLoading({ isLoading: false });
            setSuccessModal(true)
        } else {
            setLoading({ isLoading: false });
            setFailureModal(true);
        }
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Date: moment(stock?.Inwarddate).format("DD-MMM-YYYY"),
                Item_Name: stock?.itemname,
                Barcode: stock?.Barcode,
                Batchno: stock?.BatchNo,
                UOM: stock?.unitOfMeasure,
                Opening_Stk: stock?.open_stk,
                In_qty: stock?.inqty,
                Out_qty: stock?.outqty,
                Closing: stock?.closingstk,
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Warehouse Stock Summary - Batch wise", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Warehouse Stock Summary - Batch wise ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchStockSummary() {
        await loadItemConversion();
        await loadWarehouse();
        await loadSubcategory();
        setLoading({ ...loading, isLoading: false })
    }

    const isCurrent = useRef(true)

    useEffect(() => {
        if (isCurrent.current) {
            fetchStockSummary()
            isCurrent.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (stockSummaryDetail?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(stockSummaryDetail)
        }
    }, [stockSummaryDetail])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(sortedData(stockSummaryDetail, 'Inwarddate'), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
            <div className="" style={{ width: '100%' }}>
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Warehouse Stock Summary - Batch wise</h3>
                    </div>
                    {loading?.isLoading ? <div className='SpinnerBox'>
                        <Spinner animation="grow" size="sm" />
                    </div> : null}
                    <div className="inputBoxLists">
                        <div className='ItemInwardInputBox'>
                            <div className='row'>
                                <div className='col-sm-1'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }}
                                            value={stockSummaryFields?.fromDate}
                                            onChange={handleInputchnage}
                                            name="fromDate"
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                    </div>
                                    {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-1'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }}
                                            value={stockSummaryFields?.toDate}
                                            onChange={handleInputchnage}
                                            name="toDate"
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                    </div>
                                    {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                    <div className='vertical-space-20'></div>
                                </div>
                                <div className="col-sm-2" style={{ marginLeft: "0px", marginTop: '21px' }}>
                                    <div className='inputBox'>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={loadWarehouses}
                                            getOptionLabel={(option: any) => option.warehouseName}
                                            onChange={(event, val) => handleWareHouseValue('wareHouseId', event, val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select WareHouse Name..'
                                                    name='warehouseName'
                                                />}
                                        />
                                    </div>
                                    {errors.wareHouseId && <p style={{ color: 'red' }}>{errors.wareHouseId}</p>}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '20px' }}>
                                    <div className="input-group mb-3">
                                        <input type="text"
                                            style={{ height: "47px" }}
                                            name='searchValue'
                                            value={searchValue}
                                            onChange={handleSearchInput}
                                            className="form-control" placeholder="Search Here.."
                                            aria-label="Search Outlet..."
                                            aria-describedby="search-button"
                                        ></input>
                                        <div className="input-group-append">
                                            <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                                                id="search-button">
                                                <FontAwesomeIcon icon={faSearch}
                                                // onClick={handleQuoteSearch}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                    <div className='inputBox' style={{ marginTop: '17px' }}>
                                        <select style={{ width: "213px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                            <option value="">Select Search Criteria</option>
                                            <option value="ItemName">Item Name</option>
                                            <option value="Category">CateGory </option>
                                            <option value="SubCategory">Sub Category </option>
                                        </select>
                                    </div>

                                </div>
                                <div className='col-sm-2' style={{ marginTop: '20px', paddingRight: "40px" }}>
                                    {searchCriteria?.category ?
                                        <>
                                            <div className='inputBox'>
                                                <select style={{ width: "18ch", height: "47px", marginLeft: "17px" }} className="form-selected" onChange={handleInputchnage} id="Category" name="category">
                                                    <option value="" selected>Not Select</option>
                                                    <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                    <option value="Pets">Pets</option>
                                                    <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                    <option value="Grocery">Grocery</option>
                                                </select>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </> : searchCriteria?.itemName || searchCriteria?.subCategory ?
                                            <div className='inputBox' style={{ marginLeft: "16px" }}>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={searchCriteria?.itemName ? loadItemConversions : loadSubcategories}
                                                    getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                    onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : handleValue('subCategoryId', event, val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                            name={searchCriteria?.itemName ? 'itemId' : 'subcategory'}
                                                        />}
                                                />
                                            </div> :
                                            <div className="">

                                            </div>
                                    }
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '24px' }}>
                                    <div className="btnBox">
                                        <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                        <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj((searchValue?.length > 0 ? filteredData : stockSummaryDetail))}>Excel</button>
                                        <button className='secondaryBtn' onClick={excelNewSheet}>Export</button>
                                        <button className='secondaryBtn' onClick={() => setExcel(true)}>Import</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-5'>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{stockSummaryDetail?.length} Records</button>
                                </div>
                            </div>
                            <div className=' col-sm-7' >
                                <Stack spacing={2}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                        </div>
                        <div className='tableListDetails'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Barcode</th>
                                        <th scope="col">Batchno</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Opening Stk</th>
                                        <th scope="col">In Qty</th>
                                        <th scope="col">Out Qty</th>
                                        <th scope="col">Closing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchValue?.length > 0 ? filteredData : currentPageData)?.length > 0 ?
                                        (searchValue?.length > 0 ? filteredData : currentPageData)?.map((stock, index) => {
                                            return (
                                                <tr>
                                                    <td>{moment(stock?.Inwarddate).format("DD-MMM-YYYY")}</td>
                                                    <td>{stock?.itemname}</td>
                                                    <td>{stock?.Barcode}</td>
                                                    <td>{stock?.BatchNo}</td>
                                                    <td>{stock?.unitOfMeasure}</td>
                                                    <td>{Number(stock?.open_stk)?.toFixed(2)}</td>
                                                    <td>{stock?.inqty}</td>
                                                    <td>{stock?.outqty}</td>
                                                    <td>{stock?.closingstk}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <h6> No Data Available</h6>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isExcel} onHide={() => { setExcel(false); setData([]) }} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                    <Modal.Title>Excel Import </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='imgBox'>
                        <div className='col-sm-8'>
                            <div className='inputBox'>
                                <label>Upload Excel <span>*</span></label>
                                <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUploadExcel}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/checked.png')} />
                        </div>
                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/warning.png')} />
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setFailureModal(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default WareStockSummaryBatchwise