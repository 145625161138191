import React from 'react'
import { Modal } from 'react-bootstrap'

interface MemberDetailProps {
    ismembermodel: boolean,
    modalClose: () => void,
    ismember: boolean,
    memberDetail: any
}

const MemberDetailModal: React.FC<MemberDetailProps> = ({ ismember, ismembermodel, modalClose, memberDetail, }) => {
    return (
        <Modal show={ismembermodel} onHide={modalClose} className='PriceHistoryModel'>
            <Modal.Header closeButton>
                <Modal.Title>Member Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-sm-12'>
                    {ismember ? <div className="loader "> Loading...</div> :
                        <>
                            <div className='CustomerDetail'>
                                <div className='tableBox'>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Mobile No :</td>
                                                <td><span>{memberDetail.mobileNo}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Member Id :</td>
                                                <td><span>{memberDetail.memberId}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Member Name :</td>
                                                <td><span>{memberDetail.memberName}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Member Type :</td>
                                                <td><span>{memberDetail.memberType}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Member Category :</td>
                                                <td><span>{memberDetail.memberCategory}</span></td>
                                            </tr>
                                            <tr>
                                                <td>Member Address :</td>
                                                <td><span>{memberDetail.memberAddress}</span></td>
                                            </tr>
                                            <tr>
                                                <td>points earned :</td>
                                                <td><span>{memberDetail.earnedPoints}</span></td>
                                            </tr>
                                            <tr>
                                                <td>points used :</td>
                                                <td><span>{memberDetail.redeemPoints}</span></td>
                                            </tr>
                                            <tr>
                                                <td>points balance :</td>
                                                <td><span>{memberDetail.balanceEarnedPoints}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MemberDetailModal;