import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Image, Modal, Spinner } from "react-bootstrap"
import useStores from "../../hooks";
import { IISalesOrder, IISalesOrderDet, IISalesOrderLedger } from "./model";
import { Autocomplete, TextField } from "@mui/material";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { addItemValidation, saveValidation } from "./validation";
import { useNavigate } from "react-router-dom";
import { removeDuplicates } from "../../common/shared/utils";
import LedgerMaster from "../../Accounts/LedgerMaster/LedgerMaster";
import { addLedgerValidation } from "./validation";
import NewMember from "../NewMember/NewMemberRegistration";

const SalesOrder = () => {

    const navigate = useNavigate()

    const { posMasterStore, invoiceStore, itemMasterStore } = useStores();

    const [isSpinner, setSpinner] = useState(true)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isledgerMaster, setLedgerMaster] = useState(false);
    const [newMemberModal, setNewMemberModal] = useState(false);
    const [despatchModal, setDespatchModal] = useState(false)

    const [errors, setErrors] = useState<any>({})

    const [itemMasterJson, setItemMasterJson] = useState<any[]>([])
    const [customerList, setCustomerList] = useState<any[]>([])
    const [taxMaster, setGetTaxMaster] = useState<any[]>([])

    console.log(customerList, 'customerList>>')

    const [salesOrderMaster, setSalesOrderMaster] = useState<IISalesOrder>(new IISalesOrder())
    const [salesOrderDet, setSalesOrderDet] = useState<IISalesOrderDet>(new IISalesOrderDet())
    const [salesOrderLedger, setSalesOrderLedger] = useState<IISalesOrderLedger>(new IISalesOrderLedger())

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false)
        navigate('/Admin/SalesOrder/List')
    };

    async function handleLedgerClose() {
        await itemMasterStore.getTaxMaster('InwardLedger');
        setLedgerMaster(false)
    }

    function handleInputChange(event) {
        const { name, value } = event?.target;
        setSalesOrderMaster({ ...salesOrderMaster, [name]: value })
    }

    function handleAutoComplete(value) {
        console.log(value, 'value>>')
        setSalesOrderMaster({
            ...salesOrderMaster, sOrdMDoorNo: value?.doorno, sOrdMState: value?.state, sOrdMPincode: value?.pin?.toString(), sOrdMCustID: value?.mid,
            sOrdMCustName: value?.membername, sOrdMConactNo: value?.mobileno, sOrdMCity: value?.city, sOrdMArea: value?.area, sOrdCusType: value?.cust_type ? value?.cust_type?.split('-')[0] : "B2C"
        })
    }

    function handleChangeCustomer(val) {
        let error: any = {}
        if (salesOrderMaster?.salesorderdet?.length) {
            error.sOrdMCustID = "Can't Change Customer"
        }
        setErrors({ ...error })
    }

    function handleDetInputChange(event) {
        const { name, value } = event?.target;
        let updateObje = Object.assign({}, salesOrderDet)
        updateObje[name] = value
        const updatedObj = calFunc(updateObje)
        setSalesOrderDet({ ...updatedObj })
    }

    function calFunc(salesOrderDet) {
        let taxValue: string = "0";
        let discountValue: string = "0";
        let totalPrice: string = "0";

        discountValue = (Number(salesOrderDet?.sOrdDMRP!) - Number(salesOrderDet?.sOrdDRate!))?.toFixed(2);
        if (salesOrderDet?.sOrdDGST != "0") {
            taxValue = (Number(salesOrderDet?.sOrdDRate) - (Number(salesOrderDet?.sOrdDRate!) / (1 + salesOrderDet?.sOrdDGST! / 100)))?.toFixed(2)
        }
        totalPrice = (Number(salesOrderDet?.sOrdDRate!) * Number(salesOrderDet?.sOrdDQty))?.toFixed(2)
        salesOrderDet.sOrdDDis = discountValue;
        salesOrderDet.sOrdDGSTAmt = taxValue;
        salesOrderDet.sOrdDAmt = totalPrice;
        return salesOrderDet;
    }

    function handleSelectItem(val, event) {
        let sellingPrice = salesOrderMaster?.sOrdCusType === 'B2C' ? val?.BTCSellingRate! : val?.BTBSellingRate!;

        if (val?.itemid) {
            let salesOrderDet: IISalesOrderDet = {
                sOrdDItemID: val?.itemid?.toString(),
                sOrdDItemName: val?.itemname,
                sOrdDDis: (Number(val?.Mrprate!) - Number(sellingPrice!))?.toFixed(2),
                sOrdDGST: val?.taxper?.toString() ?? "0",
                sOrdDMRP: val?.Mrprate,
                sOrdDRate: sellingPrice,
                sOrdDQty: "1",
                sOrdDGSTAmt: val?.taxper! ? ((Number(val?.Mrprate) - Number(sellingPrice!)) / (1 + val?.taxper! / 100))?.toFixed(2) : "0",
                sOrdDAmt: (Number(sellingPrice!) * Number(1))?.toFixed(2),
            }
            const updatedData = calFunc(salesOrderDet)
            setSalesOrderDet({ ...updatedData })
        }
    }

    function handleSelectLedger(name, val, event) {
        if (name === 'saleOrdLedgerId') {
            setSalesOrderLedger({ ...salesOrderLedger, saleOrdLedgerId: val?.ledgerId, saleOrdLedgername: val?.ledgerName })
        }
    }

    function handleLedgerChange(event) {
        const { name, value } = event?.target;
        setSalesOrderLedger({ ...salesOrderLedger, [name]: Number(value) })
    }

    function handleAddLedger(e) {
        e.preventDefault();
        let error: any = {};
        error = addLedgerValidation(salesOrderLedger)
        setErrors(error);
        if (Object.keys(error)?.length === 0) {
            salesOrderMaster?.salesorderledger?.push(salesOrderLedger)
            setSalesOrderMaster({ ...salesOrderMaster })
            setSalesOrderLedger({ ...salesOrderLedger, saleOrdLedgerId: 0, saleOrdLedgername: '', saleOrdLedgerAmount: 0 })
        }
    }

    const deleteLedger = (Id) => {
        let findInd = salesOrderMaster?.salesorderledger?.findIndex((each) => each?.saleOrdLedgerId === Id)
        salesOrderMaster?.salesorderledger?.splice(findInd!, 1)
        setSalesOrderMaster({ ...salesOrderMaster })
    }

    function handleAddOrRemove(index) {
        if (index !== -1) {
            salesOrderMaster?.salesorderdet?.splice(index, 1)
            setSalesOrderMaster({ ...salesOrderMaster })
        } else {
            let error: any = {}
            error = addItemValidation(salesOrderMaster, salesOrderDet)
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                let isExistsIndex = salesOrderMaster?.salesorderdet?.findIndex((each) => each?.sOrdDItemID === salesOrderDet?.sOrdDItemID)
                if (isExistsIndex !== -1) {
                    let isExistsItem = salesOrderMaster.salesorderdet![isExistsIndex!];
                    isExistsItem.sOrdDQty! = (Number(salesOrderDet?.sOrdDQty!) + Number(isExistsItem.sOrdDQty))?.toString()
                    let updatedItem = calFunc(isExistsItem)
                    salesOrderMaster.salesorderdet![isExistsIndex!] = updatedItem;
                } else {
                    salesOrderMaster?.salesorderdet?.push(salesOrderDet)
                }
                setSalesOrderDet(new IISalesOrderDet())
                setSalesOrderMaster({ ...salesOrderMaster })
            }
        }
    }

    function handlePlusOrMinus(salesorder, type: 'add' | 'minus', qty: number) {
        let isExistsIndex = salesOrderMaster?.salesorderdet?.findIndex((each) => each?.sOrdDItemID === salesorder?.sOrdDItemID)
        if (isExistsIndex !== -1) {
            let isExistsItem = salesOrderMaster.salesorderdet![isExistsIndex!];
            isExistsItem.sOrdDQty! = type === 'add' ?
                (Number(salesorder?.sOrdDQty!) + qty)?.toString() :
                (Number(salesorder?.sOrdDQty!) - qty)?.toString()
            if (isExistsItem?.sOrdDQty == '0') {
                salesOrderMaster.salesorderdet?.splice(isExistsIndex!, 1)
            } else {
                let updatedItem = calFunc(isExistsItem)
                salesOrderMaster.salesorderdet![isExistsIndex!] = updatedItem;
            }
            setSalesOrderMaster({ ...salesOrderMaster })
        }
    }

    async function handleSubmit() {
        let error: any = {}
        error = saveValidation(salesOrderMaster)
        setErrors({ ...error })
        if (Object?.keys(error)?.length === 0) {
            const status = await invoiceStore.salesOrderSave(salesOrderMaster)
            if (status === 'Success') {
                setSpinner(false);
                setSuccessModal(true);
            } else {
                setSpinner(false);
                setFailureModal(true);
            }
        }
    }

    async function fetchPOSProducts() {
        let outletId = Number(Cookies.get('outletId'));
        if (outletId) {
            const posProductList = await posMasterStore.loadPOSProductList(outletId);
            setItemMasterJson([...posProductList])
        }
    }

    async function fetchMembers() {
        const getMembers = await invoiceStore.getMembers();
        setCustomerList([...getMembers])
    }

    async function initialApiCall() {
        fetchPOSProducts()
        fetchMembers()
        const getTaxMaster = await itemMasterStore.getTaxMaster('InwardLedger');
        setGetTaxMaster([...getTaxMaster])
        setSpinner(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage?.current) {
            initialApiCall()
            isCurrentPage.current = false
        }
    }, [])

    const netAmount = useMemo(() => {
        let itemTotal = salesOrderMaster?.salesorderdet?.reduce((a, v) => a = a + Number(v?.sOrdDAmt ?? 0), 0)
        let ledgerTotal = salesOrderMaster?.salesorderledger?.reduce((a, v) => a = a + Number(v?.saleOrdLedgerAmount ?? 0), 0)

        let totalAmount = Number(itemTotal! ?? 0) + Number(ledgerTotal! ?? 0)

        return totalAmount?.toFixed(2) ?? 0
    }, [salesOrderMaster])

    return (
        <div className='container'>
            {isSpinner &&
                <div className='SpinnerBox'>
                    <Spinner animation="grow" size="sm" />
                </div>}
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                    <h3>Sale Order</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                    <div className="row">
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Invoice Date <span>*</span></label>
                                <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                    value={salesOrderMaster?.sOrdMDate}
                                    onChange={handleInputChange} name="sOrdMDate"
                                    data-date="" data-date-format="DD MMMM YYYY"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-3' style={{ display: 'flex', flexDirection: 'column' }}>
                            <button className='dfBtn' style={{ width: '75%' }} onClick={() => setDespatchModal(true)}>Despatch To</button>
                            {errors?.despatchto && <p style={{ color: 'red' }}>{errors?.despatchto}</p>}
                            <div className='vertical-space-5'></div>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Item Name<span>*</span></label>
                                <Autocomplete size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={removeDuplicates(itemMasterJson, 'itemid')}
                                    getOptionLabel={(option: any) => option.itemname}
                                    onChange={(event, val) => handleSelectItem(val, event)}
                                    renderInput={(params: any) =>
                                        <TextField  {...params} fullWidth
                                            id="outlined-size-small"
                                            size="small"
                                            placeholder='Select Item..'
                                            name='itemid'
                                        />
                                    }
                                />
                            </div>
                            {errors?.sOrdDItemID && <p style={{ color: 'red' }}>{errors?.sOrdDItemID}</p>}
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Qty <span>*</span></label>
                                <input style={{ width: "100%" }} disabled={!salesOrderDet?.sOrdDItemID} value={salesOrderDet?.sOrdDQty}
                                    onChange={handleDetInputChange} name="sOrdDQty" placeholder="Enter Qty"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>MRP <span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDMRP}
                                    name="sOrdDMRP" placeholder="MRP"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Rate <span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDRate}
                                    name="sOrdDRate" placeholder="Rate"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Discount <span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDDis}
                                    name="sOrdDDis" placeholder="Discount"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>GST Amount <span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDGSTAmt}
                                    name="sOrdDGSTAmt" placeholder="GST Amount"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>GST %<span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDGST}
                                    name="sOrdDGST" placeholder="GST %"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputBox'>
                                <label>Amount<span>*</span></label>
                                <input style={{ width: "100%" }} value={salesOrderDet?.sOrdDAmt}
                                    name="sOrdDAmt" placeholder="Amount"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-10 text-right'></div>
                        <div className='col-sm-2 ' style={{ textAlign: 'right' }}>
                            <button className='dfBtn' onClick={() => handleAddOrRemove(-1)}>ADD</button>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className="col-sm-12">
                            <div className="tableBox" style={{ position: 'relative', maxHeight: ' 400px', overflow: 'auto' }}>
                                <table className="table">
                                    <thead style={{ position: 'sticky', top: 0 }}>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">SI.No</th>
                                            <th scope="col">Itemname</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Mrp</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Gst Amount</th>
                                            <th scope="col">Gst %</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesOrderMaster?.salesorderdet?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td
                                                        style={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#dc3545', color: 'white',
                                                            borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer'
                                                        }}
                                                        onClick={() => handlePlusOrMinus(each, 'minus', 1)}
                                                    >
                                                        -
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td>{each?.sOrdDItemName}</td>
                                                    <td>{each?.sOrdDQty}</td>
                                                    <td>{each?.sOrdDMRP}</td>
                                                    <td>{each?.sOrdDRate}</td>
                                                    <td>{each?.sOrdDDis}</td>
                                                    <td>{each?.sOrdDGSTAmt}</td>
                                                    <td>{each?.sOrdDGST}</td>
                                                    <td>{each?.sOrdDAmt}</td>
                                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddOrRemove(index)} /></button></td>
                                                    <td
                                                        onClick={() => handlePlusOrMinus(each, 'add', 1)}
                                                        style={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0ac074', color: 'white',
                                                            borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer'
                                                        }}
                                                    >
                                                        +
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr style={{ position: 'sticky', bottom: 0 }}>
                                            <td colSpan={8}></td>
                                            <td>Subtotal: </td>
                                            <td>{salesOrderMaster?.salesorderdet?.reduce((a, v) => a = a + Number(v?.sOrdDAmt ?? 0), 0)?.toFixed(2)}</td>
                                            <td colSpan={2}></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {errors?.salesorderdet && <p style={{ color: 'red' }}>{errors?.salesorderdet}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <div className='inputBox'>
                                <label>Ledger </label>
                                <Autocomplete size="small" disablePortal
                                    id="combo-box-demo" options={taxMaster}
                                    disabled={!taxMaster?.length}
                                    getOptionLabel={(option: any) => option.ledgerName}
                                    onChange={(event, val) => handleSelectLedger('saleOrdLedgerId', val, event)}
                                    renderInput={(params: any) =>
                                        <TextField  {...params} fullWidth
                                            id="outlined-size-small" size="small"
                                            placeholder='Select Ledger..' name='itemid'
                                        />
                                    }
                                />
                            </div>
                            {errors.saleOrdLedgerId && <p style={{ color: 'red' }}>{errors.saleOrdLedgerId}</p>}
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-2'>
                            <button className='dfBtn full-width' style={{ marginTop: '22px' }} onClick={() => setLedgerMaster(true)}>New</button>
                        </div>
                        <div className='col-sm-2'>
                            <div className='inputBox'>
                                <label>Amount </label>
                                <input type="number" className='' style={{ width: "100%" }}
                                    name='saleOrdLedgerAmount'
                                    value={salesOrderLedger?.saleOrdLedgerAmount}
                                    onChange={handleLedgerChange}
                                />
                                {errors.saleOrdLedgerAmount && <p style={{ color: 'red' }}>{errors.saleOrdLedgerAmount}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-1 text-right'>
                            <button className='dfBtn' onClick={handleAddLedger} >ADD</button>
                            <div className='vertical-space10'></div>
                        </div>
                        <div className='col-sm-3 text-right'>
                            <div className='inputBox'>
                                <h4>Net Amount : <span>&#8377;</span> {netAmount}</h4>
                            </div>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='tableBox'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Ledger</th>
                                        <th scope="col">Amount</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesOrderMaster?.salesorderledger?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{val?.saleOrdLedgername}</td>
                                                <td>{val?.saleOrdLedgerAmount}</td>
                                                <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt}
                                                    onClick={() => deleteLedger(val?.saleOrdLedgerId)} /></button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='btnBox'>
                        <button className='secondaryBtn' onClick={handleSuccessModalClose} >List</button>
                        <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
            <div className='vertical-space-20'></div>
            <Modal show={despatchModal} onHide={() => setDespatchModal(!despatchModal)} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                    <Modal.Title>Despatch Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='outletInputField inputFormBox '>
                        <div className='inputBoxLists'>
                            <div className='row'>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label>Select Customer<span>*</span></label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={customerList}
                                            disabled={!customerList?.length}
                                            getOptionLabel={(option: any) => option.membername}
                                            onChange={(event, val) => (salesOrderMaster?.salesorderdet?.length! > 0) ? handleChangeCustomer(val) : handleAutoComplete(val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params} fullWidth
                                                    id="outlined-size-small"
                                                    size="small"
                                                    placeholder='Select Customer..'
                                                    name='itemid'
                                                />
                                            }
                                        />
                                    </div>
                                    {errors?.sOrdMCustID && <p style={{ color: 'red' }}>{errors?.sOrdMCustID}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <button className='newdfBtn full-width' onClick={() => setNewMemberModal(true)}>New Member</button>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label>Customer Name <span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            placeholder="Customer Name"
                                            value={salesOrderMaster?.sOrdMCustName}
                                            name="sOrdMCustName">
                                        </input>
                                    </div>
                                    {errors?.sOrdMCustName && <p style={{ color: 'red' }}>{errors?.sOrdMCustName}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label> Contact No<span>*</span></label>
                                        <input type="number" style={{ width: "100%" }}
                                            max={10} placeholder="Contact No"
                                            value={salesOrderMaster?.sOrdMConactNo}
                                            name="sOrdMConactNo">
                                        </input>
                                    </div>
                                    {errors?.sOrdMConactNo && <p style={{ color: 'red' }}>{errors?.sOrdMConactNo}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label>Despatch Door No<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            value={salesOrderMaster?.sOrdMDoorNo}
                                            name="sOrdMDoorNo"
                                            placeholder="Despatch Door No" ></input>
                                    </div>
                                    {errors?.sOrdMDoorNo && <p style={{ color: 'red' }}>{errors?.sOrdMDoorNo}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label> Area<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            placeholder="Area" value={salesOrderMaster?.sOrdMArea}
                                            name="sOrdMArea"></input>
                                    </div>
                                    {errors?.sOrdMArea && <p style={{ color: 'red' }}>{errors?.sOrdMArea}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label> City<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            placeholder="City"
                                            value={salesOrderMaster?.sOrdMCity}
                                            name="sOrdMCity">
                                        </input>
                                    </div>
                                    {errors?.sOrdMCity && <p style={{ color: 'red' }}>{errors?.sOrdMCity}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label>State<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            placeholder="State"
                                            value={salesOrderMaster?.sOrdMState}
                                            name="sOrdMState">
                                        </input>
                                    </div>
                                    {errors?.sOrdMState && <p style={{ color: 'red' }}>{errors?.sOrdMState}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='inputBox'>
                                        <label>Pincode<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            placeholder="Pincode"
                                            value={salesOrderMaster?.sOrdMPincode}
                                            name="sOrdMPincode">
                                        </input>
                                    </div>
                                    {errors?.sOrdMPincode && <p style={{ color: 'red' }}>{errors?.sOrdMPincode}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/checked.png')} />
                        </div>
                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/warning.png')} />
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isledgerMaster} onHide={() => setLedgerMaster(false)} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                    <h4>Ledger Creation</h4>
                </Modal.Header>
                <Modal.Body>
                    <LedgerMaster fromPaymentVoucher paymentVouCallBack={handleLedgerClose} />
                </Modal.Body>
            </Modal>
            <Modal show={newMemberModal} onHide={() => setNewMemberModal(false)} className='PriceHistoryModel'
                style={{ marginTop: "0px", height: '90vh', overflow: 'auto' }}>
                <Modal.Header closeButton>
                    <Modal.Title>New Member Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewMember fromPOS={true} emitMember={(memberDetail) => { if (memberDetail) { fetchMembers() } }} />
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default SalesOrder
