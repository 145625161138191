import React, { useState, useEffect, useRef } from "react"
import useStores from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import AutoComplete from "../../common/shared/autoComplete";
import { IPurchaseReturnSave, IPurchseReturnDetails } from "./model";
import { SubmitValidation } from "./validation";
import { removeDuplicates } from "../../common/shared/utils";

const PurchaseReturn = () => {

    const { commonStore, purchaseBillStore } = useStores();
    const navigate = useNavigate();

    const { loadEmployee, loadEmployees } = commonStore;

    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);

    const [localFields, setLocalFields] = useState<IPurchaseReturnSave>(new IPurchaseReturnSave())
    const [purchaseReturnList, setPurchaseReturnList] = useState<any[]>([])

    // console.log(, 'purchaseReturnList>>')
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setError] = useState<any>({})

    function handleInputchange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    function handleAutoComplete(name, value) {
        if (name === "createdby" && value != null) {
            setLocalFields({ ...localFields, createdby: value?.employeeId })
        } else if (name === 'supplierid' && value != null) {
            setSpinner(true)
            setLocalFields({ ...localFields, supplierid: value?.Supplierid })
            setSpinner(false)
        }
    }

    async function hanldeGenerateDebiNo() {
        setSpinner(true)
        const entryNo = await purchaseBillStore.getPurchaseBillEntryNo('DebitNote');
        setLocalFields({ ...localFields, dnoteno: entryNo?.entryNo + 1, dtype: "Debit" })
        setSpinner(false)
    }

    async function handleSave(e) {
        e.preventDefault();
        let error: any = {};
        error = SubmitValidation(localFields);
        setError(error)

        let purchaseReturnDet: IPurchseReturnDetails[] = purchaseReturnList?.filter((items) => items?.Supplierid === localFields?.supplierid && items?.Qty > 0)?.map((obj) => {
            let purchaseDetail: IPurchseReturnDetails = {
                itemid: obj?.itemid?.toString(),
                barcode: obj?.Barcode,
                batchno: obj?.batchno,
                qty: obj?.Qty,
                amount: obj?.Qty * obj?.rate,
                rate: obj?.rate,
                entryno: obj?.EntryNo
            }
            return purchaseDetail
        })

        localFields.purchaseReturndetails = purchaseReturnDet;

        if (Object.keys(error).length === 0) {
            setLoading(true);
            const status = await purchaseBillStore.savePurchaseReturn(localFields);
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }

    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/PurchaseReturn/PurchaseReturnDetail');
    }

    // const removeDuplicates = (array: any[], id): any[] => {
    //     const uniqueItems: Record<number, any> = {};
    //     array.forEach(supplier => {
    //         uniqueItems['id'] = supplier;
    //     });
    //     return Object.values(uniqueItems);
    // };

    async function fetchPreLoadingData() {
        setLoading(false)
        const [entryNo, purchaseBillReturnJSON] = await Promise.all([
            purchaseBillStore.getPurchaseBillEntryNo('PurchaseReturn'), purchaseBillStore.getPurchseBillReturnJSON(),
            loadEmployee()
        ]);
        setLocalFields({ ...localFields, rtnid: (entryNo?.entryNo + 1)?.toString() })
        setPurchaseReturnList([...purchaseBillReturnJSON])
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadingData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <>
            {isLoading &&
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>}
            <div className='container'>
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Purchase Return</h3>
                    </div>
                    <div className='inputBoxLists'>
                        <div className='row'>
                            {isSpinner &&
                                <div className='SpinnerBox'>
                                    <Spinner animation="grow" size="sm" />
                                </div>}
                            <div className='col-md-3'>
                                <div className='inputBox'>
                                    <label>DC No <span>*</span></label>
                                    <input type="text"
                                        name='assignNo' disabled
                                        value={localFields?.rtnid}
                                        className='' style={{ width: "100%" }}
                                        placeholder='DC No..'></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputBox'>
                                    <label>Entry Date <span>*</span></label>
                                    <input type="date"
                                        name='rtndate'
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                        value={localFields.rtndate = localFields?.rtndate ?? new Date().toISOString().substr(0, 10)}
                                        onChange={handleInputchange}
                                        style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                    {errors.rtndate && <p style={{ color: 'red' }}>{errors.rtndate}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='col-sm-3' >
                                <div className='inputBox'>
                                    <label>Supplier <span>*</span></label>
                                    <AutoComplete options={removeDuplicates(purchaseReturnList, 'Supplierid')} getOptionLabel="SupplierName"
                                        emitOption={(option) => handleAutoComplete('supplierid', option)} placeholder="Select Supplier..." />
                                    {errors.supplierid && <p style={{ color: 'red' }}>{errors.supplierid}</p>}
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Itemname</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">UOM</th>
                                                <th scope="col">Barcode</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">P.Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {purchaseReturnList?.length > 0 &&
                                                purchaseReturnList?.filter((items) => items?.Supplierid === localFields?.supplierid)?.map((val, key) => {
                                                    return val?.Qty > 0 && (
                                                        <tr key={key}>
                                                            <td>{val?.itemname}</td>
                                                            <td>{val?.Qty}</td>
                                                            <td>{val?.Unitname}</td>
                                                            <td>{val?.Barcode}</td>
                                                            <td>{val?.batchno}</td>
                                                            <td>{val?.rate}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {errors.purchaseReturnDetails && <p style={{ color: 'red' }}>{errors.purchaseReturnDetails}</p>}
                            <div className='vertical-space-10'></div>
                            <div className='col-sm-3' >
                                <div className='inputBox'>
                                    <label>Total Return Value</label>
                                    <input type="text" style={{ width: "100%" }} disabled
                                        name='subtotal'
                                        value={localFields.rtnAmount = Number(purchaseReturnList?.filter((items) => items?.Supplierid === localFields?.supplierid && items?.Qty > 0)?.reduce((a, v) => a = a + Number(v?.Qty * v?.rate!), 0).toFixed(2))}></input>
                                </div>
                            </div>
                            <div className="col-sm-2" style={{ marginTop: '24px' }}>
                                <button className='secondaryBtn' type='submit'
                                    onClick={hanldeGenerateDebiNo}
                                >Generate</button>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className='col-sm-3' >
                                <div className='inputBox'>
                                    <label>Debit Note</label>
                                    <input type="text" style={{ width: "100%" }} disabled
                                        name='subtotal' value={localFields?.dnoteno}
                                    ></input>
                                    {errors.dnoteno && <p style={{ color: 'red' }}>{errors.dnoteno}</p>}
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputBox'>
                                    <label>Entry Date <span>*</span></label>
                                    <input type="date"
                                        name='rtndate'
                                        defaultValue={new Date().toISOString().slice(0, 16)}
                                        value={localFields.rtndate = localFields?.rtndate ?? new Date().toISOString().slice(0, 16)}
                                        onChange={handleInputchange}
                                        style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                </div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='col-sm-6'>
                                <div className='inputBox'>
                                    <label>Created by </label>
                                    <AutoComplete
                                        placeholder='Select Created by..'
                                        options={removeDuplicates(loadEmployees, 'employeeName')}
                                        emitOption={(val) => handleAutoComplete('createdby', val)}
                                        getOptionLabel='employeeName'
                                    />
                                </div>
                                {errors.createdby && <p style={{ color: 'red' }}>{errors.createdby}</p>}
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-3' >
                                <div className='inputBox'>
                                    <label>Debit Amount</label>
                                    <input type="text" style={{ width: "100%" }} disabled
                                        name='subtotal'
                                        value={localFields.rtnAmount = Number(purchaseReturnList?.filter((items) => items?.Supplierid === localFields?.supplierid && items?.Qty > 0)?.reduce((a, v) => a = a + Number(v?.Qty * v?.rate!), 0).toFixed(2))}></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='btnBox'>
                        <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                        <button className='dfBtn' type='submit' onClick={handleSave}>Submit</button>
                    </div>

                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../../image/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default PurchaseReturn;