import React, { useState, useEffect, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IShiftMaster, ShiftMasterDetails } from './model';
import shifMasterValidation from './validation';
import moment from 'moment';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

const ShiftMaster = observer((): JSX.Element => {
  const { shiftMasterStore, memberStore } = useStores();
  const [shiftMaster, setShiftMaster] = useState<IShiftMaster>({});
  const [shiftMasterDetail, setShiftMasterDetail] = useState<ShiftMasterDetails>({});
  const [shiftMasterDetails, setShiftMasterDetails] = useState<ShiftMasterDetails[]>([]);
  const { getShiftMaster, getShiftMasterDetails } = shiftMasterStore;
  const [isLoading, setLoading] = useState(true);
  const { shiftId } = useParams<{ shiftId: string }>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [breakendingTime, setBreakendingTime] = useState('');
  const [breakstartingTime, setBreakstartingTime] = useState('');
  const [breakTime, setBreakTime] = useState('');


  const handleCancel = () => {
    navigate('/Admin/ShiftMaster/ShiftMasterDetail');
  }

  const formatTime = (dateString) => {
    console.log(dateString,'dateString');
    
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleShiftMaster = (event: any) => {
    const { name, value } = event.target;
    setBreakendingTime(value.breakendingTime)
    setBreakstartingTime(value.breakstartingTime)
    setBreakTime(value.breakTime)
    setShiftMaster({ ...shiftMaster, [name]: value });
  }

  const addShiftMaster = async () => {
    debugger
    let newObject: ShiftMasterDetails = {};

    let error: any = {};
    error = shifMasterValidation(shiftMaster);
    setErrors(error);
    setBreakendingTime('')
    setBreakstartingTime('')
    setBreakTime('')

   // if (Object.keys(error).length === 0) {
      shiftMasterDetail.shiftBreakTime = shiftMaster.breakTime
      shiftMasterDetail.shiftBreakStartingTime = shiftMaster.breakstartingTime
      shiftMasterDetail.shiftBreakEndingTime = shiftMaster.breakendingTime
      setSubmitbtn(true);
      setShiftMasterDetails([...shiftMasterDetails, shiftMasterDetail]);
      setShiftMasterDetail(newObject);
   // }
  }

  async function getShiftMasterDet() {
    if (shiftId !== undefined) {
      const data = await getShiftMaster();
      const dataDet = await getShiftMasterDetails(shiftId);
      const matchedUser = data.find(shift => shift.shiftId == shiftId);
      if (matchedUser) {
        const updatedDetails = {
          ...shiftMasterDetails,
          shiftId: dataDet[0]?.shiftId || 0,
          shiftBreakTime: dataDet[0]?.breakTime || '',
          shiftBreakStartingTime: dataDet[0]?.startingTime || '',
          shiftBreakEndingTime: dataDet[0]?.endingTime || ''
        };
        const updatedMaster = {
          ...shiftMaster,
          shiftId: matchedUser.shiftId,
          shiftName: matchedUser.shiftName,
          startingTime: formatTime(matchedUser.startingTime),
          endingTime: formatTime(matchedUser.endingTime),
          breakstartingTime: matchedUser.breakstartingTime,
          breakendingTime: matchedUser.breakendingTime,
          shiftMasterDetails: [updatedDetails]
        };
        console.log(updatedMaster,'updatedMaster');
        
        setShiftMaster(updatedMaster);
        setShiftMasterDetails(prevDetails => [...prevDetails, updatedDetails]);
        console.log(dataDet, 'dataDet');
        console.log(shiftMaster, 'shiftMaster');
      }
    }
  }

  useEffect(() => {
    console.log(shiftMaster, 'shiftMaster');
  }, [shiftMaster]);

  async function saveShiftMaster(e) {
    let status;
    console.log(shiftMaster, 'shiftMaster');
    shiftMaster.shiftMasterDetails = shiftMasterDetails;
    if (shiftId !== undefined) {
      status = await shiftMasterStore.updateShiftMaster(shiftMaster);
    }
    else {
      e.preventDefault();
      setLoading(true);
      const entryNos = await memberStore.getEntryNo('ShiftMaster');
      shiftMaster.shiftId = entryNos.entryNo + 1;
      status = await shiftMasterStore.saveShiftMaster(shiftMaster);
    }
    if (status === "Success") {
      setLoading(false)
      setSuccessModal(true)
    }
    else {
      setLoading(false)
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/ShiftMaster/ShiftMasterDetail');
  }

  function handleRemove(index) {
    shiftMasterDetails?.splice(index, 1)
    // setShiftMasterDetails(_.cloneDeep(shiftMasterDetails))
    setShiftMasterDetails(shiftMasterDetails)
  }

  async function fetchPreLoadngData() {
      setLoading(false);
      await memberStore.getEntryNo('ShiftMaster');
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoadngData();
      getShiftMasterDet();
      isCurrenPage.current = false;
    }
    return () => { }
  }, []);


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Shift Master</h3>
              </div>
              <div className='inputBoxLists'>
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Shift ID <span>*</span></label>
                        <input type="text" disabled
                          value={memberStore.entryNo.entryNo + 1}
                          className='' style={{ width: "100%" }} placeholder='Shift ID..'></input>

                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Shift Name <span>*</span></label>
                        <input type="text"
                          name='shiftName'

                          value={shiftMaster.shiftName}
                          onChange={handleShiftMaster}
                          className='' style={{ width: "100%" }} placeholder='Shift Name..'></input>
                        {errors.shiftName && <p style={{ color: 'red' }}>{errors.shiftName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Starting Time <span>*</span></label>
                        <input type="time"
                          name='startingTime'
                          value={shiftMaster.startingTime}
                          // value={shiftMaster.startingTime}  shiftValue
                          onChange={(e) => setShiftMaster({ ...shiftMaster, startingTime: e.target.value })}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" ></input>
                        {errors.startingTime && <p style={{ color: 'red' }}>{errors.startingTime}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Ending Time <span>*</span></label>
                        <input type="time"
                          name='endingTime'
                          value={shiftMaster.endingTime}
                          // value={shiftMaster.endingTime}
                          onChange={handleShiftMaster}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.endingTime && <p style={{ color: 'red' }}>{errors.endingTime}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Break Name <span>*</span></label>
                        <input type="text" className=''
                          name='breakTime'
                          defaultValue={shiftMaster.breakTime}
                          value={breakTime}
                          onChange={handleShiftMaster}
                          style={{ width: "100%" }} placeholder='Break Name..'></input>
                        {errors.breakTime && <p style={{ color: 'red' }}>{errors.breakTime}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-5'>
                      <div className='inputBox'>
                        <label>Starting Time <span>*</span></label>
                        <input type="time"
                          name='breakstartingTime'
                          defaultValue={shiftMaster.breakstartingTime}
                          value={breakstartingTime}
                          onChange={handleShiftMaster}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.breakstartingTime && <p style={{ color: 'red' }}>{errors.breakstartingTime}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-5'>
                      <div className='inputBox'>
                        <label>Ending Time <span>*</span></label>
                        <input type="time"
                          name='breakendingTime' defaultValue={shiftMaster.breakendingTime}
                          value={breakendingTime}
                          onChange={handleShiftMaster}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.breakendingTime && <p style={{ color: 'red' }}>{errors.breakendingTime}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-2'>
                      <div className='btnBox justify-flex-start pd-t-0'>

                        <button className='dfBtn' type='submit' onClick={addShiftMaster}>ADD</button>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Break Name</th>
                              <th scope="col">Starting Time</th>
                              <th scope="col">Ending Time</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              shiftMasterDetails.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.shiftBreakTime}</td>
                                    <td>{moment(val.shiftBreakStartingTime, ["HH:mm"]).format('hh:mm a')}</td>
                                    <td>{moment(val.shiftBreakEndingTime, ["HH:mm"]).format('hh:mm a')}</td>
                                    <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' type='submit' onClick={saveShiftMaster}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
      }
    </>
  );

});

export default ShiftMaster;
