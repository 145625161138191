import { useEffect, useRef, useState, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import useStores from '../../hooks';
import { observer } from 'mobx-react';
import { IPaymentCredit, IPaymentDebit, IPaymentVoucherSave, IPurchasebill } from './model';
import AutoComplete from '../../common/shared/autoComplete';
import PaymentVoucherSaveValidation, { CreditObjValidation, DebitObjValidation } from './validation';
import { areObjectsEqual } from '../../common/shared/utils';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import LedgerMaster from '../LedgerMaster/LedgerMaster';

const PaymentVoucher = observer((): JSX.Element => {

  const { paymentVocherStore, ledgerGroupStore } = useStores()
  const navigate = useNavigate();

  const { vchr_no } = useParams()

  const [byPaymentLedger, setByPayment] = useState<any[]>([])
  const [toPaymentLedger, setToPayment] = useState<any[]>([])
  const [paymentVoucherSave, setPaymentVoucherSave] = useState<IPaymentVoucherSave>(new IPaymentVoucherSave())
  const [debitObj, setDebtObj] = useState<IPaymentDebit>(new IPaymentDebit())
  const [creditObj, setCreditObj] = useState<IPaymentCredit>(new IPaymentCredit())
  const [forBillList, setForBillList] = useState<IPurchasebill[]>(new Array<IPurchasebill>())
  const [errors, setErrors] = useState<any>({})

  const [loader, setLoader] = useState(true);
  // const [option, setOption] = useState<any>({});
  const [billDet, setBillDet] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [ledgerMaster, setLedgerMaster] = useState(false)

  function hanldeInputChange(e) {
    const { name, value } = e.target;
    setPaymentVoucherSave({ ...paymentVoucherSave, [name]: value })
  }

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setDebtObj({ ...debitObj, dbtype: value })
    setCreditObj({ ...creditObj, cbtype: value })
  }

  function handleCreditObj(e) {
    const { name, value } = e.target;
    setCreditObj({ ...creditObj, [name]: name == 'creditAmount' ? Number(value) : value })
  }

  function hanldeDebitObj(e) {
    const { name, value } = e.target;
    setDebtObj({ ...debitObj, [name]: name === "debitAmount" ? Number(value) : value })
  }

  function handleRemove(obj, type) {
    if (type == 'debit') {
      const equObjInx = paymentVoucherSave?.paymentDebit?.filter((obj1) => !areObjectsEqual(obj1, obj))
      setPaymentVoucherSave({ ...paymentVoucherSave, paymentDebit: equObjInx })
    } else if (type == 'credit') {
      const equObjInx = paymentVoucherSave?.paymentCredit?.filter((obj1) => !areObjectsEqual(obj1, obj))
      setPaymentVoucherSave({ ...paymentVoucherSave, paymentCredit: equObjInx })
    }
  }

  async function updateObj(options, type) {
    if (type == 'debit') {
      setDebtObj({
        ...debitObj, debitAccountId: options?.ledgerAccountId,
        debitAccountName: options?.ledgerAccountName,
      })
      const forBillList = await paymentVocherStore?.loadPurchaseBill(options?.ledgerAccountId);
      if (forBillList?.length) {
        setForBillList(forBillList)
      }
    } else if (type == 'credit') {
      setCreditObj({
        ...creditObj, creditAccountId: options?.ledgerAccountId,
        creditAccountName: options?.ledgerAccountName,
      })
    }
  }

  function pushObjtoArr(type) {
    if (type == 'debit') {
      let error: any = {};
      error = DebitObjValidation(debitObj!)
      setErrors(error)
      if (Object.keys(error).length == 0) {
        let objArr = Object.assign([], paymentVoucherSave?.paymentDebit)
        objArr?.push(debitObj!)
        if (!objArr?.length) {
          objArr = []
        }
        if (!paymentVoucherSave?.paymentDebit?.length) {
          paymentVoucherSave!.paymentDebit = []
        }
        paymentVoucherSave!.paymentDebit = objArr
        setPaymentVoucherSave(paymentVoucherSave)
        setDebtObj({
          ...debitObj, debitAmount: 0,
          debitNarration: '',
          debitAccountId: 0,
          debitAccountName: '',
          debitDate: '',
          chequeNo: '',
          dbtype: ''
        })
        setErrors({})
      }
    } else {
      let error: any = {};
      error = CreditObjValidation(creditObj!)
      setErrors(error)
      if (Object.keys(error).length == 0) {
        let objArr = Object.assign([], paymentVoucherSave?.paymentCredit);
        creditObj.creditBankName = "";
        objArr?.push(creditObj!)
        if (!paymentVoucherSave?.paymentCredit) {
          paymentVoucherSave!.paymentCredit = []
        }
        paymentVoucherSave!.paymentCredit! = objArr;
        setPaymentVoucherSave(paymentVoucherSave)
        setCreditObj({
          ...creditObj, creditAccountName: '',
          creditAmount: 0,
          chequeNo: '',
          creditDate: '',
          creditBankName: '',
          creditNarration: '',
          creditAccountId: 0,
          cbtype: ''
        })
        setErrors({})
      }

    }
  }

  function handleCalDrAmt(prchaseBill: IPurchasebill) {
    const index = paymentVoucherSave?.purchasebill?.findIndex((purchBill) => purchBill?.record_no === prchaseBill?.record_no)
    if (!debitObj.debitAmount) {
      debitObj.debitAmount = 0;
    }
    if (!paymentVoucherSave?.purchasebill) {
      paymentVoucherSave!.purchasebill! = new Array<IPurchasebill>()
    }
    if (index! >= 0) {
      paymentVoucherSave?.purchasebill?.splice(index!, 1)
      debitObj.debitAmount = Math.abs(debitObj?.debitAmount! - prchaseBill?.netAmount!)
    } else {
      paymentVoucherSave?.purchasebill?.push(prchaseBill)
      debitObj.debitAmount = debitObj?.debitAmount! + prchaseBill?.netAmount!
    }
    setDebtObj(debitObj)
    setPaymentVoucherSave(paymentVoucherSave)
  }

  async function handleSubmit() {
    let error: any = {};
    error = PaymentVoucherSaveValidation(paymentVoucherSave)
    setErrors(error)
    if (Object.keys(error).length == 0) {
      let prchaseBill: IPurchasebill = {}
      if (!paymentVoucherSave?.purchasebill) {
        paymentVoucherSave.purchasebill = [];
        prchaseBill = {
          billtype: '',
          record_no: 0,
          billno: '',
          billdate: '',
          netAmount: 0
        }
        paymentVoucherSave?.purchasebill?.push(prchaseBill)
      }
      paymentVoucherSave.employeeId = Number(Cookies.get('userId'));
      paymentVoucherSave.paymentId = ledgerGroupStore?.entryNo?.entryNo + 1;
      const status = await paymentVocherStore.savePaymentVoucher(paymentVoucherSave);
      if (status == "Success") {
        setSuccessModal(true)
        setPaymentVoucherSave(new IPaymentVoucherSave())
      } else {
        setFailureModal(true)
      }

    }
  }

  const handleCancel = () => {
    navigate('/Admin/PaymentVoucher/PaymentVoucherDetail')
  }

  function handleNewAccount() {
    setLedgerMaster(true)
  }

  const isInitialRender = useRef(true);

  async function fetLoadLedgers() {
    setLoader(true)
    let byPaym = {
      "flag": "BY",
      "type": "PAYMENT"
    }
    const byPayment = await paymentVocherStore?.loadLedgers(byPaym)
    setByPayment(byPayment)
    let toPaym = {
      "flag": "TO",
      "type": "PAYMENT"
    }
    const toPayment = await paymentVocherStore?.loadLedgers(toPaym)
    await ledgerGroupStore.getEntryNo('payment');
    setToPayment(toPayment)
    setLoader(false)
  }

  async function fetchToAccount() {
    let toPaym = {
      "flag": "TO",
      "type": "PAYMENT"
    }
    const toPayment = await paymentVocherStore?.loadLedgers(toPaym)
    setToPayment(toPayment)
  }

  useEffect(() => {
    if (isInitialRender.current) {
      fetLoadLedgers()
      isInitialRender.current = false;
    }
    return () => {
      // Cleanup logic (if needed)
    };
  }, [])

  return (
    <>
      {loader ? <div className='SpinnerBox'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> : <div className='container'>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Payment Voucher</h3>
          </div>
          <div className='inputBoxLists pd-b-0'>
            <div className='XlInputListsBox mr-left-0'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Voucher No </label>
                    <input value={ledgerGroupStore?.entryNo?.entryNo + 1} type="text" className='' style={{ width: "100%" }} placeholder='Voucher No..' disabled></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label> Date </label>
                    <input type="date" name='paymentDate' onChange={hanldeInputChange} value={paymentVoucherSave.paymentDate = paymentVoucherSave?.paymentDate ?? new Date().toISOString().substr(0, 10)} style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date=""
                      data-date-format="DD MMMM YYYY"></input>
                  </div>
                  {errors.paymentDate && <p style={{ color: 'red' }}>{errors.paymentDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Ref. No </label>
                    <input name='referenceNo' onChange={hanldeInputChange} value={paymentVoucherSave?.referenceNo} type="text" className='' style={{ width: "100%" }} placeholder='Ref. No..'></input>
                  </div>
                  {errors.referenceNo && <p style={{ color: 'red' }}>{errors.referenceNo}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-10'>
                  <div className='inputBox'>
                    <label>By Account <span>*</span></label>
                    <AutoComplete value={debitObj?.debitAccountName} options={byPaymentLedger} placeholder={'By Account...'}
                      emitOption={(option: any) => updateObj(option, 'debit')} getOptionLabel='ledgerAccountName' />
                  </div>
                  {errors.debitAccountName && <p style={{ color: 'red' }}>{errors.debitAccountName}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputradio'>
                    <input type='radio' className='' value='onaccount' name='option' onChange={handleRadioChange}></input>&nbsp;&nbsp;
                    <label>On Account </label>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputradio'>
                    <input type='radio' className='' value='forbill' name='option' onChange={handleRadioChange}></input>&nbsp;&nbsp;
                    <label>For Bill </label>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputradio'>
                    <input type='radio' className='' value='advance' name='option' onChange={handleRadioChange}></input>&nbsp;&nbsp;
                    <label>Advance </label>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                {debitObj?.dbtype == "forbill" && <div className='col-md-7'>
                  <div className='btnBox'>
                    <button className='secondaryBtn' onClick={() => setBillDet(true)}>Bill Det</button>
                    {/* <button className='secondaryBtn'>TDS Det</button> */}
                  </div>
                </div>}
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Dr. Amount  &#8377; <span>*</span></label>
                    <input value={debitObj?.debitAmount} name='debitAmount' onChange={hanldeDebitObj} disabled={debitObj?.dbtype === "forbill"} type="text" className='' style={{ width: "100%" }} placeholder='Dr. Amount..'></input>
                  </div>
                  {errors.debitAmount && <p style={{ color: 'red' }}>{errors.debitAmount}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  {/* <div className='inputradio'>
                  <input  type='checkbox' className='' value='tds' name='option' onChange={handleRadioChange}></input>&nbsp;&nbsp;
                  <label>TDS </label>
                </div> */}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Cheque No <span>*</span></label>
                    <input value={debitObj?.chequeNo} name='chequeNo' onChange={hanldeDebitObj} type="text" className='' style={{ width: "100%" }} placeholder='Cheque No..'></input>
                  </div>
                  {errors.chequeNo && <p style={{ color: 'red' }}>{errors.chequeNo}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label> Date <span>*</span></label>
                    <input value={debitObj.debitDate = debitObj?.debitDate ?? new Date().toISOString().substr(0, 10)} name='debitDate' onChange={hanldeDebitObj} type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                  </div>
                  {errors.debitDate && <p style={{ color: 'red' }}>{errors.debitDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-8'>
                  <div className='inputBox'>
                    <label>Narration<span>*</span></label>
                    <textarea value={debitObj?.debitNarration} name='debitNarration' onChange={hanldeDebitObj} className='' style={{ width: "100%", height: '50%' }} placeholder='Narration..'></textarea>
                  </div>
                  {errors.debitNarration && <p style={{ color: 'red' }}>{errors.debitNarration}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <button className='dfBtn' onClick={() => pushObjtoArr('debit')}>Add</button>
                </div>
                <div className='vertical-space-10'></div>
                <div className='col-md-12'>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">By Account</th>
                          <th scope="col">Dr Amount</th>
                          <th scope="col">Cheque No  </th>
                          <th scope="col">Date</th>
                          <th scope="col">Narration</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentVoucherSave?.paymentDebit?.map((debit, index) => {
                          return (
                            <tr key={index}>
                              <td>{debit?.debitAccountName}</td>
                              <td>{debit?.debitAmount}</td>
                              <td>{debit?.chequeNo}</td>
                              <td>{debit?.debitDate}</td>
                              <td>{debit?.debitNarration}</td>
                              <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(debit, 'debit')}>Remove</button></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                {errors.paymentDebit && <p style={{ color: 'red' }}>{errors.paymentDebit}</p>}
                <div className='col-md-10'>
                  <div className='inputBox'>
                    <label>To Account  <span>*</span></label>
                    <AutoComplete value={creditObj?.creditAccountName} options={toPaymentLedger} placeholder={'To Account...'}
                      emitOption={(option: any) => updateObj(option, 'credit')} getOptionLabel='ledgerAccountName' />
                  </div>
                  {errors.creditAccountName && <p style={{ color: 'red' }}>{errors.creditAccountName}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-2'>
                  <div className='btnBox'>
                    <button className='secondaryBtn' style={{ marginTop: '30px' }} onClick={handleNewAccount}>New</button>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Cr Amount &#8377; <span>*</span></label>
                    <input type="text" value={creditObj?.creditAmount} name='creditAmount' onChange={handleCreditObj} className='' style={{ width: "100%" }} placeholder='Cr Amount..'></input>
                  </div>
                  {errors.creditAmount && <p style={{ color: 'red' }}>{errors.creditAmount}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className="col-md-6"></div>
                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Cheque No <span>*</span></label>
                    <input value={creditObj?.chequeNo} name='chequeNo' onChange={handleCreditObj} type="text" className='' style={{ width: "100%" }} placeholder='Cheque No..'></input>
                  </div>
                  {errors.creditChequeNo && <p style={{ color: 'red' }}>{errors.creditChequeNo}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label> Date <span>*</span></label>
                    <input value={creditObj.creditDate = creditObj?.creditDate ?? new Date().toISOString().substr(0, 10)} name='creditDate' onChange={handleCreditObj} type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                  </div>
                  {errors.creditDate && <p style={{ color: 'red' }}>{errors.creditDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-8'>
                  <div className='inputBox'>
                    <label>Narration <span>*</span></label>
                    <textarea value={creditObj?.creditNarration} name='creditNarration' onChange={handleCreditObj} className='' style={{ width: "100%", height: '50%' }} placeholder='Narration..'></textarea>
                  </div>
                  {errors.creditNarration && <p style={{ color: 'red' }}>{errors.creditNarration}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <button className='dfBtn' onClick={() => pushObjtoArr('credit')} >Add</button>
                </div>
                <div className='vertical-space-10'></div>
                <div className='col-sm-12'>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">To Account</th>
                          <th scope="col">Cr Amount</th>
                          <th scope="col">Cheque No  </th>
                          <th scope="col">Date</th>
                          <th scope="col">Narration</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentVoucherSave?.paymentCredit?.map((credit, index) => {
                          return (
                            <tr key={index}>
                              <td>{credit?.creditAccountName}</td>
                              <td>{credit?.creditAmount}</td>
                              <td>{credit?.chequeNo}</td>
                              <td>{credit?.creditDate}</td>
                              <td>{credit?.creditNarration}</td>
                              <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(credit, 'credit')}>Remove</button></td>

                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                {errors.paymentCredit && <p style={{ color: 'red' }}>{errors.paymentCredit}</p>}
              </div>
              {errors.totalSum && <p style={{ color: 'red' }}>{errors.totalSum}</p>}
            </div>
          </div>

          <Modal show={billDet} onHide={() => setBillDet(false)} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Bill Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Bill Type</th>
                      <th scope="col">Bill No</th>
                      <th scope="col">Bill Date</th>
                      <th scope="col">Net Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {forBillList?.map((purchaseBill, index) => {
                      return (
                        <tr key={index}>
                          <td><input type='checkbox' className='' value='tds' name='option' onChange={() => handleCalDrAmt(purchaseBill)}></input></td>
                          <td>{purchaseBill?.billtype}</td>
                          <td>{purchaseBill?.billno}</td>
                          <td>{moment(purchaseBill?.billdate).format('DD-MMM-YYYY')}</td>
                          <td>{purchaseBill?.netAmount}</td>

                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={ledgerMaster} onHide={() => setLedgerMaster(false)} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Ledger Master</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LedgerMaster fromPaymentVoucher={true} paymentVouCallBack={fetchToAccount} />
            </Modal.Body>
          </Modal>

          <Modal show={isSuccessModal} onHide={handleCancel} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancel}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setFailureModal(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>}
    </>
  );

});

export default PaymentVoucher;
