import React, { useState, useEffect, useRef, } from 'react';
import { Country, State, City } from 'country-state-city';
import { Modal, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';

import { TextField, Autocomplete } from '@mui/material';
import { IMember } from './model';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import memberValidator from './validation';
import moment from 'moment';
import ProgressBar from '../../common/shared/progressbar';
import { getCurrentMonthFirstDate, removeDuplicates } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';

interface POSProps {
  fromPOS?: boolean;
  emitMember?: (memberDeail: IMember) => void;
}

const NewMember: React.FC<POSProps> = observer(({ fromPOS, emitMember }): JSX.Element => {

  const navigate = useNavigate();

  const { memberStore, commonStore } = useStores();

  const [errors, setErrors] = useState<any>({});

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [memberDetail, setMemberDetail] = useState<IMember>({})
  const [memberPlanList, setMemberPlanList] = useState<any[]>([])

  const handleCancel = () => {
    navigate('/Admin/Member/list');
  }

  const handleSuccessModalClose = () => {
    debugger
    if (!fromPOS) {
      handleCancel()
    } else {
      emitMember!(memberDetail)
      setSuccessModal(false)
    }
  }

  const getStateCountry = (e: any, value: any) => {
    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      memberDetail.country = countries[0].name;
      setMemberDetail({ ...memberDetail, ['country']: countries[0].name });
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      memberDetail.state = states[0].name;
      setMemberDetail({ ...memberDetail, ['state']: states[0].name });
      setMemberDetail({ ...memberDetail, ['city']: value.name });
    }
    else {
      memberDetail.country = '';
      memberDetail.state = '';
    }
  }

  const handleInputChange = async (event: any) => {
    const { name, value } = event.target;
    if (name === 'dob') {
      memberDetail.age = getAge(value);
    }
    if (name === 'mobileNo' && value.length === 10) {
      const isExist = await commonStore.IsExistMobileNo(value);
      if (isExist) { errors.mobileNo = 'This mobile number is already registered' } else { errors.mobileNo = '' }
    }
    setMemberDetail({ ...memberDetail, [name]: value })
  }

  function handleCutomerType(value) {
    setMemberDetail({ ...memberDetail, customerCategory: value?.Membershipid?.toString(), customertype: value?.PlanName })
  }

  const saveMember = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = memberValidator(memberDetail);
    const isExist = await commonStore.IsExistMobileNo(memberDetail?.mobileNo);
    if (isExist) {
      error.mobileNoexists = 'This mobile number is already registered'
    }
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setLoading(true);
      memberDetail.registrationType = "Admin";
      const status = await memberStore.saveMember(memberDetail);
      if (status?.message === "Success") {
        setLoading(false);
        setSuccessModal(true);
      } else {
        setLoading(false);
        setFailureModal(true)
      }
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  async function fetchPreLoading() {
    await commonStore.loadCities();
    setLoading(false);
    const membershipPlanList = await memberStore.getMembershipPlan(0);
    setMemberPlanList([...membershipPlanList])
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoading();
      isCurrenPage.current = false
    }
    return () => { }
  }, []);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        {
          isLoading ? <ProgressBar /> : null
        }
        <div className='hrBox'>
          <h3>New Member Registration</h3>
        </div>
        <div className='inputBoxLists'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Date <span>*</span></label>
                <input type="date" style={{ width: "100%" }} value={memberDetail.dateofRegn = memberDetail.dateofRegn ?? new Date().toISOString().substr(0, 10)} name='dateofRegn'
                  placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" onChange={handleInputChange} onKeyDown={(event) => event.preventDefault()}></input>
                {errors.dateofRegn && <p style={{ color: 'red' }}>{errors.dateofRegn}</p>}
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-8'>
              <div className='inputBox'>
                <label>Member / Business Name <span>*</span></label>
                <input type="text" className='' style={{ width: "100%" }}
                  value={memberDetail.memberName} name='memberName'
                  onChange={handleInputChange}
                  placeholder='Member / Business Name..'></input>
                {errors.memberName && <p style={{ color: 'red' }}>{errors.memberName}</p>}
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-4'>
              <div className='inputBox'>
                <label>Gender </label>
                <select className="form-select" value={memberDetail.gender} onChange={handleInputChange} name="gender">
                  <option>Not Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Not Applicable</option>
                </select>
                {errors.gender && <p style={{ color: 'red' }}>{errors.gender}</p>}
                <div className='vertical-space-10'></div>
              </div>
            </div>
            <div className='col-md-5'>
              <div className='inputBox'>
                <label>Membership Plan <span>*</span></label>
                <AutoComplete options={removeDuplicates(memberPlanList, 'PlanName')} getOptionLabel='PlanName' emitOption={(option) => handleCutomerType(option)} placeholder='Choose Customer Plan' />
                {/* <select className="form-select" value={memberDetail?.customerCategory} onChange={handleCutomerType} name="customerCategory">
                  <option value="">Not Select</option>
                  {removeDuplicates(memberPlanList, 'PlanName')?.map((plan, index) => {
                    return (
                      <>
                        <option value={plan}>{plan?.PlanName}</option>
                      </>
                    )
                  })}
                </select> */}
                {errors.customerCategory && <p style={{ color: 'red' }}>{errors.customerCategory}</p>}
                <div className='vertical-space-10'></div>
              </div>
            </div>
            {
              memberDetail.customertype === 'B2B' ?
                <div className='col-md-12'>
                  <div className='inputBox'>
                    <label>GSTIN No<span>*</span> </label>
                    <input type="text" className='fullInput' value={memberDetail.gstIN = memberDetail.gstIN ?? ''} name='gstIN'
                      onChange={handleInputChange}
                      style={{ width: "100%" }} placeholder='Enter GSTIN no..'></input>
                    {errors.gstIN && <p style={{ color: 'red' }}>{errors.gstIN}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div> : null
            }

            <div className='col-md-12'>
              <div className='inputBox'>
                <label>Door No / Street </label>
                <input type="text" className='fullInput' value={memberDetail.doorNo} name='doorNo'
                  onChange={handleInputChange}
                  style={{ width: "100%" }} placeholder='Enter Address..'></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Area</label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  value={memberDetail.area} name='area'
                  onChange={handleInputChange}
                  placeholder='Enter Area..'></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>City </label>
                <Autocomplete size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={commonStore.loadCity}
                  getOptionLabel={(option: any) => option.name}
                  onChange={getStateCountry}
                  renderInput={(params: any) =>
                    <TextField  {...params} style={{ width: '21ch' }}
                      id="outlined-size-small"
                      color='info'
                      size="small"
                      type="text"
                      placeholder='Select City'
                      name='city'
                    />}
                />
                {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>State </label>
                <input type="text" className='fullInput' style={{ width: "100%" }} disabled
                  value={memberDetail.state} name='state'
                  onChange={handleInputChange} placeholder='Enter Area..'></input>
                <div className='vertical-space-10'></div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Country </label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  disabled
                  value={memberDetail.country} name='country'
                  onChange={handleInputChange}
                  placeholder='Enter Area..'></input>
                <div className='vertical-space-10'></div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>PIN Code <span>*</span> </label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  value={memberDetail.pinCode} name='pinCode'
                  onChange={handleInputChange}
                  placeholder='Enter Area..'></input>
                {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                <div className='vertical-space-10'></div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Mobile No <span>*</span></label>
                <input type="text" className='' maxLength={10} style={{ width: "100%" }}
                  value={memberDetail.mobileNo} name='mobileNo'
                  onChange={handleInputChange}
                  placeholder='Your Mobile No..' autoComplete='off'></input>
                {(errors?.mobileNo || errors?.mobileNoexists) && <p style={{ color: 'red' }}>{errors?.mobileNo ?? errors?.mobileNoexists}</p>}
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Pan Number</label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  value={memberDetail.panNo} name='panNo'
                  onChange={handleInputChange}
                  placeholder='Pan Number..'></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-6'>
              <div className='inputBox'>
                <label>Aadhar Number</label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  value={memberDetail.aadharNumber} name='aadharNumber'
                  onChange={(e) => e?.target?.value?.length < 13 && handleInputChange(e)}
                  placeholder='Enter Aadhar No..'></input>
              </div>
              {errors.aadharNumber && <p style={{ color: 'red' }}>{errors.aadharNumber}</p>}
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-4'>
              <div className='inputBox'>
                <label>Date Of Birth</label>
                <input type="date" style={{ width: "100%" }} placeholder={"DD-MMM-YYYY"}
                  value={memberDetail.dob = memberDetail.dob ?? getCurrentMonthFirstDate().currentDate} name='dob'
                  onChange={handleInputChange}
                  max={moment().subtract(0, 'years').format('YYYY-MM-DD')}
                  data-date="" data-date-format="DD-MMM-YYYY" onKeyDown={(event) => event.preventDefault()}></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-4'>
              <div className='inputBox'>
                <label>Age</label>
                <input type="text" className='fullInput' style={{ width: "100%" }}
                  value={memberDetail.age} name='age'
                  onChange={handleInputChange} placeholder='Age..' disabled></input>
                {errors.Age && <p style={{ color: 'red' }}>{errors.Age}</p>}
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-4'>
              <div className='inputBox'>
                <label>Anniversary Date</label>
                <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                  value={memberDetail.anniversaryDate} name='anniversaryDate'
                  onChange={handleInputChange}
                  data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='col-md-8'>
              <div className='inputBox'>
                <label>Email</label>
                <input type="text" className='fullInput'
                  value={memberDetail.email} name='email'
                  onChange={handleInputChange}
                  style={{ width: "100%" }} placeholder='Enter Email..'></input>
              </div>
              <div className='vertical-space-10'></div>
            </div>
          </div>
        </div>
        <div className='btnBox'>
          {!fromPOS && <button className='secondaryBtn' onClick={handleCancel}>List</button>}
          <button className='dfBtn' type='submit' onClick={saveMember}>Submit</button>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );

});

export default NewMember;
