import React, { useState, useEffect, useRef, } from 'react';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import Cookies from 'js-cookie';
import { IDamageEntryOutletDetails, IDamageTransfer } from './model';
import { saveValidation } from './validation';
import { removeDuplicates } from '../../common/shared/utils';


const DamageTransfer = observer((): JSX.Element => {

    const outletID = Number(Cookies.get('outletId'))

    const { commonStore, outwardtoOutletStore, damageEntryOutletStore } = useStores();

    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isSpinner, setSpinner] = useState(false);

    const { loadWarehouse, loadWarehouses, loadEmployee, loadEmployees } = commonStore;

    const [damageEntryItemList, setDamageEntryItemList] = useState<any[]>([]);
    const [damageTransferFields, setDamageTransferFields] = useState<IDamageTransfer>(new IDamageTransfer())

    console.log(damageEntryItemList, 'damageEntryItemList>>')
    function handleChangeMoveTo(event, type) {
        const { name, checked } = event?.target;
        if (checked && name) {
            setDamageTransferFields({ ...damageTransferFields, [name]: type })
        }
    }

    function handleInputchange(e) {
        const { name, value } = e.target;
        setDamageTransferFields({ ...damageTransferFields, [name]: value })
    }

    function handleAutoComplete(name, value) {
        if (name === "wId" && value != null) {
            setDamageTransferFields({ ...damageTransferFields, wId: value?.warehouseId })
        } else if (name === "createdBy" && value != null) {
            setDamageTransferFields({ ...damageTransferFields, empId: value?.employeeId })
        }
    }


    async function addAssignBatchNo(value, inx) {
        if (!damageTransferFields?.damageEntryOutletdetails?.some((obj, i) => i === inx)) {
            let damageTransferDetails: IDamageEntryOutletDetails = {};
            damageTransferDetails.damageBatchNo = value?.batch_no;
            damageTransferDetails.damageItemId = value?.itemid;
            damageTransferDetails.damageItemName = value?.itemname;
            damageTransferDetails.damageItemQty = value?.damage_stk?.toString();
            damageTransferDetails.damageBarcode = value?.Barcode;
            damageTransferDetails.damageDescription = '';
            damageTransferDetails.damageDetailId = damageTransferFields.damageId;
            damageTransferDetails.damageDetailsid = value?.damage_entry_details_id;
            damageTransferDetails.damageOutletid = value?.outlet_id

            if (!damageTransferFields?.damageEntryOutletdetails?.length) {
                damageTransferFields.damageEntryOutletdetails = [];
            }
            damageTransferFields?.damageEntryOutletdetails?.push(damageTransferDetails)
            setDamageTransferFields(damageTransferFields)
        } else {
            damageTransferFields?.damageEntryOutletdetails?.splice(inx, 1)
            setDamageTransferFields(damageTransferFields)
        }
    }

    async function saveAssignBatchNo(e) {
        e.preventDefault();
        let error: any = {}
        error = saveValidation(damageTransferFields)
        setErrors({ ...error })
        if (outletID && Object.keys(error)?.length === 0) {
            setLoading(true);
            const status = await damageEntryOutletStore.saveDamageTransferDetail(damageTransferFields);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleCancel = () => {
        navigate('/Admin/DamageTransfer/DamageTransferDetail');
    }
    const handleSuccessModalClose = () => {
        navigate('/Admin/DamageTransfer/DamageTransferDetail');
    }

    async function fetchAssignDefaultLoad() {
        await loadEmployee();
        const entryNos = await outwardtoOutletStore.getEntryNo('DamageTransfer');
        damageTransferFields.damageId = entryNos.entryNo + 1;
        setDamageTransferFields(damageTransferFields)
        const damageEntryItemList = await damageEntryOutletStore.loadDamageEntryOutletDetail(outletID)
        setDamageEntryItemList(damageEntryItemList)
        await loadWarehouse()
        setLoading(false)
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            fetchAssignDefaultLoad();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])


    return (
        <>
            {isLoading &&
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>}
            <div className='container'>
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Damage Transfer</h3>
                    </div>
                    <div className='inputBoxLists' >
                        <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Outlet</th>
                                        <th scope="col">Damage Entry No</th>
                                        <th scope="col">Damage Entry Date</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Batch No</th>
                                    </tr>
                                </thead>
                                <tbody style={{ verticalAlign: 'middle' }}>
                                    {damageEntryItemList?.slice()?.sort((a, b) => b.damage_entry_no - a.damage_entry_no)?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td><input type='checkbox' onChange={() => addAssignBatchNo(val, key)} name='option'></input></td>
                                                <td>{val?.Outletname}</td>
                                                <td>{val?.damage_entry_no}</td>
                                                <td>{moment(val?.damage_entry_date)?.format('DD-MMM-YYYY')}</td>
                                                <td>{val?.itemname}</td>
                                                <td>{val?.damage_stk}</td>
                                                <td>{val?.Unitname}</td>
                                                <td>{val?.batch_no}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='LgInputListsBox mr-left-0'>
                            <div className='vertical-space-10'></div>
                            <div className='row'>
                                {isSpinner &&
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div>}
                                <div className='vertical-space-10'></div>
                                <div className="col-md-12 flexImp">
                                    <div className='inputBox'>
                                        <input checked={damageTransferFields?.type === 'supplier'} type='radio' name='type' onChange={(event) => handleChangeMoveTo(event, 'supplier')} />
                                        <span style={{ marginLeft: '8px' }}>Move to Supplier</span>
                                    </div>
                                    <div className='inputBox'>
                                        <input checked={damageTransferFields?.type === 'warehouse'} type='radio' name='type' onChange={(event) => handleChangeMoveTo(event, 'warehouse')} />
                                        <span style={{ marginLeft: '8px' }}>Move to Warehouse</span>
                                    </div>
                                </div>
                                <div className='vertical-space-10'></div>
                                {damageTransferFields?.type === 'warehouse' && <div className='col-md-12'>
                                    <div className='inputBox'>
                                        <label>Select Warehouse <span>*</span></label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={loadWarehouses}
                                            getOptionLabel={(option: any) => option.warehouseName}
                                            onChange={(event, val) => handleAutoComplete('wId', val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params} style={{ width: '25ch' }}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Warehouse..'
                                                    name='wId'
                                                />}
                                        />
                                        {errors.wId && <p style={{ color: 'red' }}>{errors.wId}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>}
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>DC No <span>*</span></label>
                                        <input type="text"
                                            name='assignNo' disabled
                                            value={damageTransferFields.damageId}
                                            className='' style={{ width: "100%" }} placeholder='Entry No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Date <span>*</span></label>
                                        <input type="date"
                                            defaultValue={new Date().toISOString().substr(0, 10)}
                                            name='damageEntryDate' value={damageTransferFields.damageEntryDate = damageTransferFields.damageEntryDate ?? new Date().toISOString().substr(0, 10)}
                                            onChange={handleInputchange}
                                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-10'>
                                    <div className='inputBox'>
                                        <label>Created by </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={removeDuplicates(loadEmployees, 'employeeName')}
                                            // value={loadEmployees?.length > 0 && loadEmployees?.find((obj)=>obj?.employeeId == damageTransferFields?.empId)?.employeeName}
                                            onChange={(event, val) => handleAutoComplete('createdBy', val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params} style={{ width: '40ch' }}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Created by..'
                                                    name='employeeName'
                                                />}
                                        />
                                    </div>
                                    {errors.empId && <p style={{ color: 'red' }}>{errors.empId}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='inputBox'>
                                        <label>Remarks <span>*</span></label>
                                        <textarea className='' style={{ width: "100%" }}
                                            name='remarks'
                                            onChange={handleInputchange}
                                            placeholder='Remarks..'></textarea>
                                        {errors.remarks && <p style={{ color: 'red' }}>{errors.remarks}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='btnBox'>
                        <button className='secondaryBtn' onClick={handleCancel}>List</button>
                        <button className='dfBtn' type='submit'
                            onClick={saveAssignBatchNo}
                        >Submit</button>
                    </div>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/checked.png')} />
                            </div>

                            <h4>Succesfully Submitted</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>

                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Failed</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleFailureModalClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </>

    );

});

export default DamageTransfer;
