import React from 'react';
import logo from './logo.svg';
import {useNavigate} from "react-router-dom"
import {Button,Navbar, Nav,Container,NavDropdown, Form, Image, Carousel, InputGroup} from 'react-bootstrap';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Category() {
  const navigate = useNavigate();
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 }
  ];
  return (
    <div className="App">
      <Header/>
      <div className='category'>
        <div className='filterBox'>
          <div className='filter'>
            <div className='filterList'>
              <h4>Category</h4>
              <ul className='list-unstyled'>
                <li>Cleaning Supplies</li>
                <li>Electrical</li>
                <li>Hardware</li>
                <li>Kitchen & Bath Fixtures</li>
                <li>Power & Hand Tools</li>
                <li>Safety & Security</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Category;
