import React, { useState, useEffect, useDebugValue, useRef, } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { handleFindToDate, removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import { IStockAdjustment, IStockAdjustmentDetails, IStockAdjustmentModel } from './model';
import stockAdjustmentValidation, { saveValidation } from './validation';
import axios from "axios";

const StockAdjustment = observer((): JSX.Element => {
    const { commonStore, assignOfferDiscountStores, userCreationStore, posMasterStore, outwardtoOutletStore } = useStores();;
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});
    const { loadItemMaster, loadItemMasters, loadEmployee, loadSubcategories,
        loadManageOfferOrDiscount, loadWarehouse, loadOutlet, loadOutlets, loadWarehouses } = commonStore;
    const { loadPOSProductList } = posMasterStore;
    const { getUserCreation } = userCreationStore;
    const [isWarehouse, setWarehouse] = useState(false);
    const [isOutlet, setOutlet] = useState(false);
    const [isLess, setLess] = useState(false);
    const [isAdd, setAdd] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [employeeList, setEmployeeList] = useState<any>([])
    const { assignofferid } = useParams<{ assignofferid: string }>();
    const [selectedOption, setSelectedOption] = useState('warehouse');
    const [selectedOpt, setSelectedOpt] = useState('less');
    const [currentStock, setCurrentStock] = useState(0);
    const [outId, setOutId] = useState(0);   
    const [outData, setOutData] = useState<OutletOption[]>([]);
    const [productData, setProductData] = useState<ProdutOption[]>([]);
    
    //loadEmployees,
    interface ProdutOption{
        ItemId: string | number;    
        ItemName: string;   
    }
    interface OutletOption {
        OutId: string | number;  
        OutName: string;
    }

    async function fetchPreLoadngData() {
        setLoading(false)
        await Promise.all([
            loadItemMaster(),
            loadEmployee(),
            loadManageOfferOrDiscount(),
            outwardtoOutletStore.loadOutletItems(),
            loadWarehouse()
        ])
        const employeeList = await getUserCreation();
        setEmployeeList([...employeeList])
        console.log(employeeList, 'employeeList');
    }
    const isInitialRender = useRef(true);


    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadngData();
            getAssignOfferDet();
            fetchOutletName();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, []);
    const handleCancel = () => {
        navigate('/Admin/AssignOfferDiscount/list');
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/AssignOfferDiscount/list');
    }

    var radio_props = [{ val: "Warehouse", key: 'warehouse' }, { val: "Outlet", key: 'outlet' }];

    var radio_prop = [{ val: "Less", key: 'less' }, { val: "Add", key: 'add' }];

    const [options, setOptions] = useState<any>({});

    const [stockAdjustment, setStockAdjustment] = useState<IStockAdjustmentDetails>(new IStockAdjustmentDetails())
    const [assignOffer, setAssigOffer] = useState<IStockAdjustmentModel>(new IStockAdjustmentModel())
    const listRef = useRef<HTMLUListElement | null>(null);

    async function handleAutoComplete(name, value) {
        // if (name === "assignItemId" && value != null) {
        //     setStockAdjustment({ ...stockAdjustment, [name]: value?.itemid, })

        //     console.log("Current Outlet ID:", stockAdjustment.outletId);
        //     console.log("Current Item ID:", stockAdjustment.itemid);

        //     //const ItemwiseStock_Batch_currentstock = await posMasterStore.loadPOSProductList(outletId, ItemId);
        //     const posProductList = await posMasterStore.loadPOSProductList(stockAdjustment?.outletId);
        //     //const ItemwiseStock_Batch_currentstock = await posMasterStore.loadPOSProductList(stockAdjustment?.outletId);
        //     console.log(stockAdjustment, 'stockAdjustment');
        //     console.log(posProductList, 'posProductList');
        //     alert("Helo");
        //    // ItemwiseStock_Batch_currentstock
        console.log(stockAdjustment, 'stockAdjustment');

        if (name === "assignItemId" && value != null) {
            debugger
            console.log(value, 'value');

            // const newAdjustment = { 
            //     ...stockAdjustment, 
            //     [name]: value?.itemId,
            //     outletId: stockAdjustment.outletId 
            // };
            // setStockAdjustment(newAdjustment);
            setStockAdjustment({ ...stockAdjustment, [name]: value?.itemid })

            // const ItemwiseStock_Batch_currentstock = await posMasterStore.loadPOSProductList(stockAdjustment?.outletId, stockAdjustment?.itemid);

            // console.log(ItemwiseStock_Batch_currentstock, 'ItemwiseStock_Batch_currentstock');            

            // const parentNode = listRef.current;
            // if (parentNode) {               
            //     const childNode = Array.from(parentNode.children).find(child => child.textContent === 'Item 1');               
            //     if (childNode && parentNode.contains(childNode)) {
            //         parentNode.removeChild(childNode);
            //     }
            // }

        } else if (name === "outletId" && value != null) {
            // debugger             
            setStockAdjustment({ ...stockAdjustment, [name]: value?.outletid }) //assignCategoryName: value?.subCategoryName                 
        }
    }

    function addOfferToGrid(e) {
        e.preventDefault();
        let error: any = {};

        error = stockAdjustmentValidation(stockAdjustment, options);
        // if (!assignOffer!.assignedById) {
        //     error.assignedById = "Select Assigned By"
        // }
        setErrors(error);
        // if (Object.keys(error).length === 0) {
        //     stockAdjustment.assignEffectiveFromDate = moment(stockAdjustment.assignEffectiveFromDate).format('YYYY-MM-DD HH:mm:ss:SS');
        //     stockAdjustment.assignEffectiveToDate = moment(stockAdjustment.assignEffectiveToDate).format('YYYY-MM-DD HH:mm:ss:SS');
        //     if (stockAdjustment) {
        //         if (!assignOffer?.assignOffetDetails) {
        //             assignOffer.assignOffetDetails = [];
        //         }
        //         assignOffer?.assignOffetDetails?.push(stockAdjustment!)
        //         setAssigOffer(assignOffer)
        //         setStockAdjustment({
        //             ...stockAdjustment,
        //             assignOfferItemName: '', assignCategoryName: '',
        //             assignOfferName: '', assignEffectiveFromDate: new Date().toISOString(),
        //             assignEffectiveToDate: new Date().toISOString(),
        //         })
        //         setErrors({})
        //     }
        // }
    }
    
    const fetchOutletName = async () => {
        try {
            const response = await fetch('http://localhost:49882/api/Outlet/LoadOutlet');
            const data = await response.json();              
            const OutletOptions = data.map((item: any) => ({
                OutName: item.outletName,  
                OutId: item.outletid      
            }));    
            setOutData(OutletOptions); 
        } catch (error) {
            console.error('Error fetching Outlet data:', error);
        }
    };
    
    const fetchProductList = async (id) => {
        try {               
          const response = await axios.get(`http://localhost:49882/api/POS/GetItemwiseStockJson`, {
            params: {
              id: id
            }
          });
          const depOptions = response.data.map(item => ({
            ItemName: item.itemname,
            ItemId: item.itemid 
          }));
          setProductData(depOptions);  
        } catch (error) {
          console.error('Error fetching dropdown options:', error);
        }
      };   
    
    

    async function getAssignOfferDet() {
        if (assignofferid !== undefined) {
            const assignOfferList = await assignOfferDiscountStores.getAssignOfferDiscountsDetails(Number(assignofferid));
            console.log(assignOfferList, 'assignOfferList');
            setStockAdjustment({
                ...stockAdjustment,
                //  assignOffeId: assignOfferList[0]?.assignRedemptionId,
                // assignOffeDetailId: assignOfferList[0]?.assignRedemptionId,
                // assignOfferItemId: assignOfferList[0]?.assignRedemptionId,
                // assignOfferItemName: assignOfferList[0]?.assignRedemptionId,
                // assignCategoryId: assignOfferList[0]?.assignRedemptionId,
                // assignBillValue: assignOfferList[0]?.assignRedemptionId,
                // assignEveryBilling: assignOfferList[0]?.assignRedemptionId,
                // assignCategoryName: assignOfferList[0]?.assignRedemptionId,
                // assignDiscountOfferId: assignOfferList[0]?.assignRedemptionId,
                // assignOfferName: assignOfferList[0]?.assignOfferName,
                // assignEffectiveFromDate: assignOfferList[0]?.assignRedemptionId,
                // assignEffectiveToDate: assignOfferList[0]?.assignRedemptionId
            });
        }
    }

    function handleRemove(index) {
        assignOffer?.assignOffetDetails?.splice(index, 1)
        setAssigOffer(_.cloneDeep(assignOffer))
    }

    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        setOptions({ ...options, [name]: value })
        if (value === 'warehouse') {
            setWarehouse(true);
            setOutlet(false);
        }
        if (value === 'outlet') {
            setWarehouse(false);
            setOutlet(true);
        }
    }

    const handleRadioChanges = (event) => {
        const { name, value } = event.target;
        setOptions({ ...options, [name]: value })
        if (value === 'less') {
            setLess(true);
            setAdd(false);
        }
        if (value === 'add') {
            setLess(false);
            setAdd(true);
        }
    }

    async function saveAssignOfferDiscount(e) {
        // assignOffer.assinOfferType = options?.options;
        // assignOffer.approvalRequest = assignOffer?.approvalRequest ? assignOffer?.approvalRequest : "No";
        let error: any = {}
        // error = saveValidation(assignOffer)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            setLoading(true);
            const status = await assignOfferDiscountStores.saveAssignOfferDiscount(assignOffer);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    // const handleOutletItems = async (name, event, val) => {
    //     if (val != null) {
    //       setStockAdjustment({ ...stockAdjustment, [name]: val.outletid });
    //     }
    // }

    const handleOutletItems = (event) => {
        const ddoutid= event.target.value;
        setOutId(ddoutid); 
        alert(outId);
        console.log('Selected Outlet ID:', ddoutid); 
        fetchProductList(outId) ;          
      };  
     
    // const handleOutletItems = async (name, event, val) => {
    //     if (val != null) {
    //         console.log('Selected Value:', val);  
    //         console.log('Selected Outlet ID:', val.outletid);      
    //         setOutId(val.outletid);
    //         alert(outId);
    //         setStockAdjustment({ 
    //             ...stockAdjustment, 
    //             [name]: val.outletid 
    //         });
    //         alert(stockAdjustment.outletId);
    //     } else {
    //         console.log('No outlet selected or value is null');
    //     }
    // }
    

    const handleItems = async (name, event, val) => {
        if (val != null) {
          setStockAdjustment({ ...stockAdjustment, [name]: val.itemid });  
          const stockapival = {
            outletId: stockAdjustment?.outletId,
            itemid:  val.itemid
          } 
          console.log(stockapival,'stockapival');
          
        //   const ItemwiseStock_Batch_currentstock = await outwardtoOutletStore.getItemwiseStockPOS(stockapival);
        //   setCurrentStock(ItemwiseStock_Batch_currentstock?.custock)
        //   console.log(ItemwiseStock_Batch_currentstock, 'ItemwiseStock_Batch_currentstock');  
           const ItemwiseStock_Batch_currentstock = await outwardtoOutletStore.getItemwiseStockPOS(`/api/Products/ItemwiseStock_Batch_currentstock?outletId=${stockapival.outletId}&itemid=${stockapival.itemid}`);

            //const ItemwiseStock_Batch_currentstock = await outwardtoOutletStore.getItemwiseStockPOS(outletId,itemid);
            console.log("API Response:", ItemwiseStock_Batch_currentstock); 

            if (ItemwiseStock_Batch_currentstock && ItemwiseStock_Batch_currentstock.custock !== undefined) {
                setCurrentStock(ItemwiseStock_Batch_currentstock.custock);
            } else {
                console.warn("custock not found in response");
                setCurrentStock(0); 
            }
        
            const itemToFind = "outletid"; 

                const parentNode = listRef.current;
                if (parentNode) {
                   
                    const childNode = parentNode.querySelector(`[data-outletid="${itemToFind}"]`); 
                    
                    if (childNode && parentNode.contains(childNode)) {
                        parentNode.removeChild(childNode);  
                    } else {
                        console.log(`Child with outletid "${itemToFind}" not found`);
                    }
                }


        }
    }

    console.log(stockAdjustment,'stockAdjustment');

    useEffect(() => {
        const defaultEvent = { target: { value: selectedOption } };
        handleRadioChange(defaultEvent);
    }, []);

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>

                        <div className='container'>

                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox LgInputField'>
                                <div className='hrBox'>
                                    <h3>Stock Adjustment Entry</h3>
                                </div>
                                <div className='inputBoxLists pd-b-0'>
                                    <div className='LgInputListsBox mr-left-0'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Entry No </label>
                                                    <input type="text" className='' style={{ width: "100%" }}
                                                        //   onChange={handleInputchnage}
                                                        name="oMId"
                                                        //   value={OutletManagerDetails.oMId = outletManagerStore.entryNo.entryNo + 1}
                                                        placeholder='Entry No..' disabled></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Date <span>*</span></label>
                                                    <input
                                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                                        // value={OutletManagerDetails.date = OutletManagerDetails?.date ?? new Date().toISOString().substr(0, 10)}
                                                        type="date" style={{ width: "100%" }}
                                                        //  onChange={handleInputchnage}
                                                        name="date"
                                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                                    {errors.date && <p style={{ color: 'red' }}>{errors.date}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            {radio_props.map((val, key) =>
                                                <div className='col-md-3' >
                                                    <div className='inputBox'>
                                                        <label>{val.val}</label>
                                                        <input type='radio' className='' onChange={handleRadioChange} value={val.key} name='options'></input>
                                                    </div>
                                                </div>
                                            )}
                                            {errors.options && <p style={{ color: 'red' }}>{errors.options}</p>}
                                            {isWarehouse ?
                                                <div className='col-md-12'>
                                                    <div className='inputBox'>
                                                        <label>Warehouse <span>*</span></label>
                                                        <AutoComplete placeholder='Select Warehouse..' clsName='full-width' getOptionLabel='warehouseName'
                                                            emitOption={(option: any) => handleAutoComplete("warehouseId", option)} options={loadWarehouses} />
                                                        {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div> : isOutlet ?
                                                    <div className='col-md-12'>
                                                        <div className='inputBox'>
                                                            <label>Outlet<span>*</span></label>
                                                            {/* <AutoComplete
                                                                //   value={stockAdjustment?.assignOfferItemName}
                                                                clsName="full-width"
                                                                getOptionLabel="outletName"
                                                                options={loadOutlets}
                                                                emitOption={(option: any) => handleAutoComplete("outletid", option)}
                                                                placeholder="Select Outlet..."
                                                            /> */}
                                                            {/* <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                                                                emitOption={(val) => handleOutletItems('outletId', {}, val)} options={outwardtoOutletStore?.outletItemDetails} />
                                                            {errors.assignCategoryId && <p style={{ color: 'red' }}>{errors.assignCategoryId}</p>} */}
                                                            {/* <AutoComplete 
                                                                placeholder='Select Outlet..' 
                                                                clsName='full-width' 
                                                                getOptionLabel='outletName'  
                                                                emitOption={(val) => handleOutletItems('outletId', {}, val)}  
                                                                options={outwardtoOutletStore?.outletItemDetails}  
                                                            /> */}
                                                            {/* {errors.assignCategoryId && <p style={{ color: 'red' }}>{errors.assignCategoryId}</p>} */}
                                                             
                                                             <select className="form-control" id="outletName" onChange={handleOutletItems}>
                                                             <option value="">--Select OutletName--</option>
                                                                {outData.length > 0 ? (
                                                                    outData.map((item) => (
                                                                        <option key={item.OutId} value={item.OutId}>
                                                                            {item.OutName}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">Loading Outlets...</option>
                                                                )}
                                                              </select>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div> : null
                                            }
                                            <div className="col-md-12">
                                                <div className="inputBox">
                                                    <label>
                                                        Product <span>*</span>
                                                    </label>
                                                    {/* <AutoComplete
                                                        //  value={stockAdjustment?.assignOfferItemName}
                                                        clsName="full-width"
                                                        getOptionLabel="itemName"
                                                        options={loadItemMasters}
                                                        emitOption={(val) => handleItems('itemid', {}, val)}
                                                        placeholder="Select Product..."
                                                    />
                                                    {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>} */}
                                                    <select className="form-control" id="ProdName" >
                                                             <option value="">--Select ProductName--</option>
                                                                {productData.length > 0 ? (
                                                                    productData.map((item) => (
                                                                        <option key={item.ItemId} value={item.ItemId}>
                                                                            {item.ItemName}
                                                                        </option>
                                                                    ))   
                                                                ) : (
                                                                    <option value="">Loading Outlets...</option>
                                                                )}
                                                              </select>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='row'>

                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Current Stock</label>
                                                        <input
                                                        value={currentStock}
                                                            type="text"
                                                            className=''
                                                            style={{ width: "100%" }}
                                                            name="oMId"
                                                            placeholder='Current Stock..'
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                            </div>
                                            {radio_prop.map((val, key) =>
                                                <div className='col-md-3' >
                                                    <div className='inputBox'>
                                                        <label>{val.val}</label>
                                                        <input type='radio' className='' onChange={handleRadioChanges} value={val.key} name='options'></input>
                                                    </div>
                                                </div>
                                            )}
                                            {(isLess) ? (
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="inputBox">
                                                            <label>Barcode</label>
                                                            <AutoComplete
                                                                //  value={stockAdjustment?.assignOfferItemName}
                                                                clsName="full-width"
                                                                getOptionLabel="itemName"
                                                                options={loadItemMasters}
                                                                emitOption={(option: any) => handleAutoComplete("assignItemId", option)}
                                                                placeholder="Select Product..."
                                                            />
                                                        </div>
                                                        <div className="vertical-space-10"></div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="inputBox">
                                                            <label>
                                                                Batch No <span>*</span>
                                                            </label>
                                                            <AutoComplete
                                                                //  value={stockAdjustment?.assignOfferItemName}
                                                                clsName="full-width"
                                                                getOptionLabel="itemName"
                                                                options={loadItemMasters}
                                                                emitOption={(option: any) => handleAutoComplete("assignItemId", option)}
                                                                placeholder="Select Product..."
                                                            />
                                                        </div>
                                                        <div className="vertical-space-10"></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="inputBox">
                                                            <label>Barcode</label>
                                                            <input
                                                                type="text"
                                                                className=""
                                                                style={{ width: "100%" }}
                                                                name="altBarcode"
                                                                placeholder="Enter Alternative Barcode..."
                                                            />
                                                        </div>
                                                        <div className="vertical-space-10"></div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="inputBox">
                                                            <label>
                                                                Batch No <span>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=""
                                                                style={{ width: "100%" }}
                                                                name="altBatchNo"
                                                                placeholder="Enter Alternative Batch No..."
                                                            />
                                                        </div>
                                                        <div className="vertical-space-10"></div>
                                                    </div>
                                                </div>
                                            )}

                                            {/* <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Barcode</label>
                                                    <input type="text" className='' style={{ width: "100%" }}
                                                        //   onChange={handleInputchnage}
                                                        name="oMId"
                                                        //   value={OutletManagerDetails.oMId = outletManagerStore.entryNo.entryNo + 1}
                                                        placeholder='Entry No..'></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Batch No <span>*</span></label>
                                                    <input type="text" className='' style={{ width: "100%" }}
                                                        //   onChange={handleInputchnage}
                                                        name="oMId"
                                                        //   value={OutletManagerDetails.oMId = outletManagerStore.entryNo.entryNo + 1}
                                                        placeholder='Entry No..'></input>

                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div> */}
                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label>Qty Less</label>
                                                    <input type="text" className='' style={{ width: "100%" }}
                                                        //   onChange={handleInputchnage}
                                                        name="oMId"
                                                        //   value={OutletManagerDetails.oMId = outletManagerStore.entryNo.entryNo + 1}
                                                        placeholder='Qty Less..' ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <button className='dfBtn pd-0-12 full-width mr-1 ' type='submit' onClick={addOfferToGrid} >ADD</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className=''>
                                        <div className='tableBox'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Barcode</th>
                                                        <th scope="col">Batch No</th>
                                                        <th scope="col">Qty</th>
                                                        <th scope="col">Add /Less</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assignOffer?.assignOffetDetails?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                {/* <td>{moment(val.assignEffectiveFromDate).format("DD-MMM-YYYY HH:MM")}</td>
                                                                <td>{moment(val.assignEffectiveToDate).format("DD-MMM-YYYY HH:MM")}</td> */}
                                                                <td>
                                                                    <button onClick={() => handleRemove(key)} style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'>Remove</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                            {errors?.assignOffetDetails && <p style={{ color: 'red' }}>{errors?.assignOffetDetails}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleCancel}>List</button>
                                    <button className='dfBtn' type='submit' onClick={saveAssignOfferDiscount} >Submit</button>
                                </div>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    );

});

export default StockAdjustment;
