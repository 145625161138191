import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from "mobx-react";
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IWareHouseManager } from './model';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import warehouseManagerValidation from './validation';
import { removeDuplicates } from '../../common/shared/utils';


const AssignWarehouseManager = observer((): JSX.Element => {

  const [WareHouseManagerDetails, setWareHouseManagerDetails] = useState<IWareHouseManager>({});
  const { wareHouseManagerStore, commonStore, userCreationStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])

  const {
    loadWarehouse, loadWarehouses, loadEmployee, loadEmployees,
  } = commonStore;

  const handleAccessModalClose = () => setAccessModal(false);

  const handleWareHouseValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setWareHouseManagerDetails({ ...WareHouseManagerDetails, [name]: val.warehouseId, ['warehouseName']: val.warehouseName });
    }
    else {
      WareHouseManagerDetails.warehouseName = '';
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setWareHouseManagerDetails({ ...WareHouseManagerDetails, [name]: val.employeeId, ['employeeName']: val.employeeName });
    }
    else {
      WareHouseManagerDetails.employeeName = '';
    }
  }

  const saveWareHouseManager = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = warehouseManagerValidation(WareHouseManagerDetails);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setLoading(true);
      WareHouseManagerDetails.wmId = wareHouseManagerStore.entryNo.entryNo + 1
      const status = await wareHouseManagerStore.saveWareHouseManager(WareHouseManagerDetails);

      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true);

      }
      else {
        setLoading(false);
        setFailureModal(true);

      }
    }

  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignWarehouseManager/AssignWarehouseManagerDetail');
  }
  const handleInputchnage = (e) => {
    const { name, value } = e.target;


    setWareHouseManagerDetails({ ...WareHouseManagerDetails, [name]: value });
  };

  const handleChangeview = () => {

    navigate('/Admin/AssignWarehouseManager/AssignWarehouseManagerDetail');
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    await Promise.all([loadWarehouse(), loadEmployee(), wareHouseManagerStore.getEntryNo('AssignWareHouseManager')])
    const userRights = await Promise.all([userCreationStore?.getUserRight('Assign_warehouse_managers')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage?.current) {
      setLoadModal(true);
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
  }, []);

  if (isLoadModal) {
    return (
      <div className="container">
        {isAccessModal && <h1>Access Denied</h1>}
        <Modal show={isAccessModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <div className='container'>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> : null
        }
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Assign Warehouse Manager</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Entry No </label>
                  <input type="text" className='' style={{ width: "100%" }} disabled
                    name="Entry" value={WareHouseManagerDetails.wmId = wareHouseManagerStore.entryNo.entryNo + 1}
                    placeholder='Entry No..' ></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input type="Date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    value={WareHouseManagerDetails.Date = WareHouseManagerDetails?.Date ?? new Date().toISOString().substr(0, 10)}
                    name="Date"
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                  {errors.Date && <p style={{ color: 'red' }}>{errors.Date}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Warehouse <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={loadWarehouses}
                    getOptionLabel={(option: any) => option.warehouseName}
                    onChange={(event, val) => handleWareHouseValue('wareHouseId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select WareHouse Name..'
                        name='warehouseName'
                      />}
                  />
                  {errors.wareHouseId && <p style={{ color: 'red' }}>{errors.wareHouseId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Manager <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={removeDuplicates(loadEmployees, 'employeeName')}
                    getOptionLabel={(option: any) => option.employeeName}
                    onChange={(event, val) => handleEmployeeValue('employeeId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Manager..'
                        name='employeeName'
                      />}
                  />
                  {errors.employeeId && <p style={{ color: 'red' }}>{errors.employeeId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Effective Form <span>*</span></label>
                  <input type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    value={WareHouseManagerDetails.EffectiveFrom = WareHouseManagerDetails?.EffectiveFrom ?? new Date().toISOString().substr(0, 10)}
                    name="EffectiveFrom"
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleChangeview}>List</button>
            <button className='dfBtn' type='submit' onClick={saveWareHouseManager}>Submit</button>
          </div>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../../image/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../../image/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
});

export default AssignWarehouseManager;
