export class PackingPlanSave {
    packingid?: number;
    packingdate?: string;
    teamid?: number;
    machineid?: number;
    productName?: string;
    productid?: number;
    expiryDays?: string;
    qty?: number;
    unitid?: string;
    itemUOM?: string;
    batchNo?: string;
}

export class IPrintSizeMaster {
    height?: number = 0
    width?: number = 0
    unit?: string = ""
}