import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from "mobx-react";
import { IDepartment } from './model';
import useStores from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import departmentValidation from './validation';
import { Modal, Image, Button } from 'react-bootstrap';

const DepartmentMaster = observer((): JSX.Element => {
  const [departmentDetails, setdepartmentDetails] = useState<IDepartment>({});
  const { departmentStore, employeeStore } = useStores();
  const [errors, setErrors] = useState<any>({})
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const handleChangeview = () => {
    navigate('/Admin/departmentmaster/list');
  }

  const { departmentId } = useParams();

  const saveDepartment = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = departmentValidation(departmentDetails)
    setErrors(error)

    if (Object.keys(error).length === 0) {
      setLoading(true);
      let status: string = '';
      if (departmentId !== undefined) {
        status = await departmentStore.updateDepartment(departmentDetails);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await departmentStore.saveDepartment(departmentDetails);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  };

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setdepartmentDetails({ ...departmentDetails, [name]: value });
  };
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/departmentmaster/list');
  }

  async function mapItemData(DepartemntDels) {

    departmentDetails.departmentId = DepartemntDels.departmentId
    departmentDetails.department = DepartemntDels.department;
    departmentDetails.description = DepartemntDels.description;
    setdepartmentDetails(departmentDetails);
  }

  async function fetchPreLoadingData() {
    if (departmentId !== undefined) {
      const data = await departmentStore.DepartmentDetails(parseInt(departmentId!));
      mapItemData(data);
    }
    else {
      const data = await employeeStore.getEntryNo('DepartmentMaster');
      if (data !== undefined) {
        departmentDetails.departmentId = data.entryNo;
        setdepartmentDetails(departmentDetails);
      }
    }
    setLoading(false)
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoadingData();
      isCurrenPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Department Master</h3>
        </div>
        <div className='inputBoxLists'>
          <div className='inputBox'>
            <label>Department Id</label>
            <input type="text" className='' name='departmentId'
              value={departmentDetails.departmentId} disabled
              style={{ width: "80%" }} placeholder='Employee ID..'></input>
            <div className='vertical-space-10'></div>
          </div>
          <div className='inputBox'>
            <label>Department <span>*</span></label>
            <input type="text" className='' style={{ width: "80%" }}
              onChange={handleInputchnage}
              name="department"
              value={departmentDetails.department}
              placeholder='Enter Department..'></input>
            {errors.department && <p style={{ color: 'red' }}>{errors.department}</p>}
            <div className='vertical-space-10'></div>
          </div>
          <div className='inputBox'>
            <label>Description</label>
            <textarea className='' style={{ width: "100%" }}
              onChange={handleInputchnage}
              name="description"
              value={departmentDetails.description = departmentDetails.description ?? ""}
              placeholder='Enter Description..'></textarea>
            {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>}
          </div>
        </div>
        <div className='btnBox'>
          <button className='secondaryBtn' onClick={handleChangeview}>List</button>
          <button className='dfBtn' type='submit' onClick={saveDepartment}> Submit</button>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../../image/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../../image/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );

});

export default DepartmentMaster;
